import { SmallContentWithScrollbar, WaitingForBlockchain } from 'src/components';
import React from 'react';

const AddingToBlockchainProgressScreen = () => (
  <SmallContentWithScrollbar>
    <WaitingForBlockchain />
  </SmallContentWithScrollbar>
);

export default AddingToBlockchainProgressScreen;
