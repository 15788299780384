import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from '@material-ui/core';
import { AvatarAndTextItem, HeaderButton, HeaderUserDrawer } from 'src/components';

import { useStyles } from './styles';

const HeaderUserButton = ({
  login,
  userAvatar,
  organisation,
  organisationBadge,
  immediatelyOpenUserProfile,
  setImmediatelyOpenUserProfile,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const smallScreen = useMediaQuery(({ breakpoints }) => breakpoints.down('sm'));
  const desktopDevice = useMediaQuery(({ breakpoints }) => breakpoints.up('lg'));

  const [opened, setOpened] = useState(false);

  const isImmediatelyOpen =
    immediatelyOpenUserProfile !== undefined && typeof setImmediatelyOpenUserProfile === 'function';
  const isOpen = isImmediatelyOpen ? immediatelyOpenUserProfile : opened;

  const handleHeaderUserDrawerToggle = (event) => {
    if (!isImmediatelyOpen) {
      setOpened(!opened);
    } else {
      setImmediatelyOpenUserProfile();
    }
    if (event) event.preventDefault();
  };

  return (
    <>
      <HeaderButton
        variant={desktopDevice ? 'extended' : 'round'}
        icon={
          <AvatarAndTextItem
            size={desktopDevice ? 'large' : 'medium'}
            text={login}
            description={desktopDevice ? organisation : null}
            badgeChildren={organisation}
            mainImageSrc={userAvatar}
            badgeImageSrc={organisationBadge}
            classes={{ labels: classes.rootAvatarLabels, text: classes.rootAvatarLabelsMain }}
            showSettingsIcon={desktopDevice}
          />
        }
        onClick={handleHeaderUserDrawerToggle}
        classes={{ root: classes.root }}
        tooltip={t('userDrawer.title')}
      />
      <HeaderUserDrawer
        showMainHeader={smallScreen}
        smallScreen={smallScreen}
        opened={isOpen}
        handleToggle={handleHeaderUserDrawerToggle}
      />
    </>
  );
};

HeaderUserButton.propTypes = {
  login: PropTypes.string.isRequired,
  userAvatar: PropTypes.string,
  organisation: PropTypes.string,
  organisationBadge: PropTypes.string,
  immediatelyOpenUserProfile: PropTypes.bool,
  setImmediatelyOpenUserProfile: PropTypes.func,
};

export default HeaderUserButton;
