import React, { useCallback, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  DataTable,
  FullPageLoader,
  NoDocumentsBanner,
  ResponsiveDateRangePicker,
  SelectTemplateDialog,
} from 'src/components';
import { FileAdd as AddFileIcon, Plus as AddIcon } from 'src/components/CustomIcon';
import { SpeedDial } from 'src/pages/DrivePage/subcomponents';
import { RequestStatus } from 'src/helpers/reduxReuquest.util';
import { useStyles } from './styles';
import RecentPageWebsocket from './RecentPageWebsocket';
import documentsActions from '../../redux/actions/documents.actions';
import { FileColsConfig } from '../../helpers/fileAndFolderColsConfig';
import usePrevious from '../../helpers/hooks/usePrevious';
import ContactHelper from '../../helpers/contactHelper';
import { IdentitySelector } from '../../redux/selectors/identity.selector';

const RecentPage = () => {
  const filesTableTitleRef = useRef(null);
  const classes = useStyles();
  const smallScreen = useMediaQuery(({ breakpoints }) => breakpoints.down('sm'));
  const {
    fetchDocumentsStatus,
    documents,
    hasMoreDocuments,
    defaultSortKey,
    defaultSortOrder,
  } = useSelector((state) => state.documentsStore, undefined);
  const workspaceId = useSelector(IdentitySelector.selectWorkspaceId, undefined);
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [sortKey, setSortKey] = useState(defaultSortKey);
  const [sortOrder, setSortOrder] = useState(defaultSortOrder);
  const [page, setPage] = useState(0);
  const [createDocumentOpen, setCreateDocumentOpen] = useState(false);
  const [dateValue, setDateValue] = useState([null, null]);
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);
  const filesColConfig = FileColsConfig(t, false, false, false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const hasAnyDocument = documents.length > 0;
  const filesPageLimit = 40;
  const iconWidth = 40;
  const prevState = usePrevious({
    sortKey,
    sortOrder,
    page,
    dateFrom,
    dateTo,
    documents,
  });

  const isLoadingFiles = fetchDocumentsStatus === RequestStatus.PENDING;

  const allLoaded = !isLoadingFiles;

  const createDocumentDialog = {
    open: () => {
      setCreateDocumentOpen(true);
    },
    close: () => {
      setCreateDocumentOpen(false);
    },
  };

  const addDocumentAction = {
    icon: <AddFileIcon />,
    name: t(`sideBar.addDocument.${smallScreen ? 'longText' : 'shortText'}`),
    onClick: createDocumentDialog.open,
  };

  const goToDocument = (row) => {
    if (row?.documentType === 'dsl') {
      history.push(`/certificate/${row.id}`);
    } else {
      history.push(`/document/${row.id}`);
    }
  };

  const onLoadMoreFiles = () => {
    const isLoadingMoreFiles = fetchDocumentsStatus === RequestStatus.PENDING && hasMoreDocuments;
    if (!isLoadingMoreFiles && hasMoreDocuments && !isLoadingMore) {
      setPage(page + 1);
      setIsLoadingMore(true);
    }
  };

  const loadFilesRows = useCallback(() => {
    const dateFilter =
      dateFrom && dateTo
        ? {
            from: dateFrom,
            to: dateTo,
          }
        : false;

    if (workspaceId)
      dispatch(
        documentsActions.fetchAnyDocuments(
          workspaceId,
          filesPageLimit,
          page * filesPageLimit,
          'updatedAt',
          'DESC',
          page > 0,
          'updatedAt',
          dateFilter
        )
      );
  }, [page, workspaceId, dateFrom, dateTo, dispatch]);

  useEffect(() => {
    localStorage.setItem('lastMainPage', 'recent');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      documents.length > 0 &&
      prevState &&
      (prevState?.sortKey !== sortKey || prevState?.sortOrder !== sortOrder)
    ) {
      loadFilesRows();
    }
  }, [prevState, sortKey, sortOrder, documents, loadFilesRows]);

  useEffect(() => {
    if (prevState && prevState?.page !== page) {
      loadFilesRows();
    }
  }, [prevState, page, loadFilesRows]);

  useEffect(() => {
    loadFilesRows();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspaceId]);

  useEffect(() => {
    if (prevState && (prevState?.dateFrom !== dateFrom || prevState?.dateTo !== dateTo)) {
      loadFilesRows();
    }
  }, [loadFilesRows, dateFrom, dateTo, prevState]);

  useEffect(() => {
    if (
      documents &&
      JSON.stringify(documents) !== JSON.stringify(prevState?.documents) &&
      documents.length > 0
    ) {
      const missingContacts = [];
      documents.forEach((document) => {
        const matchedIdentity = ContactHelper.getEntityData(document.author);
        if (!matchedIdentity && !ContactHelper.checkIfIsInProgress(document.author)) {
          missingContacts.push(document.author);
        }
      });
      if (missingContacts.length > 0) {
        ContactHelper.addContactsToQueue(missingContacts);
      }
    }
  }, [documents, prevState]);

  return (
    <>
      <RecentPageWebsocket sortKey={sortKey} sortOrder={sortOrder} />
      {allLoaded && smallScreen && (
        <SpeedDial
          primaryAction={addDocumentAction}
          tooltip={addDocumentAction.name}
          icon={<AddIcon />}
        />
      )}

      <div className={classes.root}>
        <ResponsiveDateRangePicker
          classes={{
            root: classes.datePickerRoot,
          }}
          value={dateValue}
          setValue={(newValue) => setDateValue(newValue)}
          onApply={([dateFrom, dateTo]) => {
            const finalDateTo = dateTo ? new Date(dateTo).setHours(23, 59, 59, 999) : Date.now();
            setDateFrom(new Date(dateFrom).getTime());
            setDateTo(finalDateTo);
          }}
        />

        {hasAnyDocument && filesColConfig && (
          <DataTable
            classes={{
              headerWrapper: clsx(classes.header),
            }}
            title={t('drivePage.documentsTable.title')}
            rows={documents}
            cols={filesColConfig}
            withTableHeader
            iconWidth={iconWidth}
            headerTextWidthLikeIcon={false}
            titleRef={filesTableTitleRef}
            onClick={(row) => {
              if (smallScreen) {
                goToDocument(row);
              }
            }}
            onDblClick={(row) => {
              if (!smallScreen) {
                goToDocument(row);
              }
            }}
            onLongPress={() => undefined}
            sortBy={sortKey}
            sortOrder={sortOrder}
            onChangeSort={({ sortOrder, sortKey }) => {
              setSortKey(sortKey);
              setSortOrder(sortOrder);
              setPage(0);
            }}
            isLoading={isLoadingFiles}
            isLoadingMore={isLoadingFiles}
            hasMoreItems={hasMoreDocuments} // TODO: remove !
            paginationType="infinite"
            defaultIcon="file"
            smallScreen={smallScreen}
            disableHeader={smallScreen}
            onLoadMore={() => {
              onLoadMoreFiles();
            }}
            noItems={{
              label: t('drivePage.documentsTable.noItems'),
            }}
          />
        )}
      </div>

      {allLoaded && !hasAnyDocument && (
        <NoDocumentsBanner
          header={t('noDocuments.recent.header')}
          headerVariant={smallScreen ? 'h3' : 'h2'}
          description={t('noDocuments.recent.description')}
          showButton={!smallScreen}
          buttonText={t('noDocuments.button')}
          onClick={createDocumentDialog.open}
          classes={{ root: classes.noDocumentsBanner }}
        />
      )}

      <SelectTemplateDialog open={createDocumentOpen} onClose={createDocumentDialog.close} />

      <FullPageLoader
        open={!allLoaded && !hasMoreDocuments}
        classes={{ root: classes.loader }}
        transitionDuration={{ enter: 100, exit: 300 }}
      />
    </>
  );
};

export default RecentPage;
