import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  ({ breakpoints, palette, spacing, shape, transitions }) =>
    createStyles({
      root: {
        fontFamily: 'inherit',
        padding: 0,
        color: palette.text.primary,
        minHeight: 48,
        '& svg': {
          marginRight: 0,
        },
        '&:not(:hover):not(:focus):not(:active)': {
          '& $iconWrapper': {
            color: palette.grey.darkest,
          },
        },
      },
      mediumSize: {
        minHeight: 40,
      },
      roundVariant: {},
      extendedVariant: {
        [breakpoints.up('sm')]: {
          height: 'auto',
          padding: spacing(1),
          borderRadius: shape.borderRadius,
        },
        [breakpoints.up('lg')]: {
          paddingLeft: spacing(1.5),
          paddingRight: spacing(1.5),
        },
      },
      iconWrapper: {
        [breakpoints.up('sm')]: {
          '& + *': {
            marginLeft: spacing(1),
          },
        },
        [breakpoints.up('lg')]: {
          '& + *': {
            marginLeft: spacing(1.5),
          },
        },
        [breakpoints.up('xl')]: {
          '& + *': {
            marginLeft: spacing(2),
          },
        },
      },
      badge: {
        paddingLeft: 2.5,
        paddingRight: 2.5,
        boxShadow: `0 0 0 1px ${palette.background.paper}`,
        zIndex: 'auto',
      },
      dot: {
        backgroundColor: palette.primary.main,
      },
      additionalContent: {
        display: 'none',
        [breakpoints.up('sm')]: {
          display: 'block',
          '& + $arrow': {
            marginLeft: spacing(0.75),
          },
          '&$arrow': {
            marginLeft: spacing(0.75),
          },
        },
      },
      textWrapper: {
        textAlign: 'left',
      },
      arrow: {
        fontSize: spacing(2),
        color: palette.text.disabled,
        transition: transitions.create('transform', {
          duration: transitions.duration.shorter,
          easing: transitions.easing.easeOut,
        }),
      },
      arrowExpanded: {
        color: palette.primary.main,
        transform: 'rotate(-180deg)',
      },
    }),
  { index: 0 }
);
