import React from 'react';
import createSvgIcon from '@material-ui/icons/utils/createSvgIcon';

export default createSvgIcon(
  <>
    <path
      id="path2"
      d="m 22,10.386094 v 9.379823 C 22,20.999788 20.999371,22.000167 19.765251,22 H 4.4706074 c 1.2341287,1.67e-4 2.2347078,-1.000212 2.2347078,-2.234083 V 10.386094 C 6.7051069,9.1521818 7.7054776,8.1518023 8.9393981,8.1520113 H 19.765251 C 20.999371,8.1518023 22,9.1519738 22,10.386094 Z"
      fill="#00b1ff"
    />
    <path
      id="path4"
      d="M 19.25877,7.696328 V 8.1514943 H 8.9386814 C 7.7047611,8.1512863 6.7043902,9.1516648 6.7045902,10.385578 v 9.379756 c 0,1.233954 -1.0005791,2.234333 -2.2346995,2.234083 H 4.2346995 C 3.0005792,21.999667 2,20.999288 2,19.765334 V 5.1226245 C 2,3.8884958 3.0005792,2.88833 4.2346995,2.8885358 h 5.0129703 c 0.6274432,0 1.2259292,0.2637643 1.6494132,0.7267369 l 1.521526,1.6648293 c 0.105913,0.1157373 0.255574,0.1819348 0.412568,0.1819348 h 4.192927 c 1.234119,-2e-4 2.234666,1.0001709 2.234666,2.2342912 z"
      fill="url(#paint0_linear) #365fff"
    />
    <defs id="defs16">
      <linearGradient
        id="paint0_linear"
        x1="0"
        y1="0.50488299"
        x2="22.8153"
        y2="21.1091"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#11B1FF" id="stop8" />
        <stop offset="1" stopColor="#005DFF" id="stop10" />
      </linearGradient>
      <clipPath id="clip0">
        <rect width="24" height="24" fill="white" id="rect13" />
      </clipPath>
    </defs>
  </>,
  'FolderColor'
);
