import React from 'react';

import { createStyles, makeStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { lighten } from '@material-ui/core/styles';
import { CertificateQuantityType } from '../../../models/certificate.model';

export const useStyles = makeStyles(
  ({ spacing, shape, palette }) =>
    createStyles({
      wrapper: {
        marginTop: -4,
        display: 'flex',
        alignItems: 'center',
        marginBottom: 24,
        padding: spacing(1, 2),
        width: '100%',
        minHeight: 48,
        justifyContent: 'space-between',
        borderRadius: shape.borderRadius,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: lighten(palette.warning.main, 0.9),
        backgroundColor: lighten(palette.warning.main, 0.95),
        color: palette.warning.main,
      },
    }),
  { index: 0 }
);

const MultipleInvitationWarningBar = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { certificateQuantityType } = useSelector(
    (state) => state.certificateCreatorStore,
    undefined
  );

  if (certificateQuantityType !== CertificateQuantityType.Multiple) return null;

  return <div className={classes.wrapper}>{t('inviteToDocument.multipleWarningText')}</div>;
};

export default MultipleInvitationWarningBar;
