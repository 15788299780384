import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import {
  Card,
  CardContent,
  LinearProgress,
  List,
  ListItem,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { ConfirmDialog, ExtendedGrid, FullScreenDialog } from 'src/components';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Delete as DeleteIcon } from 'src/components/CustomIcon';
import { useDispatch, useSelector } from 'react-redux';
import identityActions from 'src/redux/actions/identity.actions';
import documentActions from 'src/redux/actions/documents.actions';
import usePrevious from 'src/helpers/hooks/usePrevious';
import { useSnackbar } from 'notistack';
import { RequestStatus } from 'src/helpers/reduxReuquest.util';
import { HeaderAndButton, Search, UserItem } from '../../subcomponents';
import { DeleteUser, InviteUsers } from './editDialogs';
import { useStyles } from '../styles';

const Users = () => {
  const { t } = useTranslation();
  const smallScreen = useMediaQuery(({ breakpoints }) => breakpoints.down('sm'));
  const classes = useStyles();
  const dispatch = useDispatch();
  const [editedSubpage, setEditedSubpage] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
  const [actionUser, setActionUser] = React.useState({});
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(true);
  const [repeatCheckDocumentsUser, setRepeatCheckDocumentsUser] = useState(false);
  const [didMount, setDidMount] = useState(false);

  const activeIdentity = useSelector((state) => state.identityStore.currentIdentity);
  const workspaceUsers = useSelector((state) => state.workspaceUserStore.workspaceUsers);
  const workspaceUsersRequestStatus = useSelector(
    (state) => state.workspaceUserStore.fetchWorkspaceUsersStatus
  );
  const workspaceUsersHasNextPage = useSelector((state) => state.workspaceUserStore.hasNextPage);
  const workspaceUsersPageIndex = useSelector((state) => state.workspaceUserStore.pageIndex);
  const deleteUserFromWorkspaceStatus = useSelector(
    (state) => state.workspaceUserStore.deleteUserFromWorkspaceStatus
  );
  const inviteUsersToWorkspaceRequestStatus = useSelector(
    (state) => state.workspaceUserStore.inviteUsersToWorkspaceStatus
  );
  const searchWorkspaceUsersStatus = useSelector(
    (state) => state.workspaceUserStore.searchWorkspaceUsersStatus
  );

  const { fetchDocumentsStatus, documents } = useSelector((state) => state.documentsStore);
  const isPending =
    fetchDocumentsStatus === RequestStatus.PENDING ||
    deleteUserFromWorkspaceStatus === RequestStatus.PENDING;
  const prevState = usePrevious({ fetchDocumentsStatus });
  const { enqueueSnackbar } = useSnackbar();
  const openSnackbar = (type, content) => {
    const options = {
      variant: type,
      message: content,
    };
    enqueueSnackbar(JSON.stringify(options));
  };

  const userData = {
    identityId: ' - ',
    login: ' - ',
    email: ' - ',
    workspace: ' - ',
    groupAll: ' - ',
  };

  const handleBack = () => {
    setEditedSubpage(null);
  };

  if (activeIdentity) {
    userData.groupAll = activeIdentity.groups.find((obj) => obj.name === 'All');
    userData.identityId = activeIdentity.identity.identityId;
    userData.login = activeIdentity.identity.name;
    userData.email = activeIdentity.identity.email;
    userData.workspace = activeIdentity.workspace;
  }

  useEffect(() => {
    setDidMount(true);
    return () => {
      setDidMount(false);
    };
  }, []);

  useEffect(() => {
    setLoading(true);
    if (
      workspaceUsersRequestStatus === RequestStatus.IDLE &&
      !workspaceUsers.length &&
      workspaceUsersPageIndex === 0
    ) {
      if (userData.groupAll.groupId) {
        setLoading(true);
        dispatch(
          identityActions.getWorkspaceGroupUsers(userData.groupAll.groupId, workspaceUsersPageIndex)
        );
      }
    }
    if (
      workspaceUsersRequestStatus === RequestStatus.SUCCESS ||
      workspaceUsersRequestStatus === RequestStatus.ERROR
    ) {
      setLoading(false);
    }
  }, [
    dispatch,
    userData.groupAll.groupId,
    workspaceUsers.length,
    workspaceUsersPageIndex,
    workspaceUsersRequestStatus,
  ]);

  useEffect(() => {
    if (
      workspaceUsersRequestStatus === RequestStatus.SUCCESS ||
      workspaceUsersRequestStatus === RequestStatus.ERROR
    ) {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchWorkspaceUsersStatus]);

  useEffect(() => {
    if (deleteUserFromWorkspaceStatus !== RequestStatus.IDLE) {
      setActionUser({});
    }
  }, [deleteUserFromWorkspaceStatus]);

  useEffect(() => {
    if (inviteUsersToWorkspaceRequestStatus === RequestStatus.SUCCESS) {
      handleBack();
    }
  }, [inviteUsersToWorkspaceRequestStatus]);

  useEffect(() => {
    if (
      prevState?.fetchDocumentsStatus === RequestStatus.PENDING &&
      fetchDocumentsStatus === RequestStatus.IDLE &&
      actionUser.identityId &&
      didMount
    ) {
      if (!documents.length) {
        handleDeleteUser();
      } else {
        if (repeatCheckDocumentsUser) {
          showInfo();
        }
        setEditedSubpage('delete');
      }
      deleteDialogToggle.close();
      setRepeatCheckDocumentsUser(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prevState, fetchDocumentsStatus]);

  useEffect(() => {
    if (searchTerm.length > 0) {
      setLoading(true);
    } else if (searchWorkspaceUsersStatus !== RequestStatus.IDLE) {
      dispatch(identityActions.clearWorkspaceUsersStore());
    }
    const delayDebounceFn = setTimeout(() => {
      if (searchTerm.length > 0) {
        dispatch(
          identityActions.searchWorkspaceGroupUsers(userData.groupAll.groupId, 0, 50, searchTerm)
        );
      }
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  const deleteDialogToggle = {
    open: () => {
      setDeleteDialogOpen(true);
    },
    close: () => {
      setDeleteDialogOpen(false);
    },
  };

  const handleSetSearchTerm = (value) => {
    setSearchTerm(value);
  };

  const handleActionUser = (user) => {
    setActionUser(user);
  };

  const checkDeleteUserHasDocuments = () => {
    dispatch(
      documentActions.fetchAnyDocuments(
        userData.workspace.workspaceId,
        10,
        0,
        'updatedAt',
        'ASC',
        false,
        '',
        '',
        [actionUser.identityId]
      )
    );
  };

  const showInfo = () => {
    openSnackbar('info', t('workspacePage.users.checkDelete.infoText'));
  };

  const handleDeleteUser = (check) => {
    if (check) {
      setRepeatCheckDocumentsUser(true);
      checkDeleteUserHasDocuments();
    } else {
      deleteDialogToggle.close();
      handleBack();
      dispatch(
        identityActions.deleteUserFromWorkspace(
          userData.workspace.workspaceId,
          actionUser.identityId
        )
      );
    }
  };

  const editPages = {
    invite: {
      title: t('workspacePage.groups.invite.header'),
      Component: (
        <InviteUsers
          workspaceUsers={workspaceUsers}
          workspaceId={userData.workspace.workspaceId}
          handleBack={handleBack}
        />
      ),
    },
    delete: {
      title: t('workspacePage.users.checkDelete.header'),
      Component: (
        <DeleteUser
          repeatCheckDocumentsUser={repeatCheckDocumentsUser}
          handleBack={handleBack}
          handleDeleteUser={handleDeleteUser}
        />
      ),
    },
  };

  useEffect(() => {
    const scrollElement = document.getElementById('scroll-element');

    const hasVerticalScrollbar = scrollElement?.scrollHeight > scrollElement?.clientHeight;
    if (workspaceUsersHasNextPage && workspaceUsersPageIndex > 0 && !hasVerticalScrollbar) {
      fetchMoreData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspaceUsersHasNextPage, workspaceUsers]);

  const fetchMoreData = () => {
    if (workspaceUsersHasNextPage && workspaceUsersPageIndex > 0 && workspaceUsers.length) {
      dispatch(
        identityActions.getWorkspaceGroupUsers(
          userData.groupAll.groupId,
          workspaceUsersPageIndex,
          50,
          searchTerm
        )
      );
    }
  };

  const renderEditPage = (name) => editPages[name].Component;
  const setTitle = (name) => editPages[name].title;

  const actions = [
    {
      icon: <DeleteIcon />,
      name: t('common.delete'),
      onClick: () => deleteDialogToggle.open(),
    },
  ];

  const renderSummaryPage = () => (
    <>
      <HeaderAndButton
        subtitle={t('workspacePage.users.subheader')}
        onButtonClick={() => setEditedSubpage('invite')}
        smallScreen={smallScreen}
        buttonLabel={t('workspacePage.users.inviteUser')}
      >
        {t('workspacePage.drawer.userManagementUser')}
      </HeaderAndButton>
      <Search
        className={classes.searchInput}
        inputLabel={`${t('common.search')}...`}
        handleSetSearchTerm={handleSetSearchTerm}
        valueInput={searchTerm}
        loading={loading}
      />
      <Card variant="outlined" className={classes.root}>
        <InfiniteScroll
          dataLength={workspaceUsers.length}
          next={fetchMoreData}
          hasMore={workspaceUsersHasNextPage}
          scrollThreshold={0.9}
          style={{
            overflow: 'hidden',
          }}
          loader={loading && <LinearProgress />}
          scrollableTarget="scroll-element"
        >
          <CardContent>
            <List>
              {!smallScreen && (
                <ListItem
                  divider={false}
                  classes={{
                    root: clsx(classes.listHeader, classes.listItemRoot),
                    container: classes.listItemContainer,
                  }}
                >
                  <ExtendedGrid container spacingX={2} alignItemsXxs="center">
                    <ExtendedGrid item xxs={12} sm={6} md={5} xl={4}>
                      <Typography variant="subtitle2">
                        {t('workspacePage.users.tableHeader.user')}
                      </Typography>
                    </ExtendedGrid>
                    {/* <ExtendedGrid item xxs={6} sm={3} md={2} xl={3}> */}
                    {/*  <Typography variant="subtitle2"> */}
                    {/*    {t('workspacePage.users.tableHeader.lastActivity')} */}
                    {/*  </Typography> */}
                    {/* </ExtendedGrid> */}
                    {/* <ExtendedGrid item xxs={6} sm={3} md={2}> */}
                    {/*  <Typography variant="subtitle2">{t('common.status')}</Typography> */}
                    {/* </ExtendedGrid> */}
                    <ExtendedGrid item xxs textAlignXxs="right">
                      <Typography variant="subtitle2">{t('common.actions')}</Typography>
                    </ExtendedGrid>
                  </ExtendedGrid>
                </ListItem>
              )}
              {workspaceUsers &&
                workspaceUsers.map((i, index) => (
                  <UserItem
                    key={index}
                    style={{
                      height: 20,
                      overflow: 'hidden',
                    }}
                    item={i}
                    actions={userData.identityId !== i.identityId ? actions : null}
                    smallScreen={smallScreen}
                    handleActionUser={handleActionUser}
                  />
                ))}
            </List>
          </CardContent>
          {loading && <LinearProgress />}
        </InfiniteScroll>
      </Card>
      <ConfirmDialog
        variant="error"
        open={deleteDialogOpen}
        title={t('workspacePage.users.deleteUser.header')}
        subtitle={t('workspacePage.users.deleteUser.description')}
        applyButtonText={t('workspacePage.users.deleteUser.delete')}
        cancelButtonText={t('common.cancel')}
        actionAccept={checkDeleteUserHasDocuments}
        actionCancel={deleteDialogToggle.close}
        isLoading={isPending}
      />
    </>
  );

  return (
    <>
      {!editedSubpage ? (
        renderSummaryPage()
      ) : (
        <FullScreenDialog
          isOpen
          onBack={handleBack}
          onClose={handleBack}
          title={setTitle(editedSubpage)}
        >
          {renderEditPage(editedSubpage)}
        </FullScreenDialog>
      )}
    </>
  );
};

export default Users;
