import { RequestStatus } from 'src/helpers/reduxReuquest.util';
import identityConstants from '../constants/identity.constants';

const initialState = {
  deleteUserFromWorkspaceStatus: RequestStatus.IDLE,
  fetchWorkspaceUsersStatus: RequestStatus.IDLE,
  hasNextPage: false,
  inviteUsersToWorkspaceStatus: RequestStatus.IDLE,
  pageIndex: 0,
  searchWorkspaceUsersStatus: RequestStatus.IDLE,
  workspaceUsers: [],
};

const updateListUsersDelete = (workspaceUsersState, userId) =>
  workspaceUsersState.map((user) => {
    if (user.identityId === userId) {
      user.active = false;
    }
    return user;
  });

function workspaceUserStore(state = initialState, action) {
  switch (action.type) {
    case identityConstants.FETCH_WORKSPACE_USERS_REQUEST:
      return {
        ...state,
        fetchWorkspaceUsersStatus: RequestStatus.PENDING,
      };
    case identityConstants.FETCH_WORKSPACE_USERS_SUCCESS:
      return {
        ...state,
        fetchWorkspaceUsersStatus: RequestStatus.SUCCESS,
        hasNextPage: action.hasNextPage,
        pageIndex: action.hasNextPage ? state.pageIndex + 1 : state.pageIndex,
        workspaceUsers: [...state.workspaceUsers, ...action.workspaceUsers],
      };
    case identityConstants.FETCH_WORKSPACE_USERS_FAILURE:
      return {
        ...state,
        fetchWorkspaceUsersStatus: RequestStatus.ERROR,
      };

    case identityConstants.FETCH_WORKSPACE_USERS_SEARCH_REQUEST:
      return {
        ...state,
        searchWorkspaceUsersStatus: RequestStatus.PENDING,
      };
    case identityConstants.FETCH_WORKSPACE_USERS_SEARCH_SUCCESS:
      return {
        ...state,
        searchWorkspaceUsersStatus: RequestStatus.SUCCESS,
        pageIndex: 0,
        workspaceUsers: [...action.workspaceUsers],
      };
    case identityConstants.FETCH_WORKSPACE_USERS_SEARCH_FAILURE:
      return {
        ...state,
        searchWorkspaceUsersStatus: RequestStatus.ERROR,
      };
    case identityConstants.INVITE_USER_TO_WORKSPACE_REQUEST:
      return {
        ...state,
        inviteUsersToWorkspaceStatus: RequestStatus.PENDING,
      };
    case identityConstants.INVITE_USER_TO_WORKSPACE_SUCCESS:
      return {
        ...state,
        inviteUsersToWorkspaceStatus: RequestStatus.SUCCESS,
      };
    case identityConstants.INVITE_USER_TO_WORKSPACE_FAILURE:
      return {
        ...state,
        inviteUsersToWorkspaceStatus: RequestStatus.ERROR,
      };

    case identityConstants.DELETE_USER_FROM_WORKSPACE_REQUEST:
      return {
        ...state,
        deleteUserFromWorkspaceStatus: RequestStatus.PENDING,
      };
    case identityConstants.DELETE_USER_FROM_WORKSPACE_SUCCESS:
      return {
        ...state,
        deleteUserFromWorkspaceStatus: RequestStatus.SUCCESS,
        workspaceUsers: updateListUsersDelete(state.workspaceUsers, action.identityId),
      };
    case identityConstants.DELETE_USER_FROM_WORKSPACE_FAILURE:
      return {
        ...state,
        deleteUserFromWorkspaceStatus: RequestStatus.ERROR,
      };
    case identityConstants.CLEAR_WORKSPACE_USERS_STORE:
      return initialState;

    default:
      return state;
  }
}

export default workspaceUserStore;
