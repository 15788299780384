import React from 'react';
import { CircularProgress } from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { EmptyBanner, SmallContentWithScrollbar } from '../index';
import { useStyles } from './styles';

const WaitingForBlockchain = ({ className: classesProp, creatorDocumentStepper }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <SmallContentWithScrollbar
      classes={{ root: clsx(classes.root, classesProp), content: classes.content }}
      scrollableContent
      size="large"
    >
      <EmptyBanner
        src="/images/frames/bc-added.svg"
        title={t('waitingForBlockchainDialog.title')}
        description={t('waitingForBlockchainDialog.description')}
        onClick={() => creatorDocumentStepper(5)}
      >
        <CircularProgress size={48} />
      </EmptyBanner>
    </SmallContentWithScrollbar>
  );
};
WaitingForBlockchain.propTypes = {
  className: PropTypes.string,
  creatorDocumentStepper: PropTypes.func,
};

export default WaitingForBlockchain;
