import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { IconButton } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { useSnackbar, SnackbarContent } from 'notistack';
import {
  Close as CloseIcon,
  Success as SuccessIcon,
  Alert as ErrorIcon,
  Info as InfoIcon,
  Help as WarningIcon,
} from '../CustomIcon';

import { useStyles } from './styles';

const SnackbarItem = forwardRef(({ className: classNameProp, id, options }, ref) => {
  const classes = useStyles();
  const { icon, message, title, variant } = options;
  const { closeSnackbar } = useSnackbar();

  const handleDismiss = () => {
    closeSnackbar(id);
  };

  return (
    <SnackbarContent ref={ref}>
      <Alert
        className={clsx(classes.root, classNameProp)}
        icon={icon}
        iconMapping={{
          success: <SuccessIcon />,
          error: <ErrorIcon />,
          info: <InfoIcon />,
          warning: <WarningIcon />,
        }}
        severity={variant}
        action={
          <IconButton size="small" onClick={handleDismiss}>
            <CloseIcon fontSize="inherit" />
          </IconButton>
        }
      >
        {title && <AlertTitle>{title}</AlertTitle>}
        {message}
      </Alert>
    </SnackbarContent>
  );
});

SnackbarItem.propTypes = {
  className: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  options: PropTypes.shape({
    icon: PropTypes.oneOf([false, undefined]),
    message: PropTypes.string,
    title: PropTypes.string,
    variant: PropTypes.oneOf(['success', 'info', 'warning', 'error']),
  }),
};

SnackbarItem.defaultProps = {
  options: {
    icon: undefined,
    variant: 'info',
  },
};

export default SnackbarItem;
