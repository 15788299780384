import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { HeaderUserButton } from 'src/components';

const UserProfile = ({ immediatelyOpenUserProfile, setImmediatelyOpenUserProfile }) => {
  const activeIdentity = useSelector((state) => state.identityStore.currentIdentity);
  const userAvatar = useSelector((state) => state.avatarStore.userAvatar);
  const workspaceAvatar = useSelector((state) => state.avatarStore.workspaceAvatar);

  const userData = {
    login: ' - ',
    organisation: ' - ',
  };
  if (activeIdentity) {
    userData.login = activeIdentity.identity.name;
    userData.organisation = activeIdentity.workspace.name;
  }

  return (
    <HeaderUserButton
      login={userData.login}
      userAvatar={
        activeIdentity && userAvatar !== false
          ? `${process.env.REACT_APP_AVATAR_URL}/identity/${activeIdentity?.identity.identityId}?kind=SMALL`
          : null
      }
      organisation={userData.organisation}
      organisationBadge={
        activeIdentity && workspaceAvatar !== false
          ? `${process.env.REACT_APP_AVATAR_URL}/workspace/${activeIdentity?.workspace.workspaceId}?kind=SMALL`
          : null
      }
      immediatelyOpenUserProfile={immediatelyOpenUserProfile}
      setImmediatelyOpenUserProfile={setImmediatelyOpenUserProfile}
    />
  );
};

UserProfile.propTypes = {
  immediatelyOpenUserProfile: PropTypes.bool,
  setImmediatelyOpenUserProfile: PropTypes.func,
};

export default UserProfile;
