import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  ({ spacing }) =>
    createStyles({
      languageSelect: {
        marginBottom: spacing(4),
        width: '100%',
      },
    }),
  { index: 0 }
);
