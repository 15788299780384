import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Avatar, Tooltip } from '@material-ui/core';
import { lighten } from '@material-ui/core/styles';
import {
  amber,
  blue,
  blueGrey,
  cyan,
  deepOrange,
  deepPurple,
  green,
  indigo,
  lightBlue,
  lightGreen,
  lime,
  orange,
  pink,
  purple,
  red,
  teal,
  yellow,
} from '@material-ui/core/colors';

import { useStyles } from './styles';

const UserAvatar = ({
  className: classNameProp,
  size,
  color,
  customColor,
  children,
  coefficientColor,
  tooltip,
  ...other
}) => {
  const classes = useStyles();

  const defaultColor = blueGrey[600];
  const colors = [
    red[600],
    pink[600],
    purple[600],
    deepPurple[600],
    indigo[600],
    blue[600],
    lightBlue[600],
    cyan[600],
    teal[600],
    green[600],
    lightGreen[600],
    lime[600],
    yellow[600],
    amber[600],
    orange[600],
    deepOrange[600],
  ];

  const colorFromText = (text) => {
    const charCodes = String(text)
      .split('')
      .map((char) => char.charCodeAt(0))
      .join('');

    return parseInt(charCodes, 10);
  };

  const returnChildren = () => {
    if (typeof children === 'string' || children instanceof String) {
      const splitText = children.replace(/[^a-zA-Z0-9À-ž ]/g, '').split(/\s+/);

      return `${splitText[0].charAt(0).toUpperCase()}${
        splitText?.length > 1 ? splitText[1].charAt(0).toUpperCase() : ''
      }`;
    }
    return children;
  };

  const child = returnChildren();
  const colorByChild = child ? colors[colorFromText(child) % colors.length] : defaultColor;

  const renderAvatar = () => (
    <Avatar
      className={clsx(
        classes.root,
        {
          [classes[`${String(size)}Size`]]: size,
          [classes[`${String(color)}Color`]]: color,
        },
        classNameProp
      )}
      {...other}
      style={
        !color && children
          ? {
              color: customColor || colorByChild,
              backgroundColor: customColor
                ? lighten(customColor, coefficientColor)
                : lighten(colorByChild, coefficientColor),
            }
          : null
      }
    >
      {child}
    </Avatar>
  );

  return <>{tooltip ? <Tooltip title={tooltip}>{renderAvatar()}</Tooltip> : renderAvatar()}</>;
};

UserAvatar.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  className: PropTypes.string,
  size: PropTypes.oneOf(['large', 'medium', 'small', 'mini']),
  color: PropTypes.oneOf(['default', 'error', 'warning', 'success', 'info']),
  customColor: PropTypes.string,
  coefficientColor: PropTypes.number,
  tooltip: PropTypes.string,
};

UserAvatar.defaultProps = {
  size: 'large',
  coefficientColor: 0.825,
};

export default UserAvatar;
