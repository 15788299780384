import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FullScreenDialog } from 'src/components';
import { ContentHeading } from '../../subcomponents';

const Summary = () => {
  const { t } = useTranslation();
  const [editedSubpage, setEditedSubpage] = useState(null);

  const handleBack = () => {
    setEditedSubpage(null);
  };

  const renderSummaryPage = () => (
    <ContentHeading>{t('workspacePage.drawer.basicSummary')}</ContentHeading>
  );

  return (
    <>
      {renderSummaryPage()}
      <FullScreenDialog
        isOpen={!!editedSubpage}
        onBack={handleBack}
        onClose={handleBack}
        title="Title"
      />
    </>
  );
};

export default Summary;
