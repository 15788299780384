import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import documentJoinActions from 'src/redux/actions/documentJoin.actions';
import documentDisplayActions from 'src/redux/actions/documentDisplay.actions';
import cookieHelper from 'src/helpers/cookieHelper';
import {
  Button,
  CircularProgress,
  Dialog,
  FormControl,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import {
  BrokenImage as NotDownloadedImageIcon,
  VisibilityOffOutlined as NoImageIcon,
} from '@material-ui/icons';
import Img from 'react-image';
import {
  AvatarAndTextItem,
  EmptyBanner,
  ExtendedGrid,
  FullPageLoader,
  FullScreenDialog,
  SmallContentWithScrollbar,
} from 'src/components';
import clsx from 'clsx';
import { RequestStatus } from 'src/helpers/reduxReuquest.util';
import { moduleName as dslService } from 'src/redux/services/documentDSL.service';
import { moduleName as documentService } from 'src/redux/services/documentCore.service';
import identityActions from 'src/redux/actions/identity.actions';
import { DocumentRole } from 'src/models/document.model';
import { useStyles } from './styles';
import JoinToDocumentWebsocket from './JoinToDocumentWebsocket';

const JoinToDocument = () => {
  const classes = useStyles();
  const match = useRouteMatch();
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const { search } = useLocation();
  const pinCode = new URLSearchParams(search).get('pin');

  const shortTermSubmitButton = useMediaQuery(({ breakpoints }) => breakpoints.only('xs'));
  const { currentIdentity, structure, structureStatus } = useSelector(
    (state) => state.identityStore,
    undefined
  );
  const {
    documentInvitationData,
    fetchDocumentInvitationStatus,
    fetchAcceptationDocumentInvitationStatus,
  } = useSelector((state) => state.documentJoinStore, undefined);
  const { getDownloadTokenStatus, downloadFiles } = useSelector(
    (state) => state.documentDisplayStore,
    undefined
  );
  const [selectedIdentityId, setSelectedIdentityId] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [isPreviewFull, setIsPreviewFull] = useState(false);
  const { invitationId } = match.params;
  const isCertificate = history.location.pathname.includes('certificate');
  const apiService = isCertificate ? dslService : documentService;
  const disabledSubmit = fetchAcceptationDocumentInvitationStatus === RequestStatus.PENDING;
  const getInvitationSuccess = fetchDocumentInvitationStatus === RequestStatus.SUCCESS;
  const getInvitationError = fetchDocumentInvitationStatus === RequestStatus.ERROR;

  const documentName = getInvitationSuccess ? documentInvitationData?.documentName : null;
  const imageDownloadToken = getInvitationSuccess
    ? documentInvitationData?.imageDownloadToken
    : null;
  const inviterName = getInvitationSuccess ? documentInvitationData?.inviterName : null;
  const workspaceName = getInvitationSuccess ? documentInvitationData?.workspaceName : null;
  const currentIdentityId = currentIdentity?.identity.identityId;
  const isSigner = documentInvitationData?.invitedRole === DocumentRole.SIGNERS;
  const isHolder = documentInvitationData?.invitedRole === DocumentRole.HOLDER;

  const isAllLoaded =
    fetchDocumentInvitationStatus !== RequestStatus.PENDING &&
    structureStatus !== RequestStatus.PENDING &&
    structure?.length !== 0 &&
    currentIdentityId &&
    selectedIdentityId;

  const parsedStructureList = isAllLoaded
    ? structure
        .filter((identityStructure) => identityStructure.identity.active)
        .map((identityStructure) => ({
          data: {
            id: identityStructure.identity.identityId,
            name: identityStructure.identity.name,
          },
          workspaceName: identityStructure.workspace.name,
          workspaceId: identityStructure.workspace.workspaceId,
        }))
    : null;

  const displayContent = !getInvitationError && !submitting;

  const handleClose = () => {
    history.push('/');
  };

  const handleChangeIdentity = (event) => {
    setSelectedIdentityId(event.target.value);
  };

  const setCurrentIdentity = (identityId) => {
    let identityData = false;
    structure.forEach((item) => {
      if (item.identity.identityId === identityId) {
        identityData = item;
      }
    });
    if (!identityData) {
      [identityData] = structure;
    }
    cookieHelper.setCookie('activeIdentity', identityData?.identity?.identityId);
    dispatch(identityActions.setCurrentIdentity(identityData));
  };

  const selectTitle = (() => {
    if (isCertificate) {
      return t('joiningToDocumentPage.takeTheCertificateAs');
    }
    return isSigner
      ? t('joiningToDocumentPage.youSignedAs')
      : t('joiningToDocumentPage.youJoinedAs');
  })();

  const togglePreview = () => setIsPreviewFull(!isPreviewFull);

  const handleSubmit = (event) => {
    event.preventDefault();
    const { invitationId } = match.params;
    setCurrentIdentity(selectedIdentityId);
    dispatch(
      documentJoinActions.acceptDocumentInvitation(
        invitationId,
        () => setSubmitting(true),
        apiService,
        pinCode ? { pin: pinCode } : {}
      )
    );
    localStorage.setItem('createdDocumentFolderId', 'root');
  };

  useEffect(() => {
    if (structureStatus === RequestStatus.SUCCESS && currentIdentity !== null) {
      setSelectedIdentityId(currentIdentity?.identity.identityId);
    }
  }, [structureStatus, currentIdentity]);

  useEffect(() => {
    if (fetchDocumentInvitationStatus === RequestStatus.IDLE) {
      dispatch(documentJoinActions.getDocumentInvitation(invitationId, apiService));
    }
  }, [dispatch, fetchDocumentInvitationStatus, invitationId, apiService]);

  useEffect(() => {
    if (
      fetchDocumentInvitationStatus === RequestStatus.SUCCESS &&
      getDownloadTokenStatus === RequestStatus.IDLE &&
      documentInvitationData
    ) {
      if (documentInvitationData?.imageDownloadToken) {
        dispatch(
          documentDisplayActions.downloadFiles(
            [{ token: documentInvitationData.imageDownloadToken }],
            -1
          )
        );
      }
    }
  }, [dispatch, documentInvitationData, fetchDocumentInvitationStatus, getDownloadTokenStatus]);

  useEffect(
    () => () => {
      dispatch(documentDisplayActions.clearFilesAndTokens());
      dispatch(documentJoinActions.clearDocumentJoinStore());
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const imageContent = (inModal = false) =>
    displayContent ? (
      <div
        className={clsx(
          classes.imageContainer,
          isPreviewFull && isCertificate && inModal && classes.imageContainerFullScreen,
          isCertificate && !isPreviewFull && classes.imageContainerClickable
        )}
        onClick={inModal ? () => undefined : togglePreview}
        aria-hidden="true"
      >
        {imageDownloadToken ? (
          <>
            {downloadFiles.length > 0 ? (
              <Img
                useSuspense={false}
                src={downloadFiles[0].file}
                loader={
                  <div className={classes.imageNotLoadedContainer}>
                    <CircularProgress />
                  </div>
                }
                unloader={
                  <div className={classes.imageNotLoadedContainer}>
                    <NotDownloadedImageIcon />
                  </div>
                }
              />
            ) : (
              <div className={classes.imageNotLoadedContainer}>
                <CircularProgress />
              </div>
            )}
          </>
        ) : (
          <div className={classes.imageNotLoadedContainer}>
            <NoImageIcon />
          </div>
        )}
      </div>
    ) : (
      <EmptyBanner
        src={`/images/frames/${getInvitationError ? 'error' : 'bc-added'}.svg`}
        title={t(
          `joiningToDocumentPage.${
            getInvitationError ? 'fetchInvitationError.error' : 'joiningPending.header'
          }`
        )}
        description={
          !getInvitationError ? t('joiningToDocumentPage.joiningPending.description') : null
        }
      >
        <CircularProgress size={48} />
      </EmptyBanner>
    );

  return (
    <>
      <Dialog open={isPreviewFull && isCertificate} onClose={togglePreview}>
        {imageContent(true)}
      </Dialog>
      <JoinToDocumentWebsocket />
      <FullScreenDialog isOpen onClose={handleClose} withLogo>
        <FullPageLoader open={!isAllLoaded} />

        {isAllLoaded && (
          <SmallContentWithScrollbar
            size="medium"
            classes={{
              mediumRootSize: classes.contentWrapper,
            }}
            startContent={
              displayContent && (
                <ExtendedGrid container spacing={2} wrapSm="nowrap">
                  <ExtendedGrid item xxs={12} sm className={classes.documentName}>
                    <Typography variant="subtitle2" color="primary" className={classes.label}>
                      <strong>
                        {isCertificate ? t('common.certificateName') : t('common.documentName')}
                      </strong>
                    </Typography>
                    <Typography variant="h2">{documentName}</Typography>
                  </ExtendedGrid>
                  <ExtendedGrid item xxs={12} sm="auto">
                    <Typography variant="subtitle2" color="primary" className={classes.label}>
                      <strong>{t('common.author')}</strong>
                    </Typography>

                    <AvatarAndTextItem
                      size="medium"
                      text={inviterName}
                      // TODO: missing identifiers
                      // mainImageSrc={}
                      // badgeImageSrc={}
                      description={workspaceName}
                      badgeChildren={workspaceName}
                      classes={{ root: classes.avatarAndTextItemRoot }}
                    />
                  </ExtendedGrid>
                </ExtendedGrid>
              )
            }
            endContent={
              displayContent && (
                <>
                  <Typography variant="h5" className={classes.label}>
                    {selectTitle}
                  </Typography>
                  <ExtendedGrid
                    container
                    spacing={2}
                    alignItemsSm="center"
                    justifyContentXxs="center"
                  >
                    <ExtendedGrid item xxs={12} xs={8} sm={7} lg={6}>
                      <FormControl classes={{ root: classes.selectFormControl }}>
                        <Select
                          value={selectedIdentityId}
                          onChange={handleChangeIdentity}
                          input={
                            <OutlinedInput
                              classes={{
                                input: classes.input,
                                notchedOutline: classes.inputNotchedOutline,
                              }}
                            />
                          }
                          MenuProps={{
                            anchorOrigin: {
                              vertical: 'bottom',
                              horizontal: 'left',
                            },
                            transformOrigin: {
                              vertical: 'top',
                              horizontal: 'left',
                            },
                            getContentAnchorEl: null,
                          }}
                        >
                          {parsedStructureList.map((item, index) => (
                            <MenuItem
                              key={index}
                              value={item.data.id}
                              selected={item.data.id === currentIdentityId}
                            >
                              <AvatarAndTextItem
                                size="medium"
                                text={item.data.name}
                                mainImageSrc={`${process.env.REACT_APP_AVATAR_URL}/identity/${item.data.id}?kind=SMALL`}
                                badgeImageSrc={`${process.env.REACT_APP_AVATAR_URL}/workspace/${item.workspaceId}?kind=SMALL`}
                                description={item.workspaceName}
                                badgeChildren={item.workspaceName}
                                classes={{ root: classes.avatarAndTextItemRoot }}
                              />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </ExtendedGrid>
                    <ExtendedGrid item xxs={12} xs={4} sm={5} lg={6}>
                      <Button
                        fullWidth
                        size="large"
                        type="submit"
                        disabled={disabledSubmit}
                        endIcon={disabledSubmit && <CircularProgress size={20} color="inherit" />}
                      >
                        {isHolder
                          ? t('joiningToDocumentPage.takeOwnership')
                          : t(
                              `joiningToDocumentPage.${
                                isCertificate ? 'readCertificate' : 'readDocument'
                              }.${shortTermSubmitButton ? 'shortTerm' : 'longTerm'}`
                            )}
                      </Button>
                    </ExtendedGrid>
                  </ExtendedGrid>
                </>
              )
            }
            hasForm={displayContent}
            formProps={
              displayContent
                ? {
                    noValidate: true,
                    autoComplete: 'off',
                    onSubmit: handleSubmit,
                  }
                : {}
            }
          >
            {imageContent(false)}
          </SmallContentWithScrollbar>
        )}
      </FullScreenDialog>
    </>
  );
};

export default JoinToDocument;
