import { hasPermission } from 'src/components/PermissionResolver/permissionUtils';
import { Permission } from '../../models/identity.model';

const hasSigningOrderPermission = (state) =>
  hasPermission({
    workspacePermissions: state.identityStore.workspacePermissions,
    permission: Permission.DOCUMENT_SERVICE_USER_SIGN_ORDER,
  });

const selectWorkspaceDetails = (state) => state.identityStore.workspaceDetails;
const selectWorkspaceId = (state) => state.identityStore.currentIdentity?.workspace?.workspaceId;

const selectCurrentIdentityOwnersGroupId = (state) =>
  state.identityStore?.workspaceGroups.find((group) => group?.info?.name === 'Owners')?.info
    ?.groupId;

export const IdentitySelector = {
  hasSigningOrderPermission,
  selectWorkspaceDetails,
  selectWorkspaceId,
  selectCurrentIdentityOwnersGroupId,
};
