import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  ({ breakpoints, spacing, palette }) =>
    createStyles({
      root: {},
      listHeader: {
        minHeight: spacing(3),
        paddingBottom: '0 !important',
      },
      listItemHeader: {
        border: 'none',
        paddingTop: spacing(6),
        paddingBottom: spacing(1),
        '&:first-child': {
          paddingTop: spacing(0),
        },
      },
      fullScreenDialog: {
        minHeight: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      content: {
        width: '100%',
        maxWidth: '388px',
      },
      label: {
        margin: 0,
        position: 'relative',
        right: -4,
        [breakpoints.down('sm')]: {
          right: 0,
        },
      },
      actionButton: {
        [breakpoints.down('sm')]: {
          display: 'flex',
          margin: '0 auto',
          maxWidth: breakpoints.values.xs,
        },
      },
      errorButton: {
        backgroundColor: palette.error.main,
        color: palette.error.contrastText,
        '&:hover': {
          backgroundColor: palette.error.light,
        },
        '&:focus, &:active': {
          backgroundColor: palette.error.light,
        },
        [breakpoints.down('sm')]: {
          display: 'flex',
          margin: '0 auto',
          maxWidth: breakpoints.values.xs,
        },
      },
      subtitleWrapper: {
        padding: '0 48px 16px',
      },
    }),
  { index: 0 }
);
