import { CircularProgress, Fab, Popover, Typography, useMediaQuery } from '@material-ui/core';
import clsx from 'clsx';
import { DataTable } from 'src/components/index';
import { Close, ChevronLeft } from 'src/components/CustomIcon';
import { CreateNewFolderRounded } from '@material-ui/icons';
import { FolderColsConfig } from 'src/helpers/fileAndFolderColsConfig';
import { func, node, string } from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import documentsActions from 'src/redux/actions/documents.actions';
import { useStyles } from './styles';
import { useFolderStructure } from './useFolderStructure';
import DirectoryExplorerCreateInput from './DirectoryExplorerCreateInput';
import WebSocketHelper from '../../helpers/WebSocketHelper';
import usePrevious from '../../helpers/hooks/usePrevious';

const DirectoryExplorer = ({ handleChange, onClose, anchorEl, workspaceId, initialFolderId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [locationState, setLocationState] = useState({
    id: initialFolderId || 'root',
    parentId: null,
  });
  const [selectedLocation, setSelectedLocation] = useState([]);
  const [selectedLocationDetails, setSelectedLocationDetails] = useState(null);
  const [showCreateNewFolder, setShowCreateNewFolder] = useState(false);
  const [createPending, setCreateFolderPending] = useState(false);
  const prevState = usePrevious({ createPending });
  const shouldRefetch = prevState?.createPending && !createPending;
  const [folderStructureState, currentFolder] = useFolderStructure(
    workspaceId,
    locationState.id,
    shouldRefetch
  );
  const classes = useStyles();
  const ws = useRef(null);
  useEffect(() => {
    if (initialFolderId) {
      setLocationState({ id: initialFolderId, parentId: null });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!anchorEl && !initialFolderId) {
      setLocationState({ id: 'root', parentId: null });
      setSelectedLocation([]);
    }
  }, [anchorEl, initialFolderId]);

  const open = !!anchorEl;
  const id = open ? 'location-explorer-popover' : undefined;

  const handleSelect = () => {
    const name = (() => {
      if (selectedLocation.length > 0) {
        return selectedLocationDetails.name;
      }

      if (currentFolder.name === '/') {
        return 'Drive';
      }

      return currentFolder.name;
    })();

    handleChange({
      id: selectedLocation.length > 0 ? selectedLocationDetails.id : currentFolder.id,
      name,
    });
    onClose();
  };

  const handleGoBack = () => {
    if (showCreateNewFolder) {
      setShowCreateNewFolder(false);
    } else {
      setLocationState((state) => ({
        ...state,
        id: currentFolder.parent || 'root',
        parentId: null,
      }));
    }
  };

  const onFolderClick = (row, isDoubleClick) => {
    if (!isDoubleClick) {
      const prevSelection = selectedLocation.length > 0;

      if (!prevSelection) {
        setSelectedLocation([row.id]);
        setSelectedLocationDetails(row);
        return;
      }

      setSelectedLocation([]);
      setSelectedLocationDetails(null);
    } else {
      setSelectedLocation([]);
      setSelectedLocationDetails(null);
      setLocationState({
        name: row.name,
        id: row.id,
        parentId: row.parent,
      });
    }
  };

  const foldersRowsData = folderStructureState?.folderStructure?.filter(
    (folder) => folder.parent === currentFolder?.id && !folder.isRoot && !folder.isTrash
  );

  const smallScreen = useMediaQuery(({ breakpoints }) => breakpoints.down('sm'));

  const noFoldersConfig = {
    label: t('drivePage.foldersTable.noItems'),
  };

  const noDocumentsConfig = {
    label: t('drivePage.documentsTable.noItems'),
  };

  const currentFolderName = currentFolder?.isRoot ? 'Drive' : currentFolder?.name;

  const onCreateSubmit = ({ name }) => {
    dispatch(documentsActions.createFolder(workspaceId, currentFolder.id, name));
    setCreateFolderPending(true);
  };

  const onWebSocketMessage = (message, setPending) => {
    if (message.type === 'RESULT') {
      switch (message.code) {
        case 'FOLDER_CREATED':
          onFolderClick(message.data.result, true);
          setPending(false);
          setShowCreateNewFolder(false);
          break;
        default:
          console.warn(`Unknown code [${message.code}]`, message);
          break;
      }
    }
  };

  useEffect(() => {
    if (workspaceId) {
      ws.current = WebSocketHelper.subscribe(
        'document-indexing-service',
        `workspaces/${workspaceId}`,
        (message) => {
          onWebSocketMessage(message, setCreateFolderPending);
        }
      );
    }

    return () => {
      WebSocketHelper.unsubscribe(ws.current);
    };
  }, [workspaceId, setCreateFolderPending]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Popover
        id={id}
        open={open}
        onClose={onClose}
        anchorEl={anchorEl}
        className={classes.popover}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
      >
        <div className={classes.locationExplorer}>
          {folderStructureState.isLoading && (
            <div className={classes.centerWrapper}>
              <CircularProgress />
            </div>
          )}
          {!!folderStructureState.error && (
            <div className={classes.centerWrapper}>
              <Typography>{folderStructureState.error}</Typography>
            </div>
          )}

          {!folderStructureState.isLoading && !folderStructureState.error && (
            <>
              <div className={classes.locationExplorerHeader}>
                <Typography className={classes.locationExplorerHeaderText} variant="h5">
                  {(!currentFolder?.isRoot || showCreateNewFolder) && (
                    <Fab
                      color="primary"
                      size="small"
                      className={classes.backButton}
                      onClick={handleGoBack}
                    >
                      <ChevronLeft />
                    </Fab>
                  )}
                  {showCreateNewFolder ? (
                    <DirectoryExplorerCreateInput onSubmit={onCreateSubmit} />
                  ) : (
                    currentFolderName
                  )}
                </Typography>
                {!showCreateNewFolder && (
                  <Fab
                    color="primary"
                    size="small"
                    className={classes.closeButton}
                    onClick={onClose}
                  >
                    <Close />
                  </Fab>
                )}
              </div>
              <div className={classes.locationExplorerContent}>
                {showCreateNewFolder ? (
                  <div className={classes.createNewFolderContent}>
                    <Typography color="textSecondary">
                      {t('directoryExplorer.contentText', { value: currentFolderName })}
                    </Typography>
                  </div>
                ) : (
                  <>
                    <div className={classes.locationExplorerContentCategory}>
                      <DataTable
                        classes={{
                          headerWrapper: clsx(classes.header, classes.firstHeader),
                          dataTableWrapper: classes.dataTableWrapper,
                          tilesContainer: classes.tilesContainer,
                        }}
                        title={t('drivePage.foldersTable.title')}
                        iconWidth={40}
                        headerTextWidthLikeIcon
                        rows={foldersRowsData}
                        cols={FolderColsConfig(t)}
                        onClick={(row) => {
                          onFolderClick(row, false);
                        }}
                        selectable
                        onDblClick={(row) => {
                          onFolderClick(row, true);
                        }}
                        selectedRows={selectedLocation}
                        isLoading={folderStructureState.isLoading}
                        defaultIcon="folder"
                        tileRowsOnMobile
                        smallScreen={smallScreen}
                        noItems={noFoldersConfig}
                      />
                    </div>
                    <div className={classes.locationExplorerContentCategory}>
                      <DataTable
                        classes={{
                          headerWrapper: clsx(classes.header, classes.firstHeader),
                          dataTableWrapper: classes.dataTableWrapper,
                          tilesContainer: clsx(
                            classes.tilesContainer,
                            folderStructureState?.documents?.length > 0 &&
                              classes.tilesContainerGrayed
                          ),
                        }}
                        title={t('drivePage.documentsTable.title')}
                        iconWidth={40}
                        headerTextWidthLikeIcon
                        rows={folderStructureState.documents}
                        cols={FolderColsConfig(t)}
                        selectable
                        selectedRows={selectedLocation}
                        isLoading={folderStructureState.isLoading}
                        defaultIcon="file"
                        tileRowsOnMobile
                        smallScreen={smallScreen}
                        noItems={noDocumentsConfig}
                      />
                    </div>
                  </>
                )}
              </div>
              <div className={classes.locationExplorerFooter}>
                <Fab
                  disabled={showCreateNewFolder}
                  color="primary"
                  size="small"
                  onClick={() => setShowCreateNewFolder(true)}
                >
                  <CreateNewFolderRounded />
                </Fab>
                <Typography
                  className={classes.locationExplorerSubmitButton}
                  variant="body1"
                  onClick={handleSelect}
                >
                  {selectedLocation.length > 0
                    ? t('createCertificate.putThere')
                    : t('createCertificate.putHere')}
                </Typography>
              </div>
            </>
          )}
        </div>
      </Popover>
    </>
  );
};

DirectoryExplorer.propTypes = {
  handleChange: func,
  onClose: func,
  anchorEl: node,
  workspaceId: string,
  initialFolderId: string,
};

export default DirectoryExplorer;
