export const CertificateSteps = {
  SelectTemplateType: 'SelectTemplateType',
  SelectQuantityType: 'SelectQuantityType',
  MapSingleFields: 'MapSingleFields',
  TemplatePreview: 'TemplatePreview',
  ImportMultipleData: 'ImportMultipleData',
  MapMultipleData: 'MapMultipleData',
  UploadAdditionalDocuments: 'UploadAdditionalDocuments',
  ChooseLocation: 'ChooseLocation',
  InvitePeople: 'InvitePeople',
  ProgressScreen: 'ProgressScreen',
  CreatingCertificatesProgressScreen: 'CreatingCertificatesProgressScreen',
  ErrorLimit: 'ErrorLimit',
};

export const TransferOwnershipStep = {
  TransferOwnership: 'TransferOwnership',
  ByEmail: 'ByEmail',
};

export const CertificateQuantityType = {
  Single: 'single',
  Multiple: 'multiple',
};
