import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { ButtonBase } from '@material-ui/core';
import { MoreHor as MoreIcon, ArrowDownSLine as ChevronDownIcon } from '../CustomIcon';
import { useStylesCollapsed } from './styles';

const BreadcrumbCollapsed = React.forwardRef(({ active, ...other }, ref) => {
  const classes = useStylesCollapsed();

  return (
    <li ref={ref}>
      <ButtonBase className={clsx(classes.button, active && classes.active)} focusRipple {...other}>
        <MoreIcon className={classes.icon} />
        <ChevronDownIcon className={classes.iconChevron} />
      </ButtonBase>
    </li>
  );
});

BreadcrumbCollapsed.propTypes = {
  active: PropTypes.bool,
};

export default BreadcrumbCollapsed;
