import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import communicationsActions from 'src/redux/actions/communications.actions';
import {
  ButtonBase,
  ListItemText,
  ListItemIcon,
  Tooltip,
  Typography,
  useTheme,
  CircularProgress,
} from '@material-ui/core';
import TimeAgo from 'timeago-react';
import { useTranslation } from 'react-i18next';
import * as timeago from 'timeago.js';
import pl from 'timeago.js/lib/lang/pl';
import { Avatar } from 'src/components';
import { Check as CheckIcon, Info as InfoIcon } from 'src/components/CustomIcon';
import { RequestStatus } from 'src/helpers/reduxReuquest.util';
import { useStyles } from './styles';
import { DateTimeFormats, FormatDate } from '../../../../helpers/dateTimeFormats';

const HeaderSidenavNotificationsItem = ({
  className: classNameProp,
  notification,
  live,
  timeRangeAgo,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const { i18n, t } = useTranslation();
  const dispatch = useDispatch();
  const { language } = i18n;
  timeago.register('pl', pl);
  const { content, createdAt, id, read } = notification;
  const type = Date.now() - createdAt <= timeRangeAgo ? 'ago' : 'date';
  const date = new Date(createdAt);
  const translationKey = 'TRANSLATE_';

  let messageContent = content;
  if (content.includes(translationKey)) {
    const splitText = content.split(' ');
    messageContent = '';

    splitText.forEach((item) => {
      if (item.includes(translationKey)) {
        messageContent += `${t(`notifications.messages.${item}`)} `;
      } else {
        messageContent += `${item} `;
      }
    });
  }

  const setSystemMessageAsReadRequest = useSelector(
    (state) => state.communicationsStore.setSystemMessageAsRead
  );
  const settingSystemMessageAsReadById = useSelector(
    (state) => state.communicationsStore.settingSystemMessageAsReadById
  );

  const setAsRead = (notificationId) => {
    if (read === false && settingSystemMessageAsReadById !== notificationId) {
      dispatch(communicationsActions.setNotificationSystemMessageAsRead(notificationId));
    }
  };

  const loading = settingSystemMessageAsReadById === id;

  const Item = (
    <ButtonBase
      disabled={setSystemMessageAsReadRequest === RequestStatus.PENDING}
      classes={{
        root: clsx(classes.root, read ? classes.read : classes.notRead, classNameProp),
        disabled: classes.disabled,
      }}
      onClick={!read ? () => setAsRead(id) : undefined}
    >
      <ListItemIcon>
        <div className={classes.avatar}>
          <Avatar
            size="medium"
            color={read ? 'default' : undefined}
            customColor={!read ? theme.palette.info.light : undefined}
          >
            <InfoIcon />
          </Avatar>
          {loading && <CircularProgress className={classes.loader} thickness={1.5} />}
        </div>
      </ListItemIcon>
      <ListItemText
        classes={{ multiline: classes.textMultiline }}
        primary={
          <Typography variant="body2" color="textPrimary" className={classes.notificationContent}>
            {messageContent}
          </Typography>
        }
        secondary={
          <Typography
            component="span"
            variant="subtitle2"
            color="textSecondary"
            className={classes.date}
          >
            {type === 'ago' && (
              <TimeAgo datetime={createdAt} locale={language.toLowerCase()} live={live} />
            )}
            {type === 'date' && (
              <FormatDate
                date={date}
                dateFormat={DateTimeFormats.dateLong}
                timeFormat={DateTimeFormats.timeLong}
              />
            )}
          </Typography>
        }
      />
      {!read && (
        <div className={classes.checkIconWrapper}>
          <CheckIcon color="primary" />
        </div>
      )}
    </ButtonBase>
  );

  return <>{read ? Item : <Tooltip title={t('notifications.setAsRead.setOne')}>{Item}</Tooltip>}</>;
};

HeaderSidenavNotificationsItem.propTypes = {
  className: PropTypes.string,
  notification: PropTypes.shape({
    content: PropTypes.string,
    createdAt: PropTypes.number,
    id: PropTypes.number,
    read: PropTypes.bool,
    updatedAt: PropTypes.number,
    userId: PropTypes.string,
  }),
  live: PropTypes.bool,
  timeRangeAgo: PropTypes.number,
};

HeaderSidenavNotificationsItem.defaultProps = {
  live: false,
  timeRangeAgo: 86400000,
};

export default HeaderSidenavNotificationsItem;
