import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import documentManagementActions from 'src/redux/actions/documentManagement.actions';
import { useTranslation } from 'react-i18next';
import { DialogContent, TextField } from '@material-ui/core';
import { ConfirmDialog } from 'src/components';
import { RequestStatus } from 'src/helpers/reduxReuquest.util';
import { IdentitySelector } from 'src/redux/selectors/identity.selector';
import { documentDisplaySelector } from 'src/redux/selectors/documentDisplay.selector';
import certificateManagementActions from 'src/redux/actions/certificateManagement.actions';

const RejectDocument = ({ open, documentId, onClose }) => {
  const { t } = useTranslation();
  const [value, setValue] = useState('');

  const dispatch = useDispatch();
  const { workspaceId } = useSelector(IdentitySelector.selectWorkspaceDetails, undefined);
  const isCertificate = useSelector(documentDisplaySelector.isCertificate, undefined);
  const rejectingDocumentStatus = useSelector(
    (state) => state.documentManagementStore.rejectingDocumentStatus,
    undefined
  );

  const isPending = rejectingDocumentStatus === RequestStatus.PENDING;
  const isDisabled = !value || isPending;

  return (
    <ConfirmDialog
      open={open}
      isLoading={isPending}
      isDisabled={isDisabled}
      variant="error"
      title={t('documentActions.rejectDocument.header')}
      applyButtonText={t('documentActions.rejectDocument.reject')}
      cancelButtonText={t('common.cancel')}
      actionAccept={() => {
        if (isCertificate) {
          dispatch(
            certificateManagementActions.rejectCertificate({
              documentId,
              workspaceId,
              reason: value,
              callback: onClose,
            })
          );
        } else {
          dispatch(documentManagementActions.rejectDocument(documentId, value, onClose));
        }
      }}
      actionCancel={onClose}
    >
      <DialogContent>
        <TextField
          autoFocus
          placeholder={t('documentActions.rejectDocument.description')}
          fullWidth
          multiline
          rowsMax={2}
          onChange={(event) => {
            setValue(event.target.value);
          }}
          inputProps={{ maxLength: 50 }}
          disabled={isPending}
        />
      </DialogContent>
    </ConfirmDialog>
  );
};

RejectDocument.propTypes = {
  open: PropTypes.bool,
  documentId: PropTypes.string.isRequired,
  onClose: PropTypes.func,
};

RejectDocument.defaultProps = {
  open: false,
};

export default RejectDocument;
