import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import usePrevious from 'src/helpers/hooks/usePrevious';
import { useDispatch, useSelector } from 'react-redux';
import billingActions from 'src/redux/actions/billing.actions';
import {
  Box,
  Button,
  CircularProgress,
  DialogContent,
  Divider,
  IconButton,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { LocalOfferRounded as DiscountIcon } from '@material-ui/icons';
import { ConfirmDialog } from 'src/components';
import {
  ArrowDown as ArrowDownIcon,
  Equal as EqualIcon,
  Close as MultiplicationIcon,
  Info as InfoIcon,
} from 'src/components/CustomIcon';
import { RequestStatus } from 'src/helpers/reduxReuquest.util';
import { useStyles } from './styles';

const SubscriptionPricing = ({
  className: classNameProp,
  disabled,
  workspaceId,
  trialPrice,
  onChange,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [didMount, setDidMount] = useState(false);
  const [openDiscountDialog, setOpenDiscountDialog] = useState(false);
  const [openPriceDetailsDialog, setOpenPriceDetailsDialog] = useState(false);
  const [discountCode, setDiscountCode] = useState('');

  const estimateSubscriptionPriceStatus = useSelector(
    (state) => state.billingStore.estimateSubscriptionPriceStatus
  );
  const estimatedSubscriptionPrice = useSelector(
    (state) => state.billingStore.estimatedSubscriptionPrice
  );
  const estimatedSubscriptionPriceWithDiscount = useSelector(
    (state) => state.billingStore.estimatedSubscriptionPriceWithDiscount
  );

  const estimateSubscriptionPriceStatusPending =
    estimateSubscriptionPriceStatus === RequestStatus.PENDING;
  const estimateSubscriptionPriceStatusSuccess =
    estimateSubscriptionPriceStatus === RequestStatus.SUCCESS;

  const currency = estimatedSubscriptionPrice?.currency.toUpperCase();
  const trialPeriod = estimatedSubscriptionPrice?.trialDays > 0;
  const usersAmount = estimatedSubscriptionPrice?.users;
  const pricePerUser = (estimatedSubscriptionPrice?.pricePerUser / 100).toFixed(2);
  const priceForAll = ((estimatedSubscriptionPrice?.pricePerUser * usersAmount) / 100).toFixed(2);
  const hasDiscountForAll = estimatedSubscriptionPrice?.discount;

  const discountAmountForAll = { type: null, value: 0, coupon: null };
  if (hasDiscountForAll) {
    const isPercentageDiscount = estimatedSubscriptionPrice?.discount?.discountPercent;

    discountAmountForAll.type = isPercentageDiscount ? 'percent' : 'amount';
    discountAmountForAll.value = isPercentageDiscount
      ? estimatedSubscriptionPrice?.discount?.discountPercent
      : estimatedSubscriptionPrice?.discount?.discountAmount / 100;
    discountAmountForAll.coupon = estimatedSubscriptionPrice?.discount?.name;
  }
  const percentDiscount = discountAmountForAll?.type === 'percent';

  const discountValue = percentDiscount
    ? (discountAmountForAll?.value / 100) * priceForAll
    : discountAmountForAll?.value;
  const prizeAfterDiscount = (priceForAll - discountValue).toFixed(2);

  const discountType = `${
    percentDiscount ? discountAmountForAll?.value : discountAmountForAll?.value.toFixed(2)
  }${percentDiscount ? '%' : ` ${currency}`}`;

  const prevState = usePrevious({ estimateSubscriptionPriceStatus });

  const estimatePrice = () => {
    if (workspaceId) {
      dispatch(billingActions.estimateSubscriptionPrice(workspaceId, discountCode));
    }
  };

  useEffect(() => {
    setDidMount(true);
  }, []);

  useEffect(() => {
    if (!didMount) {
      estimatePrice();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [didMount]);

  useEffect(() => {
    if (
      typeof onChange === 'function' &&
      prevState?.estimateSubscriptionPriceStatus === RequestStatus.PENDING &&
      estimateSubscriptionPriceStatusSuccess
    ) {
      onChange(estimatedSubscriptionPriceWithDiscount ? discountCode : '');
    }
  }, [
    discountCode,
    estimateSubscriptionPriceStatusSuccess,
    estimatedSubscriptionPriceWithDiscount,
    onChange,
    prevState?.estimateSubscriptionPriceStatus,
  ]);

  const handleOpenDiscountDialogToggle = {
    close: () => {
      setOpenDiscountDialog(false);
    },
    open: () => {
      setOpenDiscountDialog(true);
    },
  };
  const handleOpenPriceDetailsDialogToggle = {
    close: () => {
      setOpenPriceDetailsDialog(false);
    },
    open: () => {
      setOpenPriceDetailsDialog(true);
    },
  };

  const renderPriceDetails = () => (
    <div className={classes.priceDetails}>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell>{t('paymentMethod.priceList.priceDetails.user')}</TableCell>
            <TableCell>
              <strong>{`${pricePerUser} ${currency}`}</strong>
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell />
            <TableCell>
              <MultiplicationIcon />
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell>{t('paymentMethod.priceList.priceDetails.usersAmount')}</TableCell>
            <TableCell>
              <strong>{usersAmount}</strong>
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell />
            <TableCell>
              <EqualIcon />
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell>{t('paymentMethod.priceList.priceDetails.priceForAll')}</TableCell>
            <TableCell>
              <strong
                className={clsx(!hasDiscountForAll && classes.priceDetailsMainItem)}
              >{`${priceForAll} ${currency}`}</strong>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>

      {hasDiscountForAll && (
        <>
          <Divider />

          <Table>
            <TableBody>
              <TableRow>
                <TableCell>{t('paymentMethod.priceList.priceDetails.discount')}</TableCell>
                <TableCell>
                  <strong>{`-${discountType}`}</strong>{' '}
                  {percentDiscount && `(${discountValue.toFixed(2)} ${currency})`}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell />
                <TableCell>
                  <ArrowDownIcon />
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>{t('paymentMethod.priceList.priceDetails.afterDiscount')}</TableCell>
                <TableCell>
                  <strong
                    className={classes.priceDetailsMainItem}
                  >{`${prizeAfterDiscount} ${currency}`}</strong>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </>
      )}
    </div>
  );

  if (!didMount) {
    return null;
  }
  return (
    <>
      <div className={clsx(classes.root, classNameProp)}>
        {estimateSubscriptionPriceStatusPending ? (
          <Box textAlign="center">
            <CircularProgress size={24} />
          </Box>
        ) : (
          <>
            {estimateSubscriptionPriceStatusSuccess && (
              <>
                {pricePerUser && (
                  <div className={classes.prices}>
                    {hasDiscountForAll && (
                      <div className={classes.highlightedBox}>
                        <Typography
                          variant="h6"
                          dangerouslySetInnerHTML={{
                            __html: t('paymentMethod.discount.messageType', {
                              discount: discountType,
                            }),
                          }}
                        />
                        <Typography
                          variant="subtitle2"
                          color="primary"
                          dangerouslySetInnerHTML={{
                            __html: `(${t('paymentMethod.discount.message', {
                              coupon: discountAmountForAll.coupon,
                            })})`,
                          }}
                        />
                      </div>
                    )}

                    <div>
                      {usersAmount && (
                        <>
                          <div className={classes.prizeForAll}>
                            <div>
                              <Typography
                                variant={hasDiscountForAll ? 'h5' : 'h2'}
                                color={
                                  // eslint-disable-next-line no-nested-ternary
                                  hasDiscountForAll
                                    ? 'secondary'
                                    : trialPeriod
                                    ? 'textSecondary'
                                    : 'primary'
                                }
                                classes={{ root: clsx(hasDiscountForAll && classes.oldPrice) }}
                              >{`${priceForAll} ${currency}`}</Typography>

                              {hasDiscountForAll && (
                                <Typography
                                  variant={trialPeriod ? 'h3' : 'h2'}
                                  color={trialPeriod ? 'textSecondary' : 'primary'}
                                >{`${prizeAfterDiscount} ${currency}`}</Typography>
                              )}
                            </div>

                            <Tooltip
                              title={t('paymentMethod.priceList.priceDetails.details')}
                              placement="top"
                            >
                              <IconButton
                                size="small"
                                onClick={() => handleOpenPriceDetailsDialogToggle.open()}
                              >
                                <InfoIcon color="disabled" />
                              </IconButton>
                            </Tooltip>
                          </div>

                          <Typography variant="subtitle2" component="span">
                            {t(
                              `paymentMethod.priceList.subscription.${estimatedSubscriptionPrice?.interval}`
                            )}
                          </Typography>
                        </>
                      )}
                    </div>

                    {trialPeriod && (
                      <div className={clsx(classes.highlightedBox, classes.highlightedBoxVariant)}>
                        <Typography
                          dangerouslySetInnerHTML={{
                            __html: t('paymentMethod.priceList.trialInformation', {
                              price: `${trialPrice} ${currency}`,
                              days: estimatedSubscriptionPrice?.trialDays,
                            }),
                          }}
                        />
                      </div>
                    )}

                    <Button
                      size="small"
                      variant="outlined"
                      color="default"
                      disabled={disabled}
                      startIcon={<DiscountIcon color="secondary" />}
                      onClick={() => {
                        handleOpenDiscountDialogToggle.open();
                        setDiscountCode('');
                      }}
                    >
                      {t('paymentMethod.discount.button')}
                    </Button>
                  </div>
                )}
              </>
            )}
          </>
        )}
      </div>

      {!disabled && (
        <>
          <ConfirmDialog
            open={openDiscountDialog}
            title={t('workspacePage.subscriptionsAndPayments.addDiscountCodeTitle')}
            subtitle={t('workspacePage.subscriptionsAndPayments.addDiscountCodeDescription')}
            applyButtonText={t('workspacePage.subscriptionsAndPayments.addDiscountCodeAccept')}
            cancelButtonText={t('common.cancel')}
            isDisabled={!discountCode}
            actionAccept={() => {
              handleOpenDiscountDialogToggle.close();
              estimatePrice();
            }}
            actionCancel={() => {
              handleOpenDiscountDialogToggle.close();
              setDiscountCode('');
            }}
          >
            <DialogContent>
              <Box textAlign="left">
                <TextField
                  name="promoCode"
                  label={t('workspacePage.subscriptionsAndPayments.addDiscountCodeSubtitle')}
                  placeholder={t(
                    'workspacePage.subscriptionsAndPayments.addDiscountCodePlaceholder'
                  )}
                  fullWidth
                  autoFocus
                  value={discountCode}
                  onChange={(event) => {
                    setDiscountCode(event.target.value);
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <DiscountIcon color="disabled" />
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
            </DialogContent>
          </ConfirmDialog>

          <ConfirmDialog
            open={openPriceDetailsDialog}
            title={t('paymentMethod.priceList.priceDetails.details')}
            applyButtonText={t('common.ok')}
            actionAccept={() => {
              handleOpenPriceDetailsDialogToggle.close();
            }}
          >
            <DialogContent>{renderPriceDetails()}</DialogContent>
          </ConfirmDialog>
        </>
      )}
    </>
  );
};

SubscriptionPricing.propTypes = {
  className: PropTypes.string,
  workspaceId: PropTypes.string,
  disabled: PropTypes.bool,
  trialPrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
};
SubscriptionPricing.defaultProps = {
  trialPrice: 0,
};

export default SubscriptionPricing;
