import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Button, CircularProgress, duration } from '@material-ui/core';
import { EmptyBanner, FullScreenDialog, SmallContentWithScrollbar } from 'src/components';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { useStyles } from './styles';

const NoWorkspaceBillingData = ({ open, transitionDuration, toggleImmediatelyOpenUserProfile }) => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();

  const { currentIdentity, isOwner, workspaceDetails } = useSelector(
    (state) => state.identityStore
  );

  const isLoaded =
    currentIdentity !== undefined &&
    currentIdentity !== null &&
    workspaceDetails !== undefined &&
    workspaceDetails !== null;

  return (
    <FullScreenDialog isOpen={open} withLogo transitionDuration={transitionDuration}>
      <SmallContentWithScrollbar
        size="small"
        endContent={
          isLoaded ? (
            <>
              {isOwner && (
                <Button size="large" fullWidth onClick={() => history.push('/payment-method/add')}>
                  {t('noWorkspaceBillingData.addData')}
                </Button>
              )}

              <Button
                size="large"
                color="default"
                fullWidth
                onClick={toggleImmediatelyOpenUserProfile}
              >
                {t('noWorkspaceBillingData.openUserSettings')}
              </Button>
            </>
          ) : null
        }
        classes={{ endContent: classes.buttons }}
      >
        {isLoaded ? (
          <EmptyBanner
            src={`/images/frames/${isOwner ? 'billing-owner.svg' : 'billing-user.svg'}`}
            title={t('noWorkspaceBillingData.header')}
            description={t(`noWorkspaceBillingData.${isOwner ? 'owner' : 'user'}.description`)}
          />
        ) : (
          <Box textAlign="center">
            <CircularProgress size={48} />
          </Box>
        )}
      </SmallContentWithScrollbar>
    </FullScreenDialog>
  );
};

NoWorkspaceBillingData.propTypes = {
  open: PropTypes.bool,
  transitionDuration: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.shape({
      appear: PropTypes.number,
      enter: PropTypes.number,
      exit: PropTypes.number,
    }),
  ]),
  toggleImmediatelyOpenUserProfile: PropTypes.func,
};

NoWorkspaceBillingData.defaultProps = {
  open: false,
  transitionDuration: { enter: duration.enteringScreen, exit: duration.leavingScreen },
};

export default NoWorkspaceBillingData;
