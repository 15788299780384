import i18n from 'src/helpers/i18n';
import { SNACKBAR_VARIANT } from 'src/models/common.model';
import identityConstants from '../constants/identity.constants';
import identityService from '../services/identity.service';
import snackbarActions from './snackbar.actions';
import cookieHelper from '../../helpers/cookieHelper';
import billingActions from './billing.actions';
import contactsActions from './contacts.actions';
import documentDSLServices from '../services/documentDSL.service';
import { ErrorCode } from '../../models/errors.model';

function getUserStructure() {
  return (dispatch) => {
    dispatch({ type: identityConstants.FETCH_STRUCTURE_REQUEST });
    identityService
      .getUserStructure()
      .then((response) => {
        dispatch({
          type: identityConstants.FETCH_STRUCTURE_SUCCESS,
          structure: response?.data?.collection,
        });
      })
      .catch(() => {
        dispatch({ type: identityConstants.FETCH_STRUCTURE_FAILURE });
      });
  };
}

function getWorkspaceStructure(workspaceId) {
  return (dispatch) => {
    dispatch({ type: identityConstants.FETCH_WORKSPACE_STRUCTURE_REQUEST });
    identityService
      .getWorkspaceStructure(workspaceId)
      .then((response) => {
        dispatch({
          type: identityConstants.FETCH_WORKSPACE_STRUCTURE_SUCCESS,
          groups: response.data.groups,
          info: response.data.info,
        });
      })
      .catch(() => {
        dispatch({ type: identityConstants.FETCH_WORKSPACE_STRUCTURE_FAILURE });
      });
  };
}

function fetchWorkspaceInfo(workspaceId) {
  return (dispatch) => {
    dispatch({ type: identityConstants.FETCH_WORKSPACE_INFO_REQUEST });
    identityService
      .fetchWorkspaceInfo(workspaceId)
      .then((response) => {
        dispatch({
          type: identityConstants.FETCH_WORKSPACE_INFO_SUCCESS,
          workspaceInfo: response.data,
        });
      })
      .catch(() => {
        dispatch({ type: identityConstants.FETCH_WORKSPACE_INFO_FAILURE });
      });
  };
}

function updateWorkspaceInfo(workspaceId, newData) {
  return (dispatch) => {
    dispatch({ type: identityConstants.UPDATE_WORKSPACE_INFO_REQUEST });
    identityService
      .updateWorkspaceInfo(workspaceId, newData)
      .then(() => {
        dispatch({ type: identityConstants.UPDATE_WORKSPACE_INFO_SUCCESS, workspaceInfo: newData });

        dispatch(
          snackbarActions.enqueueSnackbar(
            SNACKBAR_VARIANT.SUCCESS,
            i18n.t('snackbars.common.successTitle'),
            i18n.t('workspacePage.settings.updateDataSuccess')
          )
        );
      })
      .catch(() => {
        dispatch(
          snackbarActions.enqueueSnackbar(
            SNACKBAR_VARIANT.ERROR,
            i18n.t('snackbars.common.errorTitle'),
            i18n.t('workspacePage.settings.updateDataFailed')
          )
        );
        dispatch({ type: identityConstants.UPDATE_WORKSPACE_INFO_FAILURE });
      });
  };
}

function updateWorkspaceDetails(workspaceId, workspaceInfo) {
  return (dispatch) => {
    dispatch({ type: identityConstants.UPDATE_WORKSPACE_DETAILS_REQUEST });
    identityService
      .updateWorkspaceDetails(workspaceId, workspaceInfo)
      .then(() => {
        dispatch({
          type: identityConstants.UPDATE_WORKSPACE_DETAILS_SUCCESS,
          workspaceDetails: workspaceInfo,
        });
        dispatch(
          snackbarActions.enqueueSnackbar(
            SNACKBAR_VARIANT.SUCCESS,
            i18n.t('snackbars.common.successTitle'),
            i18n.t('workspacePage.settings.updateNameSuccess')
          )
        );
      })
      .catch(() => {
        dispatch({ type: identityConstants.UPDATE_WORKSPACE_DETAILS_FAILURE });
        dispatch(
          snackbarActions.enqueueSnackbar(
            SNACKBAR_VARIANT.ERROR,
            i18n.t('snackbars.common.errorTitle'),
            i18n.t('workspacePage.settings.updateNameFailed')
          )
        );
      });
  };
}

function setCurrentIdentity(identity) {
  return { type: identityConstants.SET_ACTIVE_IDENTITY, identity };
}

function createWorkspace(workspaceData) {
  return (dispatch) => {
    dispatch({ type: identityConstants.CREATE_NEW_WORKSPACE_REQUEST });
    identityService
      .createWorkspace(workspaceData)
      .then((response) => {
        dispatch({
          type: identityConstants.CREATE_NEW_WORKSPACE_SUCCESS,
          data: response,
        });
        dispatch(
          snackbarActions.enqueueSnackbar(
            SNACKBAR_VARIANT.SUCCESS,
            i18n.t('snackbars.common.successTitle'),
            i18n.t('createWorkspace.success')
          )
        );
        dispatch(identityActions.getUserStructure());
      })
      .catch(() => {
        dispatch({ type: identityConstants.CREATE_NEW_WORKSPACE_FAILURE });
      });
  };
}

function switchContactEmail(email) {
  return (dispatch) => {
    dispatch({ type: identityConstants.SWITCH_CONTACT_EMAIL_REQUEST });
    identityService
      .switchContactEmail()
      .then((response) => {
        if (response) {
          dispatch({ type: identityConstants.SWITCH_CONTACT_EMAIL_SUCCESS, email });
          dispatch(
            snackbarActions.enqueueSnackbar(
              SNACKBAR_VARIANT.SUCCESS,
              i18n.t('snackbars.common.successTitle'),
              i18n.t('userConfigurationPage.identity.emailSwitch')
            )
          );
        }
      })
      .catch((error) => {
        dispatch({ type: identityConstants.SWITCH_CONTACT_EMAIL_FAILURE, error });
        dispatch(
          snackbarActions.enqueueSnackbar(
            SNACKBAR_VARIANT.ERROR,
            i18n.t('snackbars.common.errorTitle'),
            `${i18n.t('snackbars.common.error')} ${error.code}.`
          )
        );
      });
  };
}

function getWorkspaceGroupUsers(groupId, page, limit, value) {
  return (dispatch) => {
    dispatch({ type: identityConstants.FETCH_WORKSPACE_USERS_REQUEST });
    identityService
      .getWorkspaceGroupUsers(groupId, page, limit, value)
      .then((response) => {
        dispatch({
          type: identityConstants.FETCH_WORKSPACE_USERS_SUCCESS,
          workspaceUsers: response.data.collection,
          hasNextPage: response.data.hasNextPage,
        });
      })
      .catch(() => {
        dispatch({ type: identityConstants.FETCH_WORKSPACE_USERS_FAILURE });
      });
  };
}

function searchWorkspaceGroupUsers(groupId, page, limit, value) {
  return (dispatch) => {
    dispatch({ type: identityConstants.FETCH_WORKSPACE_USERS_SEARCH_REQUEST });
    identityService
      .searchWorkspaceGroupUsers(groupId, page, limit, value)
      .then((response) => {
        dispatch({
          type: identityConstants.FETCH_WORKSPACE_USERS_SEARCH_SUCCESS,
          workspaceUsers: response.data.collection,
          hasNextPage: response.data.hasNextPage,
        });
      })
      .catch(() => {
        dispatch({ type: identityConstants.FETCH_WORKSPACE_USERS_SEARCH_FAILURE });
      });
  };
}

function inviteUserToWorkspace(workspaceId, usersData) {
  return (dispatch) => {
    dispatch({ type: identityConstants.INVITE_USER_TO_WORKSPACE_REQUEST });
    identityService
      .inviteUserToWorkspace(workspaceId, usersData)
      .then((response) => {
        dispatch({
          type: identityConstants.INVITE_USER_TO_WORKSPACE_SUCCESS,
          status: response,
        });
        dispatch(
          snackbarActions.enqueueSnackbar(
            SNACKBAR_VARIANT.SUCCESS,
            i18n.t('snackbars.common.successTitle'),
            usersData.length > 1
              ? i18n.t('workspacePage.users.invitationsSentComplete')
              : i18n.t('workspacePage.users.invitationSentComplete')
          )
        );
      })
      .catch((error) => {
        dispatch({ type: identityConstants.INVITE_USER_TO_WORKSPACE_FAILURE });
        dispatch(
          snackbarActions.enqueueSnackbar(
            SNACKBAR_VARIANT.ERROR,
            i18n.t('snackbars.common.errorTitle'),
            `${i18n.t('snackbars.common.error')} ${error.code}.`
          )
        );
      });
  };
}

function deleteUserFromWorkspace(workspaceId, identityId) {
  return (dispatch) => {
    dispatch({ type: identityConstants.DELETE_USER_FROM_WORKSPACE_REQUEST });
    identityService
      .deleteUserFromWorkspace(workspaceId, identityId)
      .then((response) => {
        dispatch({
          type: identityConstants.DELETE_USER_FROM_WORKSPACE_SUCCESS,
          identityId,
          status: response,
        });
        dispatch(
          snackbarActions.enqueueSnackbar(
            SNACKBAR_VARIANT.SUCCESS,
            i18n.t('snackbars.common.successTitle'),
            i18n.t('workspacePage.users.deleteUserSuccess')
          )
        );
      })
      .catch((error) => {
        dispatch({ type: identityConstants.DELETE_USER_FROM_WORKSPACE_FAILURE });
        dispatch(
          snackbarActions.enqueueSnackbar(
            SNACKBAR_VARIANT.ERROR,
            i18n.t('snackbars.common.errorTitle'),
            `${i18n.t('snackbars.common.error')} ${error.code}.`
          )
        );
      });
  };
}

function updateMembersWorkspaceGroup(groupId, members) {
  return { type: identityConstants.UPDATE_WORKSPACE_MEMBERS_GROUP, groupId, members };
}

function clearWorkspaceUsersStore() {
  return { type: identityConstants.CLEAR_WORKSPACE_USERS_STORE };
}

function sendTransactionFromNewDocument(txn, callback = () => undefined) {
  return (dispatch) => {
    dispatch({ type: identityConstants.SEND_TRANSACTION_FROM_NEW_DOCUMENT_REQUEST });
    identityService
      .sendTransaction(txn)
      .then((response) => {
        dispatch({
          type: identityConstants.SEND_TRANSACTION_FROM_NEW_DOCUMENT_SUCCESS,
          data: response,
        });
        callback();
      })
      .catch(() => {
        dispatch({ type: identityConstants.SEND_TRANSACTION_FROM_NEW_DOCUMENT_FAILURE });
      });
  };
}

const sendTransactionFromNewCertificate = (txn, callback = () => undefined) => (dispatch) => {
  dispatch({ type: identityConstants.SEND_TRANSACTION_FROM_NEW_CERTIFICATE_REQUEST });
  identityService
    .sendTransaction(txn)
    .then((response) => {
      dispatch({
        type: identityConstants.SEND_TRANSACTION_FROM_NEW_CERTIFICATE_SUCCESS,
        data: response,
      });
      callback();
    })
    .catch(() => {
      dispatch({ type: identityConstants.SEND_TRANSACTION_FROM_NEW_CERTIFICATE_FAILURE });
    });
};

function getWorkspacePermissions(workspaceId) {
  return (dispatch) => {
    dispatch({ type: identityConstants.FETCH_WORKSPACE_PERMISSIONS_REQUEST });
    identityService
      .getWorkspacePermissions(workspaceId)
      .then((response) => {
        dispatch({
          type: identityConstants.FETCH_WORKSPACE_PERMISSIONS_SUCCESS,
          data: response.data.collection,
        });
      })
      .catch(() => {
        dispatch({ type: identityConstants.FETCH_WORKSPACE_PERMISSIONS_FAILURE });
      });
  };
}

function fetchWorkspaceWorkflows(workspaceId) {
  return (dispatch) => {
    dispatch({ type: identityConstants.FETCH_WORKSPACE_WORKFLOWS_REQUEST });
    documentDSLServices
      .fetchWorkspaceWorkflows(workspaceId)
      .then((response) => {
        dispatch({
          type: identityConstants.FETCH_WORKSPACE_WORKFLOWS_SUCCESS,
          data: response.data.data,
        });
      })
      .catch((errors) => {
        if (errors.code === ErrorCode.IDENTITY_NOT_READY) {
          setTimeout(() => {
            dispatch(fetchWorkspaceWorkflows(workspaceId));
          }, 5000);
        }
        dispatch({
          type: identityConstants.FETCH_WORKSPACE_WORKFLOWS_FAILURE,
          payload: errors.code,
        });
      });
  };
}
function switchWorkspace(workspaceId, callback = () => undefined) {
  return (dispatch, getState) => {
    const { structure } = getState().identityStore;
    let identityData = false;

    const availableIdentities = structure
      ? structure.filter((structureItem) => structureItem.identity.active)
      : structure;

    availableIdentities.forEach((item) => {
      if (item.workspace.workspaceId === workspaceId) {
        identityData = item;
      }
    });

    if (!identityData) {
      [identityData] = availableIdentities;
    }
    cookieHelper.setCookie('activeIdentity', identityData?.identity?.identityId);

    if (identityData?.workspace) {
      const workspaceId = identityData?.workspace?.workspaceId;
      dispatch(billingActions.clearStore());
      dispatch(getWorkspaceStructure(workspaceId));
      dispatch(contactsActions.initializeContacts(workspaceId));
      dispatch(billingActions.checkAccessToWorkspace(identityData?.workspace?.workspaceId));
      dispatch(getWorkspacePermissions(identityData?.workspace?.workspaceId));
    }
    dispatch(setCurrentIdentity(identityData));
    callback();
  };
}

const identityActions = {
  getUserStructure,
  setCurrentIdentity,
  getWorkspaceStructure,
  fetchWorkspaceInfo,
  updateWorkspaceInfo,
  createWorkspace,
  switchContactEmail,
  getWorkspaceGroupUsers,
  searchWorkspaceGroupUsers,
  inviteUserToWorkspace,
  deleteUserFromWorkspace,
  clearWorkspaceUsersStore,
  updateWorkspaceDetails,
  updateMembersWorkspaceGroup,
  sendTransactionFromNewDocument,
  getWorkspacePermissions,
  fetchWorkspaceWorkflows,
  switchWorkspace,
  sendTransactionFromNewCertificate,
};

export default identityActions;
