import React from 'react';

import {
  Edit as QualifiedSignatureIcon,
  Email as EmailIcon,
  FilmCamera as VideoIcon,
  Mobile as EmailAndSmsIcon,
} from 'src/components/CustomIcon';

export const Methods = (t) => [
  {
    label: t('selectionSignature.item1.label'),
    description: t('selectionSignature.item1.text'),
    info: {
      title: t('selectionSignature.item1.modalTitle'),
      description: t('selectionSignature.item1.modalText'),
    },
    icon: <EmailIcon />,
    value: 'MAIL',
    disabled: true,
  },
  {
    label: t('selectionSignature.item2.label'),
    additionalLabel: t('selectionSignature.item2.smallText'),
    description: t('selectionSignature.item2.text'),
    info: {
      title: t('selectionSignature.item2.modalTitle'),
      description: t('selectionSignature.item2.modalText'),
    },
    icon: <EmailAndSmsIcon />,
    value: 'MAIL_SMS',
  },
  {
    label: t('selectionSignature.item3.label'),
    description: t('selectionSignature.item3.text'),
    icon: <VideoIcon />,
    value: 'VIDEO',
    disabled: true,
  },
  {
    label: t('selectionSignature.item4.label'),
    description: t('selectionSignature.item4.text'),
    icon: <QualifiedSignatureIcon />,
    value: 'QES',
    disabled: true,
  },
];

export default Methods;
