import { lighten } from '@material-ui/core/styles';
import palette from '../palette';

export default {
  root: {
    fontSize: '1.2rem',
    width: 40,
    height: 40,
  },
  colorDefault: {
    color: palette.grey.dark,
    backgroundColor: lighten(palette.grey.dark, 0.825),
  },
};
