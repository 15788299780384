import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import documentInvitationActions from 'src/redux/actions/documentInvitiation.actions';
import documentManagementActions from 'src/redux/actions/documentManagement.actions';
import certificateCreatorActions from 'src/redux/actions/certificateCreator.actions';
import usePrevious from 'src/helpers/hooks/usePrevious';
import {
  Button,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Switch,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@material-ui/core';

import { HelpOutline as HelpOutlineIcon } from '@material-ui/icons';
import { Help } from 'src/components/CustomIcon';
import {
  AddAndRemoveUsers,
  ConfirmDialog,
  ContentContainer,
  SmallContentWithScrollbar,
  Tag,
} from 'src/components';
import { IdentitySelector } from 'src/redux/selectors/identity.selector';
import { documentManagementSelector } from 'src/redux/selectors/documentManagement.selector';
import { DocumentRole, DocumentType, mapToDocumentRole } from 'src/models/document.model';
import { RequestStatus } from 'src/helpers/reduxReuquest.util';
import { SNACKBAR_VARIANT } from 'src/models/common.model';
import { moduleName as dslService, MUTATION_NAMES } from 'src/redux/services/documentDSL.service';
import { moduleName as documentService } from 'src/redux/services/documentCore.service';
import { documentInvitationsSelector } from 'src/redux/selectors/documentInvitations.selector';
import certificateManagementActions from 'src/redux/actions/certificateManagement.actions';
import { useTransactions } from 'src/pages/DocumentActions/helpers';
import { InviteSection } from './subcomponents';
import { useStyles } from './styles';
import {
  mergeUsersWithContacts,
  mergeUsersWithGroupsAndContacts,
  sortInvitedSigners,
} from './helpers';
import { filterOptionsFunctions } from '../AddAndRemoveUsers/helpers';
import ConfirmPublicAccessModal from './subcomponents/confirmPublicAccessModal';
import MultipleInvitationWarningBar from './subcomponents/MultipleInvitationWarningBar';
import { documentDisplaySelector } from '../../redux/selectors/documentDisplay.selector';
import { CertificateQuantityType } from '../../models/certificate.model';
import snackbarActions from '../../redux/actions/snackbar.actions';

const InviteToDocument = ({ smallScreen, canInviteToSigners, returnToDocument, documentIds }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const isCertificate = history.location.pathname.includes('certificate');
  const apiService = isCertificate ? dslService : documentService;
  const mobileScreen = useMediaQuery(({ breakpoints }) => breakpoints.down('xs'));
  const { id: documentId } = useParams();
  const [didMount, setDidMount] = useState(false);
  const [authorSigning, setAuthorSigning] = useState(false);
  const [invitedSigners, setInvitedSigners] = useState([]);
  const [invitedHolders, setInvitedHolders] = useState([]);
  const [invitedRoles, setInvitedRoles] = useState([]);
  const [clearSignersInput, setClearSignersInput] = useState(false);
  const [clearHolderInput, setClearHolderInput] = useState(false);
  const [clearRolesInput, setClearRolesInput] = useState(false);
  const [showSigningOrderConfirmModal, setShowSigningOrderConfirmModal] = useState(false);
  const [cancelInvitations, setCancelInvitations] = useState([]);

  const [confirmationModalState, setConfirmationModalState] = useState({
    isOpen: false,
    callback: () => undefined,
  });

  const { document } = useSelector((state) => state.documentDisplayStore, undefined);
  const availableRoles = useSelector(
    (state) => documentInvitationsSelector.selectAvailableRoles(state, t),
    undefined
  );
  const defaultRole =
    availableRoles.find((role) => role.value === DocumentRole.VIEWERS)?.value ||
    availableRoles[0]?.value;
  const activeInvitedSigners = invitedSigners.filter((role) => role?.active);
  const [advancedSettings, setAdvancedSettings] = useState({
    publicAccess: document?.publicFile || false,
    transferable: document?.additionalAttributes?.transferable || false,
  });

  const { isLoading: isLoadingAllowedTransactions, allowedTransactions } = useTransactions(
    document?.id,
    null,
    isCertificate
  );

  useEffect(() => {
    setDidMount(true);
    return () => setDidMount(false);
  }, []);

  const {
    currentIdentity,
    workspaceStructureStatus,
    workspaceGroups,
    workspaceUsers,
  } = useSelector((state) => state.identityStore, undefined);
  const isSigningOrderAvailable = useSelector(
    IdentitySelector.hasSigningOrderPermission,
    undefined
  );
  const {
    documentRoles,
    documentSigners,
    fetchDocumentRolesStatus,
    fetchDocumentSignersStatus,
    fetchWorkspaceEntitiesStatus,
    inviteUsersByMailStatus,
    isOrderSignificant,
    replaceDocumentRolesStatus,
    replaceDocumentSignersStatus,
    workspaceEntities,
    fetchDocumentRoleTypesStatus,
    documentRoleTypes,
  } = useSelector((state) => state.documentInvitationsStore, undefined);
  const [localIsOrderSignificant, setLocalIsOrderSignificant] = useState(isOrderSignificant);
  const { contactsAvailable, contactBookEntitiesFetchStatus } = useSelector(
    (state) => state.contactsStore,
    undefined
  );

  const { getDocumentInvitationsStatus, documentInvitations } = useSelector(
    (state) => state.documentManagementStore,
    undefined
  );
  const {
    certificateQuantityType,
    sentForGeneratingList,
    generatedCertFileList,
    multipleHolders,
  } = useSelector((state) => state.certificateCreatorStore, undefined);
  const hasSomeSigning = useSelector(documentManagementSelector.hasDocumentAnySigning, undefined);
  const isDocumentValid = useSelector(documentDisplaySelector.isDocumentValid, undefined);

  const workspaceId = currentIdentity?.workspace?.workspaceId;
  const documentWorkspaceId = document?.workspaceId;
  const prevState = usePrevious({
    contactBookEntitiesFetchStatus,
    documentRoles,
    documentSigners,
    fetchDocumentRolesStatus,
    fetchDocumentSignersStatus,
    fetchWorkspaceEntitiesStatus,
    inviteUsersByMailStatus,
    invitedRoles,
    invitedSigners,
    invitedHolders,
    replaceDocumentRolesStatus,
    replaceDocumentSignersStatus,
  });

  const signersOptions = useMemo(
    () =>
      workspaceStructureStatus === RequestStatus.SUCCESS ||
      (prevState?.contactBookEntitiesFetchStatus === RequestStatus.PENDING &&
        contactBookEntitiesFetchStatus === RequestStatus.SUCCESS) ||
      (prevState?.fetchWorkspaceEntitiesStatus === RequestStatus.PENDING &&
        fetchWorkspaceEntitiesStatus === RequestStatus.SUCCESS)
        ? mergeUsersWithContacts({
            contactsAvailable,
            workspaceEntities,
            workspaceId,
            workspaceUsers,
          })
        : [],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      contactBookEntitiesFetchStatus,
      fetchWorkspaceEntitiesStatus,
      prevState?.contactBookEntitiesFetchStatus,
      prevState?.fetchWorkspaceEntitiesStatus,
      workspaceStructureStatus,
    ]
  );

  const rolesOptions = useMemo(
    () =>
      workspaceStructureStatus === RequestStatus.SUCCESS ||
      (prevState?.contactBookEntitiesFetchStatus === RequestStatus.PENDING &&
        contactBookEntitiesFetchStatus === RequestStatus.SUCCESS) ||
      (prevState?.fetchWorkspaceEntitiesStatus === RequestStatus.PENDING &&
        fetchWorkspaceEntitiesStatus === RequestStatus.SUCCESS)
        ? mergeUsersWithGroupsAndContacts({
            contactsAvailable,
            documentWorkspaceId,
            workspaceEntities,
            workspaceGroups,
            workspaceId,
            workspaceUsers,
          })
        : [],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      contactBookEntitiesFetchStatus,
      fetchWorkspaceEntitiesStatus,
      prevState?.contactBookEntitiesFetchStatus,
      prevState?.fetchWorkspaceEntitiesStatus,
      workspaceStructureStatus,
    ]
  );

  useEffect(() => {
    if (
      sentForGeneratingList.length === generatedCertFileList.length &&
      generatedCertFileList.length > 0
    ) {
      dispatch(
        snackbarActions.enqueueSnackbar(
          SNACKBAR_VARIANT.SUCCESS,
          t('createCertificate.createSuccessSnackbarTitle')
        )
      );
      if (certificateQuantityType === CertificateQuantityType.Multiple) {
        const roleCollection = generatedCertFileList
          .filter((file) => file.eventType === 'CERTIFICATE_FILE_CREATED')
          .filter((item) => !!multipleHolders[item.correlationId])
          .map((item) => ({
            documentId: item.documentId,
            role: DocumentRole.HOLDER,
            email: multipleHolders[item.correlationId],
          }));

        if (roleCollection.length > 0)
          dispatch(
            certificateCreatorActions.inviteMultipleByRole({
              collection: roleCollection,
            })
          );
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const docId = documentId || document.id;
    if (docId && fetchDocumentRoleTypesStatus === RequestStatus.IDLE) {
      dispatch(documentManagementActions.getDocumentMembers(docId, apiService));
      dispatch(documentInvitationActions.getDocumentRoleTypes(docId, apiService));
    }
  }, [dispatch, document, documentId, fetchDocumentRoleTypesStatus, apiService]);

  useEffect(() => {
    const docId = documentId || document.id;
    if (docId && documentRoleTypes.length > 0) {
      if (documentRoleTypes.includes(DocumentRole.SIGNERS)) {
        dispatch(documentInvitationActions.getDocumentSigners(docId, apiService));
      }

      dispatch(documentInvitationActions.getDocumentRoles(docId, documentRoleTypes, apiService));
      if (getDocumentInvitationsStatus !== RequestStatus.SUCCESS) {
        dispatch(documentManagementActions.getDocumentInvitations(docId, apiService));
      }
    }
  }, [dispatch, documentId, apiService, document, documentRoleTypes, getDocumentInvitationsStatus]);

  useEffect(
    () => () => {
      dispatch(documentInvitationActions.clearStore());
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    if (
      prevState?.fetchDocumentSignersStatus === RequestStatus.PENDING &&
      fetchDocumentSignersStatus === RequestStatus.SUCCESS
    ) {
      const signers = signersOptions.filter((role) => documentSigners?.indexOf(role.id) > -1);
      const invitedSigners = documentInvitations.filter(
        (invitation) => invitation.role === DocumentRole.SIGNERS
      );
      const enrichedInvitedSigners = invitedSigners.map((invitedSigner) => ({
        id: new Date(),
        name: invitedSigner.mail,
        email: t('inviteToDocument.entitiesList.mailInvitation'),
        role: null,
        invitedRole: true,
        active: true,
      }));

      setInvitedSigners(
        sortInvitedSigners({
          itemsToSort: [...signers, ...enrichedInvitedSigners],
          sortingArray: documentSigners,
        })
      );
    }
  }, [
    t,
    fetchDocumentSignersStatus,
    prevState,
    signersOptions,
    documentSigners,
    documentInvitations,
  ]);

  useEffect(() => {
    if (
      prevState?.fetchDocumentRolesStatus === RequestStatus.PENDING &&
      fetchDocumentRolesStatus === RequestStatus.SUCCESS
    ) {
      const enrichedRoles = [];
      documentRoles.forEach((role) => {
        enrichedRoles.push({
          ...rolesOptions?.find((option) => role.id === option.id),
          role: role.role,
        });
      });
      const invitedRoles = documentInvitations.filter(
        (invitation) => mapToDocumentRole(invitation.role) !== DocumentRole.SIGNERS
      );
      const enrichedInvitedRoles = invitedRoles.map((invitedRole) => ({
        id: new Date(),
        name: invitedRole.mail,
        email: t('inviteToDocument.entitiesList.mailInvitation'),
        role: mapToDocumentRole(invitedRole.role),
        invitedRole: true,
        active: true,
      }));

      setInvitedRoles([...enrichedRoles, ...enrichedInvitedRoles]);
    }
  }, [
    documentInvitations,
    documentRoles,
    fetchDocumentRolesStatus,
    prevState?.fetchDocumentRolesStatus,
    rolesOptions,
    t,
  ]);

  useEffect(() => {
    if (
      prevState?.fetchDocumentRolesStatus === RequestStatus.PENDING &&
      fetchDocumentRolesStatus === RequestStatus.SUCCESS
    ) {
      const enrichedRoles = [];
      documentRoles.forEach((role) => {
        enrichedRoles.push({
          ...rolesOptions?.find((option) => role.id === option.id),
          role: role.role,
        });
      });
      const filteredHolder = rolesOptions.filter((option) =>
        documentRoles?.find((role) => role.id === option.id && role.role === DocumentRole.HOLDER)
      );

      const enrichedHolders = filteredHolder.map((item) => ({
        ...item,
        role: documentRoles?.find((role) => role.id === item.id)?.role,
        active: item.active,
      }));
      const invitedRoles = documentInvitations.filter(
        (invitation) => invitation.role !== DocumentRole.SIGNERS
      );
      const invitedHolders = documentInvitations.filter(
        (invitation) => invitation.role === DocumentRole.HOLDER
      );
      const enrichedInvitedRoles = invitedRoles.map((invitedRole) => ({
        id: new Date(),
        name: invitedRole.mail,
        email: t('inviteToDocument.entitiesList.mailInvitation'),
        role: invitedRole.role,
        invitedRole: true,
        active: true,
      }));
      const enrichedInvitedHolders = invitedHolders.map((invitedRole) => ({
        id: new Date(),
        name: invitedRole.mail,
        email:
          !invitedRole.mail && invitedRole.role === DocumentRole.HOLDER
            ? t('common.generatedToken')
            : t('inviteToDocument.entitiesList.mailInvitation'),
        role: invitedRole.role,
        invitedRole: true,
        active: true,
      }));

      setInvitedRoles([...enrichedRoles, ...enrichedInvitedRoles]);
      setInvitedHolders([...enrichedHolders, ...enrichedInvitedHolders]);
    }
  }, [
    documentInvitations,
    documentRoles,
    fetchDocumentRolesStatus,
    prevState?.fetchDocumentRolesStatus,
    rolesOptions,
    t,
  ]);

  useEffect(() => {
    if (prevState?.invitedSigners.length !== invitedSigners.length) {
      setClearSignersInput(false);
    }
    if (prevState?.invitedHolders.length !== invitedHolders.length) {
      setClearHolderInput(false);
    }
    if (prevState?.invitedRoles.length !== invitedRoles.length) {
      setClearRolesInput(false);
    }
  }, [prevState, invitedSigners, invitedRoles, invitedHolders]);

  const toggleIsOrderSignificant = () => {
    const showInfoModal =
      localIsOrderSignificant === false &&
      activeInvitedSigners.some(
        (signer) => !signer.workspaceId || signer.workspaceId !== workspaceId
      );
    // check if user is from workspace, otherwise info modal
    if (showInfoModal) {
      setShowSigningOrderConfirmModal(true);
    } else {
      setLocalIsOrderSignificant(!localIsOrderSignificant);
    }
  };

  const debounce = (func, timeout = 300) => {
    let t;
    return (...args) => {
      clearTimeout(t);
      t = setTimeout(() => func.apply(this, args), timeout);
    };
  };

  const handleChangeAuthorSigning = (event) => {
    setAuthorSigning(event.target.checked);
  };

  const handleRoleAdd = (roleId, type = DocumentRole.SIGNERS) => {
    if (type === DocumentRole.SIGNERS) {
      const role = signersOptions.find((item) => item.id === roleId);
      setClearSignersInput(true);
      setInvitedSigners([...invitedSigners, role]);
    } else if (type === DocumentRole.HOLDER) {
      const role = signersOptions.find((item) => item.id === roleId);
      setClearHolderInput(true);
      setInvitedHolders([{ ...role, role: type }]);
    } else {
      const role = rolesOptions.find((item) => item.id === roleId);

      setClearRolesInput(true);
      if (role !== undefined) {
        setInvitedRoles([
          ...invitedRoles,
          {
            ...role,
            role: type,
          },
        ]);
      } else {
        setInvitedRoles([...invitedRoles]);
      }
    }
  };

  const handleRoleDelete = (roleId, isInvite = false, type = DocumentRole.SIGNERS) => {
    if (type === DocumentRole.SIGNERS) {
      setInvitedSigners(
        invitedSigners.filter((invitedRole) => {
          if (isInvite && invitedRole.invitedRole) {
            return invitedRole.name !== roleId;
          }
          return invitedRole.id !== roleId;
        })
      );
    } else if (type === DocumentRole.HOLDER) {
      setInvitedHolders([]);
      setClearHolderInput(true);
    } else {
      setInvitedRoles(
        invitedRoles.filter((invitedRole) => {
          if (isInvite && invitedRole?.invitedRole === true) {
            return invitedRole.name !== roleId && isInvite;
          }
          return invitedRole.id !== roleId;
        })
      );
    }
    if (isInvite) {
      setCancelInvitations([
        ...cancelInvitations,
        {
          email: roleId,
          role: type,
        },
      ]);
    }
  };

  const confirmSetSigningOrder = () => {
    // get signers outside workspace and prepare for deletion
    activeInvitedSigners
      .filter((signer) => !signer.workspaceId || signer.workspaceId !== workspaceId)
      .forEach(({ invitedRole, role, id, name }) => {
        !invitedRole
          ? handleRoleDelete(id, false, role || DocumentRole.SIGNERS)
          : handleRoleDelete(name, true, role || DocumentRole.SIGNERS);
      });

    // update signers list
    setInvitedSigners(
      activeInvitedSigners.filter(
        (signer) => signer.workspaceId && signer.workspaceId === workspaceId
      )
    );
    setShowSigningOrderConfirmModal(false);
    setLocalIsOrderSignificant(true);
  };

  const handleNewEmailAdd = ({
    emailRole,
    type = DocumentRole.SIGNERS,
    allowDuplicatedForDiffIds = false,
  }) => {
    // prevent adding new users when order is important
    if (localIsOrderSignificant) return;
    if (filterOptionsFunctions.isMail(emailRole.name)) {
      const enrichedEmailRole = {
        ...emailRole,
        email: t('inviteToDocument.entitiesList.mailInvitationToBeSend'),
        role: type !== DocumentRole.SIGNERS ? type : null,
      };

      if (
        type === DocumentRole.SIGNERS &&
        filterOptionsFunctions.isNameAndMailNotRepeated({
          items: invitedSigners,
          value: enrichedEmailRole.name,
          allowDuplicatedForDiffIds,
        })
      ) {
        setClearSignersInput(true);
        setInvitedSigners([...invitedSigners, enrichedEmailRole]);
      }
      if (
        type === DocumentRole.HOLDER &&
        filterOptionsFunctions.isNameAndMailNotRepeated({
          items: invitedSigners,
          value: enrichedEmailRole.name,
          allowDuplicatedForDiffIds,
        })
      ) {
        setClearSignersInput(true);
        setInvitedHolders([enrichedEmailRole]);
      }
      if (
        type !== DocumentRole.SIGNERS &&
        filterOptionsFunctions.isNameAndMailNotRepeated({
          items: invitedRoles,
          value: enrichedEmailRole.name,
        })
      ) {
        setClearRolesInput(true);
        setInvitedRoles([...invitedRoles, enrichedEmailRole]);
      }
    }
  };

  const handleRoleUpdate = (id, role) => {
    const holder = invitedRoles.find((role) => role.role === DocumentRole.HOLDER);
    const rolesNoHolder = invitedRoles.filter((role) => role.role !== DocumentRole.HOLDER);
    const updatedIndex = rolesNoHolder.findIndex((documentRole) => documentRole.id === id);
    const updatedRoles = [
      ...rolesNoHolder.slice(0, updatedIndex),
      {
        ...rolesNoHolder[updatedIndex],
        role,
      },
      ...rolesNoHolder.slice(updatedIndex + 1),
      holder,
    ];
    setInvitedRoles(updatedRoles.filter((el) => el));
  };

  const isPending = useMemo(
    () =>
      fetchDocumentSignersStatus === RequestStatus.PENDING ||
      replaceDocumentSignersStatus === RequestStatus.PENDING ||
      fetchDocumentRolesStatus === RequestStatus.PENDING ||
      replaceDocumentRolesStatus === RequestStatus.PENDING ||
      inviteUsersByMailStatus === RequestStatus.PENDING,
    [
      fetchDocumentSignersStatus,
      replaceDocumentSignersStatus,
      fetchDocumentRolesStatus,
      replaceDocumentRolesStatus,
      inviteUsersByMailStatus,
    ]
  );

  const isLoading = useMemo(() => fetchWorkspaceEntitiesStatus === RequestStatus.PENDING, [
    fetchWorkspaceEntitiesStatus,
  ]);

  const handleInvitation = () => {
    const docId = documentId || document.id;

    // Distinction between new emails and roles through id type check.
    const invitedSignersEmails = invitedSigners.filter(
      (role) => typeof role.id !== 'string' && !role.invitedRole
    );
    const updatedSigners = invitedSigners.filter((role) => typeof role.id === 'string');

    const invitedRolesEmails = invitedRoles.filter(
      (role) =>
        typeof role.id !== 'string' && !role.invitedRole && role.role !== DocumentRole.HOLDER
    );

    const updatedRoles = invitedRoles.filter((role) => typeof role.id === 'string');

    const documentSignerInvitations = documentInvitations.filter(
      (invitation) => invitation.role === DocumentRole.SIGNERS
    );

    if (invitedSignersEmails?.length > 0 || invitedRolesEmails?.length > 0) {
      if (
        certificateQuantityType === CertificateQuantityType.Multiple &&
        documentIds &&
        documentIds.length > 0
      ) {
        dispatch(
          certificateCreatorActions.inviteMultipleByRole({
            collection: documentIds
              .map((documentId) => [
                ...invitedRolesEmails.map((el) => ({
                  documentId,
                  role: el.role.substring(0, el.role.length - 1),
                  email: el.name,
                })),
                ...invitedSignersEmails.map((el) => ({
                  documentId,
                  role: DocumentRole.SIGNERS,
                  email: el.name,
                })),
              ])
              .flat(),
          })
        );
      } else {
        dispatch(
          documentInvitationActions.inviteRolesByMail(
            docId,
            [...invitedSignersEmails, ...invitedRolesEmails],
            isCertificate
          )
        );
      }
    }

    if (isCertificate) {
      const admins = invitedRoles
        .filter((invited) => invited.role === DocumentRole.ADMINS && typeof invited.id === 'string')
        .map((el) => el.id);
      const editors = invitedRoles
        .filter(
          (invited) => invited.role === DocumentRole.EDITORS && typeof invited.id === 'string'
        )
        .map((el) => el.id);
      const viewers = invitedRoles
        .filter(
          (invited) => invited.role === DocumentRole.VIEWERS && typeof invited.id === 'string'
        )
        .map((el) => el.id);

      if (
        AVAILABLE_MUTATIONS.includes(MUTATION_NAMES.MODIFY_SIGNERS) &&
        ((Array.isArray(updatedSigners) && canInviteToSigners) ||
          invitedSignersEmails.length > 0 ||
          cancelInvitations.length > 0)
      ) {
        dispatch(
          certificateCreatorActions.updateCertificateSigners({
            documentIds: documentIds || [docId],
            workspaceId,
            isOrderSignificant: localIsOrderSignificant,
            signers: updatedSigners ? updatedSigners.map((role) => role.id) : [],
          })
        );
      }

      dispatch(
        certificateCreatorActions.updateCertificateRoles({
          workspaceId,
          documentIds: documentIds || [docId],
          admins,
          editors,
          viewers,
        })
      );
      if (
        invitedHolders[0] &&
        !documentInvitations.map((el) => el.mail).includes(invitedHolders[0].email) &&
        AVAILABLE_MUTATIONS.includes(MUTATION_NAMES.CHANGE_HOLDER)
      ) {
        dispatch(
          documentInvitationActions.inviteRolesByMail(docId, [invitedHolders[0]], isCertificate)
        );
      }

      if (
        document.publicFile !== undefined &&
        advancedSettings.publicAccess !== undefined &&
        document.publicFile !== advancedSettings.publicAccess
      ) {
        (documentIds || [docId]).forEach((dId) => {
          dispatch(
            certificateManagementActions.setCertificateAccessLevel(
              dId,
              advancedSettings.publicAccess
            )
          );
        });
      }

      if (
        !isDocumentValid &&
        advancedSettings.transferable !== undefined &&
        document.additionalAttributes.transferable !== advancedSettings.transferable
      ) {
        (documentIds || [docId]).forEach((dId) => {
          dispatch(
            certificateManagementActions.setCertificateTransferability(
              dId,
              workspaceId,
              advancedSettings.transferable
            )
          );
        });
      }

      if (cancelInvitations.filter((invitation) => invitation.email).length > 0) {
        dispatch(certificateManagementActions.deleteRolesByMail(documentId, cancelInvitations));
      }

      returnToDocument();
    } else {
      const requiredSignersCount =
        updatedSigners.length +
        documentSignerInvitations.length +
        invitedSignersEmails.length -
        cancelInvitations.length;

      if (
        (Array.isArray(updatedSigners) &&
          canInviteToSigners &&
          document?.documentType === DocumentType.DOCUMENT_TO_BE_SIGNED) ||
        invitedSignersEmails.length > 0 ||
        cancelInvitations.length > 0
      ) {
        dispatch(
          documentInvitationActions.replaceDocumentSigners(
            documentId,
            localIsOrderSignificant,
            requiredSignersCount,
            updatedSigners ? updatedSigners.map((role) => role.id) : []
          )
        );
      }

      if (Array.isArray(updatedRoles)) {
        dispatch(documentInvitationActions.replaceDocumentRoles(documentId, updatedRoles));
      }

      if (cancelInvitations.length > 0) {
        dispatch(documentInvitationActions.deleteRolesByMail(documentId, cancelInvitations));
      }

      returnToDocument();
    }
  };

  const getWorkspaceEntities = (value) => {
    if (workspaceId && fetchWorkspaceEntitiesStatus !== RequestStatus.PENDING) {
      dispatch(documentInvitationActions.getWorkspaceEntities(workspaceId, value));
    }
  };

  const roleEntities = workspaceEntities?.map((entity) => ({
    active: entity.active,
    email: entity.email,
    group: entity.group || false,
    id: entity.entityId,
    identityId: entity.entityId,
    name: entity.entityName,
    role: null,
    workspaceId: entity.workspaceId,
    workspaceName: entity.workspaceName,
  }));

  const signerEntities = roleEntities.filter((item) => item.group === false);

  const isButtonDisabled =
    ([DocumentType.DOCUMENT_TO_BE_SIGNED, DocumentType.CERTIFICATES].includes(
      document.documentType
    ) &&
      invitedSigners.length === 0) ||
    (document?.documentType === DocumentType.DURABLE_MEDIA && invitedRoles.length === 0);

  const handleChangeTransferableSettings = () => {
    setAdvancedSettings((state) => ({
      ...state,
      transferable: !state.transferable,
    }));
  };

  const handleChangePublicAccessSettings = () => {
    if (advancedSettings.publicAccess) {
      setAdvancedSettings((state) => ({
        ...state,
        publicAccess: false,
      }));

      return;
    }

    const callback = () => {
      setAdvancedSettings((state) => ({
        ...state,
        publicAccess: true,
      }));
    };

    setConfirmationModalState({
      isOpen: true,
      callback,
    });
  };

  const handleModalClose = () => {
    setConfirmationModalState((state) => ({
      ...state,
      isOpen: false,
    }));
  };

  if (!didMount) {
    return null;
  }

  const AVAILABLE_MUTATIONS = allowedTransactions?.map((mutation) => mutation.transitionName);

  const canChangeHolder = AVAILABLE_MUTATIONS.includes(MUTATION_NAMES.CHANGE_HOLDER);
  const canModifySigners =
    document.documentType === DocumentType.CERTIFICATES
      ? AVAILABLE_MUTATIONS.includes(MUTATION_NAMES.MODIFY_SIGNERS)
      : canInviteToSigners;

  return (
    <>
      {isCertificate && (
        <ConfirmPublicAccessModal
          open={confirmationModalState.isOpen}
          handleClose={handleModalClose}
          callback={confirmationModalState.callback}
        />
      )}
      <SmallContentWithScrollbar
        classes={{ root: classes.root, content: classes.content }}
        scrollableContent
        size={smallScreen ? 'large' : 'medium'}
        startContent={
          <Typography variant={smallScreen ? 'h3' : 'h2'}>{t('inviteToDocument.title')}</Typography>
        }
        endContent={
          <Button
            disabled={isButtonDisabled}
            className={classes.actionButton}
            size="large"
            fullWidth
            onClick={handleInvitation}
          >
            {t('inviteToDocument.apply')}
          </Button>
        }
      >
        <div className={classes.section}>
          <MultipleInvitationWarningBar />
          {document &&
            [DocumentType.DOCUMENT_TO_BE_SIGNED, DocumentType.CERTIFICATES].includes(
              document.documentType
            ) && (
              <InviteSection alwaysOpen title={t('inviteToDocument.section.signers.title')}>
                <ContentContainer size="small" vertical="bottom">
                  <Typography variant="body2">
                    {t(
                      `inviteToDocument.section.signers.${
                        canModifySigners ? 'enterEmails' : 'noPossibilityToEnterEmails'
                      }`
                    )}
                  </Typography>
                </ContentContainer>
                {isSigningOrderAvailable && (
                  <ContentContainer size="small" vertical="bottom">
                    <div className={classes.switchWrapper}>
                      <FormControlLabel
                        classes={{
                          root: clsx(
                            mobileScreen && classes.switchMobileRoot,
                            classes.sectionSwitchLabel
                          ),
                        }}
                        labelPlacement="end"
                        control={
                          <Switch
                            disabled={hasSomeSigning || !canModifySigners}
                            checked={Boolean(localIsOrderSignificant)}
                            onChange={toggleIsOrderSignificant}
                          />
                        }
                        label={t('inviteToDocument.section.signers.isOrderSignificant')}
                      />
                      <Tooltip
                        title={t('inviteToDocument.section.signers.orderSignificantTooltip')}
                      >
                        <HelpOutlineIcon className={classes.helpIcon} />
                      </Tooltip>
                      <Tooltip title={t('inviteToDocument.section.signers.betaTooltip')}>
                        <div className={classes.tagWrapper}>
                          <Tag className={classes.tag} color="success">
                            {t('inviteToDocument.section.signers.beta')}
                          </Tag>
                        </div>
                      </Tooltip>
                    </div>
                  </ContentContainer>
                )}

                <AddAndRemoveUsers
                  disabled={isPending}
                  isPending={isPending}
                  loading={isLoading}
                  disableInviting={!canModifySigners}
                  documentInvitationMode
                  isOrderSignificant={localIsOrderSignificant && !hasSomeSigning}
                  clearInput={clearSignersInput}
                  classes={{ root: classes.innerContent }}
                  hideInput={isDocumentValid}
                  items={activeInvitedSigners.map((item) => {
                    item.hideRemove = isDocumentValid;
                    return item;
                  })}
                  availableRoles={availableRoles}
                  authorAction={
                    <FormControlLabel
                      classes={{
                        root: clsx(mobileScreen && classes.switchMobileRoot),
                        label: clsx(
                          classes.switchLabel,
                          authorSigning && classes.switchLabelChecked
                        ),
                      }}
                      labelPlacement={mobileScreen ? 'bottom' : 'start'}
                      control={
                        <Switch
                          classes={{
                            root: clsx(mobileScreen && classes.switchInputMobileRoot),
                          }}
                          checked={authorSigning}
                          onChange={handleChangeAuthorSigning}
                        />
                      }
                      label={t('inviteToDocument.section.signers.iAmSigning')}
                    />
                  }
                  onInputChange={debounce((value) => getWorkspaceEntities(value))}
                  availableItems={signerEntities}
                  placeholder={t('inviteToDocument.section.signers.addPeopleOrGroups')}
                  noResultsText={t('workspacePage.groups.manageUsers.noResults')}
                  onAddClick={handleRoleAdd}
                  onDeleteClick={handleRoleDelete}
                  allowDuplicatedForDiffIds={Boolean(false)} // temporary disabled
                  onAddNewEmailClick={
                    (emailRole) =>
                      handleNewEmailAdd({ emailRole, allowDuplicatedForDiffIds: false }) // temporary disabled
                  }
                  onItemsOrderChange={
                    localIsOrderSignificant && isSigningOrderAvailable && !hasSomeSigning
                      ? (newItems) => setInvitedSigners([...newItems])
                      : undefined
                  }
                />
              </InviteSection>
            )}
          {isCertificate && canChangeHolder && (
            <InviteSection
              alwaysOpen={document?.documentType !== DocumentType.DOCUMENT_TO_BE_SIGNED}
              title={t('inviteToDocument.section.holder.title')}
            >
              <ContentContainer size="small" vertical="bottom">
                <Typography variant="body2">
                  {documentIds && documentIds.length > 1
                    ? t('inviteToDocument.section.holder.descriptionMulti')
                    : t('inviteToDocument.section.holder.descriptionSingle')}
                </Typography>
              </ContentContainer>
              {(!documentIds || (documentIds.length === 1 && canChangeHolder)) && (
                <AddAndRemoveUsers
                  disabled={isPending}
                  isPending={isPending}
                  loading={isLoading}
                  hideInput={documentIds?.length > 1 || invitedHolders.length === 1}
                  documentInvitationMode
                  isOrderSignificant={localIsOrderSignificant && !hasSomeSigning}
                  clearInput={clearHolderInput}
                  classes={{ root: classes.innerContent }}
                  items={invitedHolders.map((item) => {
                    item.hideRemove = !canChangeHolder;
                    return item;
                  })}
                  availableItems={signerEntities}
                  authorAction={
                    <FormControlLabel
                      classes={{
                        root: clsx(mobileScreen && classes.switchMobileRoot),
                        label: clsx(
                          classes.switchLabel,
                          authorSigning && classes.switchLabelChecked
                        ),
                      }}
                      labelPlacement={mobileScreen ? 'bottom' : 'start'}
                      control={
                        <Switch
                          classes={{
                            root: clsx(mobileScreen && classes.switchInputMobileRoot),
                          }}
                          checked={authorSigning}
                          onChange={handleChangeAuthorSigning}
                        />
                      }
                      label={t('inviteToDocument.section.signers.iAmSigning')}
                    />
                  }
                  onInputChange={debounce((value) => getWorkspaceEntities(value))}
                  placeholder={t('inviteToDocument.section.holder.placeholder')}
                  hideNoResults={invitedHolders.length !== 1}
                  onAddClick={(roleId) => handleRoleAdd(roleId, DocumentRole.HOLDER)}
                  onDeleteClick={handleRoleDelete}
                  allowDuplicatedForDiffIds={false} // temporary disabled
                  onAddNewEmailClick={(emailRole) =>
                    handleNewEmailAdd({
                      emailRole,
                      type: DocumentRole.HOLDER,
                      allowDuplicatedForDiffIds: false,
                    })
                  }
                />
              )}
            </InviteSection>
          )}
          <InviteSection
            alwaysOpen={document?.documentType !== DocumentType.DOCUMENT_TO_BE_SIGNED}
            title={t('inviteToDocument.section.roles.title')}
          >
            <ContentContainer size="small" vertical="bottom">
              <Typography variant="body2">
                {t('inviteToDocument.section.roles.enterEmails')}
              </Typography>
            </ContentContainer>
            <AddAndRemoveUsers
              disabled={isPending}
              isPending={isPending}
              loading={isLoading}
              documentInvitationMode
              sortByGroup
              clearInput={clearRolesInput}
              classes={{ root: classes.innerContent }}
              items={invitedRoles
                .filter(
                  (role) => role.active && mapToDocumentRole(role.role) !== DocumentRole.HOLDER
                )
                .map((member) => {
                  if (member.name === 'Owners' && isCertificate) {
                    member.hideRemove = true;
                    member.invitedRole = true;
                  }

                  return member;
                })}
              availableRoles={availableRoles}
              availableItems={roleEntities?.filter(
                (entity) =>
                  (entity.group === true && documentWorkspaceId === entity.workspaceId) ||
                  entity.group === false
              )}
              onInputChange={debounce((value) => getWorkspaceEntities(value))}
              placeholder={t('inviteToDocument.section.roles.addPeopleOrGroups')}
              noResultsText={t('workspacePage.groups.manageUsers.noResults')}
              onAddClick={(roleId) => handleRoleAdd(roleId, defaultRole)}
              onDeleteClick={handleRoleDelete}
              onAddNewEmailClick={(emailRole) =>
                handleNewEmailAdd({ emailRole, type: defaultRole })
              }
              onRoleUpdate={handleRoleUpdate}
            />
          </InviteSection>
          {isCertificate && isLoadingAllowedTransactions && <CircularProgress />}
          {isCertificate && !isLoadingAllowedTransactions && (
            <InviteSection title={t('inviteToDocument.section.advancedSettings.title')} alwaysOpen>
              <FormGroup className={classes.switchBottomWrapper}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={advancedSettings.publicAccess}
                      onChange={handleChangePublicAccessSettings}
                    />
                  }
                  label={
                    <div className={classes.row}>
                      {t('documentActions.tabs.recipients.checkboxes.publicAccess.title')}
                      <Tooltip
                        title={t('documentActions.tabs.recipients.checkboxes.publicAccess.hint')}
                      >
                        <Help className={classes.helpIconCheckbox} />
                      </Tooltip>
                    </div>
                  }
                />
              </FormGroup>
              <FormGroup className={classes.switchBottomWrapper}>
                <FormControlLabel
                  disabled={!AVAILABLE_MUTATIONS.includes(MUTATION_NAMES.SET_TRANSFERABILITY)}
                  control={
                    <Switch
                      checked={advancedSettings.transferable}
                      onChange={handleChangeTransferableSettings}
                    />
                  }
                  label={
                    <div className={classes.row}>
                      {t('documentActions.tabs.recipients.checkboxes.retransferCertificate.title')}
                      <Tooltip
                        title={t(
                          'documentActions.tabs.recipients.checkboxes.retransferCertificate.hint'
                        )}
                      >
                        <Help className={classes.helpIconCheckbox} />
                      </Tooltip>
                    </div>
                  }
                />
              </FormGroup>
            </InviteSection>
          )}
        </div>
      </SmallContentWithScrollbar>
      <ConfirmDialog
        open={showSigningOrderConfirmModal}
        variant="primary"
        subtitle={t('inviteToDocument.section.signers.orderSignificantConfirmModal')}
        applyButtonText={t('common.yes')}
        cancelButtonText={t('common.no')}
        actionAccept={confirmSetSigningOrder}
        actionCancel={() => setShowSigningOrderConfirmModal(false)}
      />
    </>
  );
};

InviteToDocument.propTypes = {
  smallScreen: PropTypes.bool,
  canInviteToSigners: PropTypes.bool,
  returnToDocument: PropTypes.func,
  documentIds: PropTypes.arrayOf(PropTypes.string),
};

InviteToDocument.defaultProps = {
  canInviteToSigners: true,
};

export default InviteToDocument;
