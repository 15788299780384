enum certificateCreatorConstants {
  SET_CREATOR_STEP = 'SET_CREATOR_STEP',
  SET_CREATOR_STEP_BACK = 'SET_CREATOR_STEP_BACK',
  SET_SELECTED_TEMPLATE_ID = 'SET_SELECTED_TEMPLATE_ID',
  SET_CERTIFICATE_QUANTITY_TYPE = 'SET_CERTIFICATE_QUANTITY_TYPE',
  SET_MAPPED_FIELDS = 'SET_MAPPED_FIELDS',
  SET_RAW_IMPORTED_DATA = 'SET_RAW_IMPORTED_DATA',
  SET_DRAFT_FROM_IMPORTED_DATA = 'SET_DRAFT_FROM_IMPORTED_DATA',
  SET_CURRENT_LOCATION = 'SET_CURRENT_LOCATION',
  UPDATE_DRAFT_IMPORTED_DATA = 'UPDATE_DRAFT_IMPORTED_DATA',
  UPDATE_DRAFT_SINGLE_FIELDS = 'UPDATE_DRAFT_SINGLE_FIELDS',
  ADD_NEW_ROW_TO_DRAFT_IMPORTED_DATA = 'ADD_NEW_ROW_TO_DRAFT_IMPORTED_DATA',
  UPDATE_SELECTED_DRAFT_IMPORTED_DATA = 'UPDATE_SELECTED_DRAFT_IMPORTED_DATA',
  DELETED_SELECTED_DRAFT_IMPORTED_DATA = 'DELETED_SELECTED_DRAFT_IMPORTED_DATA',

  FETCH_TEMPLATE_LIST_REQUEST = 'FETCH_TEMPLATE_LIST_REQUEST',
  FETCH_TEMPLATE_LIST_SUCCESS = 'FETCH_TEMPLATE_LIST_SUCCESS',
  FETCH_TEMPLATE_LIST_FAILURE = 'FETCH_TEMPLATE_LIST_FAILURE',

  FETCH_TEMPLATE_LIST_THUMBNAIL_TOKEN_REQUEST = 'FETCH_TEMPLATE_LIST_THUMBNAIL_TOKEN_REQUEST',
  FETCH_TEMPLATE_LIST_THUMBNAIL_TOKEN_SUCCESS = 'FETCH_TEMPLATE_LIST_THUMBNAIL_TOKEN_SUCCESS',
  FETCH_TEMPLATE_LIST_THUMBNAIL_TOKEN_FAILURE = 'FETCH_TEMPLATE_LIST_THUMBNAIL_TOKEN_FAILURE',

  FETCH_TEMPLATE_PREVIEW_REQUEST = 'FETCH_TEMPLATE_PREVIEW_REQUEST',
  FETCH_TEMPLATE_PREVIEW_SUCCESS = 'FETCH_TEMPLATE_PREVIEW_SUCCESS',
  FETCH_TEMPLATE_PREVIEW_FAILURE = 'FETCH_TEMPLATE_PREVIEW_FAILURE',

  FETCH_TEMPLATE_FIELDS_REQUEST = 'FETCH_TEMPLATE_FIELDS_REQUEST',
  FETCH_TEMPLATE_FIELDS_SUCCESS = 'FETCH_TEMPLATE_FIELDS_SUCCESS',
  FETCH_TEMPLATE_FIELDS_FAILURE = 'FETCH_TEMPLATE_FIELDS_FAILURE',

  CREATE_NEW_CERTIFICATE_FILE_REQUEST = 'CREATE_NEW_CERTIFICATE_FILE_REQUEST',
  CREATE_NEW_CERTIFICATE_FILE_SUCCESS = 'CREATE_NEW_CERTIFICATE_FILE_SUCCESS',
  CREATE_NEW_CERTIFICATE_FILE_FAILURE = 'CREATE_NEW_CERTIFICATE_FILE_FAILURE',

  UPDATE_NEW_CERTIFICATE_FILE_CREATED_LIST = 'UPDATE_NEW_CERTIFICATE_FILE_CREATED_LIST',

  CREATE_NEW_CERTIFICATE_REQUEST = 'CREATE_NEW_CERTIFICATE_REQUEST',
  CREATE_NEW_CERTIFICATE_SUCCESS = 'CREATE_NEW_CERTIFICATE_SUCCESS',
  CREATE_NEW_CERTIFICATE_FAILURE = 'CREATE_NEW_CERTIFICATE_FAILURE',

  UPDATE_CERTIFICATE_ROLES_REQUEST = 'UPDATE_CERTIFICATE_ROLES_REQUEST',
  UPDATE_CERTIFICATE_ROLES_SUCCESS = 'UPDATE_CERTIFICATE_ROLES_SUCCESS',
  UPDATE_CERTIFICATE_ROLES_FAILURE = 'UPDATE_CERTIFICATE_ROLES_FAILURE',

  UPDATE_CERTIFICATE_SIGNERS_REQUEST = 'UPDATE_CERTIFICATE_SIGNERS_REQUEST',
  UPDATE_CERTIFICATE_SIGNERS_SUCCESS = 'UPDATE_CERTIFICATE_SIGNERS_SUCCESS',
  UPDATE_CERTIFICATE_SIGNERS_FAILURE = 'UPDATE_CERTIFICATE_SIGNERS_FAILURE',

  INVITE_MULTIPLE_HOLDERS_REQUEST = 'INVITE_MULTIPLE_HOLDERS_REQUEST',
  INVITE_MULTIPLE_HOLDERS_SUCCESS = 'INVITE_MULTIPLE_HOLDERS_SUCCESS',
  INVITE_MULTIPLE_HOLDERS_FAILURE = 'INVITE_MULTIPLE_HOLDERS_FAILURE',

  SET_MULTIPLE_HOLDERS = 'SET_MULTIPLE_HOLDERS',

  ADD_CREATED_CERTIFICATE = 'ADD_CREATED_CERTIFICATE',

  RESET_FOR_NEW_TEMPLATE = 'RESET_FOR_NEW_TEMPLATE',
  CLEAR = 'CLEAR',
}

export default certificateCreatorConstants;
