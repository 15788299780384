import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { Button, Drawer, useMediaQuery } from '@material-ui/core';
import { Plus as PlusIcon } from 'src/components/CustomIcon';
import { ContentContainer, Logo, MenuNavigationList, SelectTemplateDialog } from 'src/components';
import palette from 'src/theme/options/palette';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PermissionResolver from 'src/components/PermissionResolver';
import { AppMenuMobile } from '../index';
import { SidenavLinks, MoreLinks, SidenavMobileLinks } from '../../Links';
import { useStyles } from './styles';

const AppMenuSidebar = ({ className: classNameProp }) => {
  const { t } = useTranslation();
  const [createDocumentOpen, setCreateDocumentOpen] = useState(false);
  const classes = useStyles();
  const desktopScreen = useMediaQuery(({ breakpoints }) => breakpoints.up('md'));
  const location = useLocation();
  const workspaceTree = useSelector((state) => state.documentsStore.workspaceTree);
  const folderPath = useSelector((state) => state.documentsStore.folderPath);
  const folderIdPathSplitted = folderPath?.idPath.split('/');
  const isEditorInCurrentFolder =
    workspaceTree && folderIdPathSplitted.length > 1
      ? workspaceTree[folderIdPathSplitted[0]]?.folders[folderIdPathSplitted[1]]?.canEdit
      : true;
  const canAddDocument =
    location.pathname !== '/recent' &&
    location.pathname !== '/shared-with-me' &&
    isEditorInCurrentFolder;

  const createDocumentDialogToggle = {
    open: () => {
      setCreateDocumentOpen(true);
    },
    close: () => {
      setCreateDocumentOpen(false);
    },
  };

  const addDocumentButtonProps = {
    label: t('sideBar.addDocument.shortText'),
    onClick: () => createDocumentDialogToggle.open(),
    icon: <PlusIcon />,
    color: palette.primary.main,
  };

  return (
    <>
      {desktopScreen ? (
        <Drawer
          open
          variant="permanent"
          anchor="left"
          classes={{
            root: clsx(classes.drawerRoot, classNameProp),
            paper: clsx(classes.drawerPaper),
          }}
        >
          <ContentContainer size="medium" className={classes.topContent}>
            <div className={classes.logo}>
              <Logo size="large" variant="inverted" />
              {/* <Img
            useSuspense={false}
            src="images/logowhite.svg"
            className={classes.image}
            container={(children) => <div className={classes.imageContainer}>{children}</div>}
            loader={
              <div className={classes.loaderWrapper}>
                <div className={classes.loader}>
                  <CircularProgress size={18} />
                </div>
              </div>
            }
          />
          <Typography variant="subtitle2" color="inherit" align="center">
            <small>Powered by DoxyChain</small>
          </Typography> */}
            </div>
            <PermissionResolver tooltip={t('sideBar.noPermission')} manualDisable={!canAddDocument}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                fullWidth
                className={classes.addButton}
                startIcon={addDocumentButtonProps.icon}
                onClick={addDocumentButtonProps.onClick}
              >
                {addDocumentButtonProps.label}
              </Button>
            </PermissionResolver>
          </ContentContainer>

          <ContentContainer
            vertical="bottom"
            horizontal={false}
            size="medium"
            className={classes.mainContent}
          >
            <MenuNavigationList
              items={SidenavLinks(t)}
              size="medium"
              variant="inverted"
              className={classes.menuDesktop}
            />
          </ContentContainer>
        </Drawer>
      ) : (
        <AppMenuMobile
          items={[...SidenavMobileLinks(t)]}
          moreItems={[...MoreLinks(t)]}
          classes={{
            item: classes.navItem,
            itemActive: classes.navItemActive,
            itemIcon: classes.navItemIcon,
          }}
        />
      )}

      <SelectTemplateDialog
        open={createDocumentOpen}
        onClose={() => createDocumentDialogToggle.close()}
      />
    </>
  );
};

AppMenuSidebar.propTypes = {
  className: PropTypes.string,
};

export default AppMenuSidebar;
