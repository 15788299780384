import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { DialogTitleWithButton, ListChoiceButton } from 'src/components';
import { useDispatch, useSelector } from 'react-redux';
import documentCreationActions from 'src/redux/actions/documentCreation.actions';
import identityActions from 'src/redux/actions/identity.actions';
import { DocumentType } from 'src/models/document.model';
import { RequestStatus } from 'src/helpers/reduxReuquest.util';
import { ErrorCode } from 'src/models/errors.model';
import { Options } from './Options';
import { useStyles } from './styles';

const SelectTemplateDialog = ({ open, onClose }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const smallScreen = useMediaQuery(({ breakpoints }) => breakpoints.down('sm'));

  const dispatch = useDispatch();
  const templateDocument = useSelector(
    (state) => state.documentCreationStore.templateDocument,
    undefined
  );
  const {
    currentIdentity,
    isOwner: isWorkspaceOwner,
    workspaceWorkflows,
    workspaceWorkflowsStatus,
    workspaceWorkflowsErrorCode,
  } = useSelector((state) => state.identityStore, undefined);

  const workspaceId = currentIdentity?.workspace?.workspaceId;
  const hasAnyPermission = workspaceWorkflows.length !== 0;

  const onDialogEnter = () => {
    dispatch(identityActions.getWorkspacePermissions(workspaceId));
    dispatch(identityActions.fetchWorkspaceWorkflows(workspaceId));
  };

  const handleChange = (value) => {
    // hide choose signature DOXY-787
    // set mail_sms as default signature level
    if (value === DocumentType.DOCUMENT_TO_BE_SIGNED) {
      dispatch(documentCreationActions.setSignatureDocument('MAIL_SMS'));
    } else {
      dispatch(documentCreationActions.setSignatureDocument(''));
    }
    dispatch(documentCreationActions.setTemplateDocument(value));
    if (
      value === DocumentType.CERTIFICATES &&
      !workspaceWorkflows.includes(DocumentType.CERTIFICATES) &&
      window?.userengage
    )
      if (!chatCertsClicked) {
        window.userengage('event.certificates_need_clicked');
        setChatCertsClicked(true);
      }
  };

  const onHandleClose = () => {
    dispatch(documentCreationActions.setTemplateDocument(null));
    onClose();
  };

  const onHandleCreate = () => {
    const slugTemplate = templateDocument.toLowerCase().replaceAll('_', '-');
    history.push(`/document-creator/${slugTemplate}`);
    onClose();
  };

  const onHandleSubmitCertsRequest = () => {
    if (window.userengage && !chatCertsSubmited) {
      setLoadingChatCerts(true);
      setChatCertsSubmited(true);
      window.userengage('event.certificates_need_submit');
      setTimeout(() => {
        if (templateDocument === DocumentType.CERTIFICATES) {
          dispatch(documentCreationActions.setTemplateDocument(null));
          setLoadingChatCerts(false);
        }
      }, 1000);
    }
  };

  const [loadingChatCerts, setLoadingChatCerts] = useState(false);
  const [chatCertsSubmited, setChatCertsSubmited] = useState(false);
  const [chatCertsClicked, setChatCertsClicked] = useState(false);

  return (
    <Dialog
      fullScreen={smallScreen}
      maxWidth="xs"
      disableBackdropClick
      disableEscapeKeyDown
      open={open}
      className={classes.root}
      onEnter={onDialogEnter}
    >
      <DialogTitleWithButton
        title={!smallScreen ? t('selectTemplate.title') : null}
        titleVariant="h3"
        onClose={onHandleClose}
      />

      <DialogContent>
        <div className={classes.content}>
          {smallScreen && (
            <Typography variant="h3" className={classes.mobileTitle}>
              {t('selectTemplate.title')}
            </Typography>
          )}

          <div className={classes.buttons}>
            {!hasAnyPermission && workspaceWorkflowsStatus === RequestStatus.SUCCESS && (
              <Alert severity="warning">
                <Typography>{t('selectTemplate.permissionAlert')}</Typography>
                {isWorkspaceOwner && (
                  <Button
                    size="small"
                    variant="outlined"
                    classes={{ outlinedPrimary: classes.alertButton }}
                    component={Link}
                    to="/workspace-settings/subscriptions-and-billing/manage"
                  >
                    {t('common.learnMore')}
                  </Button>
                )}
              </Alert>
            )}
            {workspaceWorkflowsErrorCode === ErrorCode.IDENTITY_NOT_READY && (
              <Alert severity="warning">
                <Typography>{t('selectTemplate.identityAlert')}</Typography>
              </Alert>
            )}

            {Options(t).map((item, index) => (
              <ListChoiceButton
                key={index}
                icon={item.icon}
                iconColor={item.iconColor}
                label={item.label}
                description={item.description}
                iconVariant={item.iconVariant}
                disabled={
                  // how we ended up there: https://doxychain.slack.com/archives/C03NL5UFHQU/p1676625244154599
                  // disable item when there is no internet connection
                  // or when it is neither a cert nor within workspace permissions
                  workspaceWorkflowsStatus !== RequestStatus.SUCCESS ||
                  (item.value !== DocumentType.CERTIFICATES &&
                    !workspaceWorkflows.includes(item.value))
                }
                value={item.value}
                onClick={() => handleChange(item.value)}
                selected={item.value === templateDocument}
              />
            ))}
            {templateDocument === DocumentType.CERTIFICATES &&
              !workspaceWorkflows.includes(DocumentType.CERTIFICATES) && (
                <Alert severity="info">
                  <Typography>{t('selectTemplate.noCertificates.alert')}</Typography>
                  {isWorkspaceOwner && (
                    <Button
                      size="small"
                      variant="outlined"
                      classes={{ outlinedPrimary: classes.alertButton }}
                      onClick={onHandleSubmitCertsRequest}
                      endIcon={loadingChatCerts && <CircularProgress size={20} color="inherit" />}
                      disabled={chatCertsSubmited}
                    >
                      {t('selectTemplate.noCertificates.button')}
                    </Button>
                  )}
                </Alert>
              )}
          </div>
        </div>
      </DialogContent>

      <DialogActions>
        <Button
          className={classes.actionButton}
          fullWidth
          size="large"
          onClick={onHandleCreate}
          disabled={
            !templateDocument ||
            !hasAnyPermission ||
            (templateDocument === DocumentType.CERTIFICATES &&
              !workspaceWorkflows.includes(DocumentType.CERTIFICATES))
          }
        >
          {t('selectTemplate.apply')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

SelectTemplateDialog.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
};
SelectTemplateDialog.defaultProps = {
  open: false,
};

export default SelectTemplateDialog;
