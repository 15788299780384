import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useDragLayer } from 'react-dnd';
import { useStyles } from './styles';
import ItemsTemplate from './ItemsTemplate';

const FieldDragLayer = ({ selectedRows }) => {
  const classes = useStyles();
  const { itemType, isDragging, currentOffset } = useDragLayer((monitor) => ({
    itemType: monitor.getItemType(),
    currentOffset: monitor.getClientOffset(),
    isDragging: monitor.isDragging(),
  }));

  const getItemStyles = (currentOffset) => {
    let itemStyle;

    if (!currentOffset) {
      itemStyle = { display: 'none' };
    } else {
      const { x, y } = currentOffset;
      const transform = `translate(${x}px, ${y}px)`;
      itemStyle = {
        transform,
        WebkitTransform: transform,
      };
    }

    return itemStyle;
  };

  const renderItem = (type) => type === 'ITEM' && <ItemsTemplate fields={selectedRows} />;

  return (
    isDragging && (
      <div className={clsx(classes.layer)} style={getItemStyles(currentOffset)}>
        {renderItem(itemType)}
      </div>
    )
  );
};

FieldDragLayer.propTypes = {
  selectedRows: PropTypes.arrayOf(PropTypes.string),
};

export default FieldDragLayer;
