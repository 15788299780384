import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { ButtonBase, Tooltip } from '@material-ui/core';
import { MobileBottomMenuList } from 'src/components';
import { MoreHor as OptionsIcon } from 'src/components/CustomIcon';
import { useStyles } from './styles';

const SpeedDial = ({ className: classNameProp, icon, primaryAction, actions, tooltip }) => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const handleToggle = {
    close: () => {
      setOpen(false);
    },
    open: () => {
      setOpen(true);
    },
  };

  const hasPrimaryAction = primaryAction !== undefined;
  const hasMenuActions = actions !== undefined;
  const menuActions = hasMenuActions ? actions : [];

  const renderButton = () => (
    <ButtonBase
      onClick={hasPrimaryAction ? primaryAction.onClick : handleToggle.open}
      classes={{ root: clsx(classes.button, classNameProp) }}
    >
      {icon || <OptionsIcon />}
    </ButtonBase>
  );

  if (!hasPrimaryAction && !hasMenuActions) {
    return null;
  }
  return (
    <div className={classes.root}>
      {tooltip ? (
        <Tooltip
          title={hasPrimaryAction && !hasMenuActions && primaryAction.name}
          placement="right"
        >
          {renderButton()}
        </Tooltip>
      ) : (
        renderButton()
      )}

      <MobileBottomMenuList
        actions={hasPrimaryAction ? menuActions.concat(primaryAction) : menuActions}
        open={open}
        onClose={handleToggle.close}
      />
    </div>
  );
};

SpeedDial.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.node,
  primaryAction: PropTypes.shape({
    icon: PropTypes.node,
    name: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
  }),
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.node,
      name: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
    })
  ),
  tooltip: PropTypes.string,
};

SpeedDial.defaultProps = {};

export default SpeedDial;
