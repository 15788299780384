import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  ({ breakpoints, palette, spacing, transitions }) =>
    createStyles({
      root: {},
      listItemContainer: {
        '&:first-child': {
          marginTop: spacing(-2),
        },
        '&:last-child': {
          marginBottom: spacing(-2),
        },
        '& $listItemRoot': {
          paddingRight: 32,
        },
      },
      listItemRoot: {
        color: palette.text.primary,
        padding: spacing(2, 0),
        [breakpoints.up('md')]: {
          '&:first-child': {
            paddingTop: 0,
          },
          '&:last-child': {
            paddingBottom: 0,
          },
        },
        '&:hover': {
          color: 'inherit',
          '& $actionButton': {
            color: palette.text.primary,
          },
          '& $colorOnHover, & $actionButtonIcon': {
            color: `${palette.primary.main} !important`,
          },
        },
      },
      listItemDeleted: {
        opacity: 0.5,
        pointerEvents: 'none',
      },
      colorOnHover: {
        transition: transitions.create(['color'], {
          duration: transitions.duration.short,
        }),
      },
      actionButtonsWrapper: {
        textAlign: 'right',
        marginRight: spacing(-1.25),
        [breakpoints.down('md')]: {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-end',
        },
      },
      actionButton: {
        paddingLeft: spacing(1.25),
        paddingRight: spacing(1.25),
      },
      actionButtonIcon: {
        transition: 'inherit',
        color: `${palette.text.secondary} !important`,
      },
      mobileActionButtonWrapper: {
        display: 'flex',
        flexDirection: 'column',
        right: spacing(-1),
      },
      membersCell: {
        display: 'flex',
        alignItems: 'center',
        '& > *:first-child': {
          minWidth: spacing(2.5),
          [breakpoints.up('md')]: {
            minWidth: spacing(3),
          },
        },
        '& > *:not(:last-child)': {
          marginRight: spacing(0.5),
          [breakpoints.up('md')]: {
            marginRight: spacing(1),
          },
        },
      },
    }),
  { index: 0 }
);
