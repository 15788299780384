import { createStyles, makeStyles } from '@material-ui/core';
import { fade } from '@material-ui/core/styles';

export const useStyles = makeStyles(
  ({ breakpoints, palette, spacing, typography }) =>
    createStyles({
      root: {},
      mainItem: {
        '&:not(:last-child)': {
          marginBottom: spacing(1.5),
          '& $subItems': {
            paddingBottom: spacing(1),
          },
        },
      },
      smallMainItem: {
        '&:not(:last-child)': {
          marginBottom: spacing(1),
          '& $subItems': {
            paddingBottom: spacing(0.5),
          },
        },
      },
      mediumMainItem: {
        '&:not(:last-child)': {
          marginBottom: spacing(1.5),
          '& $subItems': {
            paddingBottom: spacing(1),
          },
        },
      },
      largeMainItem: {
        '&:not(:last-child)': {
          marginBottom: spacing(2.5),
          '& $subItems': {
            paddingBottom: spacing(2),
          },
        },
      },
      item: {
        background: 'transparent',
        width: '100%',
        fontFamily: typography.h5.fontFamily,
        fontWeight: typography.fontWeightMedium,
        paddingLeft: spacing(2),
        paddingRight: spacing(2),
        whiteSpace: 'initial',
        '&:not($nestedItem)': {
          fontSize: typography.h5.fontSize,
        },
        '& $itemIcon': {
          transition: 'inherit',
        },
        '&$itemActive': {
          fontWeight: typography.fontWeightBold,
        },
        '&:hover, &:focus, &$itemActive': {
          background: 'transparent',
        },
      },
      smallItemSize: {},
      mediumItemSize: {
        [breakpoints.up('md')]: {
          paddingLeft: spacing(2.5),
          paddingRight: spacing(2.5),
          '&$nestedItem': {
            paddingLeft: spacing(2.5) + 32,
          },
        },
        [breakpoints.up('lg')]: {
          paddingLeft: spacing(3.5),
          paddingRight: spacing(3.5),
          '&$nestedItem': {
            paddingLeft: spacing(3.5) + 32,
          },
        },
      },
      largeItemSize: {
        [breakpoints.up('md')]: {
          paddingLeft: spacing(3),
          paddingRight: spacing(3),
          '&$nestedItem': {
            paddingLeft: spacing(3) + 32,
          },
        },
        [breakpoints.up('lg')]: {
          paddingLeft: spacing(5),
          paddingRight: spacing(5),
          '&$nestedItem': {
            paddingLeft: spacing(5) + 32,
          },
        },
      },
      nestedItem: {
        fontSize: '1rem',
        fontWeight: typography.fontWeightRegular,
        paddingLeft: spacing(2) + 32,
        '&$itemActive': {
          fontWeight: typography.fontWeightMedium,
        },
      },
      itemActive: {},
      itemIcon: {},
      itemNoClick: {
        pointerEvents: 'none',
      },
      subItems: {},
      defaultColor: {
        '& $item': {
          color: palette.text.primary,
          '&:hover:not($itemActive), &:focus:not($itemActive)': {
            '& $itemIcon': {
              color: palette.text.primary,
              filter: `drop-shadow(0px 8px 14px ${fade(palette.text.primary, 0.45)})`,
            },
          },
        },
        '& $nestedItem': {
          color: palette.text.secondary,
          '&:hover:not($itemActive), &:focus:not($itemActive)': {
            color: palette.text.primary,
          },
          '&$itemActive': {
            color: palette.primary.main,
          },
        },
        '& $itemActive': {
          '& $itemIcon': {
            color: palette.primary.main,
            '& svg': {
              filter: `drop-shadow(0px 8px 14px ${fade(palette.primary.main, 0.45)})`,
            },
          },
        },
        '& $itemIcon': {
          color: palette.grey.main,
        },
      },
      invertedColor: {
        '& $item': {
          color: palette.primary.contrastText,
          '&:hover:not($itemActive), &:focus:not($itemActive)': {
            '& $itemIcon': {
              color: fade(palette.primary.contrastText, palette.tonalOffset * 3),
            },
          },
        },
        '& $nestedItem': {
          color: fade(palette.primary.contrastText, 0.35),
          '&:hover:not($itemActive), &:focus:not($itemActive)': {
            color: fade(palette.primary.contrastText, 0.5),
          },
          '&$itemActive': {
            color: palette.primary.light,
          },
        },
        '& $itemActive': {
          '& $itemIcon': {
            color: palette.primary.light,
            '& svg': {
              filter: `drop-shadow(0px 8px 14px ${fade(palette.primary.light, 0.45)})`,
            },
          },
        },
        '& $itemIcon': {
          color: fade(palette.primary.contrastText, palette.tonalOffset),
        },
      },
    }),
  { index: 0 }
);
