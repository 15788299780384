import React from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { DropZone, FullScreenDialog, SignatureSelection, WaitingForBlockchain } from '../index';
import { useStyles } from './styles';
import { DropzoneVariant } from '../DropZone/helpers';

const CreatorDocument = ({ openedStep, handleClose, creatorDocumentStepper }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const open = openedStep !== false;
  const smallScreen = useMediaQuery(({ breakpoints }) => breakpoints.down('sm'));

  const renderStep = () => {
    switch (openedStep) {
      case 1:
        return (
          <DropZone
            variant={DropzoneVariant.DOCUMENT}
            smallScreen={smallScreen}
            creatorDocumentStepper={creatorDocumentStepper}
            uploadStepTitle={t('dropzones.uploadTitle')}
          />
        );

      case 2:
        return (
          <SignatureSelection
            smallScreen={smallScreen}
            creatorDocumentStepper={creatorDocumentStepper}
          />
        );

      case 4:
        return <WaitingForBlockchain creatorDocumentStepper={creatorDocumentStepper} />;

      default:
        return null;
    }
  };

  return (
    <section className={classes.root}>
      <FullScreenDialog isOpen={open} onClose={handleClose} withLogo={!smallScreen}>
        {renderStep()}
      </FullScreenDialog>
    </section>
  );
};

CreatorDocument.propTypes = {
  openedStep: PropTypes.oneOf([false, 1, 2, 3, 4, 5]),
  handleClose: PropTypes.func,
  creatorDocumentStepper: PropTypes.func,
};
CreatorDocument.defaultProps = {
  openedStep: false,
};

export default CreatorDocument;
