import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  ({ breakpoints, palette, spacing }) =>
    createStyles({
      root: {},
      content: {
        [breakpoints.down('sm')]: {
          flex: 1,
        },
      },
      innerContent: {
        margin: `0 0 ${spacing(-1)}px 0`,
      },
      section: {
        margin: '0 auto 40px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        minHeight: '100%',
        [breakpoints.down('sm')]: {
          maxWidth: breakpoints.values.sm,
        },
      },
      switchInputMobileRoot: {
        marginRight: 0,
      },
      switchMobileRoot: {
        alignItems: 'flex-end',
      },
      switchLabel: {
        fontSize: '10px !important',
      },
      sectionSwitchLabel: {
        '& span': {
          fontSize: 12,
          color: palette.grey.darkest,
        },
      },
      switchLabelChecked: {
        color: palette.primary.main,
      },
      actionButton: {
        display: 'block',
        margin: '0 auto',
        [breakpoints.down('sm')]: {
          maxWidth: breakpoints.values.xs,
        },
      },
      helpIcon: {
        fontSize: 16,
        color: palette.primary.main,
      },
      switchWrapper: {
        display: 'flex',
        alignItems: 'center',
      },
      tagWrapper: {
        marginLeft: 'auto',
      },
      tag: {
        fontSize: 12,
        padding: '3px 10px',
      },
      row: {
        display: 'flex',
        alignItems: 'center',
      },
      helpIconCheckbox: {
        color: palette.primary.main,
        marginLeft: '10px',
      },
      switchBottomWrapper: {
        marginBottom: 8,
      },
    }),
  { index: 0 }
);
