import { makeStyles, createStyles } from '@material-ui/core';

// eslint-disable-next-line import/prefer-default-export
export const useStyles = makeStyles(
  ({ breakpoints }) =>
    createStyles({
      dragIcon: {
        position: 'absolute',
        cursor: 'grab',
        right: '100%',
        padding: 8,
        display: 'flex',
        [breakpoints.down('sm')]: {
          left: -23,
        },
        '& svg': {
          fontSize: 16,
        },
      },
    }),
  { index: 0 }
);
