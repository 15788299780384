import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useStyles } from '../styles';

const IconCell = ({ className: classNameProp, icon, ...otherProps }) => {
  const classes = useStyles();
  return (
    <div className={clsx(classes.iconCell, classNameProp)} {...otherProps}>
      {icon}
    </div>
  );
};

IconCell.propTypes = {
  icon: PropTypes.node,
  className: PropTypes.string,
};

export default IconCell;
