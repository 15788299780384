import React, { useEffect, useState } from 'react';
import { CreatorDocument } from 'src/components';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import documentCreationActions from 'src/redux/actions/documentCreation.actions';
import identityActions from 'src/redux/actions/identity.actions';
import { goBack } from 'src/helpers/goBack';
import { RequestStatus } from 'src/helpers/reduxReuquest.util';
import { DocumentType } from '../../models/document.model';
import CertificateCreator from '../CertificateCreator';
import DocumentCreatorWebsocket from './DocumentCreatorWebsocket';

const DocumentCreatorPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    filesListDropzone,
    templateDocument,
    signatureDocument,
    createNewDocumentStatus,
    newDocument,
  } = useSelector((state) => state.documentCreationStore, undefined);
  const { currentIdentity } = useSelector((state) => state.identityStore, undefined);
  const [openedStep, setOpenedStep] = useState(1);

  useEffect(() => {
    if (createNewDocumentStatus === RequestStatus.SUCCESS) {
      dispatch(
        identityActions.sendTransactionFromNewDocument(newDocument.txn, () =>
          dispatch(documentCreationActions.clearDocumentCreatorStore())
        )
      );
      setOpenedStep(4);
    }
  }, [createNewDocumentStatus, dispatch, newDocument]);

  const creatorDocumentStepper = (stepNumber) => {
    switch (stepNumber) {
      case 1:
        setOpenedStep(1);
        break;

      case 2:
        createDocument();
        break;

      case 3:
        createDocument();
        break;

      case 4:
      case 5:
        break;
      default:
        handleClose();
        break;
    }
  };

  const createDocument = () => {
    const name = `Document #${Math.floor(Math.random() * 100000 + 1)}`;
    const workspaceId = currentIdentity?.workspace?.workspaceId;
    const filesId = filesListDropzone
      .filter((file) => file.uploadStatus === RequestStatus.SUCCESS)
      .map((file) => file.fileId);

    const currentFolderId = localStorage.getItem('currentFolderId');
    dispatch(
      documentCreationActions.createNewDocument(
        workspaceId,
        name,
        templateDocument,
        filesId,
        signatureDocument,
        currentFolderId
      )
    );
  };

  const handleClose = () => {
    const currentFolderId = localStorage.getItem('currentFolderId');
    if (currentFolderId) {
      currentFolderId === 'root'
        ? history.push(`/drive/`)
        : history.push(`/drive/${currentFolderId}`);
    } else {
      goBack(history);
    }
    setTimeout(() => {
      dispatch(documentCreationActions.clearDocumentCreatorStore());
    }, 500);
  };

  return (
    <>
      <DocumentCreatorWebsocket />
      {templateDocument === DocumentType.CERTIFICATES ? (
        <CertificateCreator />
      ) : (
        <CreatorDocument
          openedStep={openedStep}
          handleClose={handleClose}
          creatorDocumentStepper={creatorDocumentStepper}
        />
      )}
    </>
  );
};

export default DocumentCreatorPage;
