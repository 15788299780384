import { RequestStatus } from 'src/helpers/reduxReuquest.util';
import billingConstants from '../constants/billing.constants';

const initialState = {
  accessToWorkspace: false,
  estimateSubscriptionPriceStatus: RequestStatus.IDLE,
  estimatedSubscriptionPrice: null,
  estimatedSubscriptionPriceWithDiscount: false,
  fetchAccessToWorkspaceStatus: RequestStatus.IDLE,
  initializeCustomerCardStatus: RequestStatus.IDLE,
  invoiceUserData: null,
  invoiceUserDataStatus: RequestStatus.IDLE,
  promotionCodeStatus: RequestStatus.IDLE,
  promotionData: null,
  reactiveSubscribePlanStatus: RequestStatus.IDLE,
  subscribePlanStatus: RequestStatus.IDLE,
  subscribePlanTaxIdError: false,
  unsubscribePlanStatus: RequestStatus.IDLE,
};

const removeUsedCouponFromEstimatedSubscriptionPlan = (estimatedSubscriptionPrice) => {
  const estimatedObject = estimatedSubscriptionPrice;
  delete estimatedObject?.discount;

  return estimatedObject;
};

function billingStore(state = initialState, action) {
  switch (action.type) {
    case billingConstants.ESTIMATE_SUBSCRIPTION_PRICE_REQUEST:
      return {
        ...state,
        estimateSubscriptionPriceStatus: RequestStatus.PENDING,
        estimatedSubscriptionPriceWithDiscount: false,
      };
    case billingConstants.ESTIMATE_SUBSCRIPTION_PRICE_SUCCESS:
      return {
        ...state,
        estimateSubscriptionPriceStatus: RequestStatus.SUCCESS,
        estimatedSubscriptionPrice: action.data,
        estimatedSubscriptionPriceWithDiscount: !!action.data?.discount,
      };
    case billingConstants.ESTIMATE_SUBSCRIPTION_PRICE_FAILURE:
      if (action.couponNotFound) {
        return {
          ...state,
          estimateSubscriptionPriceStatus: RequestStatus.SUCCESS,
          estimatedSubscriptionPrice: removeUsedCouponFromEstimatedSubscriptionPlan(
            state.estimatedSubscriptionPrice
          ),
          estimatedSubscriptionPriceWithDiscount: false,
        };
      }
      return {
        ...state,
        estimateSubscriptionPriceStatus: RequestStatus.ERROR,
      };

    case billingConstants.FETCH_ACCESS_TO_WORKSPACE_REQUEST:
      return {
        ...state,
        fetchAccessToWorkspaceStatus: RequestStatus.PENDING,
      };
    case billingConstants.FETCH_ACCESS_TO_WORKSPACE_SUCCESS:
      return {
        ...state,
        fetchAccessToWorkspaceStatus: RequestStatus.SUCCESS,
        accessToWorkspace: action.accessToWorkspace,
      };
    case billingConstants.FETCH_ACCESS_TO_WORKSPACE_FAILURE:
      return {
        ...state,
        fetchAccessToWorkspaceStatus: action.accessToWorkspaceFailedFetch
          ? RequestStatus.ERROR
          : RequestStatus.SUCCESS,
        accessToWorkspace: action.accessToWorkspace,
      };

    case billingConstants.INITIALIZE_CUSTOMER_CARD_REQUEST:
      return {
        ...state,
        initializeCustomerCardStatus: RequestStatus.PENDING,
      };
    case billingConstants.INITIALIZE_CUSTOMER_CARD_SUCCESS:
      return {
        ...state,
        initializeCustomerCardStatus: RequestStatus.SUCCESS,
      };
    case billingConstants.INITIALIZE_CUSTOMER_CARD_FAILURE:
      return {
        ...state,
        initializeCustomerCardStatus: RequestStatus.ERROR,
      };

    case billingConstants.SUBSCRIBE_PLAN_REQUEST:
      return {
        ...state,
        subscribePlanStatus: RequestStatus.PENDING,
        subscribePlanTaxIdError: false,
      };
    case billingConstants.SUBSCRIBE_PLAN_SUCCESS:
      return {
        ...state,
        subscribePlanStatus: RequestStatus.SUCCESS,
        subscribePlanTaxIdError: false,
      };
    case billingConstants.SUBSCRIBE_PLAN_FAILURE:
      return {
        ...state,
        subscribePlanStatus: RequestStatus.ERROR,
        subscribePlanTaxIdError: action?.taxIdError,
      };
    case billingConstants.UNSUBSCRIBE_PLAN_REQUEST:
      return {
        ...state,
        unsubscribePlanStatus: RequestStatus.PENDING,
      };
    case billingConstants.UNSUBSCRIBE_PLAN_SUCCESS:
      return {
        ...state,
        unsubscribePlanStatus: RequestStatus.SUCCESS,
      };
    case billingConstants.UNSUBSCRIBE_PLAN_FAILURE:
      return {
        ...state,
        unsubscribePlanStatus: RequestStatus.ERROR,
      };
    case billingConstants.REACTIVE_SUBSCRIBE_PLAN_REQUEST:
      return {
        ...state,
        reactiveSubscribePlanStatus: RequestStatus.PENDING,
      };
    case billingConstants.REACTIVE_SUBSCRIBE_PLAN_SUCCESS:
      return {
        ...state,
        reactiveSubscribePlanStatus: RequestStatus.SUCCESS,
      };
    case billingConstants.REACTIVE_SUBSCRIBE_PLAN_FAILURE:
      return {
        ...state,
        reactiveSubscribePlanStatus: RequestStatus.ERROR,
      };
    case billingConstants.FETCH_INVOICE_USER_DATA_REQUEST:
      return {
        ...state,
        invoiceUserDataStatus: RequestStatus.PENDING,
      };
    case billingConstants.FETCH_INVOICE_USER_DATA_SUCCESS:
      return {
        ...state,
        invoiceUserDataStatus: RequestStatus.SUCCESS,
        invoiceUserData: action.data,
      };
    case billingConstants.FETCH_INVOICE_USER_DATA_FAILURE:
      return {
        ...state,
        invoiceUserDataStatus: RequestStatus.ERROR,
      };
    case billingConstants.ADD_PROMOTION_CODE_REQUEST:
      return {
        ...state,
        promotionCodeStatus: RequestStatus.PENDING,
      };
    case billingConstants.ADD_PROMOTION_CODE_SUCCESS:
      return {
        ...state,
        promotionCodeStatus: RequestStatus.SUCCESS,
        promotionData: action.data,
      };
    case billingConstants.ADD_PROMOTION_CODE_FAILURE:
      return {
        ...state,
        promotionCodeStatus: RequestStatus.ERROR,
      };

    case billingConstants.CLEAR_BILLING_STORE:
      return {
        ...initialState,
      };

    default:
      return state;
  }
}

export default billingStore;
