import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Button } from '@material-ui/core';
import { EmptyBanner, SmallContentWithScrollbar } from 'src/components';
import { useStyles } from './styles';

const NoDocumentsBanner = ({
  className: classNameProp,
  header,
  headerVariant,
  description,
  showButton,
  buttonText,
  onClick,
}) => {
  const classes = useStyles();
  return (
    <div className={clsx(classes.root, classNameProp)}>
      <SmallContentWithScrollbar
        size="large"
        endContent={
          showButton &&
          typeof onClick === 'function' &&
          buttonText && (
            <Button size="large" fullWidth className={classes.button} onClick={onClick}>
              {buttonText}
            </Button>
          )
        }
      >
        <EmptyBanner
          src="/images/frames/first-document.svg"
          title={header}
          titleVariant={headerVariant}
          description={description}
        />
      </SmallContentWithScrollbar>
    </div>
  );
};

NoDocumentsBanner.propTypes = {
  className: PropTypes.string,
  header: PropTypes.string,
  headerVariant: PropTypes.oneOfType([
    PropTypes.oneOf([
      'body1',
      'body2',
      'button',
      'caption',
      'h1',
      'h2',
      'h3',
      'h4',
      'h5',
      'h6',
      'inherit',
      'overline',
      'subtitle1',
      'subtitle2',
    ]),
    PropTypes.string,
  ]),
  description: PropTypes.string,
  showButton: PropTypes.bool,
  buttonText: PropTypes.string,
  onClick: PropTypes.func,
};

NoDocumentsBanner.defaultProps = {
  headerVariant: 'h1',
  showButton: false,
};

export default NoDocumentsBanner;
