export const taxIdTypes = [
  {
    label: 'Australia',
    enum: 'au_abn',
    prefix: '',
    example: '12345678912',
    description: 'Australian Business Number (AU ABN)',
  },
  {
    label: 'Austria',
    enum: 'eu_vat',
    prefix: 'ATU',
    example: '12345678',
    description: 'European VAT number',
  },
  {
    label: 'Belgium',
    enum: 'eu_vat',
    prefix: 'BE',
    example: '0123456789',
    description: 'European VAT number',
  },
  {
    label: 'Brazil (CNPJ number)',
    enum: 'br_cnpj',
    prefix: '',
    example: '01.234.456/5432-10',
    description: 'Brazilian CNPJ number',
  },
  {
    label: 'Brazil (CPF number)',
    enum: 'br_cpf',
    prefix: '',
    example: '123.456.789-87',
    description: 'Brazilian CPF number',
  },
  {
    label: 'Bulgaria',
    enum: 'eu_vat',
    prefix: 'BG',
    example: '0123456789',
    description: 'European VAT number',
  },
  {
    label: 'Canada (BN)',
    enum: 'ca_bn',
    prefix: '',
    example: '123456789',
    description: 'Canadian BN',
  },
  {
    label: 'Canada (GST/HST number)',
    enum: 'ca_gst_hst',
    prefix: '',
    example: '123456789RT0002',
    description: 'Canadian GST/HST number',
  },
  {
    label: 'Canada (PST number - British Columbia)',
    enum: 'ca_pst_bc',
    prefix: 'PST-',
    example: '1234-5678',
    description: 'Canadian PST number (British Columbia)',
  },
  {
    label: 'Canada (PST number - Manitoba)',
    enum: 'ca_pst_mb',
    prefix: '',
    example: '123456-7',
    description: 'Canadian PST number (Manitoba)',
  },
  {
    label: 'Canada (PST number - Saskatchewan)',
    enum: 'ca_pst_sk',
    prefix: '',
    example: '1234567',
    description: 'Canadian PST number (Saskatchewan)',
  },
  {
    label: 'Canada (QST number - Québec)',
    enum: 'ca_qst',
    prefix: '',
    example: '1234567890TQ1234',
    description: 'Canadian QST number (Québec)',
  },
  {
    label: 'Chile',
    enum: 'cl_tin',
    prefix: '',
    example: '12.345.678-K',
    description: 'Chilean TIN',
  },
  {
    label: 'Croatia',
    enum: 'eu_vat',
    prefix: 'HR',
    example: '12345678912',
    description: 'European VAT number',
  },
  {
    label: 'Cyprus',
    enum: 'eu_vat',
    prefix: 'CY',
    example: '12345678Z',
    description: 'European VAT number',
  },
  {
    label: 'Czech Republic',
    enum: 'eu_vat',
    prefix: 'CZ',
    example: '1234567890',
    description: 'European VAT number',
  },
  {
    label: 'Denmark',
    enum: 'eu_vat',
    prefix: 'DK',
    example: '12345678',
    description: 'European VAT number',
  },
  {
    label: 'Estonia',
    enum: 'eu_vat',
    prefix: 'EE',
    example: '123456789',
    description: 'European VAT number',
  },
  {
    label: 'Finland',
    enum: 'eu_vat',
    prefix: 'FI',
    example: '12345678',
    description: 'European VAT number',
  },
  {
    label: 'France',
    enum: 'eu_vat',
    prefix: 'FR',
    example: 'AB123456789',
    description: 'European VAT number',
  },
  {
    label: 'Germany',
    enum: 'eu_vat',
    prefix: 'DE',
    example: '123456789',
    description: 'European VAT number',
  },
  {
    label: 'Greece',
    enum: 'eu_vat',
    prefix: 'EL',
    example: '123456789',
    description: 'European VAT number',
  },
  {
    label: 'Hong Kong',
    enum: 'hk_br',
    prefix: '',
    example: '12345678',
    description: 'Hong Kong BR number',
  },
  {
    label: 'Hungary',
    enum: 'eu_vat',
    prefix: 'HU',
    example: '12345678912',
    description: 'European VAT number',
  },
  {
    label: 'India',
    enum: 'in_gst',
    prefix: '',
    example: '12ABCDE3456FGZH',
    description: 'Indian GST number',
  },
  {
    label: 'Indonesia',
    enum: 'id_npwp',
    prefix: '',
    example: '12.345.678.9-012.345',
    description: 'Indonesian NPWP number',
  },
  {
    label: 'Ireland',
    enum: 'eu_vat',
    prefix: 'IE',
    example: '1234567AB',
    description: 'European VAT number',
  },
  {
    label: 'Israel',
    enum: 'il_vat',
    prefix: '',
    example: '000012345',
    description: 'Israel VAT',
  },
  {
    label: 'Italy',
    enum: 'eu_vat',
    prefix: 'IT',
    example: '12345678912',
    description: 'European VAT number',
  },
  {
    label: 'Japan (Corporate Number)',
    enum: 'jp_cn',
    prefix: '',
    example: '1234567891234',
    description: 'Japanese Corporate Number (*Hōjin Bangō*)',
  },
  {
    label: "Japan (Registered Foreign Businesses' Registration Number)",
    enum: 'jp_rn',
    prefix: '',
    example: '12345',
    description:
      "Japanese Registered Foreign Businesses' Registration Number (*Tōroku Kokugai Jigyōsha no Tōroku Bangō*)",
  },
  {
    label: 'Korea, Republic of',
    enum: 'kr_brn',
    prefix: '',
    example: '123-45-67890',
    description: 'Korean BRN',
  },
  {
    label: 'Latvia',
    enum: 'eu_vat',
    prefix: 'LV',
    example: '12345678912',
    description: 'European VAT number',
  },
  {
    label: 'Liechtenstein',
    enum: 'li_uid',
    prefix: 'CHE',
    example: '123456789',
    description: 'Liechtensteinian UID number',
  },
  {
    label: 'Lithuania',
    enum: 'eu_vat',
    prefix: 'LT',
    example: '123456789123',
    description: 'European VAT number',
  },
  {
    label: 'Luxembourg',
    enum: 'eu_vat',
    prefix: 'LU',
    example: '12345678',
    description: 'European VAT number',
  },
  {
    label: 'Malaysia (FRP)',
    enum: 'my_frp',
    prefix: '',
    example: '12345678',
    description: 'Malaysian FRP number',
  },
  {
    label: 'Malaysia (ITN)',
    enum: 'my_itn',
    prefix: 'C ',
    example: '1234567890',
    description: 'Malaysian ITN',
  },
  {
    label: 'Malta',
    enum: 'eu_vat',
    prefix: 'MT',
    example: '12345678',
    description: 'European VAT number',
  },
  {
    label: 'Mexico',
    enum: 'mx_rfc',
    prefix: '',
    example: 'ABC010203AB9',
    description: 'Mexican RFC number',
  },
  {
    label: 'Netherlands',
    enum: 'eu_vat',
    prefix: 'NL',
    example: '123456789B12',
    description: 'European VAT number',
  },
  {
    label: 'New Zealand',
    enum: 'nz_gst',
    prefix: '',
    example: '123456789',
    description: 'New Zealand GST number',
  },
  {
    label: 'Norway',
    enum: 'no_vat',
    prefix: '',
    suffix: 'MVA',
    example: '123456789',
    description: 'Norwegian VAT number',
  },
  {
    label: 'Poland',
    enum: 'eu_vat',
    prefix: 'PL',
    example: '1234567890',
    description: 'European VAT number',
  },
  {
    label: 'Portugal',
    enum: 'eu_vat',
    prefix: 'PT',
    example: '123456789',
    description: 'European VAT number',
  },
  {
    label: 'Romania',
    enum: 'eu_vat',
    prefix: 'RO',
    example: '1234567891',
    description: 'European VAT number',
  },
  {
    label: 'Russian Federation (INN)',
    enum: 'ru_inn',
    prefix: '',
    example: '1234567891',
    description: 'Russian INN',
  },
  {
    label: 'Russian Federation (KPP)',
    enum: 'ru_kpp',
    prefix: '',
    example: '123456789',
    description: 'Russian KPP',
  },
  {
    label: 'Saudi Arabia',
    enum: 'sa_vat',
    prefix: '',
    example: '123456789012345',
    description: 'Saudi Arabia VAT',
  },
  {
    label: 'Singapore (GST)',
    enum: 'sg_gst',
    prefix: '',
    example: 'M12345678X',
    description: 'Singaporean GST',
  },
  {
    label: 'Singapore (UEN)',
    enum: 'sg_uen',
    prefix: '',
    example: '123456789F',
    description: 'Singaporean UEN',
  },
  {
    label: 'Slovakia',
    enum: 'eu_vat',
    prefix: 'SK',
    example: '1234567891',
    description: 'European VAT number',
  },
  {
    label: 'Slovenia',
    enum: 'eu_vat',
    prefix: 'SI',
    example: '12345678',
    description: 'European VAT number',
  },
  {
    label: 'South Africa',
    enum: 'za_vat',
    prefix: '',
    example: '4123456789',
    description: 'South African VAT number',
  },
  {
    label: 'Spain (CIF number)',
    enum: 'es_cif',
    prefix: '',
    example: 'A12345678',
    description: 'Spanish CIF number',
  },
  {
    label: 'Spain (European VAT number)',
    enum: 'eu_vat',
    prefix: 'ES',
    example: 'A1234567Z',
    description: 'European VAT number',
  },
  {
    label: 'Sweden',
    enum: 'eu_vat',
    prefix: 'SE',
    example: '123456789123',
    description: 'European VAT number',
  },
  {
    label: 'Switzerland',
    enum: 'ch_vat',
    prefix: 'CHE-',
    suffix: ' MWST',
    example: '123.456.789',
    description: 'Switzerland VAT number',
  },
  {
    label: 'Taiwan',
    enum: 'tw_vat',
    prefix: '',
    example: '12345678',
    description: 'Taiwanese VAT',
  },
  {
    label: 'Thailand',
    enum: 'th_vat',
    prefix: '',
    example: '1234567891234',
    description: 'Thai VAT',
  },
  {
    label: 'United Arab Emirates',
    enum: 'ae_trn',
    prefix: '',
    example: '123456789012345',
    description: 'United Arab Emirates TRN',
  },
  {
    label: 'United Kingdom (United Kingdom VAT number)',
    enum: 'gb_vat',
    prefix: 'GB',
    example: '123456789',
    description: 'United Kingdom VAT number',
  },
  {
    label: 'United Kingdom (Northern Ireland VAT number)',
    enum: 'eu_vat',
    prefix: 'XI',
    example: '123456789',
    description: 'Northern Ireland VAT number',
  },
  {
    label: 'United States',
    enum: 'us_ein',
    prefix: '',
    example: '12-3456789',
    description: 'United States EIN',
  },
];
