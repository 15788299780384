import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  ({ breakpoints, custom, mixins, palette }) =>
    createStyles({
      root: {
        display: 'flex',
        minHeight: '100%',
        width: '100%',
      },
      drawerRoot: {
        width: '100%',
        maxWidth: '100%',
        [breakpoints.up('xs')]: {
          width: custom.fullModalMenuWidth,
          maxWidth: '100%',
        },
        [breakpoints.only('sm')]: {
          width: '50%',
          minWidth: custom.fullModalMenuWidth,
        },
      },
      drawerPaper: {
        width: 'inherit',
        maxWidth: 'inherit',
        minWidth: 'inherit',
        borderRight: `none`,
        [breakpoints.up('md')]: {
          borderRight: `1px solid ${palette.grey.lightest}`,
        },
      },
      content: {
        flexGrow: 1,
        position: 'relative',
      },
      backButton: {
        display: 'flex',
        alignItems: 'center',
        position: 'fixed',
        top: 0,
        left: 0,
        ...mixins.toolbar,
      },
    }),
  { index: 0 }
);
