import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  ({ breakpoints, palette }) =>
    createStyles({
      root: {
        textAlign: 'inherit',
      },
      rootAvatarLabels: {
        display: 'none',
        [breakpoints.up('lg')]: {
          display: 'block',
        },
      },
      rootAvatarLabelsMain: {
        color: palette.text.primary,
      },
    }),
  { index: 0 }
);
