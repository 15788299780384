import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Button,
  CardContent,
  CardHeader,
  CircularProgress,
  Paper,
  TextField,
  useMediaQuery,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import useForm from 'src/helpers/hooks/useForm';

import { useDispatch, useSelector } from 'react-redux';
import billingActions from 'src/redux/actions/billing.actions';

import {
  useStripe,
  useElements,
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
} from '@stripe/react-stripe-js';
import { Alert } from '@material-ui/lab';
import {
  CountrySelect,
  ExtendedGrid,
  FullScreenDialog,
  Gap,
  SmallContentWithScrollbar,
  StripeInputElement,
} from 'src/components';
import { Alert as ErrorIcon, CreditCard as CreditCardIcon } from 'src/components/CustomIcon';
import { RequestStatus } from 'src/helpers/reduxReuquest.util';
import { SubscriptionPricing, TaxIdSelect } from './subcomponents';
import { useStyles } from './styles';

const PaymentMethod = ({ className: classNameProp, transitionDuration }) => {
  const classes = useStyles();
  const smallScreen = useMediaQuery(({ breakpoints }) => breakpoints.down('sm'));
  const { t } = useTranslation();
  const { type: formType } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();

  const { currentIdentity: activeIdentity, isOwner: isWorkspaceOwner } = useSelector(
    (state) => state.identityStore
  );
  const {
    accessToWorkspace,
    fetchAccessToWorkspaceStatus,
    initializeCustomerCardStatus,
    invoiceUserDataStatus,
    subscribePlanStatus,
    subscribePlanTaxIdError,
  } = useSelector((state) => state.billingStore);

  const workspaceId = activeIdentity?.workspace?.workspaceId;
  const isAddPaymentForm = formType !== 'edit';

  const [didMount, setDidMount] = useState(false);
  const [isPending, setIsPending] = useState(false);
  const [cardError, setCardError] = useState(null);
  const [country, setCountry] = useState('');
  const [discountCode, setDiscountCode] = useState('');
  const [taxIdInputError, setTaxIdInputError] = useState(subscribePlanTaxIdError);
  const [taxValue, setTaxValue] = useState({
    taxIdType: '',
    value: '',
  });

  const invoiceUserDataStatusError = invoiceUserDataStatus === RequestStatus.ERROR;
  const initializingCard = initializeCustomerCardStatus === RequestStatus.PENDING;
  const isLoading = isPending || (isAddPaymentForm && initializingCard);

  const redirectLinks = {
    mainPage: '/drive',
    paymentManage: '/workspace-settings/subscriptions-and-billing/manage',
    paymentAdd: '/payment-method/add',
    paymentEdit: '/payment-method/edit',
  };

  useEffect(() => {
    setDidMount(true);
  }, [didMount]);

  useEffect(() => {
    if (isWorkspaceOwner !== null) {
      if (isWorkspaceOwner === false) {
        history.push(redirectLinks.mainPage);
      } else if (fetchAccessToWorkspaceStatus === RequestStatus.SUCCESS) {
        if (isAddPaymentForm && accessToWorkspace) {
          history.push(
            invoiceUserDataStatusError ? redirectLinks.paymentAdd : redirectLinks.paymentEdit
          );
        }
        if (!isAddPaymentForm && !accessToWorkspace) {
          history.push(redirectLinks.paymentAdd);
        }
      }
    }
  }, [
    accessToWorkspace,
    fetchAccessToWorkspaceStatus,
    history,
    invoiceUserDataStatusError,
    isAddPaymentForm,
    isWorkspaceOwner,
    redirectLinks.mainPage,
    redirectLinks.paymentAdd,
    redirectLinks.paymentEdit,
  ]);

  useEffect(() => {
    if (subscribePlanStatus === RequestStatus.ERROR) {
      setIsPending(false);
    }
  }, [subscribePlanStatus]);

  useEffect(() => {
    if (subscribePlanStatus === RequestStatus.ERROR && subscribePlanTaxIdError) {
      setTaxIdInputError(true);
    }
  }, [subscribePlanStatus, subscribePlanTaxIdError]);

  const validateForm = (formData) => {
    const formErrors = {};
    ['name', 'line1', 'postal_code', 'city', 'country', 'email'].forEach((item) => {
      if (!formData[item] || formData[item].trim().length === 0) {
        formErrors[item] = t('workspacePage.editModals.valueCannotBeEmpty');
      }
    });
    return formErrors;
  };

  const sendData = async (clientSecret) => {
    // eslint-disable-next-line camelcase
    const { city, country, line1, line2, postal_code } = values;
    const billingDetails = {
      address: { city, country, line1, line2, postal_code },
      name: values.name,
      email: values.email,
    };

    const result = await stripe.confirmCardSetup(clientSecret, {
      payment_method: {
        card: elements.getElement(CardNumberElement),
        billing_details: billingDetails,
      },
    });

    if (result.error) {
      setCardError(result.error.message);
      setIsPending(false);
    } else {
      setCardError(null);

      dispatch(
        billingActions.subscribePlan(
          workspaceId,
          result.setupIntent.payment_method,
          taxValue,
          isAddPaymentForm ? discountCode : '',
          () =>
            history.push(
              accessToWorkspace && !isAddPaymentForm
                ? redirectLinks.paymentManage
                : redirectLinks.mainPage
            )
        )
      );
    }
  };

  const handleUpdate = () => {
    if (!stripe || !elements || !workspaceId) {
      return;
    }
    setIsPending(true);

    dispatch(
      billingActions.initializeCustomerCard(workspaceId, (clientSecret) => sendData(clientSecret))
    );
  };

  const { values, errors, handleChange, handleSubmit, setValue } = useForm(
    handleUpdate,
    validateForm,
    {
      name: '',
      country: '',
      line1: '',
      line2: '',
      postal_code: '',
      city: '',
      email: '',
    }
  );

  const handleClose = () => {
    if (invoiceUserDataStatusError) {
      history.push(redirectLinks.mainPage);
    } else {
      history.push(
        isWorkspaceOwner && accessToWorkspace ? redirectLinks.paymentManage : redirectLinks.mainPage
      );
    }
  };

  if (!didMount || !isWorkspaceOwner) {
    return null;
  }
  return (
    <FullScreenDialog
      isOpen
      onClose={handleClose}
      title={t(`paymentMethod.header.${isAddPaymentForm ? 'add' : 'edit'}`)}
      transitionDuration={transitionDuration}
      className={clsx(classes.root, classNameProp)}
      classes={{ content: classes.content }}
    >
      <SmallContentWithScrollbar
        size="large"
        endContent={
          <ExtendedGrid container justifyContentXxs="center">
            <ExtendedGrid item xxs={12} sm={6}>
              <Gap />
              <Button
                color="primary"
                size="large"
                type="submit"
                fullWidth
                disabled={isLoading}
                endIcon={isPending && <CircularProgress size={20} color="inherit" />}
              >
                {t(`paymentMethod.${isAddPaymentForm ? 'addMethod' : 'editMethod'}`)}
              </Button>
            </ExtendedGrid>
          </ExtendedGrid>
        }
        hasForm
        formProps={{
          noValidate: true,
          autoComplete: 'off',
          onSubmit: handleSubmit,
        }}
      >
        <ExtendedGrid
          container
          spacing={smallScreen ? 4 : 6}
          alignItemsXxs="center"
          justifyContentXxs="center"
        >
          <ExtendedGrid item xxs={12} sm={9} md={6} classes={{ root: classes.inputs }}>
            <TextField
              label={t('paymentMethod.name.label')}
              placeholder={t('paymentMethod.name.placeholder')}
              fullWidth
              name="name"
              value={values.name}
              onChange={handleChange}
              disabled={isPending}
              error={errors.name?.length > 0}
              helperText={errors.name}
            />

            <TextField
              label={t('paymentMethod.email.label')}
              placeholder={t('paymentMethod.email.placeholder')}
              fullWidth
              type="email"
              name="email"
              value={values.email}
              onChange={handleChange}
              disabled={isPending}
              error={errors.email?.length > 0}
              helperText={errors.email}
            />

            <ExtendedGrid container spacingY={1}>
              <ExtendedGrid item xxs={12}>
                <TextField
                  label={t('paymentMethod.address.label')}
                  placeholder={t('paymentMethod.address.placeholder1')}
                  fullWidth
                  name="line1"
                  value={values.line1}
                  onChange={handleChange}
                  disabled={isPending}
                  error={errors.line1?.length > 0}
                  helperText={errors.line1}
                />
              </ExtendedGrid>
              <ExtendedGrid item xxs={12}>
                <TextField
                  placeholder={t('paymentMethod.address.placeholder2')}
                  fullWidth
                  name="line2"
                  value={values.line2}
                  onChange={handleChange}
                  disabled={isPending}
                />
              </ExtendedGrid>
            </ExtendedGrid>

            <div>
              <ExtendedGrid container spacingX={1} spacingY={2}>
                <ExtendedGrid item xxs={12} xs={4}>
                  <TextField
                    label={t('paymentMethod.postalCode.label')}
                    placeholder={t('paymentMethod.postalCode.placeholder')}
                    fullWidth
                    name="postal_code"
                    value={values.postal_code}
                    onChange={handleChange}
                    disabled={isPending}
                    error={errors.postal_code?.length > 0}
                    helperText={errors.postal_code}
                  />
                </ExtendedGrid>
                <ExtendedGrid item xxs={12} xs={8}>
                  <TextField
                    label={t('paymentMethod.city.label')}
                    placeholder={t('paymentMethod.city.placeholder')}
                    fullWidth
                    name="city"
                    value={values.city}
                    onChange={handleChange}
                    disabled={isPending}
                    error={errors.city?.length > 0}
                    helperText={errors.city}
                  />
                </ExtendedGrid>
              </ExtendedGrid>
            </div>

            <CountrySelect
              label={t('paymentMethod.country.label')}
              placeholder={t('paymentMethod.country.placeholder')}
              name="country"
              countryCode={country}
              country={values.country}
              changeCountry={(country) => {
                if (country) {
                  setCountry(country.code);
                  setValue('country', country.code, { shouldDirty: true });
                } else {
                  setCountry('');
                  setValue('country', '');
                }
              }}
              disabled={isPending}
              error={errors.country?.length > 0}
              helperText={errors.country}
            />

            <TaxIdSelect
              error={taxIdInputError}
              disabled={isPending}
              onChange={(newValue) => {
                setTaxValue(newValue);
              }}
              onChangeInput={() => setTaxIdInputError(false)}
            />
          </ExtendedGrid>

          <ExtendedGrid item xxs={12} sm={9} md={6}>
            {workspaceId && isAddPaymentForm && (
              <Alert severity="warning" className={classes.limitedAccessInfo}>
                {t('paymentMethod.limitedAccessibilityInfo')}
              </Alert>
            )}
            <div>
              {cardError && (
                <Alert
                  severity="error"
                  icon={<ErrorIcon />}
                  classes={{
                    root: classes.cardErrorRoot,
                    icon: classes.cardErrorIcon,
                    message: classes.cardErrorMessage,
                  }}
                >
                  {cardError}
                </Alert>
              )}

              <Paper classes={{ root: classes.cardContainer }}>
                <CardHeader
                  title={t('paymentMethod.card.details')}
                  avatar={<CreditCardIcon color="secondary" />}
                  classes={{ root: classes.cardHeader, avatar: classes.cardHeaderIcon }}
                />
                <CardContent classes={{ root: classes.cardContent }}>
                  <ExtendedGrid container spacingX={smallScreen ? 1 : 2} spacingY={2}>
                    <ExtendedGrid item xxs={12}>
                      <StripeInputElement
                        component={CardNumberElement}
                        label={t('paymentMethod.card.number')}
                        name="ccnumber"
                        disabled={isPending}
                        stripeOptions={{ iconStyle: 'solid', showIcon: true }}
                      />
                    </ExtendedGrid>
                    <ExtendedGrid item xxs={12} xs={6}>
                      <StripeInputElement
                        component={CardExpiryElement}
                        label={t('paymentMethod.card.expiration')}
                        name="ccexp"
                        disabled={isPending}
                      />
                    </ExtendedGrid>
                    <ExtendedGrid item xxs={12} xs={6}>
                      <StripeInputElement
                        component={CardCvcElement}
                        label={t('paymentMethod.card.cvc')}
                        name="cvc"
                        disabled={isPending}
                      />
                    </ExtendedGrid>
                  </ExtendedGrid>
                </CardContent>
              </Paper>
            </div>

            {workspaceId && isAddPaymentForm && (
              <SubscriptionPricing
                workspaceId={workspaceId}
                disabled={isLoading}
                onChange={(value) => setDiscountCode(value)}
              />
            )}
          </ExtendedGrid>
        </ExtendedGrid>
      </SmallContentWithScrollbar>
    </FullScreenDialog>
  );
};

PaymentMethod.propTypes = {
  className: PropTypes.string,
  transitionDuration: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.shape({
      appear: PropTypes.number,
      enter: PropTypes.number,
      exit: PropTypes.number,
    }),
  ]),
};

PaymentMethod.defaultProps = {
  transitionDuration: { enter: 0, exit: 0 },
};

export default PaymentMethod;
