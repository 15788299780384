import { fade } from '@material-ui/core/styles';
import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  ({ custom, breakpoints, palette, spacing }) =>
    createStyles({
      root: {
        maxWidth: '100%',
      },
      drawerPaper: {
        top: 0,
        bottom: 0,
        height: '100%',
        width: '100%',
        maxWidth: '100%',
        [breakpoints.up('sm')]: {
          maxWidth: custom.userMenuMaxWidth,
          overflow: 'hidden',
        },
      },
      top: {
        justifyContent: 'flex-end',
      },
      content: {
        width: '100%',
        flex: 1,
        overflowY: 'auto',
        paddingTop: spacing(1),
        paddingBottom: spacing(1),
        [breakpoints.up('lg')]: {
          paddingBottom: spacing(3),
        },
      },
      bottom: {
        [breakpoints.up('lg')]: {
          paddingTop: `${spacing(2)}px !important`,
        },
      },
      closeButton: {
        [breakpoints.up('lg')]: {
          marginRight: -12,
        },
      },
      newButton: {
        width: `calc(100% + ${spacing(2)}px)`,
        marginLeft: spacing(-1),
        marginRight: spacing(-1),
        padding: spacing(1),
        justifyContent: 'start',
      },
      newButtonIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: spacing(5),
        height: spacing(5),
        borderRadius: '50%',
        color: palette.primary.main,
        backgroundColor: fade(palette.primary.light, 0.1),
        marginTop: 0,
        marginBottom: 0,
        marginLeft: 0,
        marginRight: spacing(1),
        [breakpoints.down('sm')]: {
          width: spacing(4),
          height: spacing(4),
        },
        [breakpoints.up('lg')]: {
          marginRight: spacing(1.5),
        },
        [breakpoints.up('xl')]: {
          marginRight: spacing(2),
        },
      },
      logoutButton: {
        display: 'flex',
        maxWidth: breakpoints.values.xs,
        margin: '0 auto',
      },
    }),
  { index: 0 }
);
