import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { CollapseItem } from 'src/components';
import { useStyles } from './styles';

const Section = ({ className: classNameProp, title, children, openOnStart, alwaysOpen }) => {
  const classes = useStyles();
  return (
    <CollapseItem
      openOnStart={openOnStart}
      title={title}
      alwaysOpen={alwaysOpen}
      classes={{ root: clsx(classes.root, classNameProp) }}
    >
      {children}
    </CollapseItem>
  );
};

Section.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  title: PropTypes.string,
  item: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    data: PropTypes.object,
    date: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    groups: PropTypes.array,
    // eslint-disable-next-line react/forbid-prop-types
    emails: PropTypes.array,
  }),
  openOnStart: PropTypes.bool,
  alwaysOpen: PropTypes.bool,
};

Section.defaultProps = {
  openOnStart: false,
  alwaysOpen: false,
};

export default Section;
