import React from 'react';
import { useTheme } from '@material-ui/core';

import {
  FileEdit as SignedDocumentIcon,
  Shield as DurableMediumIcon,
  CertificateIcon,
} from 'src/components/CustomIcon';
import { DocumentType } from '../../models/document.model';

export const Options = (t) => {
  const theme = useTheme();
  const { palette } = theme;

  return [
    {
      label: t('selectTemplate.signedDocument.title'),
      description: t('selectTemplate.signedDocument.description'),
      icon: <SignedDocumentIcon />,
      iconColor: palette.common.purple,
      value: DocumentType.DOCUMENT_TO_BE_SIGNED,
    },
    {
      label: t('selectTemplate.durableMedium.title'),
      description: t('selectTemplate.durableMedium.description'),
      icon: <DurableMediumIcon />,
      iconColor: palette.common.green,
      value: DocumentType.DURABLE_MEDIA,
    },
    {
      label: t('selectTemplate.certificate.title'),
      description: t('selectTemplate.certificate.description'),
      icon: <CertificateIcon />,
      iconColor: palette.primary.light,
      value: DocumentType.CERTIFICATES,
    },
  ];
};

export default Options;
