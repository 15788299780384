import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  ({ breakpoints, palette, spacing, shadows, typography }) =>
    createStyles({
      root: {
        flex: 1,
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        overflowX: 'hidden',
        overflowY: 'scroll',
        height: '100%',
        padding: 'inherit',
        paddingBottom: 0,
        '&::-webkit-scrollbar-thumb': {
          background: palette.grey.main,
        },
        '&::-webkit-scrollbar-thumb:hover': {
          background: palette.grey.dark,
        },
      },
      inner: {
        display: 'flex',
        minHeight: '100%',
        [breakpoints.up('md')]: {
          paddingLeft: 48 + spacing(2),
        },
      },
      layers: {
        position: 'sticky',
        top: '50%',
        transform: 'translateY(-50%)',
        left: 0,
        height: 0,
        zIndex: 99,
      },
      layersInner: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        transform: 'translateY(-50%)',
        pointerEvents: 'none',
        '& > *': {
          pointerEvents: 'auto',
          [breakpoints.up('md')]: {
            marginLeft: spacing(-0.5),
          },
          [breakpoints.up('lg')]: {
            marginLeft: spacing(-1.5),
          },
          '&:not([disabled])': {
            backgroundColor: palette.common.white,
          },
          '&:not(:first-child)': {
            marginTop: 6,
          },
          '&:not(:last-child)': {
            marginBottom: 6,
          },
          '&[disabled]': {
            opacity: 0.25,
            backgroundColor: 'transparent',
          },
        },
      },
      layersMenu: {
        minWidth: 200,
        marginLeft: spacing(1),
      },
      layersMenuItem: {
        fontSize: typography.subtitle2.fontSize,
      },
      layersMenuItemIcon: {
        display: 'flex',
        alignItems: 'center',
        '& svg': {
          width: 16,
          height: 16,
        },
      },
      layersMenuCloseButton: {
        marginRight: -4,
        width: spacing(3),
        minWidth: spacing(3),
        height: spacing(3),
        minHeight: spacing(3),
        '& svg': {
          width: spacing(2),
          height: spacing(2),
        },
      },
      activeLayersFabButton: {
        color: palette.primary.main,
        boxShadow: shadows[15],
      },
      documentWrapper: {
        outline: 0,
        flex: 1,
      },
      image: {
        minHeight: 32,
        boxShadow: shadows[6],
      },
      imageContainer: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: 'auto',
        marginBottom: spacing(6),
        [breakpoints.down('lg')]: {
          marginBottom: spacing(4),
        },
        [breakpoints.down('md')]: {
          marginBottom: spacing(3),
        },
        [breakpoints.down('sm')]: {
          marginBottom: spacing(3),
        },
        '&:not($imageEmptyContainer) img': {
          display: 'block',
          width: '100%',
          margin: 0,
        },
      },
      imageInner: {
        overflow: 'hidden',
        position: 'relative',
        display: 'block',
        margin: '0 auto',
        width: '100%',
        maxWidth: '210mm',
        boxShadow: shadows[12],
      },
      watermark: {
        position: 'absolute',
        top: 0,
        right: 0,
        zIndex: 1,
        width: '60%',
        maxWidth: '152px',
        transform: 'rotate(45deg)',
        pointerEvents: 'none',
        [breakpoints.up('xs')]: {
          width: '20%',
          maxWidth: 'unset',
          minWidth: '150px',
        },
        [breakpoints.up('sm')]: {
          minWidth: '160px',
        },
      },
      watermarkInner: {
        paddingBottom: '100%',
        '& span': {
          position: 'absolute',
          top: '10%',
          transform: 'translateY(-50%)',
          width: '120%',
          marginLeft: '-10%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          lineHeight: 1,
          height: '18%',
          backgroundColor: palette.success.main,
          color: palette.success.contrastText,
          opacity: 0.7,
          fontSize: typography.subtitle2.fontSize,
        },
        [breakpoints.up('xs')]: {
          '& span': {
            top: '13%',
            height: '20%',
          },
        },
        [breakpoints.up('sm')]: {
          '& span': {
            top: '20%',
            height: '22%',
            fontSize: typography.body1.fontSize,
          },
        },
        [breakpoints.up('md')]: {
          '& span': {
            top: '13%',
            height: '20%',
            fontSize: typography.subtitle2.fontSize,
          },
        },
        [breakpoints.up('lg')]: {
          '& span': {
            top: '20%',
            height: '22%',
            fontSize: typography.body1.fontSize,
          },
        },
      },
      imageEmptyContainer: {
        '& $emptyImageBoxContainer': {
          position: 'relative',
          backgroundColor: palette.background.paper,
          width: '100%',
        },
      },
      imageMaximized: {
        '& img, & > div': {
          maxWidth: '100% !important',
        },
        '& $watermarkInner': {
          [breakpoints.up('xl')]: {
            '& span': {
              fontSize: typography.h4.fontSize,
            },
          },
        },
      },
      imageLoaderWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100%',
      },
      imageLoader: {
        margin: '0 auto',
      },
      loaderFile: {
        position: 'absolute',
        left: spacing(1),
        right: spacing(1),
        bottom: 0,
        padding: spacing(0.5),
        backgroundColor: palette.background.default,
        pointerEvents: 'none',
      },
      emptyFilesInfoBox: {
        flex: 1,
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        overflowX: 'hidden',
        overflowY: 'auto',
        padding: 'inherit',
      },
      emptyImageBoxContainer: {
        position: 'relative',
      },
      emptyImageBox: {
        position: 'relative',
        overflow: 'hidden',
        width: '100%',
        [breakpoints.up('xs')]: {
          paddingBottom: '70.7%',
        },
      },
      emptyImageBoxInner: {
        padding: spacing(2),
        [breakpoints.up('xs')]: {
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translateX(-50%) translateY(-50%)',
          width: '100%',
        },
        [breakpoints.up('md')]: {
          padding: spacing(3),
        },
        [breakpoints.up('lg')]: {
          padding: spacing(5),
        },
      },
      emptyImageIcon: {
        fontSize: spacing(8),
        color: palette.grey.light,
        [breakpoints.up('xs')]: {
          fontSize: spacing(11),
        },
        [breakpoints.up('sm')]: {
          fontSize: spacing(14),
        },
      },
      firstLoader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
      },
      loaderMenu: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 22,
      },
    }),
  { index: 0 }
);
