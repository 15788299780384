import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, CircularProgress } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as yup from 'yup';
import moment from 'moment';
import { RequestStatus } from 'src/helpers/reduxReuquest.util';
import { CertificateQuantityType, CertificateSteps } from 'src/models/certificate.model';
import { generateUUID } from 'src/helpers/globalUtils';
import { RegexFormulas } from 'src/helpers/regex';
import certificateCreatorActions from 'src/redux/actions/certificateCreator.actions';
import MappingLayout from '../components/MappingLayout/MappingLayout';
import MapField from '../components/MapField/MapField';
import { useStyles } from './styles';

const MapSingleFields = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { currentIdentity } = useSelector((state) => state.identityStore, undefined);
  const workspaceId = currentIdentity?.workspace?.workspaceId;
  const { t } = useTranslation();
  const {
    selectedTemplate,
    certificateQuantityType,
    draftSingleData,
    templateDetails,
    templateDetailsStatus,
  } = useSelector((state) => state.certificateCreatorStore, undefined);
  const isLoading = templateDetailsStatus === RequestStatus.PENDING;

  const onFieldChange = (name, value) => {
    formik.setFieldValue(name, value);
    dispatch(certificateCreatorActions.updateDraftSingleFields({ [name]: value }));
  };

  const onCreateClick = () => {
    dispatch(
      certificateCreatorActions.setCreatorStep({
        currentStep: CertificateSteps.CreatingCertificatesProgressScreen,
      })
    );
    if (certificateQuantityType === CertificateQuantityType.Single) {
      const templateFields = {};

      Object.keys(draftSingleData).forEach((key) => {
        if (templateDetails.fields[key]) {
          if (templateDetails.fields[key].format === 'date') {
            if (draftSingleData[key]) {
              templateFields[key] = moment(new Date(draftSingleData[key])).format('yyyy-MM-DD');
            } else {
              templateFields[key] = moment(new Date()).format('yyyy-MM-DD');
            }
            return;
          }
          if (key !== 'documentName') {
            templateFields[key] = draftSingleData[key] === '' ? null : draftSingleData[key];
          }
          if (key === 'holderMailMulti') {
            delete templateFields[key];
          }
        }
      });

      dispatch(
        certificateCreatorActions.createCertificate({
          workspaceId,
          templateId: selectedTemplate.id,
          certificateList: [
            {
              documentName: draftSingleData.documentName || null,
              correlationId: generateUUID(),
              templateFields,
            },
          ],
        })
      );
    }
  };

  const getValidationSchema = () => {
    const validationFields = {};
    const initialValues = {};
    Object.keys(templateDetails?.fields)?.forEach((fieldName) => {
      const { required, format } = templateDetails.fields[fieldName];
      if (required) {
        if (format === 'date') {
          validationFields[fieldName] = yup
            .date()
            .typeError(t('validation.dateFormat'))
            .required(t('validation.required'));
        } else if (format === 'base64') {
          validationFields[fieldName] = yup
            .string()
            .transform((currentValue) => currentValue.slice(0, 500))
            .matches(RegexFormulas.base64, t('validation.base64'))
            .required(t('validation.required'));
        } else {
          validationFields[fieldName] = yup.string().required(t('validation.required'));
        }
        initialValues[fieldName] = '';
      } else if (format === 'date') {
        validationFields[fieldName] = yup.date().typeError(t('validation.dateFormat'));
      } else if (fieldName === 'documentName') {
        validationFields[fieldName] = yup
          .string()
          .matches(RegexFormulas.folderName, t('validation.fileName'));
      } else if (format === 'base64') {
        validationFields[fieldName] = yup
          .string()
          .transform((currentValue) => currentValue.slice(0, 500))
          .matches(RegexFormulas.base64, t('validation.base64'));
      }
    });
    const validation = yup.object(validationFields);
    return { validation, initialValues };
  };

  const formik = useFormik({
    initialValues: getValidationSchema().initialValues,
    validationSchema: getValidationSchema().validation,
    onSubmit: (values) => {
      onCreateClick(values);
    },
  });

  const renderTemplateDetails =
    templateDetails &&
    Object.keys(templateDetails?.fields)
      ?.sort((a, b) => (templateDetails.fields[a].order > templateDetails.fields[b].order ? 1 : -1))
      .map((fieldName, fieldIndex) => {
        const { label, placeholder, format, required } = templateDetails.fields[fieldName];
        if (['holderMailMulti', 'attached_files'].includes(fieldName)) return null;

        return (
          <MapField
            key={`field_${fieldName}_${fieldIndex}`}
            label={`${label}${required ? '*' : ''}`}
            placeholder={placeholder}
            format={format}
            value={formik.values[fieldName]}
            error={formik.touched[fieldName] && Boolean(formik.errors[fieldName])}
            helperText={formik.touched[fieldName] && formik.errors[fieldName]}
            name={fieldName}
            onFieldChange={onFieldChange}
          />
        );
      });

  return (
    <MappingLayout>
      <form onSubmit={formik.handleSubmit}>
        <div className={classes.fieldsWrapper}>
          {isLoading && <CircularProgress size={48} />}

          {!isLoading && renderTemplateDetails}
        </div>
        <Button size="large" type="submit" fullWidth disabled={formik.isSubmitting}>
          {t('createCertificate.createNewCertificate')}
        </Button>
      </form>
    </MappingLayout>
  );
};

export default MapSingleFields;
