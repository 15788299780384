import SockJS from 'sockjs-client';

class WebSocketHelper {
  sockJs = null;

  connected = false;

  subQueue = [];

  subscriptionTree = {
    /* module: {
      path: {
        subscribed: true/false,
        subscriptionPending: true/false,
        subscriptions: {
          id: {
            onMessage: func
            onSubscribe: func
          }
        }
      }
    } */
  };

  constructor() {
    this.sockJs = new SockJS(`${process.env.REACT_APP_BASE_URL}/sockjs`);
    this.sockJs.onopen = () => {
      console.warn('WS opened');
      this.connected = true;
      this.processQueue();
    };

    this.sockJs.onmessage = (e) => {
      this.parsePush(e);
    };

    this.sockJs.onclose = () => {
      this.connected = false;
      console.warn('WS closed');
    };
  }

  processQueue() {
    this.subQueue.forEach((sub) => {
      this.subscribe(
        sub.subData.module,
        sub.subData.path,
        sub.subData.onMessage,
        sub.subData.onSubscribe,
        sub.subId
      );
    });
  }

  parsePush(pushData) {
    switch (pushData.type) {
      case 'message':
      default:
        this.parseMessage(pushData.data);
    }
  }

  parseMessage(message) {
    let jsonData = null;
    try {
      jsonData = JSON.parse(message);
    } catch (e) {}
    if (jsonData) {
      switch (jsonData.action) {
        case 'push':
          this.processPush(jsonData.topic, jsonData.message);
          break;
        case 'subscribe-private-confirm':
          this.confirmSubscriptions(jsonData.module, jsonData.path);
          break;
        default:
          break;
      }
    }
  }

  confirmSubscriptions(module, path) {
    const subFound = this.propagateMessage(module, path, 'onSubscribe');
    if (!subFound) {
      this.unsubscribe(module, path);
    }
  }

  propagateMessage(module, path, type, message) {
    let subFound = false;
    if (this.subscriptionTree[module]) {
      if (this.subscriptionTree[module][path]) {
        const subsIds = Object.keys(this.subscriptionTree[module][path].subscriptions);
        if (subsIds.length > 0) {
          subFound = true;
          if (type === 'onSubscribe') {
            this.subscriptionTree[module][path].subscribed = true;
            this.subscriptionTree[module][path].subscriptionPending = false;
          }
          subsIds.forEach((subKey) => {
            if (
              typeof this.subscriptionTree[module][path].subscriptions[subKey][type] === 'function'
            ) {
              if (type === 'onSubscribe') {
                this.subscriptionTree[module][path].subscriptions[subKey].onSubscribe(subKey);
              } else {
                this.subscriptionTree[module][path].subscriptions[subKey].onMessage(message);
              }
            }
          });
        }
      }
    }
    return subFound;
  }

  processPush(topic, message) {
    const { module, path } = this.parseTopic(topic);
    this.propagateMessage(module, path, 'onMessage', message);
  }

  parseTopic(topic) {
    const topicParts = topic.split('/');
    const [module] = topicParts.splice(0, 1);
    const path = topicParts.join('/');
    return { module, path };
  }

  generateUUID() {
    let uuid = '';
    for (let i = 0; i < 32; i += 1) {
      // eslint-disable-next-line no-bitwise
      const random = (Math.random() * 16) | 0;

      // eslint-disable-next-line eqeqeq
      if (i == 8 || i == 12 || i == 16 || i == 20) {
        uuid += '-';
      }
      // eslint-disable-next-line no-bitwise,eqeqeq,no-nested-ternary
      uuid += (i == 12 ? 4 : i == 16 ? (random & 3) | 8 : random).toString(16);
    }
    return uuid;
  }

  subscribe(module, path, onMessage, onSubscribe, forceId) {
    const subId = forceId || this.generateUUID();
    if (!this.connected) {
      this.subQueue.push({ subId, subData: { module, path, onMessage, onSubscribe } });
      return subId;
    }
    let subExist = true;
    const { subscriptionTree } = this;
    if (!Object.prototype.hasOwnProperty.call(subscriptionTree, module)) {
      this.subscriptionTree[module] = {};
      subExist = false;
    }
    if (!Object.prototype.hasOwnProperty.call(subscriptionTree[module], path)) {
      this.subscriptionTree[module][path] = {
        subscribed: false,
        subscriptionPending: true,
        subscriptions: {},
      };
      subExist = false;
    } else if (
      this.subscriptionTree[module][path].subscribed &&
      !this.subscriptionTree[module][path].subscriptionPending
    ) {
      subExist = true;
    } else if (
      !this.subscriptionTree[module][path].subscribed &&
      !this.subscriptionTree[module][path].subscriptionPending
    ) {
      subExist = false;
    }

    this.subscriptionTree[module][path].subscriptions[subId] = { onMessage, onSubscribe };

    if (!subExist) {
      this.sockJs.send(
        JSON.stringify({
          module,
          path,
          action: 'subscribe-private',
        })
      );
    } else if (typeof onSubscribe === 'function') {
      onSubscribe(subId);
    }
    return subId;
  }

  clearSubscriptionCallBack(id, module, path) {
    let subscriptionAlive = true;
    let targetModule = module;
    let targetPath = path;
    if (!targetModule && !targetPath) {
      const availableModules = Object.keys(this.subscriptionTree);
      let foundSub = false;
      for (let i = 0; i < availableModules.length; i += 1) {
        const availablePaths = Object.keys(this.subscriptionTree[availableModules[i]]);
        for (let x = 0; x < availablePaths.length; x += 1) {
          if (this.subscriptionTree[availableModules[i]][availablePaths[x]].subscriptions[id]) {
            targetPath = availablePaths[x];
            targetModule = availableModules[i];
            foundSub = true;
            break;
          }
        }
        if (foundSub) {
          break;
        }
      }
    }

    if ((!targetPath && !targetModule) || !this.connected) {
      let queueIndex = -1;
      this.subQueue.forEach((queueItem, index) => {
        if (queueItem.id === id) {
          queueIndex = index;
        }
      });
      this.subQueue.splice(queueIndex, 1);
    } else if (this.subscriptionTree?.[targetModule]?.[targetPath]?.subscriptions[id]) {
      delete this.subscriptionTree[targetModule][targetPath].subscriptions[id];
      const keysLeft = Object.keys(this.subscriptionTree[targetModule][targetPath].subscriptions);
      if (keysLeft.length === 0) {
        subscriptionAlive = false;
        this.subscriptionTree[targetModule][targetPath].subscribed = false;
      }
    }

    return { isAlive: subscriptionAlive, module: targetModule, path: targetPath };
  }

  unsubscribe(id, module, path) {
    if (id) {
      const subAlive = this.clearSubscriptionCallBack(id, module, path);
      if (!subAlive.isAlive) {
        this.sockJs.send(
          JSON.stringify({
            module: subAlive.module,
            path: subAlive.path,
            action: 'unsubscribe',
          })
        );
      }
    }
  }
}

const websocketInstance = new WebSocketHelper();

export default websocketInstance;
