import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  ({ custom, palette, spacing, breakpoints }) =>
    createStyles({
      root: {},
      appBar: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
      },
      breadcrumbs: {
        position: 'sticky',
        top: 0,
        left: 0,
        zIndex: 2,
        '&:before': {
          content: "''",
          display: 'block',
          position: 'absolute',
          top: spacing(-2),
          bottom: 0,
          left: spacing(-2),
          right: spacing(-2),
          backgroundColor: 'inherit',
        },
        '& > *': {
          position: 'relative',
        },
      },
      header: {
        position: 'sticky',
        left: 0,
        backgroundColor: palette.background.paper,
        zIndex: 1,
        top: custom.breadcrumbsMenuHeight,
      },
      firstHeader: {},
      secondHeader: {},
      inFolderHeader: {},
      loader: {
        position: 'absolute',
        zIndex: 1300,
      },
      noDocumentsBanner: {
        '& img': {
          [breakpoints.up('md')]: {
            marginTop: custom.breadcrumbsMenuHeight + spacing(1),
          },
        },
      },
    }),
  { index: 0 }
);
