import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  ({ breakpoints, palette, spacing, typography }) => {
    const generateCardDivider = (top = true) => ({
      '&:after': {
        content: '""',
        display: 'block',
        height: 1,
        backgroundColor: palette.divider,
        position: 'absolute',
        top: top ? 0 : 'auto',
        bottom: !top ? 0 : 'auto',
        left: spacing(2),
        right: spacing(2),
        [breakpoints.up('md')]: {
          left: spacing(3),
          right: spacing(3),
        },
        [breakpoints.up('lg')]: {
          left: spacing(5),
          right: spacing(5),
        },
      },
    });
    return createStyles({
      root: {},
      contentContainer: {
        display: 'flex',
        flexDirection: 'column',
      },
      contentLoader: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      card: {
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'stretch',
        '& [class^="MuiCardActions-root"], & [class*="MuiCardActions-root"]': {
          position: 'relative',
          paddingTop: spacing(2),
          ...generateCardDivider(),
          [breakpoints.up('lg')]: {
            marginTop: spacing(-1),
          },
        },
        '& [class^="MuiCardContent-root"], & [class*="MuiCardContent-root"]': {
          display: 'flex',
          alignItems: 'center',
          flex: 1,
        },
      },
      cardHeader: {
        position: 'relative',
        paddingBottom: spacing(2),
        [breakpoints.up('lg')]: {
          // marginTop: spacing(-1),
        },
        ...generateCardDivider(false),
      },
      cardActions: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        [breakpoints.up('lg')]: {
          alignItems: 'flex-end',
        },
      },
      cardActionsLeft: {
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        textAlign: 'left',
      },
      loader: {
        padding: spacing(2, 0),
        display: 'flex',
        justifyContent: 'center',
      },
      listHeader: {
        minHeight: spacing(3),
        paddingBottom: '0 !important',
      },
      listItemContainer: {
        '&:first-child': {
          marginTop: spacing(-2),
        },
        '&:last-child': {
          marginBottom: spacing(-2),
        },
        '& $listItemRoot': {
          paddingRight: 32,
        },
      },
      listItemRoot: {
        color: palette.text.primary,
        padding: spacing(2, 0),
        [breakpoints.up('md')]: {
          '&:first-child': {
            paddingTop: 0,
          },
          '&:last-child': {
            paddingBottom: 0,
          },
        },
        '&:hover': {
          color: 'inherit',
        },
      },
      fullScreenDialog: {
        minHeight: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      content: {
        width: '100%',
      },
      divider: {
        margin: spacing(2, 2, 0, 2),
        [breakpoints.up('md')]: {
          margin: spacing(2, 3, 0, 3),
        },
        [breakpoints.up('lg')]: {
          margin: spacing(3, 5, 0, 5),
        },
      },
      oldPrice: {
        fontSize: 16,
        textDecoration: 'line-through',
        fontWeight: typography.fontWeightRegular,
      },
      cardTitle: {
        position: 'relative',
        '&:after': {
          content: '""',
          position: 'absolute',
          bottom: 0,
          left: 0,
          height: '1px',
          width: '100%',
          backgroundColor: palette.grey.lightest,
        },
      },
      actionButton: {
        marginLeft: spacing(-1.5),
        marginRight: spacing(-1.5),
        padding: spacing(1, 1.5),
        fontSize: typography.fontSize,
        '&:not($actionButtonCancel)': {
          color: palette.primary.main,
        },
        '&:not(:first-child)': {
          marginTop: 2,
          marginLeft: spacing(-1.5),
        },
      },
      actionButtonCancel: {},
      actionButtonCode: {
        paddingTop: spacing(0.5),
        paddingBottom: spacing(0.5),
        zIndex: 2,
        '&:not(:first-child)': {
          marginLeft: spacing(0),
        },
        '& svg': {
          display: 'block',
          fontSize: spacing(2),
          marginRight: spacing(0.5),
        },
        '& h6, & span': {
          lineHeight: 1.3,
        },
      },
      imageContainer: {
        position: 'absolute',
        bottom: 0,
        right: -16,
        zIndex: 1,
        [breakpoints.up('sm')]: {
          right: 0,
        },
        [breakpoints.up('md')]: {
          right: -20,
        },
        [breakpoints.up('lg')]: {
          right: -16,
        },
        [breakpoints.up('xl')]: {
          right: -8,
        },
      },
      image: {
        display: 'block',
        width: 100,
        opacity: 0.5,
        [breakpoints.up('sm')]: {
          opacity: 1,
        },
        [breakpoints.up('md')]: {
          width: 120,
        },
        [breakpoints.up('xl')]: {
          width: 142,
        },
      },
      imageBank: {
        display: 'inline-block',
        lineHeight: 1,
        minWidth: 56,
        maxWidth: 72,
        marginBottom: -4,
        width: '100%',
        [breakpoints.up('lg')]: {
          maxWidth: 84,
        },
      },
      status: {
        color: palette.success.main,
      },
      success: {
        color: palette.success.main,
      },
      warning: {
        color: palette.warning.main,
      },
      error: {
        color: palette.error.main,
      },
      searchInput: {
        [breakpoints.up('md')]: {
          maxWidth: '50%',
        },
      },
    });
  },
  { index: 0 }
);
