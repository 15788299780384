import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  ({ palette }) =>
    createStyles({
      root: {},
      input: {
        overflow: 'hidden',
        paddingTop: 15,
        '&:not($disabled)': {
          backgroundColor: palette.background.paper,
        },
      },
      disabled: {
        pointerEvents: 'none',
      },
    }),
  { index: 0 }
);
