import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import documentCreationActions from 'src/redux/actions/documentCreation.actions';
import { Button, Typography } from '@material-ui/core';
import { ConfirmDialog, ListChoiceButton, SmallContentWithScrollbar } from 'src/components';
import { Methods } from './Methods';

import { useStyles } from './styles';

const SignatureSelection = ({ className: classesProp, smallScreen, creatorDocumentStepper }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [signatureSelectionModalOpen, setSignatureSelectionModalOpen] = useState(false);
  const [signatureSelectionModalInfo, setSignatureSelectionModalInfo] = useState({});

  const dispatch = useDispatch();
  const signatureDocument = useSelector((state) => state.documentCreationStore.signatureDocument);

  const changeMethod = (value) => {
    dispatch(documentCreationActions.setSignatureDocument(value));
  };

  useEffect(() => {
    if (!signatureDocument) {
      changeMethod('MAIL_SMS');
    }
  });

  const signatureSelectionModalToggle = {
    open: (info) => {
      setSignatureSelectionModalOpen(true);
      setSignatureSelectionModalInfo(info);
    },
    close: () => {
      setSignatureSelectionModalOpen(false);
    },
  };

  return (
    <>
      <SmallContentWithScrollbar
        scrollableContent
        size={smallScreen ? 'large' : 'small'}
        classes={{ root: clsx(classes.root, classesProp), content: classes.content }}
        startContent={
          <Typography variant={smallScreen ? 'h3' : 'h2'}>
            {t('selectionSignature.title')}
          </Typography>
        }
        endContent={
          <Button
            size="large"
            fullWidth
            className={classes.actionButton}
            onClick={() => creatorDocumentStepper(3, signatureDocument)}
            disabled={!signatureDocument}
          >
            {t('common.createDocument')}
          </Button>
        }
      >
        <div className={classes.items}>
          {Methods(t).map((item, index) => (
            <ListChoiceButton
              key={index}
              icon={item.icon}
              label={item.label}
              additionalLabel={item.additionalLabel}
              description={item.description}
              info={item.info}
              disabled={!!item.disabled}
              value={item.value}
              hasModalInfo={!!item?.info}
              onClick={() => changeMethod(item.value)}
              onMoreInfoClick={
                item?.info
                  ? () => {
                      signatureSelectionModalToggle.open(item.info);
                    }
                  : undefined
              }
              selected={item.value === signatureDocument}
              fullWidth
              radio
            />
          ))}
        </div>
      </SmallContentWithScrollbar>

      <ConfirmDialog
        open={signatureSelectionModalOpen}
        variant="primary"
        smallTitle
        title={signatureSelectionModalInfo?.title ? signatureSelectionModalInfo.title : null}
        subtitle={
          signatureSelectionModalInfo?.description ? signatureSelectionModalInfo.description : null
        }
        cancelButtonText={t('common.back')}
        actionCancel={signatureSelectionModalToggle.close}
      />
    </>
  );
};

SignatureSelection.propTypes = {
  className: PropTypes.string,
  smallScreen: PropTypes.bool,
  creatorDocumentStepper: PropTypes.func,
};

export default SignatureSelection;
