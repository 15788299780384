import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  ({ breakpoints, spacing }) =>
    createStyles({
      buttons: {
        '& button': {
          '&:not(:last-child)': {
            marginBottom: spacing(1),
            [breakpoints.up('md')]: {
              marginBottom: spacing(2),
            },
          },
        },
      },
    }),
  { index: 0 }
);
