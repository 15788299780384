import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import identityActions from 'src/redux/actions/identity.actions';
import useForm from 'src/helpers/hooks/useForm';
import validate from 'src/helpers/formValidationRules';
import {
  Button,
  CircularProgress,
  duration,
  TextField,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { EmptyBanner, FullScreenDialog, Gap, SmallContentWithScrollbar } from 'src/components';
import { RequestStatus } from 'src/helpers/reduxReuquest.util';
import authActions from '../../redux/actions/auth.actions';

const CreateWorkspace = ({
  isOpen,
  handleClose,
  noIdentity,
  transitionDuration,
  structureError,
  showLogout = false,
}) => {
  const { t } = useTranslation();
  const [page, setPage] = useState(noIdentity || structureError ? 'no-identity-banner' : 'form');
  const smallScreen = useMediaQuery(({ breakpoints }) => breakpoints.down('sm'));
  const dispatch = useDispatch();
  const createWorkspaceStatus = useSelector((state) => state.identityStore.createWorkspaceStatus);

  const handleUpdate = () => {
    dispatch(identityActions.createWorkspace({ name: values.workspaceName, description: '' }));
    if (typeof handleClose === 'function') {
      handleClose();
    }
  };
  const handleLogout = () => {
    dispatch(authActions.deleteToken());
  };

  const { values, errors, handleChange, handleSubmit } = useForm(handleUpdate, validate, {
    workspaceName: '',
  });

  const showForm = page === 'form';
  const isPending = createWorkspaceStatus === RequestStatus.PENDING;

  useEffect(() => {
    if (structureError) {
      setPage('no-identity-banner');
    }
  }, [structureError]);

  return (
    <FullScreenDialog
      isOpen={isOpen}
      onClose={handleClose}
      withLogo
      transitionDuration={transitionDuration}
    >
      <SmallContentWithScrollbar
        size={showForm ? 'small' : 'medium'}
        startContent={
          showForm && (
            <Typography variant={smallScreen ? 'h3' : 'h2'}>
              {t('createWorkspace.title')}
            </Typography>
          )
        }
        endContent={
          <>
            {showForm && (
              <Button
                size="large"
                type="submit"
                fullWidth
                disabled={isPending}
                endIcon={isPending && <CircularProgress size={20} color="inherit" />}
              >
                {t('createWorkspace.button')}
              </Button>
            )}
            {showLogout && (
              <>
                <Gap />
                <Button variant="outlined" size="large" fullWidth onClick={handleLogout}>
                  {t('common.logout')}
                </Button>
              </>
            )}
          </>
        }
        hasForm={showForm}
        formProps={{
          noValidate: true,
          autoComplete: 'off',
          onSubmit: handleSubmit,
        }}
      >
        {showForm && !structureError && (
          <TextField
            name="workspaceName"
            placeholder={t('createWorkspace.placeholder')}
            value={values.workspaceName || ''}
            onChange={(event) => handleChange(event)}
            error={errors.workspaceName?.length > 0}
            helperText={errors.workspaceName}
            fullWidth
          />
        )}
        {!showForm && !structureError && (
          <EmptyBanner
            src="/images/frames/no-workspace.svg"
            title={t('createWorkspace.noIdentity.header')}
            description={t('createWorkspace.noIdentity.description')}
            buttonLabel={t('createWorkspace.noIdentity.button')}
            onClick={() => setPage('form')}
          />
        )}
        {structureError && (
          <EmptyBanner
            src="/images/frames/no-workspace.svg"
            title={t('createWorkspace.structureError.header')}
            description={t('createWorkspace.structureError.description')}
            buttonLabel={t('createWorkspace.structureError.button')}
            onClick={() => {
              window.location.reload();
            }}
          />
        )}
      </SmallContentWithScrollbar>
    </FullScreenDialog>
  );
};

CreateWorkspace.propTypes = {
  isOpen: PropTypes.bool,
  showLogout: PropTypes.bool,
  handleClose: PropTypes.func,
  noIdentity: PropTypes.bool,
  transitionDuration: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.shape({
      appear: PropTypes.number,
      enter: PropTypes.number,
      exit: PropTypes.number,
    }),
  ]),
  structureError: PropTypes.bool,
};

CreateWorkspace.defaultProps = {
  noIdentity: false,
  transitionDuration: { enter: duration.enteringScreen, exit: duration.leavingScreen },
};

export default CreateWorkspace;
