import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import authActions from 'src/redux/actions/auth.actions';
import { Button, Drawer, Typography } from '@material-ui/core';
import {
  AvatarAndTextItem,
  ContentContainer,
  HeaderButton,
  TopBarContainer,
  CreateWorkspace,
} from 'src/components';
import identityActions from 'src/redux/actions/identity.actions';
import { Close as CloseIcon, Plus as AddIcon } from 'src/components/CustomIcon';
import { HeaderUserDrawerBox, LanguageSelector } from './subcomponents';

import { useStyles } from './styles';

const HeaderUserDrawer = ({ smallScreen, showMainHeader, opened, handleToggle }) => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const activeIdentity = useSelector((state) => state.identityStore.currentIdentity);
  const isOwner = useSelector((state) => state.identityStore.isOwner);
  const structureIdentity = useSelector((state) => state.identityStore.structure);
  const availableIdentities = structureIdentity
    ? structureIdentity.filter((structure) => structure.identity.active)
    : structureIdentity;

  const [state, setState] = useState({
    createWorkspaceDialogOpen: false,
  });

  const userData = {
    login: ' - ',
    email: ' - ',
    organisation: ' - ',
    organisationId: ' - ',
  };

  if (activeIdentity) {
    userData.login = activeIdentity.identity.name;
    userData.email = activeIdentity.identity.email;
    userData.organisation = activeIdentity.workspace.name;
    userData.organisationId = activeIdentity.workspace.workspaceId;
  }

  const avatarSize = !smallScreen ? 'large' : 'medium';

  const handleClose = () => {
    setState({
      ...state,
      createWorkspaceDialogOpen: false,
    });
  };

  const handleLogout = () => {
    dispatch(authActions.deleteToken());
  };

  const changeWorkspace = (id) => {
    if (typeof handleToggle === 'function') {
      handleToggle();
    }
    dispatch(identityActions.switchWorkspace(id, () => history.push('/')));
  };

  return (
    <>
      {typeof handleToggle === 'function' && (
        <Drawer
          anchor="right"
          open={opened}
          variant="temporary"
          classes={{
            paper: clsx(classes.drawerPaper),
          }}
          onClose={handleToggle}
          className={classes.root}
        >
          <TopBarContainer className={classes.top}>
            <HeaderButton
              color="primary"
              className={classes.closeButton}
              onClick={handleToggle}
              icon={<CloseIcon />}
              tooltip={t('common.close')}
            />
          </TopBarContainer>

          <ContentContainer vertical={false} className={classes.content}>
            <LanguageSelector />
            {showMainHeader && (
              <HeaderUserDrawerBox title={t('userDrawer.title')} titleVariant="h3" />
            )}
            <HeaderUserDrawerBox title={t('userDrawer.loggedInAs')}>
              <AvatarAndTextItem
                text={userData.login}
                description={userData.email}
                spacing
                size={avatarSize}
                mainImageSrc={
                  activeIdentity
                    ? `${process.env.REACT_APP_AVATAR_URL}/identity/${activeIdentity?.identity.identityId}?kind=SMALL`
                    : null
                }
                onClick={() => {
                  history.push('/user-settings');
                  handleToggle();
                }}
              />
            </HeaderUserDrawerBox>

            <HeaderUserDrawerBox title={t('userDrawer.inOrganisation')}>
              <AvatarAndTextItem
                text={userData.organisation}
                spacing
                size={avatarSize}
                mainImageSrc={
                  activeIdentity
                    ? `${process.env.REACT_APP_AVATAR_URL}/workspace/${activeIdentity?.workspace.workspaceId}?kind=SMALL`
                    : null
                }
                onClick={
                  isOwner
                    ? () => {
                        history.push('/workspace-settings');
                        handleToggle();
                      }
                    : null
                }
              />
            </HeaderUserDrawerBox>

            <HeaderUserDrawerBox title={t('userDrawer.otherIdentities')}>
              {availableIdentities?.length > 1 ? (
                availableIdentities
                  .filter((item) => item.workspace.workspaceId !== userData.organisationId)
                  .map((item, index) => (
                    <AvatarAndTextItem
                      key={index}
                      description={item.identity.name}
                      text={item.workspace.name}
                      spacing
                      size={avatarSize}
                      mainImageSrc={`${process.env.REACT_APP_AVATAR_URL}/workspace/${item.workspace.workspaceId}?kind=SMALL`}
                      tooltip={t('common.changeIdentity')}
                      isButton
                      onClick={() => {
                        changeWorkspace(item.workspace.workspaceId);
                      }}
                    />
                  ))
              ) : (
                <Typography variant="subtitle2" component="div" align="center">
                  {t('userDrawer.otherIdentitiesEmpty')}
                </Typography>
              )}
            </HeaderUserDrawerBox>

            <HeaderUserDrawerBox>
              <Button
                variant="text"
                onClick={() => setState({ ...state, createWorkspaceDialogOpen: true })}
                startIcon={<AddIcon />}
                classes={{
                  root: classes.newButton,
                  startIcon: classes.newButtonIcon,
                }}
              >
                {t('common.newOrganisation')}
              </Button>
            </HeaderUserDrawerBox>
          </ContentContainer>

          <ContentContainer className={classes.bottom}>
            <Button size="large" fullWidth className={classes.logoutButton} onClick={handleLogout}>
              {t('common.logout')}
            </Button>
          </ContentContainer>
        </Drawer>
      )}

      <CreateWorkspace
        isOpen={state.createWorkspaceDialogOpen}
        onClose={handleClose}
        handleClose={handleClose}
      />
    </>
  );
};

HeaderUserDrawer.propTypes = {
  smallScreen: PropTypes.bool,
  showMainHeader: PropTypes.bool,
  opened: PropTypes.bool.isRequired,
  handleToggle: PropTypes.func,
};

HeaderUserDrawer.defaultProps = {
  showMainHeader: false,
};

export default HeaderUserDrawer;
