import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import React from 'react';
import { Tooltip } from '@material-ui/core';
import { PermissionList } from 'src/models/identity.model';
import { hasPermission, resolveVariant } from './permissionUtils';

const DisabledComponent = ({ disabled, children }) => React.cloneElement(children, { disabled });

DisabledComponent.propTypes = {
  disabled: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

const PermissionResolver = ({ tooltip, permission, variant, children, manualDisable }) => {
  const workspacePermissions = useSelector(
    (state) => state.identityStore.workspacePermissions,
    undefined
  );
  const permissionGranted = hasPermission({
    workspacePermissions,
    permission,
  });
  const disabled =
    manualDisable === true || (!permissionGranted && variant === resolveVariant.DISABLE);

  if (permissionGranted || manualDisable === false) return children;

  if (disabled) {
    if (tooltip) {
      return (
        <Tooltip title={tooltip}>
          <div>
            <DisabledComponent disabled>{children}</DisabledComponent>
          </div>
        </Tooltip>
      );
    }
    return <DisabledComponent disabled>{children}</DisabledComponent>;
  }
  return null;
};

PermissionResolver.propTypes = {
  variant: PropTypes.oneOf([resolveVariant.HIDE, resolveVariant.DISABLE]),
  permission: PropTypes.oneOf(PermissionList),
  tooltip: PropTypes.string,
  manualDisable: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

export default PermissionResolver;
