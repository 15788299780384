import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  ({ breakpoints }) =>
    createStyles({
      root: { textAlign: 'center' },
      content: {
        height: '100%',
        [breakpoints.down('sm')]: {
          flex: 1,
        },
      },
    }),
  { index: 0 }
);
