import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { InviteToDocument } from 'src/components';
import documentDisplayActions from 'src/redux/actions/documentDisplay.actions';
import { moduleName as documentDslService } from 'src/redux/services/documentDSL.service';
import { useHistory } from 'react-router-dom';
import { CertificateQuantityType } from '../../../../models/certificate.model';

const InvitePeople = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { generatedCertFileList, certificateQuantityType, currentLocation } = useSelector(
    (state) => state.certificateCreatorStore
  );
  const LOCAL_STORAGE_CLOSED_INVITATION_DIALOG = 'ever_closed_invitation_dialog';
  const everClosedInvitations = JSON.parse(
    localStorage.getItem(LOCAL_STORAGE_CLOSED_INVITATION_DIALOG) || '[]'
  );

  useEffect(() => {
    dispatch(
      documentDisplayActions.getDocument(
        generatedCertFileList[0]?.documentId || '7b88ee3a-c406-47ed-8a88-8fab676df723',
        documentDslService
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <InviteToDocument
        documentIds={generatedCertFileList
          .filter((file) => file.eventType === 'CERTIFICATE_FILE_CREATED')
          .map((el) => el.documentId)}
        returnToDocument={() => {
          localStorage.setItem(
            LOCAL_STORAGE_CLOSED_INVITATION_DIALOG,
            JSON.stringify([
              ...everClosedInvitations,
              ...generatedCertFileList.map((el) => el.documentId),
            ])
          );
          if (certificateQuantityType === CertificateQuantityType.Multiple) {
            history.push(
              currentLocation.name === 'Drive' ? '/drive' : `/drive/${currentLocation.id}`
            );
          } else if (generatedCertFileList[0]?.documentId) {
            history.push(`/certificate/${generatedCertFileList[0]?.documentId}`);
          }
        }}
      />
    </>
  );
};

export default InvitePeople;
