/* eslint-disable import/no-cycle */
import i18n from 'src/helpers/i18n';
import { SNACKBAR_VARIANT } from 'src/models/common.model';
import identityConstants from 'src/redux/constants/identity.constants';
import authConstants from 'src/redux/constants/auth.constants';
import authService from 'src/redux/services/auth.service';
import { switchLanguageInBrowser } from 'src/helpers/globalUtils';
import snackbarActions from './snackbar.actions';

const getUserProfile = () => (dispatch) => {
  dispatch({ type: authConstants.GET_USER_PROFILE_REQUEST });
  authService
    .getUserProfile()
    .then((response) => {
      if (response) {
        // set chat bot user data
        if (window.UE) {
          window.UE.pageHit({
            apiKey: 'w8jwni',
            email: response.data.email,
            user_id: response.data.userId,
          });
        }
        switchLanguageInBrowser(response.data.language.toUpperCase());
        dispatch({ type: authConstants.GET_USER_PROFILE_SUCCESS, response });
      }
    })
    .catch((error) => {
      dispatch({ type: authConstants.GET_USER_PROFILE_ERROR, error });
      dispatch(
        snackbarActions.enqueueSnackbar(
          SNACKBAR_VARIANT.ERROR,
          i18n.t('snackbars.common.errorTitle'),
          `${i18n.t('snackbars.common.error')} ${error.code}.`
        )
      );
    });
};

const getPermissions = () => (dispatch) => {
  dispatch({ type: authConstants.GET_PERMISSIONS_REQUEST });
  authService
    .getPermissions()
    .then((response) => {
      dispatch({ type: authConstants.GET_PERMISSIONS_SUCCESS, payload: response.data });
    })
    .catch((error) => {
      dispatch({ type: authConstants.GET_PERMISSIONS_ERROR, error });
      dispatch(
        snackbarActions.enqueueSnackbar(
          SNACKBAR_VARIANT.ERROR,
          i18n.t('snackbars.common.errorTitle'),
          `${i18n.t('snackbars.common.error')} ${error.code}.`
        )
      );
    });
};

const getApiKeys = () => (dispatch) => {
  dispatch({ type: authConstants.GET_API_KEYS_REQUEST });
  authService
    .getApiKeys()
    .then((response) => {
      if (response) {
        dispatch({ type: authConstants.GET_API_KEYS_SUCCESS, payload: response.data.collection });
      }
    })
    .catch((error) => {
      dispatch({ type: authConstants.GET_API_KEYS_ERROR, error });
      dispatch(
        snackbarActions.enqueueSnackbar(
          SNACKBAR_VARIANT.ERROR,
          i18n.t('snackbars.common.errorTitle'),
          `${i18n.t('snackbars.common.error')} ${error.code}.`
        )
      );
    });
};

const createNewApiKey = ({ permissions, name }) => (dispatch) => {
  dispatch({ type: authConstants.CREATE_API_KEY_REQUEST });
  authService
    .createNewApiKey({ permissions, name })
    .then((response) => {
      if (response) {
        dispatch({ type: authConstants.CREATE_API_KEY_SUCCESS });
        dispatch(
          snackbarActions.enqueueSnackbar(
            SNACKBAR_VARIANT.SUCCESS,
            i18n.t('snackbars.common.successTitle'),
            i18n.t('snackbars.common.success')
          )
        );
      }
    })
    .catch((error) => {
      dispatch({ type: authConstants.CREATE_API_KEY_ERROR, error });
      dispatch(
        snackbarActions.enqueueSnackbar(
          SNACKBAR_VARIANT.ERROR,
          i18n.t('snackbars.common.errorTitle'),
          `${i18n.t('snackbars.common.error')} ${error.code}.`
        )
      );
    });
};

const deleteApiKey = (publicKey) => (dispatch) => {
  dispatch({ type: authConstants.DELETE_API_KEY_REQUEST });
  authService
    .deleteApiKey(publicKey)
    .then((response) => {
      if (response) {
        dispatch({ type: authConstants.DELETE_API_KEY_SUCCESS });
        dispatch(
          snackbarActions.enqueueSnackbar(
            SNACKBAR_VARIANT.SUCCESS,
            i18n.t('snackbars.common.successTitle'),
            i18n.t('snackbars.common.success')
          )
        );
      }
    })
    .catch((error) => {
      dispatch({ type: authConstants.DELETE_API_KEY_ERROR, error });
      dispatch(
        snackbarActions.enqueueSnackbar(
          SNACKBAR_VARIANT.ERROR,
          i18n.t('snackbars.common.errorTitle'),
          `${i18n.t('snackbars.common.error')} ${error.code}.`
        )
      );
    });
};

const updateUsername = (updatedUsername) => (dispatch) => {
  dispatch({ type: authConstants.PATCH_USER_PROFILE_USERNAME_REQUEST });
  authService
    .patchUserName(updatedUsername)
    .then((response) => {
      if (response) {
        dispatch({ type: authConstants.PATCH_USER_PROFILE_USERNAME_SUCCESS, updatedUsername });
        dispatch(
          snackbarActions.enqueueSnackbar(
            SNACKBAR_VARIANT.SUCCESS,
            i18n.t('snackbars.common.successTitle'),
            i18n.t('snackbars.common.success')
          )
        );
      }
    })
    .catch((error) => {
      dispatch({ type: authConstants.PATCH_USER_PROFILE_USERNAME_ERROR, error });
      dispatch(
        snackbarActions.enqueueSnackbar(
          SNACKBAR_VARIANT.ERROR,
          i18n.t('snackbars.common.errorTitle'),
          `${i18n.t('snackbars.common.error')} ${error.code}.`
        )
      );
    });
};

const updatePassword = (passwords) => (dispatch) => {
  dispatch({ type: authConstants.PATCH_USER_PROFILE_PASSWORD_REQUEST });
  authService
    .patchPassword(passwords.oldPassword, passwords.newPassword)
    .then((response) => {
      if (response) {
        dispatch({ type: authConstants.PATCH_USER_PROFILE_PASSWORD_SUCCESS });
        dispatch(
          snackbarActions.enqueueSnackbar(
            SNACKBAR_VARIANT.SUCCESS,
            i18n.t('snackbars.common.successTitle'),
            i18n.t('snackbars.common.success')
          )
        );
      }
    })
    .catch((error) => {
      dispatch({ type: authConstants.PATCH_USER_PROFILE_PASSWORD_ERROR, error });
      dispatch(
        snackbarActions.enqueueSnackbar(
          SNACKBAR_VARIANT.ERROR,
          i18n.t('snackbars.common.errorTitle'),
          `${i18n.t('snackbars.common.error')} ${error.code}.`
        )
      );
    });
};

const updateEmail = (updatedEmail) => (dispatch) => {
  dispatch({ type: authConstants.PATCH_USER_PROFILE_EMAIL_REQUEST });
  authService
    .patchEmail(updatedEmail)
    .then((response) => {
      if (response) {
        dispatch({ type: authConstants.PATCH_USER_PROFILE_EMAIL_SUCCESS, updatedEmail });
      }
    })
    .catch((error) => {
      dispatch({ type: authConstants.PATCH_USER_PROFILE_EMAIL_ERROR, error });
      dispatch(
        snackbarActions.enqueueSnackbar(
          SNACKBAR_VARIANT.ERROR,
          i18n.t('snackbars.common.errorTitle'),
          `${i18n.t('snackbars.common.error')} ${error.code}.`
        )
      );
    });
};

const confirmEmail = (confirmationCode) => (dispatch) => {
  dispatch({ type: authConstants.POST_USER_PROFILE_EMAIL_REQUEST });
  authService
    .postEmail(confirmationCode)
    .then((response) => {
      if (response) {
        dispatch({ type: authConstants.POST_USER_PROFILE_EMAIL_SUCCESS });
        dispatch(
          snackbarActions.enqueueSnackbar(
            SNACKBAR_VARIANT.SUCCESS,
            i18n.t('snackbars.common.successTitle'),
            i18n.t('snackbars.common.success')
          )
        );
      }
    })
    .catch((error) => {
      dispatch({ type: authConstants.POST_USER_PROFILE_EMAIL_ERROR, error });
      if (error.code !== 'CODE_VERIFICATION_FAILED') {
        dispatch(
          snackbarActions.enqueueSnackbar(
            SNACKBAR_VARIANT.ERROR,
            `${i18n.t('snackbars.common.errorTitle')}`,
            `${i18n.t('snackbars.common.error')} ${error.code}.`
          )
        );
      }
    });
};

const updatePhoneNumber = (updatedPhoneNumber) => (dispatch) => {
  dispatch({ type: authConstants.PATCH_USER_PROFILE_PHONE_REQUEST });
  authService
    .patchPhoneNumber(updatedPhoneNumber)
    .then((response) => {
      if (response) {
        dispatch({ type: authConstants.PATCH_USER_PROFILE_PHONE_SUCCESS, updatedPhoneNumber });
      }
    })
    .catch((error) => {
      dispatch({ type: authConstants.PATCH_USER_PROFILE_PHONE_ERROR, error });
      dispatch(
        snackbarActions.enqueueSnackbar(
          SNACKBAR_VARIANT.ERROR,
          i18n.t('snackbars.common.errorTitle'),
          `${i18n.t('snackbars.common.error')} ${error.code}.`
        )
      );
    });
};

const confirmPhoneNumber = (confirmationCode) => (dispatch) => {
  dispatch({ type: authConstants.POST_USER_PROFILE_PHONE_REQUEST });
  authService
    .postPhoneNumber(confirmationCode)
    .then((response) => {
      if (response) {
        dispatch({ type: authConstants.POST_USER_PROFILE_PHONE_SUCCESS });
        dispatch(
          snackbarActions.enqueueSnackbar(
            SNACKBAR_VARIANT.SUCCESS,
            i18n.t('snackbars.common.successTitle'),
            i18n.t('snackbars.common.success')
          )
        );
      }
    })
    .catch((error) => {
      dispatch({ type: authConstants.POST_USER_PROFILE_PHONE_ERROR, error });
      if (error.code !== 'CODE_VERIFICATION_FAILED') {
        dispatch(
          snackbarActions.enqueueSnackbar(
            SNACKBAR_VARIANT.ERROR,
            i18n.t('snackbars.common.errorTitle'),
            `${i18n.t('snackbars.common.error')} ${error.code}.`
          )
        );
      }
    });
};

const checkTokenStatus = (permissions = []) => (dispatch) => {
  dispatch({ type: authConstants.CHECK_TOKEN_STATUS_REQUEST });
  authService
    .validatePermissions(permissions)
    .then(() => {
      dispatch({ type: authConstants.CHECK_TOKEN_STATUS_SUCCESS });
    })
    .catch((error) => {
      dispatch({ type: authConstants.CHECK_TOKEN_STATUS_FAILURE, error });
      dispatch(
        snackbarActions.enqueueSnackbar(
          SNACKBAR_VARIANT.ERROR,
          i18n.t('snackbars.common.errorTitle'),
          i18n.t('snackbars.common.error') + error
        )
      );
    });
};

const deleteToken = () => (dispatch) => {
  dispatch({ type: authConstants.DELETE_TOKEN_USER_REQUEST });
  authService
    .deleteToken()
    .then(() => {
      dispatch({ type: authConstants.DELETE_TOKEN_USER_SUCCESS });
      window.location.href = process.env.REACT_APP_AUTH_FRONT_URL;
    })
    .catch((error) => {
      dispatch({ type: authConstants.DELETE_TOKEN_USER_FAILURE, error });
      dispatch(
        snackbarActions.enqueueSnackbar(
          SNACKBAR_VARIANT.ERROR,
          i18n.t('snackbars.common.errorTitle'),
          i18n.t('snackbars.common.error') + error
        )
      );
    });
};

const patchLanguage = (newLanguage) => (dispatch) => {
  dispatch({ type: authConstants.PATCH_USER_PROFILE_LANGUAGE_REQUEST });
  authService
    .patchUserLanguage(newLanguage)
    .then(() => {
      dispatch({ type: authConstants.PATCH_USER_PROFILE_LANGUAGE_SUCCESS, newLanguage });
    })
    .catch(() => {
      dispatch({ type: authConstants.PATCH_USER_PROFILE_LANGUAGE_FAILURE });
    });
};

const deleteAccount = (errorCallback) => (dispatch) => {
  dispatch({ type: authConstants.DELETE_USER_ACCOUNT_REQUEST });
  authService
    .deleteUserAccount()
    .then(() => {
      dispatch({ type: authConstants.DELETE_USER_ACCOUNT_SUCCESS });
      const delPath = i18n.translator.translate('urls.accountDeleted');
      window.location.href = `${process.env.REACT_APP_AUTH_FRONT_URL}${delPath}`;
    })
    .catch((error) => {
      if (error?.code === 'NON_EMPTY_WORKSPACE_ONLY_OWNER') {
        dispatch({
          type: identityConstants.UPDATE_NON_EMPTY_WORKSPACE_ONLY_OWNER,
          payload: error?.data?.workspaceIds,
        });
        errorCallback();
      }
      dispatch({ type: authConstants.DELETE_USER_ACCOUNT_FAILURE });
    });
};

const authActions = {
  getApiKeys,
  getPermissions,
  createNewApiKey,
  deleteApiKey,
  checkTokenStatus,
  confirmEmail,
  confirmPhoneNumber,
  deleteAccount,
  deleteToken,
  getUserProfile,
  patchLanguage,
  updateEmail,
  updatePassword,
  updatePhoneNumber,
  updateUsername,
};

export default authActions;
