import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  ({ palette }) =>
    createStyles({
      largeSize: {},
      mediumSize: {
        width: 32,
        height: 32,
        fontSize: '0.93rem',
      },
      smallSize: {
        width: 24,
        height: 24,
        fontSize: '0.65rem',
      },
      miniSize: {
        width: 16,
        height: 16,
        fontSize: 8,
      },
      errorColor: {
        backgroundColor: palette.error.main,
        color: palette.error.contrastText,
      },
      warningColor: {
        backgroundColor: palette.warning.main,
        color: palette.warning.contrastText,
      },
      infoColor: {
        backgroundColor: palette.primary.main,
        color: palette.info.contrastText,
      },
      successColor: {
        backgroundColor: palette.success.main,
        color: palette.success.contrastText,
      },
    }),
  { index: 0 }
);
