import React, { useState } from 'react';
import i18t from 'i18next';
import { MenuItem, Select, Tooltip } from '@material-ui/core';
import { switchLanguageInBrowser } from 'src/helpers/globalUtils';
import { useDispatch } from 'react-redux';
import authActions from 'src/redux/actions/auth.actions';
import { useStyles } from './styles';

const LanguageSelector = () => {
  const classes = useStyles();
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const dispatch = useDispatch();

  const handleTooltip = (bool) => {
    setTooltipOpen(bool);
  };

  const getLanguageIconAndName = (language) => {
    switch (language) {
      case 'PL':
        return {
          code: 'pl-PL',
          text: 'language.pl-PL.text',
        };

      case 'EN':
      default:
        return {
          code: 'en-GB',
          text: 'language.en-GB.text',
        };
    }
  };

  const changeUserLanguage = (language) => {
    dispatch(authActions.patchLanguage(language));
    switchLanguageInBrowser(language.toUpperCase());
  };

  const items = ['EN', 'PL'];
  const languageItems = [];

  items.map((language) =>
    languageItems.push({
      value: language,
      active: i18t.language.toLocaleUpperCase() === language,
      name: i18t.t(getLanguageIconAndName(language).text),
      onClick: () => changeUserLanguage(language),
    })
  );

  return (
    <>
      <Tooltip title={i18t.t('common.changeLanguage')} open={tooltipOpen}>
        <Select
          onMouseEnter={() => {
            handleTooltip(true);
          }}
          onMouseLeave={() => {
            handleTooltip(false);
          }}
          onOpen={() => {
            handleTooltip(false);
          }}
          onClose={() => {
            handleTooltip(false);
          }}
          value={i18t.language.toLocaleUpperCase()}
          onChange={(event) => changeUserLanguage(event.target.value)}
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
            getContentAnchorEl: null,
          }}
          className={classes.languageSelect}
        >
          {languageItems.map((language, index) => (
            <MenuItem key={index} value={language.value}>
              {language.name}
            </MenuItem>
          ))}
        </Select>
      </Tooltip>
    </>
  );
};

export default LanguageSelector;
