import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  ({ breakpoints, custom, mixins, palette, spacing }) =>
    createStyles({
      root: {
        '&:not($smallScreenRoot)': {
          flex: 1,
          paddingRight: spacing(2),
        },
      },
      smallScreenRoot: {},
      groupHeader: {
        position: 'sticky',
        top: 0,
        backgroundColor: palette.background.paper,
        zIndex: 1,
        minHeight: 48,
        '& h6': {
          width: 40,
          whiteSpace: 'nowrap',
        },
        '& .titleCell': {
          '& > *': {
            display: 'none',
          },
        },
        '&:not(:first-child)': {
          marginTop: spacing(2),
        },
      },
      smallScreenGroupHeader: {
        minHeight: 40,
        '&:before': {
          content: "''",
          display: 'block',
          backgroundColor: 'inherit',
          position: 'absolute',
          left: 0,
          right: 0,
          bottom: 0,
          height: '100%',
        },
        '& > *': {
          position: 'relative',
        },
      },
      option: {
        border: 'none !important',
        padding: 0,
      },
      appBar: {
        // borderBottom: `1px solid ${palette.divider}`,
      },
      backButton: {
        marginRight: spacing(1),
      },
      loader: {
        position: 'absolute',
        // right: spacing(1),
        right: spacing(1.5),
        zIndex: 1,
        pointerEvents: 'none',
      },
      inputRoot: {
        '&:not($smallScreenInputRoot)': {
          backgroundColor: palette.background.default,
        },
      },
      smallScreenInputRoot: {
        padding: spacing(0, 2),
        [breakpoints.up('md')]: {
          padding: spacing(0, 3),
        },
        [breakpoints.up('lg')]: {
          padding: spacing(0, 5),
        },
        '& $loader': {
          right: spacing(2),
        },
      },
      smallScreenInput: {
        marginTop: -1,
        paddingLeft: '0 !important',
        paddingRight: `${spacing(1)}px !important`,
        boxSizing: 'border-box',
        ...mixins.toolbar,
      },
      smallScreenPopper: {
        width: '100% !important',
        left: 0,
        top: custom.headerMobileNavHeight,
        [breakpoints.up('md')]: {
          top: custom.headerNavHeight,
        },
      },
      popperDisablePortal: {
        [breakpoints.up('md')]: {
          marginTop: spacing(1),
        },
        '&:not($smallScreenPopperDisablePortal)': {
          [breakpoints.between('md', 'lg')]: {
            position: 'fixed',
            top: custom.headerNavHeight / 2 + 48 / 2,
          },
          [breakpoints.only('md')]: {
            left: `calc(${custom.leftMenuWidth}px + ${spacing(3)}px)`,
            right: spacing(3),
            minWidth: `calc(100% - ${custom.leftMenuWidth + spacing(6)}px)`,
            maxHeight: `calc(100% - ${custom.headerNavHeight + spacing(3)}px)`,
          },
          [breakpoints.only('lg')]: {
            left: `calc(${custom.leftMenuWidth}px + ${spacing(5)}px)`,
            right: spacing(5),
            minWidth: `calc(100% - ${custom.leftMenuWidth + spacing(10)}px)`,
            maxHeight: `calc(100% - ${custom.headerNavHeight + spacing(5)}px)`,
          },
        },
      },
      smallScreenPopperDisablePortal: {},
      smallScreenPaper: {},
      listBox: {
        maxHeight: `calc(100vh - ${custom.headerMobileNavHeight}px)`,
        [breakpoints.up('md')]: {
          maxHeight: `calc(100vh - ${custom.headerNavHeight}px)`,
        },
      },
      smallScreenListBox: {
        overflowX: 'hidden',
        maxHeight: 'none',
        height: `calc(100vh - ${custom.headerMobileNavHeight}px)`,
        padding: spacing(2, 1.5),
        paddingTop: '0 !important',
        '& $smallScreenGroupHeader': {
          padding: spacing(1, 2),
          marginLeft: spacing(-1.5),
          marginRight: spacing(-1.5),
        },
        [breakpoints.up('sm')]: {
          padding: spacing(2, 1),
          '& $smallScreenGroupHeader': {
            marginLeft: spacing(-1),
            marginRight: spacing(-1),
          },
        },
        [breakpoints.up('md')]: {
          height: `calc(100vh - ${custom.headerNavHeight}px)`,
          padding: spacing(2, 1.5),
          '& $smallScreenGroupHeader': {
            marginLeft: spacing(-1.5),
            marginRight: spacing(-1.5),
          },
        },
        [breakpoints.up('lg')]: {
          padding: spacing(2, 2.5),
          '& $smallScreenGroupHeader': {
            marginLeft: spacing(-2.5),
            marginRight: spacing(-2.5),
          },
        },
      },
      smallScreenNoOptionsAndLoading: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        ...mixins.toolbar,
      },
      endAdornment: {
        right: `${spacing(1)}px !important`,
        top: '50%',
        transform: 'translateY(-50%)',
      },
      smallScreenEndAdornment: {
        right: spacing(2),
      },
      clearIndicator: {
        marginRight: 0,
        padding: 0,
        height: 32,
        minHeight: 32,
        width: 32,
        minWidth: 32,
        '& svg': {
          fontSize: 18,
        },
      },
      smallScreenClearIndicator: {
        height: 40,
        minHeight: 40,
        width: 40,
        minWidth: 40,
        '& svg': {
          fontSize: 24,
        },
      },
      hasClearIcon: {
        '& $inputRoot': {
          paddingRight: '48px !important',
        },
      },
      smallScreenHasClearIcon: {
        '& $smallScreenInputRoot': {
          paddingRight: 40 + spacing(2),
        },
      },
    }),
  { index: 0 }
);
