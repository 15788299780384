import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
  Button,
  CircularProgress,
  Fab,
  Popover,
  useMediaQuery,
  Typography,
  Modal,
  Box,
  TextField,
} from '@material-ui/core';
import {
  FileEdit as SignedDocumentIcon,
  OpenInNew as OpenInNewIcon,
  FilesWithShield as FilesWithShieldIcon,
  Shield as ShieldIcon,
  Download as DownloadIcon,
  Settings as SettingsIcon,
  ImportExport as ImportExportIcon,
  Check as CheckIcon,
  Close as CloseIcon,
  Stopwatch as StopwatchIcon,
  CertificateIcon,
  HourglassProgress,
  PenOutlined,
} from 'src/components/CustomIcon';
import clsx from 'clsx';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { CheckCircleOutline } from '@material-ui/icons';
import documentManagementActions from 'src/redux/actions/documentManagement.actions';
import certificateManagementActions from 'src/redux/actions/certificateManagement.actions';
import { MUTATION_NAMES } from 'src/redux/services/documentDSL.service';
import { ExtendedGrid } from 'src/components';
import { RequestStatus } from 'src/helpers/reduxReuquest.util';
import { useTransactions } from 'src/pages/DocumentActions/helpers';

import { useStyles } from './styles';
import { TabHeader } from '../../subcomponents';
import InfoSection from './subcomponents/InfoSection';
import LabelValueReadonly from './subcomponents/LabelValueReadonly';
import ContactHelper from '../../../../helpers/contactHelper';
import { DocumentStatus, DocumentType, getStatusName } from '../../../../models/document.model';
import { documentDisplaySelector } from '../../../../redux/selectors/documentDisplay.selector';
import { DateTimeFormats, FormatDate } from '../../../../helpers/dateTimeFormats';

const MODAL_TYPES = {
  TRANSFER_OWNERSHIP: 'TRANSFER_OWNERSHIP',
  REVALIDATE: 'REVALIDATE',
  TEMPORARILY_INVALIDATE: 'TEMPORARILY_INVALIDATE',
  INVALIDATE: 'INVALIDATE',
};

const Info = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const history = useHistory();
  const isCertificate = history.location.pathname.includes('certificate');

  const { document } = useSelector((state) => state.documentDisplayStore, undefined);
  const expiresOn = useSelector(documentDisplaySelector.selectDocumentExpiresOn, undefined);
  const { getDocumentCardStatus, documentSigners } = useSelector(
    (state) => state.documentManagementStore,
    undefined
  );

  const { isLoading, allowedTransactions } = useTransactions(
    document?.id,
    document?.status,
    isCertificate
  );

  const classes = useStyles();

  const smallDevice = useMediaQuery(({ breakpoints }) => breakpoints.down('sm'));
  const showInExplorer = () => {
    window
      .open(`${process.env.REACT_APP_EXPLORER_FRONT_URL}document/${document.id}`, '_blank')
      .focus();
  };

  const [settingsAnchorEl, setSettingsAnchorEl] = useState(null);
  const [modalState, setModalState] = useState({ open: false, type: null });
  const [reason, setReason] = useState('');

  const handleCloseModal = () => {
    setModalState({ open: false, type: null });
    setReason('');
  };

  const handleSettingsClick = (event) => {
    setSettingsAnchorEl(event.currentTarget);
  };

  const handleSettingsClose = () => {
    setSettingsAnchorEl(null);
  };

  const open = Boolean(settingsAnchorEl);
  const id = open ? 'document-details-settings-popover' : undefined;

  const isDownloadingDocumentCard = getDocumentCardStatus === RequestStatus.PENDING;

  const downloadDocumentCard = () => {
    dispatch(documentManagementActions.getDocumentCard(document.id, document.workspaceId));
  };
  const author = ContactHelper.getEntityData(document?.authorId);

  const handleChangeReason = (e) => {
    setReason(e.target.value);
  };

  const triggerTransferOwnership = () => {
    handleSettingsClose();
    setModalState({ open: true, type: MODAL_TYPES.TRANSFER_OWNERSHIP });
  };

  const triggerRevalidate = () => {
    handleSettingsClose();
    setModalState({ open: true, type: MODAL_TYPES.REVALIDATE });
  };

  const triggerTemporarilyInvalidate = () => {
    handleSettingsClose();
    setModalState({ open: true, type: MODAL_TYPES.TEMPORARILY_INVALIDATE });
  };

  const triggerInvalidate = () => {
    handleSettingsClose();
    setModalState({ open: true, type: MODAL_TYPES.INVALIDATE });
  };

  const getDocumentTabInfo = () => {
    switch (document?.documentType) {
      case DocumentType.DOCUMENT_TO_BE_SIGNED:
        return {
          icon: <SignedDocumentIcon />,
          title: t('documentActions.tabs.info.documentWithSignature.title'),
          description: t('documentActions.tabs.info.documentWithSignature.description'),
        };
      case DocumentType.DURABLE_MEDIA:
        return {
          icon: <ShieldIcon />,
          title: t('documentActions.tabs.info.durableMedia.title'),
          description: t('documentActions.tabs.info.durableMedia.description'),
        };

      default:
        return {
          icon: <CertificateIcon />,
          title: t('documentActions.tabs.info.certificate.title'),
          description: t('documentActions.tabs.info.certificate.description'),
        };
    }
  };

  const renderModal = (() => {
    const submit = () => {
      if (modalState.type === MODAL_TYPES.INVALIDATE) {
        dispatch(
          certificateManagementActions.invalidateCertificate(
            document.id,
            document.workspaceId,
            reason
          )
        );
        handleCloseModal();

        return;
      }

      if (modalState.type === MODAL_TYPES.REVALIDATE) {
        dispatch(
          certificateManagementActions.validateCertificate(
            document.id,
            document.workspaceId,
            reason
          )
        );
        handleCloseModal();

        return;
      }

      if (modalState.type === MODAL_TYPES.TEMPORARILY_INVALIDATE) {
        dispatch(
          certificateManagementActions.invalidateTemporarilyCertificate(
            document.id,
            document.workspaceId,
            reason
          )
        );
        handleCloseModal();

        return;
      }

      if (modalState.type === MODAL_TYPES.TRANSFER_OWNERSHIP) {
        history.push(`/transfer-ownership/${document.id}`);
      }
    };

    switch (modalState.type) {
      case MODAL_TYPES.TRANSFER_OWNERSHIP:
        return (
          <>
            <Typography variant={smallDevice ? 'h3' : 'h2'} className={classes.header}>
              {t('documentActions.tabs.info.transferOwnership.title')}
            </Typography>
            <Typography
              id="modal-modal-description"
              sx={{ mt: 2 }}
              className={classes.message}
              variant="body1"
            >
              {t('documentActions.tabs.info.transferOwnership.confirmation')}
            </Typography>

            <div style={{ width: '100%' }}>
              <Button
                size="large"
                type="button"
                fullWidth
                className={classes.submitButton}
                onClick={submit}
              >
                {t('documentActions.tabs.info.transferOwnership.submit')}
              </Button>
              <Button
                size="large"
                type="button"
                fullWidth
                variant="text"
                onClick={handleCloseModal}
              >
                {t('common.cancel')}
              </Button>
            </div>
          </>
        );

      case MODAL_TYPES.REVALIDATE:
        return (
          <>
            <Typography variant={smallDevice ? 'h3' : 'h2'} className={classes.header}>
              {t('documentActions.tabs.info.settings.revalidate.title')}
            </Typography>
            <Typography
              id="modal-modal-description"
              sx={{ mt: 2 }}
              className={classes.message}
              variant="body1"
            >
              {t('documentActions.tabs.info.settings.revalidate.caption')}
            </Typography>
            <TextField
              multiline
              className={classes.textArea}
              maxRows={6}
              value={reason}
              onChange={handleChangeReason}
              placeholder={t('documentActions.tabs.info.settings.revalidate.placeholder')}
            />
            <div style={{ width: '100%' }}>
              <Button
                size="large"
                type="button"
                fullWidth
                color="success"
                className={classes.successButton}
                onClick={submit}
                disabled={!reason}
              >
                {t('documentActions.tabs.info.settings.revalidate.submit')}
              </Button>
              <Button size="large" type="button" fullWidth variant="text">
                {t('common.cancel')}
              </Button>
            </div>
          </>
        );

      case MODAL_TYPES.TEMPORARILY_INVALIDATE:
        return (
          <>
            <Typography variant={smallDevice ? 'h3' : 'h2'} className={classes.header}>
              {t('documentActions.tabs.info.settings.temporarilyInvalidate.title')}
            </Typography>
            <Typography
              id="modal-modal-description"
              sx={{ mt: 2 }}
              className={classes.message}
              variant="body1"
            >
              <Trans
                i18nKey="documentActions.tabs.info.settings.temporarilyInvalidate.caption_html"
                components={{ strong: <strong className={classes.messageWithWarning} /> }}
              />
            </Typography>
            <TextField
              multiline
              className={classes.textArea}
              maxRows={6}
              value={reason}
              onChange={handleChangeReason}
              placeholder={t(
                'documentActions.tabs.info.settings.temporarilyInvalidate.placeholder'
              )}
            />
            <div style={{ width: '100%' }}>
              <Button
                size="large"
                type="button"
                fullWidth
                className={classes.warningButton}
                onClick={submit}
                disabled={!reason}
              >
                {t('documentActions.tabs.info.settings.temporarilyInvalidate.submit')}
              </Button>
              <Button
                size="large"
                type="button"
                fullWidth
                variant="text"
                onClick={handleCloseModal}
              >
                {t('common.cancel')}
              </Button>
            </div>
          </>
        );

      case MODAL_TYPES.INVALIDATE:
        return (
          <>
            <Typography variant={smallDevice ? 'h3' : 'h2'} className={classes.header}>
              {t('documentActions.tabs.info.settings.invalidate.title')}
            </Typography>
            <Typography
              id="modal-modal-description"
              sx={{ mt: 2 }}
              className={classes.message}
              variant="body1"
            >
              <Trans
                i18nKey="documentActions.tabs.info.settings.invalidate.caption_html"
                components={{ strong: <strong className={classes.messageWithError} /> }}
              />
            </Typography>
            <TextField
              multiline
              className={classes.textArea}
              maxRows={6}
              value={reason}
              onChange={handleChangeReason}
              placeholder={t('documentActions.tabs.info.settings.invalidate.placeholder')}
            />
            <div style={{ width: '100%' }}>
              <Button
                size="large"
                type="button"
                fullWidth
                className={classes.errorButton}
                onClick={submit}
                disabled={!reason}
              >
                {t('documentActions.tabs.info.settings.invalidate.submit')}
              </Button>
              <Button
                size="large"
                type="button"
                fullWidth
                variant="text"
                onClick={handleCloseModal}
              >
                {t('common.cancel')}
              </Button>
            </div>
          </>
        );
      default:
        return null;
    }
  })();

  const mediumScreen = useMediaQuery(({ breakpoints }) => breakpoints.down('md'));

  // TODO: remove after decision
  const getStatusComponent = (text, color = 'default', icon = null) => {
    const containerStyle = clsx(classes.documentStatus, color);

    return (
      <div className={containerStyle}>
        {icon}
        <Typography component="span" variant="body1" className={classes.documentStatusText}>
          {text}
        </Typography>
      </div>
    );
  };

  const status = (() => {
    const statusText = document.status;

    const statusName = getStatusName({
      status: statusText,
      isShort: mediumScreen,
      t,
      isCertificate,
    });
    if (documentSigners.length === 0) {
      return getStatusComponent(
        getStatusName({
          status: DocumentStatus.READY_FOR_PREVIEW,
          isShort: mediumScreen,
          t,
          isCertificate,
        }),
        'success',
        <CheckCircleOutline />
      );
    }
    switch (statusText) {
      case DocumentStatus.ISSUING:
      case DocumentStatus.SIGNING:
        return getStatusComponent(statusName, 'info', <HourglassProgress />);
      case DocumentStatus.EDITING:
        return getStatusComponent(statusName, 'default', <PenOutlined />);
      case DocumentStatus.SIGNED:
      case DocumentStatus.FINISHED:
      case DocumentStatus.READY_FOR_PREVIEW:
      case DocumentStatus.VALID:
        return getStatusComponent(statusName, 'success', <CheckCircleOutline />);
      case DocumentStatus.EXPIRED:
      case DocumentStatus.INVALIDATED:
      case DocumentStatus.REJECTED:
        return getStatusComponent(statusName, 'error', <CloseIcon />);

      case DocumentStatus.TEMPORARILY_INVALIDATE:
        return getStatusComponent(statusName, 'warning', <StopwatchIcon />);
      default:
        return getStatusComponent(statusText?.[0].toUpperCase() + statusText?.slice(1));
    }
  })();

  const AVAILABLE_MUTATIONS = allowedTransactions?.map((mutation) => mutation.transitionName);
  const hasAnyAction =
    AVAILABLE_MUTATIONS.includes(MUTATION_NAMES.CHANGE_HOLDER) ||
    AVAILABLE_MUTATIONS.includes(MUTATION_NAMES.INVALIDATE_TEMPORARILY_DOCUMENT) ||
    AVAILABLE_MUTATIONS.includes(MUTATION_NAMES.INVALIDATE_DOCUMENT) ||
    AVAILABLE_MUTATIONS.includes(MUTATION_NAMES.VALIDATE_DOCUMENT);

  return (
    <>
      <Modal
        open={modalState.open}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className={classes.modal}>{renderModal}</Box>
      </Modal>
      {!isLoading && (
        <Popover
          id={id}
          open={open}
          anchorEl={settingsAnchorEl}
          onClose={handleSettingsClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <div className={classes.settingsPopover}>
            {AVAILABLE_MUTATIONS.includes(MUTATION_NAMES.CHANGE_HOLDER) && (
              <Typography
                className={classes.settingsPopoverItem}
                onClick={triggerTransferOwnership}
              >
                <ImportExportIcon />
                <Typography className={classes.settingsPopoverItemLabel}>
                  {t('documentActions.tabs.info.transferOwnership.title')}
                </Typography>
              </Typography>
            )}

            {AVAILABLE_MUTATIONS.includes(MUTATION_NAMES.VALIDATE_DOCUMENT) && (
              <Typography className={classes.settingsPopoverItem} onClick={triggerRevalidate}>
                <CheckIcon />
                <Typography className={classes.settingsPopoverItemLabel}>
                  {t('documentActions.tabs.info.settings.revalidate.title')}
                </Typography>
              </Typography>
            )}

            {AVAILABLE_MUTATIONS.includes(MUTATION_NAMES.INVALIDATE_TEMPORARILY_DOCUMENT) && (
              <Typography
                className={classes.settingsPopoverItem}
                onClick={triggerTemporarilyInvalidate}
              >
                <StopwatchIcon />
                <Typography className={classes.settingsPopoverItemLabel}>
                  {t('documentActions.tabs.info.settings.temporarilyInvalidate.title')}
                </Typography>
              </Typography>
            )}

            {AVAILABLE_MUTATIONS.includes(MUTATION_NAMES.INVALIDATE_DOCUMENT) && (
              <Typography className={classes.settingsPopoverItem} onClick={triggerInvalidate}>
                <CloseIcon />
                <Typography className={classes.settingsPopoverItemLabel}>
                  {t('documentActions.tabs.info.settings.invalidate.title')}
                </Typography>
              </Typography>
            )}
          </div>
        </Popover>
      )}
      <div>
        <TabHeader
          label=""
          action={
            <Button
              size="small"
              variant="text"
              color="primary"
              endIcon={<OpenInNewIcon />}
              onClick={showInExplorer}
            >
              {t('documentActions.tabs.info.showInExplorer')}
            </Button>
          }
        />

        <InfoSection
          startIcon={getDocumentTabInfo().icon}
          title={getDocumentTabInfo().title}
          description={getDocumentTabInfo().description}
          endButton={
            isCertificate &&
            hasAnyAction && (
              <Fab
                onClick={handleSettingsClick}
                variant="round"
                size="small"
                color="primary"
                aria-describedby={id}
              >
                <SettingsIcon />
              </Fab>
            )
          }
        >
          {status}

          <ExtendedGrid
            container
            spacingY={smallDevice ? 4 : 2}
            wrapLg="nowrap"
            alignItemsXxs="center"
          >
            <ExtendedGrid item {...{ xxs: 12, xs: 6, md: 12, lg: true }}>
              <LabelValueReadonly
                label={t('common.created')}
                value={
                  <FormatDate
                    date={document?.createdAt}
                    dateFormat={DateTimeFormats.dateLongShortMonth}
                    timeFormat={DateTimeFormats.timeShort}
                  />
                }
              />
            </ExtendedGrid>
            {isCertificate && (
              <ExtendedGrid item {...{ xxs: 12, xs: 6, md: 12, lg: true }}>
                <LabelValueReadonly
                  label={t('common.expires')}
                  value={
                    expiresOn ? (
                      <FormatDate
                        date={expiresOn}
                        dateFormat={DateTimeFormats.dateLongShortMonth}
                        timeFormat={DateTimeFormats.timeShort}
                      />
                    ) : (
                      t('common.noExpiring')
                    )
                  }
                />
              </ExtendedGrid>
            )}
          </ExtendedGrid>
          <LabelValueReadonly
            label={isCertificate ? t('common.issuedBy') : t('common.author')}
            value={author.name}
          />
          <LabelValueReadonly label={t('common.documentIdentifier')} value={document?.id} />
        </InfoSection>

        <InfoSection
          startIcon={<FilesWithShieldIcon />}
          title={t('documentActions.tabs.info.documentCard.title')}
          description={t('documentActions.tabs.info.documentCard.description')}
          endButton={
            <Fab
              onClick={downloadDocumentCard}
              variant="round"
              size="small"
              color="primary"
              disabled={isDownloadingDocumentCard}
            >
              {isDownloadingDocumentCard ? <CircularProgress size={20} /> : <DownloadIcon />}
            </Fab>
          }
        />
      </div>
    </>
  );
};

export default Info;
