import transitions from '@material-ui/core/styles/transitions';
import typography from '../typography';
import palette from '../palette';
import spacing from '../spacing';

export default {
  root: {
    color: palette.text.secondary,
    paddingTop: spacing(1),
    paddingBottom: spacing(1),
    fontSize: typography.htmlFontSize,
    lineHeight: 'inherit',
    '&:hover': {
      color: palette.text.primary,
    },
    '&$focusVisible': {
      color: palette.text.primary,
    },
    '&$selected': {
      fontWeight: typography.fontWeightMedium,
      color: palette.primary.main,
      backgroundColor: palette.background.paper,
      '&:hover': {
        backgroundColor: palette.action.hover,
      },
    },
  },
  divider: {
    borderBottom: `1px solid ${palette.divider}`,
  },
  gutters: {
    paddingLeft: 12,
    paddingRight: 12,
  },
  dense: {
    minHeight: 36,
    paddingTop: spacing(0.5),
    paddingBottom: spacing(0.5),
  },
  button: {
    transition: transitions.create(['background-color', 'box-shadow', 'border', 'color'], {
      duration: transitions.duration.short,
    }),
  },
};
