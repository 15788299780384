import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import billingActions from 'src/redux/actions/billing.actions';
import { useTranslation } from 'react-i18next';
import { Button, CircularProgress, TextField, Typography } from '@material-ui/core';
import { SmallContentWithScrollbar } from 'src/components';
import PropTypes from 'prop-types';
import { RequestStatus } from 'src/helpers/reduxReuquest.util';

const AddDiscountCode = ({ onClose, workspaceId }) => {
  const { t } = useTranslation();
  const [value, setValue] = useState('');
  const dispatch = useDispatch();
  const promotionCodeStatus = useSelector(
    (state) => state.billingStore.promotionCodeStatus,
    undefined
  );

  useEffect(() => {
    if (promotionCodeStatus === RequestStatus.SUCCESS && value.length) {
      onClose(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [promotionCodeStatus]);

  const isPending = promotionCodeStatus === RequestStatus.PENDING;
  const isDisabled = !value || isPending;

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(billingActions.addPromotionCode(workspaceId, value));
  };

  return (
    <SmallContentWithScrollbar
      startContent={
        <Typography>
          {t('workspacePage.subscriptionsAndPayments.addDiscountCodeSubtitle')}
        </Typography>
      }
      endContent={
        <Button
          fullWidth
          size="large"
          disabled={isDisabled}
          type="submit"
          endIcon={isPending && <CircularProgress size={20} color="inherit" />}
        >
          {t('workspacePage.subscriptionsAndPayments.addDiscountCodeAccept')}
        </Button>
      }
      hasForm
      formProps={{
        noValidate: true,
        autoComplete: 'off',
        onSubmit: handleSubmit,
      }}
    >
      <TextField
        autoFocus
        fullWidth
        placeholder={t('workspacePage.subscriptionsAndPayments.addDiscountCodePlaceholder')}
        onChange={(event) => {
          setValue(event.target.value);
        }}
        disabled={isPending}
      />
    </SmallContentWithScrollbar>
  );
};

AddDiscountCode.propTypes = {
  onClose: PropTypes.func,
  workspaceId: PropTypes.string,
};

export default AddDiscountCode;
