import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  ({ palette, spacing }) =>
    createStyles({
      root: {
        display: 'flex',
        alignItems: 'center',
      },
      button: {
        marginRight: spacing(1),
        '&:not(:hover):not(:focus):not(:active)': {
          '& svg': {
            color: palette.grey.darkest,
          },
        },
      },
    }),
  { index: 0 }
);
