import { RequestStatus } from 'src/helpers/reduxReuquest.util';
import identityConstants from '../constants/identity.constants';
import { deactivatedGroup, updateOnlyOneGroupInState } from './workspaceGroups.reducer';

const initialState = {
  structure: null,
  structureStatus: RequestStatus.IDLE,
  createWorkspaceStatus: RequestStatus.IDLE,
  currentIdentity: null,
  isOwner: null,
  nonEmptyWorkspaces: [],
  sendTransactionFromNewDocumentStatus: RequestStatus.IDLE,
  sendTransactionFromNewCertificateStatus: RequestStatus.IDLE,
  switchContactEmailRequestError: null,
  switchContactEmailRequestStatus: RequestStatus.IDLE,
  updateWorkspaceDetailsStatus: RequestStatus.IDLE,
  updateWorkspaceInfoStatus: RequestStatus.IDLE,
  workspaceDetails: {},
  workspaceGroups: [],
  workspaceInfo: {},
  workspaceInfoStatus: RequestStatus.IDLE,
  workspacePermissions: [],
  workspacePermissionsStatus: RequestStatus.IDLE,
  workspaceWorkflows: [],
  workspaceWorkflowsStatus: RequestStatus.IDLE,
  workspaceWorkflowsErrorCode: null,
  workspaceStructureStatus: RequestStatus.IDLE,
  workspaceUsers: [],
};

const parseGroupsToUsers = (groups) => {
  const users = [];
  const allGroup = groups.find((group) => group?.info?.name === 'All');
  if (allGroup) {
    allGroup.members.forEach((user) => {
      users.push(user);
    });
  }
  return users;
};

const updateStructureWorkspace = (structure, newWorkspace, targetWorkspaceId) => {
  const newStructure = [...structure];
  for (let i = 0; i < structure.length; i += 1) {
    if (structure[i].workspace.workspaceId === targetWorkspaceId) {
      newStructure[i].workspace = { ...structure[i].workspace, ...newWorkspace };
    }
  }
  return newStructure;
};

const updateStructureIdentityContactMail = (structure, currentIdentity, email) => {
  const newStructure = [...structure];
  const updatedIndex = newStructure.findIndex(
    (user) => user.identity.identityId === currentIdentity.identity.identityId
  );

  return [
    ...newStructure.slice(0, updatedIndex),
    {
      ...newStructure[updatedIndex],
      identity: {
        ...newStructure[updatedIndex].identity,
        contactEmail: email,
      },
    },
    ...newStructure.slice(updatedIndex + 1),
  ];
};

const isOwner = (currentIdentity) => {
  if (!currentIdentity) return false;
  const ownersGroup = currentIdentity.groups.find(
    (group) => group.name === 'Owners' && group.active
  );
  return !!ownersGroup;
};

const updateMembersInGroup = (state, groupId, members) =>
  state.workspaceGroups.map((group) => {
    if (group.info.groupId === groupId) {
      group.members = members;
    }
    return group;
  });

function identityStore(state = initialState, action) {
  switch (action.type) {
    case identityConstants.FETCH_STRUCTURE_REQUEST:
      return {
        ...state,
        structureStatus: RequestStatus.PENDING,
      };
    case identityConstants.FETCH_STRUCTURE_SUCCESS:
      return {
        ...state,
        structureStatus: RequestStatus.SUCCESS,
        structure: action.structure,
      };
    case identityConstants.FETCH_STRUCTURE_FAILURE:
      return {
        ...state,
        structureStatus: RequestStatus.ERROR,
      };
    case identityConstants.SET_ACTIVE_IDENTITY:
      return {
        ...state,
        currentIdentity: action.identity,
        isOwner: isOwner(action.identity),
      };
    case identityConstants.FETCH_WORKSPACE_STRUCTURE_REQUEST:
      return {
        ...state,
        workspaceStructureStatus: RequestStatus.PENDING,
      };
    case identityConstants.FETCH_WORKSPACE_STRUCTURE_SUCCESS:
      return {
        ...state,
        workspaceStructureStatus: RequestStatus.SUCCESS,
        workspaceDetails: action.info,
        workspaceGroups: action.groups,
        workspaceUsers: parseGroupsToUsers(action.groups),
      };
    case identityConstants.FETCH_WORKSPACE_STRUCTURE_FAILURE:
      return {
        ...state,
        workspaceStructureStatus: RequestStatus.ERROR,
      };
    case identityConstants.FETCH_WORKSPACE_INFO_REQUEST: {
      return {
        ...state,
        workspaceInfoStatus: RequestStatus.PENDING,
      };
    }
    case identityConstants.FETCH_WORKSPACE_INFO_SUCCESS:
      return {
        ...state,
        workspaceInfoStatus: RequestStatus.SUCCESS,
        workspaceInfo: action.workspaceInfo,
      };
    case identityConstants.FETCH_WORKSPACE_INFO_FAILURE:
      return {
        ...state,
        workspaceInfoStatus: RequestStatus.ERROR,
      };
    case identityConstants.UPDATE_WORKSPACE_INFO_REQUEST:
      return {
        ...state,
        updateWorkspaceInfoStatus: RequestStatus.PENDING,
      };
    case identityConstants.UPDATE_WORKSPACE_INFO_SUCCESS:
      return {
        ...state,
        updateWorkspaceInfoStatus: RequestStatus.SUCCESS,
        workspaceInfo: {
          ...state.workspaceInfo,
          ...action.workspaceInfo,
        },
      };
    case identityConstants.UPDATE_WORKSPACE_INFO_FAILURE:
      return {
        ...state,
        updateWorkspaceInfoStatus: RequestStatus.ERROR,
      };
    case identityConstants.CREATE_NEW_WORKSPACE_FAILURE:
      return {
        ...state,
        createWorkspaceStatus: RequestStatus.ERROR,
      };
    case identityConstants.CREATE_NEW_WORKSPACE_SUCCESS:
      return {
        ...state,
        createWorkspaceStatus: RequestStatus.SUCCESS,
      };
    case identityConstants.CREATE_NEW_WORKSPACE_REQUEST:
      return {
        ...state,
        createWorkspaceStatus: RequestStatus.PENDING,
      };
    case identityConstants.SWITCH_CONTACT_EMAIL_REQUEST:
      return {
        ...state,
        switchContactEmailRequestStatus: RequestStatus.PENDING,
      };
    case identityConstants.SWITCH_CONTACT_EMAIL_SUCCESS:
      return {
        ...state,
        switchContactEmailRequestStatus: RequestStatus.SUCCESS,
        currentIdentity: {
          ...state.currentIdentity,
          identity: {
            ...state.currentIdentity.identity,
            contactEmail: action.email,
          },
        },
        structure: updateStructureIdentityContactMail(
          state.structure,
          state.currentIdentity,
          action.email
        ),
      };
    case identityConstants.SWITCH_CONTACT_EMAIL_FAILURE:
      return {
        ...state,
        switchContactEmailRequestStatus: RequestStatus.ERROR,
        switchContactEmailRequestError: action.error,
      };
    case identityConstants.UPDATE_WORKSPACE_DETAILS_REQUEST:
      return {
        ...state,
        updateWorkspaceDetailsStatus: RequestStatus.PENDING,
      };
    case identityConstants.UPDATE_WORKSPACE_DETAILS_SUCCESS:
      return {
        ...state,
        structure: updateStructureWorkspace(
          state.structure,
          action.workspaceDetails,
          state.currentIdentity?.workspace?.workspaceId
        ),
        updateWorkspaceDetailsStatus: RequestStatus.SUCCESS,
        workspaceDetails: {
          ...state.workspaceDetails,
          ...action.workspaceDetails,
        },

        currentIdentity: {
          ...state.currentIdentity,
          workspace: {
            ...state.currentIdentity.workspace,
            ...action.workspaceDetails,
          },
        },
      };
    case identityConstants.UPDATE_WORKSPACE_DETAILS_FAILURE:
      return {
        ...state,
        updateWorkspaceDetailsStatus: RequestStatus.ERROR,
      };
    case identityConstants.SEND_TRANSACTION_FROM_NEW_DOCUMENT_REQUEST:
      return {
        ...state,
        sendTransactionFromNewDocumentStatus: RequestStatus.PENDING,
      };
    case identityConstants.SEND_TRANSACTION_FROM_NEW_DOCUMENT_SUCCESS:
      return {
        ...state,
        sendTransactionFromNewDocumentStatus: RequestStatus.SUCCESS,
      };
    case identityConstants.SEND_TRANSACTION_FROM_NEW_DOCUMENT_FAILURE:
      return {
        ...state,
        sendTransactionFromNewDocumentStatus: RequestStatus.ERROR,
      };
    case identityConstants.SEND_TRANSACTION_FROM_NEW_CERTIFICATE_REQUEST:
      return {
        ...state,
        sendTransactionFromNewCertificateStatus: RequestStatus.PENDING,
      };
    case identityConstants.SEND_TRANSACTION_FROM_NEW_CERTIFICATE_SUCCESS:
      return {
        ...state,
        sendTransactionFromNewCertificateStatus: RequestStatus.SUCCESS,
      };
    case identityConstants.SEND_TRANSACTION_FROM_NEW_CERTIFICATE_FAILURE:
      return {
        ...state,
        sendTransactionFromNewCertificateStatus: RequestStatus.ERROR,
      };
    case identityConstants.FETCH_WORKSPACE_PERMISSIONS_REQUEST:
      return {
        ...state,
        workspacePermissionsStatus: RequestStatus.PENDING,
      };
    case identityConstants.FETCH_WORKSPACE_PERMISSIONS_SUCCESS:
      return {
        ...state,
        workspacePermissionsStatus: RequestStatus.SUCCESS,
        workspacePermissions: action.data,
      };
    case identityConstants.FETCH_WORKSPACE_PERMISSIONS_FAILURE:
      return {
        ...state,
        workspacePermissionsStatus: RequestStatus.ERROR,
      };
    case identityConstants.FETCH_WORKSPACE_WORKFLOWS_REQUEST:
      return {
        ...state,
        workspaceWorkflowsStatus: RequestStatus.PENDING,
      };
    case identityConstants.FETCH_WORKSPACE_WORKFLOWS_SUCCESS:
      return {
        ...state,
        workspaceWorkflowsStatus: RequestStatus.SUCCESS,
        workspaceWorkflows: action.data,
        workspaceWorkflowsErrorCode: null,
      };
    case identityConstants.FETCH_WORKSPACE_WORKFLOWS_FAILURE:
      return {
        ...state,
        workspaceWorkflowsStatus: RequestStatus.ERROR,
        workspaceWorkflowsErrorCode: action.payload,
      };
    case identityConstants.UPDATE_WORKSPACE_GROUP_AFTER_FETCHING:
      return {
        ...state,
        workspaceGroups: updateOnlyOneGroupInState(state.workspaceGroups, action.data),
      };

    case identityConstants.UPDATE_WORKSPACE_MEMBERS_GROUP:
      return {
        ...state,
        workspaceGroups: updateMembersInGroup(state, action.groupId, action.members),
      };

    case identityConstants.DELETE_WORKSPACE_GROUP_AFTER_REMOVING:
      return {
        ...state,
        workspaceGroups: deactivatedGroup(state.workspaceGroups, action.groupId),
      };
    case identityConstants.UPDATE_NON_EMPTY_WORKSPACE_ONLY_OWNER:
      return {
        ...state,
        nonEmptyWorkspaces: state.structure.filter((structure) =>
          action.payload.includes(structure.workspace.workspaceId)
        ),
      };

    default:
      return state;
  }
}

export default identityStore;
