import transitions from '@material-ui/core/styles/transitions';
import { lighten } from '@material-ui/core/styles';
import palette from '../palette';
import typography from '../typography';
import spacing from '../spacing';
import shadows from '../shadows';

export default {
  root: {
    width: 48,
    height: 48,
    minHeight: 48,
    minWidth: 48,
    textTransform: 'none',
    fontSize: '1.1rem',
    boxShadow: 'none',
    color: palette.secondary.main,
    backgroundColor: 'transparent',
    transition: transitions.create(['background-color', 'color', 'box-shadow', 'border'], {
      duration: transitions.duration.short,
    }),
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&:focus, &:active': {
      backgroundColor: palette.secondary.light,
    },
    '&:hover, &:focus, &:active': {
      color: palette.primary.main,
    },
    '&$disabled': {
      color: lighten(palette.secondary.main, 0.65),
      backgroundColor: 'transparent',
    },
    '&$focusVisible': {
      boxShadow: shadows[6],
    },
  },
  primary: {
    color: palette.secondary.main,
    backgroundColor: palette.common.white,
    border: `1px solid ${palette.secondary.light}`,
    '&:hover': {
      borderColor: palette.secondary.main,
      backgroundColor: palette.common.white,
    },
    '&:focus, &:active': {
      borderColor: palette.primary.main,
      backgroundColor: palette.common.white,
    },
    '&:hover, &:focus, &:active': {
      color: palette.primary.main,
      backgroundColor: palette.common.white,
    },
    '&$disabled': {
      borderColor: palette.secondary.light,
      backgroundColor: palette.common.white,

      color: lighten(palette.secondary.main, 0.65),
    },
  },
  secondary: {
    color: palette.secondary.main,
    backgroundColor: 'transparent',
    '&:hover': {
      color: palette.primary.main,
      boxShadow: shadows[15],
      backgroundColor: 'transparent',
    },
    '&:focus, &:active': {
      backgroundColor: palette.secondary.light,
    },
    '&$disabled': {
      opacity: 0.75,
      backgroundColor: palette.secondary.light,
      color: palette.secondary.main,
    },
  },
  sizeMedium: {
    width: 40,
    height: 40,
    minHeight: 40,
    minWidth: 40,
    fontSize: '1rem',
  },
  sizeSmall: {
    width: 32,
    height: 32,
    minHeight: 32,
    minWidth: 32,
    fontSize: '1rem',
    '& svg': {
      width: 20,
      height: 20,
    },
  },
  extended: {
    '& $label': {
      fontSize: typography.button.fontSize,
    },
    '&$sizeSmall': {
      '& $label': {
        fontSize: 12,
      },
    },
    '&$sizeMedium': {
      '& $label': {
        fontSize: '1.1rem',
      },
    },
    '& svg': {
      marginRight: spacing(1),
    },
  },
};
