import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  ({ typography }) =>
    createStyles({
      root: {
        '& $prefix, & $suffix': {
          fontWeight: typography.fontWeightMedium,
        },
      },
      prefix: {},
      suffix: {},
    }),
  { index: 0 }
);
