import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  ({ palette }) =>
    createStyles({
      root: {},
      largeSize: {},
      mediumSize: {
        width: 34,
        height: 34,
        fontSize: '0.93rem',
      },
      smallSize: {
        width: 26,
        height: 26,
        fontSize: '0.65rem',
      },
      miniSize: {
        width: 18,
        height: 18,
        fontSize: '0.57rem',
      },
      badge: {
        width: 18,
        height: 18,
        borderRadius: '50%',
        backgroundColor: palette.background.paper,
        padding: 1,
        zIndex: 'auto',
      },
    }),
  { index: 0 }
);
