import { Button, CircularProgress, Typography, useMediaQuery } from '@material-ui/core';

import { SmallContentWithScrollbar } from 'src/components';
import { CertificateQuantityType, CertificateSteps } from 'src/models/certificate.model';

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import certificateCreatorActions from 'src/redux/actions/certificateCreator.actions';
import { selectCurrentLocation } from 'src/redux/selectors/certificateCreator.selector';
import { IdentitySelector } from 'src/redux/selectors/identity.selector';
import DirectoryExplorer from 'src/components/DirectoryExplorer/DirectoryExplorer';
import ChooseLocationCertificateButton from 'src/components/DirectoryExplorer/ChooseLocationCertificateButton';
import { useFolderStructure } from 'src/components/DirectoryExplorer/useFolderStructure';
import CertificateHelper from 'src/helpers/certificateHelper';
import { useStyles } from './styles';

const ChooseLocation = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const { uploadTokens } = useSelector((state) => state.documentCreationStore, undefined);
  const { workspaceId } = useSelector(IdentitySelector.selectWorkspaceDetails);
  const ownersGroupId = useSelector(IdentitySelector.selectCurrentIdentityOwnersGroupId, undefined);
  const {
    templateDetails,
    certificateQuantityType,
    generatedCertFileList,
    currentLocation,
  } = useSelector((state) => state.certificateCreatorStore, undefined);

  const { currentIdentity } = useSelector((state) => state.identityStore, undefined);
  const currentIdentityId = currentIdentity?.identity.identityId;
  const currentFolderId = localStorage.getItem('currentFolderId');
  const [folderStructureState, currentFolder] = useFolderStructure(
    workspaceId,
    currentFolderId || 'root'
  );

  useEffect(() => {
    if (currentFolder) {
      dispatch(
        certificateCreatorActions.setCurrentLocation({
          id: currentFolder.id,
          name: currentFolder.name === '/' ? 'Drive' : currentFolder.name,
        })
      );
    }
  }, [currentFolder, dispatch]);

  const location = useSelector(selectCurrentLocation);

  const smallScreen = useMediaQuery(({ breakpoints }) => breakpoints.down('sm'));

  const closeLocationExplorer = () => {
    setAnchorEl(null);
  };

  const openLocationExplorer = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const documentAttributes = [];

    if (certificateQuantityType === CertificateQuantityType.Single) {
      generatedCertFileList.forEach((cert) => {
        documentAttributes.push(
          CertificateHelper.createCertificateAttributeForTransactionPost({
            files: [...cert.fileIds, ...uploadTokens.map((file) => file.fileId)],
            documentId: cert.documentId,
            identityId: currentIdentityId,
            ownersGroupId,
          })
        );
      });
    } else if (certificateQuantityType === CertificateQuantityType.Multiple) {
      generatedCertFileList
        .filter((cert) => cert.eventType === 'CERTIFICATE_FILE_CREATED')
        .forEach((cert) => {
          const additionalFileIds =
            generatedCertFileList
              .filter((el) => el.eventType === 'CERTIFICATE_ADDITIONAL_FILES_CREATED')
              .find((el) => el.correlationId === cert.correlationId)?.fileIds || [];

          documentAttributes.push(
            CertificateHelper.createCertificateAttributeForTransactionPost({
              files: [...cert.fileIds, ...additionalFileIds],
              documentId: cert.documentId,
              identityId: currentIdentityId,
              ownersGroupId,
            })
          );
        });
    }

    dispatch(
      certificateCreatorActions.createCertificateTransaction({
        workspaceId,
        workflowName: templateDetails.workflowName,
        documentAttributes,
        folderId: currentLocation.id,
      })
    );

    dispatch(
      certificateCreatorActions.setCreatorStep({
        currentStep: CertificateSteps.ProgressScreen,
      })
    );
  };

  const handleChange = (location) => {
    dispatch(certificateCreatorActions.setCurrentLocation(location));
  };

  return (
    <SmallContentWithScrollbar
      startContent={
        <Typography variant={smallScreen ? 'h3' : 'h2'}>
          {t('createCertificate.chooseLocation')}
        </Typography>
      }
      endContent={
        <Button size="large" type="submit" fullWidth disabled={!location?.id}>
          {t('createCertificate.continue')}
        </Button>
      }
      formProps={{
        noValidate: true,
        autoComplete: 'off',
        onSubmit,
      }}
      hasForm
    >
      {folderStructureState.isLoading && (
        <div className={classes.centered}>
          <CircularProgress />
        </div>
      )}
      {!folderStructureState.isLoading && !folderStructureState.error && (
        <div className={classes.items}>
          <ChooseLocationCertificateButton
            currentValue={location}
            onOpenClick={openLocationExplorer}
          />
          {anchorEl && (
            <DirectoryExplorer
              initialFolderId={currentLocation.id}
              currentValue={location}
              workspaceId={workspaceId}
              onClose={closeLocationExplorer}
              handleChange={handleChange}
              anchorEl={anchorEl}
            />
          )}
        </div>
      )}
    </SmallContentWithScrollbar>
  );
};

export default ChooseLocation;
