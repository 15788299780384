import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import communicationsActions from 'src/redux/actions/communications.actions';
import { useTranslation } from 'react-i18next';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CircularProgress,
  Fab,
  List,
  ListItem,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { ExtendedGrid } from 'src/components';
import { ContentHeading } from 'src/pages/WorkspaceConfiguration/subcomponents';
import { RequestStatus } from 'src/helpers/reduxReuquest.util';
import { ArrowDownSLine as ArrowDownIcon } from 'src/components/CustomIcon';
import NotificationItem from './subcomponents/NotificationItem';
import { useStyles } from './styles';

const Notifications = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();

  const firstRender = useRef(true);
  const [loading, setLoading] = useState(true);

  const services = [
    {
      type: 'email',
      title: t('userConfigurationPage.notifications.services.email'),
    },
    {
      type: 'sms',
      title: t('userConfigurationPage.notifications.services.sms'),
    },
    {
      type: 'systemMessage',
      title: t('userConfigurationPage.notifications.services.systemMessage'),
    },
  ];
  const smallScreen = useMediaQuery(({ breakpoints }) => breakpoints.down('sm'));

  const channels = useSelector((state) => state.communicationsStore.channels, undefined);
  const channelsRequestStatus = useSelector(
    (state) => state.communicationsStore.channelsStatus,
    undefined
  );
  const disabledChannels = [
    'PROFILE_DELETE_RETENTION',
    'PROFILE_DELETE_START',
    'SIGN_ORDER',
    'CERT_ACTION_INVITATION',
    'CERT_ACTION_SIGN_INVITATION',
    'CERT_ACTION_OWNERSHIP_INVITATION',
  ];
  const filteredChannels = channels.filter(
    (channel) => !disabledChannels.includes(channel.notificationTemplate)
  );

  const accountNotifications = filteredChannels.filter(
    (channel) =>
      !channel.notificationTemplate.includes('DOC_') &&
      !channel.notificationTemplate.includes('CERT_ACTION')
  );
  const documentNotifications = filteredChannels.filter((channel) =>
    channel.notificationTemplate.includes('DOC_')
  );
  const certificateNotifications = filteredChannels.filter((channel) =>
    channel.notificationTemplate.includes('CERT_ACTION')
  );
  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      if (channelsRequestStatus === RequestStatus.IDLE) {
        dispatch(communicationsActions.getUserNotificationChannelsSettings());
      }
    }
  }, [channelsRequestStatus, dispatch]);

  useMemo(() => {
    if (channelsRequestStatus === RequestStatus.PENDING) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [channelsRequestStatus]);

  const expandIcon = (
    <Fab size="small" color="primary">
      <ArrowDownIcon />
    </Fab>
  );

  const renderHeader = (label, headings = [], alignItems = 'center') => (
    <ListItem
      disableGutters
      divider={false}
      classes={{
        root: classes.listItemHeader,
      }}
    >
      <ExtendedGrid container spacing={smallScreen ? 0 : 2} alignItemsXxs={alignItems}>
        <ExtendedGrid item xxs={12} md={4}>
          <Typography variant={smallScreen ? 'h5' : 'h6'} color="textSecondary" component="label">
            {label}
          </Typography>
        </ExtendedGrid>
        {!smallScreen &&
          headings.map((heading, key) => (
            <ExtendedGrid key={key} item xs textAlignXxs="center" textAlignMd="center">
              <Typography variant="subtitle2">{heading.title}</Typography>
            </ExtendedGrid>
          ))}
      </ExtendedGrid>
    </ListItem>
  );

  return (
    <>
      <ContentHeading subtitle={t('userConfigurationPage.notifications.subtitle')}>
        {t('userConfigurationPage.notifications.header')}
      </ContentHeading>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={expandIcon} className={classes.title}>
          <Typography variant="h5">
            {t('userConfigurationPage.notifications.sectionAccountTitle')}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          {loading ? (
            <Box textAlign="center">
              <CircularProgress />
            </Box>
          ) : (
            <List className={classes.list}>
              {renderHeader(t('userConfigurationPage.notifications.servicesTitle'), services)}
              {channels &&
                accountNotifications.map((channel, index) => (
                  <NotificationItem
                    key={index}
                    item={channel}
                    services={services}
                    smallScreen={smallScreen}
                  />
                ))}
            </List>
          )}
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={expandIcon} className={classes.title}>
          <Typography variant="h5">
            {t('userConfigurationPage.notifications.sectionDocumentsTitle')}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          {loading ? (
            <Box textAlign="center">
              <CircularProgress />
            </Box>
          ) : (
            <List className={classes.list}>
              {renderHeader(t('userConfigurationPage.notifications.servicesTitle'), services)}
              {channels &&
                documentNotifications.map((channel, index) => (
                  <NotificationItem
                    key={index}
                    item={channel}
                    services={services}
                    smallScreen={smallScreen}
                  />
                ))}
            </List>
          )}
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={expandIcon} className={classes.title}>
          <Typography variant="h5">
            {t('userConfigurationPage.notifications.sectionCertificatesTitle')}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          {loading ? (
            <Box textAlign="center">
              <CircularProgress />
            </Box>
          ) : (
            <List className={classes.list}>
              {renderHeader(t('userConfigurationPage.notifications.servicesTitle'), services)}
              {channels &&
                certificateNotifications.map((channel, index) => (
                  <NotificationItem
                    key={index}
                    item={channel}
                    services={services}
                    smallScreen={smallScreen}
                  />
                ))}
            </List>
          )}
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default Notifications;
