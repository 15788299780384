import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  ({ spacing }) =>
    createStyles({
      root: {
        '&:not(:last-child)': {
          marginBottom: spacing(1),
        },
      },
    }),
  { index: 0 }
);
