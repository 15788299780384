import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import documentManagementActions from 'src/redux/actions/documentManagement.actions';
import contactsActions from 'src/redux/actions/contacts.actions';
import moment from 'moment';
import 'moment/locale/pl'; // without this line it didn't work
import InfiniteScroll from 'react-infinite-scroll-component';
import ContactHelper from 'src/helpers/contactHelper';
import {
  Button,
  Checkbox,
  Chip,
  LinearProgress,
  ListItemText,
  TextField,
  Typography,
} from '@material-ui/core';
import { ExtendedGrid, ResponsiveDateRangePicker } from 'src/components';
import { Autocomplete } from '@material-ui/lab';
import { RequestStatus } from 'src/helpers/reduxReuquest.util';
import { HistoryItem } from './subcomponents';
import { useStyles } from './styles';
import { moduleName as dslService } from '../../../../redux/services/documentDSL.service';
import { moduleName as documentService } from '../../../../redux/services/documentCore.service';
import { documentDisplaySelector } from '../../../../redux/selectors/documentDisplay.selector';
import { HistoryType } from '../../../../models/document.model';

const History = ({
  className: classNameProp,
  fullHeight,
  onCollapseClick,
  shortListMaxItems,
  smallDevice,
}) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const { language } = i18n;
  const { id } = useParams();
  const dispatch = useDispatch();
  const isCertificate = useSelector(documentDisplaySelector.isCertificate, undefined);
  const apiService = isCertificate ? dslService : documentService;
  const {
    historyDocument,
    historyHasNextPage,
    getDocumentHistoryStatus,
    clearHistoryStatus,
  } = useSelector((state) => state.documentManagementStore, undefined);
  const documentInfo = useSelector((state) => state.documentDisplayStore.document, undefined);
  const { contacts, contactBookEntitiesFetchStatus } = useSelector(
    (state) => state.contactsStore,
    undefined
  );

  const { createdAt } = documentInfo;

  const [historyType, setHistoryType] = useState([]);
  const [rangeDate, setRangeDate] = useState([
    new Date(createdAt).getTime(),
    new Date().getTime() + 86400000,
  ]);
  const [dateValue, setDateValue] = useState([new Date(createdAt), new Date()]);
  const [missingContacts, setMissingContacts] = useState([]);

  const historyTypes = isCertificate
    ? [
        HistoryType.CREATE,
        HistoryType.ADD_ENTITY,
        HistoryType.DELETE_ENTITY,
        HistoryType.DELETE_INVITATION,
        HistoryType.DOWNLOAD,
        HistoryType.REJECT,
        HistoryType.RENAME,
        HistoryType.SIGN,
        HistoryType.SEND_INVITATION,
        HistoryType.VIEW,
        HistoryType.VISIBILITY_CHANGE,
        HistoryType.CHANGE_SIGN_ORDER,
        HistoryType.CHANGE_HOLDER,
        HistoryType.INVALIDATE,
        HistoryType.INVALIDATE_TEMPORARY,
        HistoryType.EXPIRED,
        HistoryType.VALIDATE,
        HistoryType.TRANSFERABILITY_CHANGE,
        HistoryType.TRANSFER_INVITATION_CREATED,
        HistoryType.TRANSFER_INVITATION_REVOKED,
      ]
    : [
        HistoryType.SIGN,
        HistoryType.SEND_INVITATION,
        HistoryType.DELETE_INVITATION,
        HistoryType.ADD_ENTITY,
        HistoryType.DELETE_ENTITY,
        HistoryType.DOWNLOAD,
        HistoryType.REJECT,
        HistoryType.RENAME,
        HistoryType.CREATE,
        HistoryType.VIEW,
      ];

  const getHistoryTypesTranslations = (type) => ({
    short: t(`historyType.${type}.shortText`),
    normal: t(`historyType.${type}.normalText`),
  });

  const isLoading =
    getDocumentHistoryStatus === RequestStatus.IDLE ||
    getDocumentHistoryStatus === RequestStatus.PENDING;
  const documentHistoryStatusSuccess = getDocumentHistoryStatus === RequestStatus.SUCCESS;

  const dateFilterIsChange =
    rangeDate[0] !== createdAt && rangeDate[1] !== new Date().getTime() + 86400000;

  useEffect(() => {
    if (documentHistoryStatusSuccess) {
      if (((fullHeight && !smallDevice) || smallDevice) && historyHasNextPage) {
        const scrollElement = document.getElementById('scroll-element-sidebar');

        const hasVerticalScrollbar = scrollElement?.scrollHeight > scrollElement?.clientHeight;
        if (!hasVerticalScrollbar) {
          fetchMoreHistoryData();
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentHistoryStatusSuccess, fullHeight]);

  useEffect(() => {
    if (documentHistoryStatusSuccess && !clearHistoryStatus && dateFilterIsChange) {
      dispatch(documentManagementActions.clearDocumentHistory());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rangeDate]);

  useEffect(() => {
    if (documentHistoryStatusSuccess && clearHistoryStatus) {
      fetchFilterHistoryData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clearHistoryStatus]);

  const fetchMoreHistoryData = () => {
    if ((fullHeight || smallDevice) && id) {
      dispatch(
        documentManagementActions.getHistoryDocument({
          documentId: id,
          workspaceId: documentInfo.workspaceId,
          limit: 10,
          offset: historyDocument.length,
          performedAction: historyType,
          rangeDate,
          service: apiService,
        })
      );
    }
  };

  const clearFilterSetting = () => {
    setHistoryType([]);
    if (rangeDate.length && dateFilterIsChange) {
      setDateValue([new Date(createdAt), new Date()]);
      setRangeDate([new Date(createdAt).getTime(), new Date().getTime() + 86400000]);
    }
    if (historyType.length || dateFilterIsChange) {
      dispatch(documentManagementActions.clearDocumentHistory());
    }
  };

  const isFilters = rangeDate.length || historyType.length;

  const fetchFilterHistoryData = () => {
    dispatch(
      documentManagementActions.getHistoryDocument({
        documentId: id,
        workspaceId: documentInfo.workspaceId,
        limit: 10,
        offset: historyDocument.length,
        performedAction: historyType,
        rangeDate,
        service: apiService,
      })
    );
  };
  const contactsNotFound = [];

  const historyObject =
    smallDevice || fullHeight ? historyDocument : historyDocument.slice(0, shortListMaxItems);

  historyObject.map((item, index) => {
    item.dateGroup = moment
      .unix(new Date(item.date) / 1000)
      .locale(language)
      .format('ll');
    item.dateActive = item.dateGroup !== historyObject[index - 1]?.dateGroup;
    item.lastDateInGroup =
      item.dateGroup !==
      moment
        .unix(new Date(historyObject[index + 1]?.date) / 1000)
        .locale(language)
        .format('ll');
    if (
      item.metadata &&
      item.metadata.entityId &&
      !ContactHelper.checkIfIsInProgress(item.metadata.entityId)
    ) {
      contactsNotFound.push(item.metadata.entityId);
    }
    return item;
  });

  if (JSON.stringify(contactsNotFound) !== JSON.stringify(missingContacts)) {
    setMissingContacts(contactsNotFound);
  }

  useEffect(() => {
    if (missingContacts.length > 0 && contactBookEntitiesFetchStatus !== RequestStatus.PENDING) {
      dispatch(contactsActions.addMissingToQueue(missingContacts));
    }
  }, [missingContacts, contactBookEntitiesFetchStatus, dispatch, contacts]);

  return (
    <div className={clsx(classes.root, classNameProp)}>
      {!!onCollapseClick && fullHeight && (
        <div className={clsx(classes.hideShowFullWrapper, classes.hideFullWrapper)}>
          <Button
            fullWidth
            color="secondary"
            onClick={() => {
              onCollapseClick();
              clearFilterSetting();
            }}
          >
            <Typography variant="body2">
              <strong>{t('documentActions.tabs.history.hideFullHistory')}</strong>
            </Typography>
          </Button>
        </div>
      )}

      {(fullHeight || smallDevice) && (
        <ExtendedGrid container spacing={smallDevice ? 1 : 2} className={classes.topElement}>
          <ExtendedGrid item xxs={12} sm={6} md={12}>
            <ResponsiveDateRangePicker
              classes={{
                inputDisabled: classes.inputDisabled,
              }}
              smallDevice={smallDevice}
              startDate={rangeDate.length ? rangeDate[0] : null}
              endDate={rangeDate.length ? rangeDate[1] : null}
              value={dateValue}
              setValue={setDateValue}
              asButton={false}
              placeholderFrom={t('calendar.example')}
              placeholderEnd={t('calendar.example')}
              onApply={([dateFrom, dateTo]) => {
                if (dateFrom && dateTo) {
                  setRangeDate([
                    new Date(dateFrom).getTime(),
                    new Date(dateTo).getTime() + 86400000,
                  ]);
                }
              }}
            />
          </ExtendedGrid>

          <ExtendedGrid item xxs={12} sm={6} md={12}>
            <Autocomplete
              multiple
              options={historyTypes}
              getOptionLabel={(option) => {
                const optionText = getHistoryTypesTranslations(option);
                return `${optionText.short} ${optionText.normal}`;
              }}
              // filterSelectedOptions
              value={historyType}
              defaultValue={undefined}
              disableCloseOnSelect
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder={
                    historyType.length < 1
                      ? `${t('documentActions.tabs.history.searchByType')}...`
                      : undefined
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    classes: {
                      disabled: classes.inputDisabled,
                    },
                    ...params.InputProps,
                  }}
                />
              )}
              renderOption={(option, { selected }) => (
                <>
                  <Checkbox checked={selected} />
                  <ListItemText>{getHistoryTypesTranslations(option).normal}</ListItemText>
                </>
              )}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    label={getHistoryTypesTranslations(option).short}
                    size="small"
                    classes={{
                      disabled: classes.historyChipDisabled,
                      deleteIcon: classes.historyChipDeleteButton,
                    }}
                    {...getTagProps({ index })}
                  />
                ))
              }
              onChange={(event, newInputValue) => {
                setHistoryType(newInputValue);
                dispatch(documentManagementActions.clearDocumentHistory());
              }}
            />
          </ExtendedGrid>
        </ExtendedGrid>
      )}
      <div className={clsx(classes.entriesList, !fullHeight && classes.entriesListSpacingBottom)}>
        {historyObject.length ? (
          <InfiniteScroll
            dataLength={historyObject.length}
            next={fetchMoreHistoryData}
            scrollThreshold="20px"
            hasMore={historyHasNextPage}
            style={{
              overflow: 'initial',
              position: 'relative',
            }}
            loader={false}
            scrollableTarget="scroll-element-sidebar"
          >
            {historyObject.map((item, index) => (
              <HistoryItem
                key={index}
                date={item.date}
                userId={item.identityId}
                action={item.action}
                metadata={item.metadata}
                dateGroup={item.dateGroup}
                dateActive={item.dateActive}
                lastDateInGroup={item.lastDateInGroup}
              />
            ))}
          </InfiniteScroll>
        ) : (
          !isLoading && (
            <div className={classes.noItems}>
              <Typography variant="subtitle2" component="span">
                {t('documentActions.tabs.history.noItems')}{' '}
                {isFilters && (
                  <>
                    {' - '}
                    <Typography
                      variant="subtitle2"
                      component="span"
                      color="primary"
                      onClick={clearFilterSetting}
                      className={classes.textButton}
                    >
                      <strong> {t('documentActions.tabs.history.clearFilters')}</strong>
                    </Typography>
                  </>
                )}
              </Typography>
            </div>
          )
        )}

        {isLoading && (
          <div className={classes.loaderFile}>
            <LinearProgress />
          </div>
        )}
      </div>

      {!!onCollapseClick && !fullHeight && (
        <div className={clsx(classes.hideShowFullWrapper, classes.showFullWrapper)}>
          <Button fullWidth color="secondary" onClick={onCollapseClick}>
            <Typography variant="body2">
              <strong>{t('documentActions.tabs.history.showFullHistory')}</strong>
            </Typography>
          </Button>
        </div>
      )}
    </div>
  );
};

History.propTypes = {
  className: PropTypes.string,
  shortListMaxItems: PropTypes.number,
  fullHeight: PropTypes.bool,
  onCollapseClick: PropTypes.func,
  smallDevice: PropTypes.bool,
};

History.defaultProps = {
  shortListMaxItems: 4,
  fullHeight: false,
};

export default History;
