import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FullScreenDialog } from 'src/components';
import { useTranslation } from 'react-i18next';
import documentCreationActions from 'src/redux/actions/documentCreation.actions';
import { RequestStatus } from 'src/helpers/reduxReuquest.util';
import { goBack } from 'src/helpers/goBack';
import StepsResolver from './steps/StepsResolver';
import { CertificateQuantityType, CertificateSteps } from '../../models/certificate.model';
import certificateCreatorActions from '../../redux/actions/certificateCreator.actions';
import { useStyles } from './styles';
import identityActions from '../../redux/actions/identity.actions';
import { DocumentType } from '../../models/document.model';
import CertificateCreatorWebsocket from './CertificateCreatorWebsocket';

const LOCAL_STORAGE_CLOSED_INVITATION_DIALOG = 'ever_closed_invitation_dialog';

const CertificateCreator = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    currentStep,
    certificateQuantityType,
    generatedCertFileList,
    currentLocation,
  } = useSelector((state) => state.certificateCreatorStore, undefined);
  const { currentIdentity, workspaceWorkflows, workspaceWorkflowsStatus } = useSelector(
    (state) => state.identityStore,
    undefined
  );
  const workspaceId = currentIdentity?.workspace?.workspaceId;
  const everClosedInvitations = JSON.parse(
    localStorage.getItem(LOCAL_STORAGE_CLOSED_INVITATION_DIALOG) || '[]'
  );
  const withLogo = [
    CertificateSteps.ProgressScreen,
    CertificateSteps.InvitePeople,
    CertificateSteps.SelectTemplateType,
    CertificateSteps.CreatingCertificatesProgressScreen,
    CertificateSteps.UploadAdditionalDocuments,
    CertificateSteps.ChooseLocation,
    CertificateSteps.InvitePeople,
  ].includes(currentStep);

  const handleClose = () => {
    const currentFolderId = localStorage.getItem('currentFolderId');
    localStorage.setItem(
      LOCAL_STORAGE_CLOSED_INVITATION_DIALOG,
      JSON.stringify([
        ...everClosedInvitations,
        ...generatedCertFileList.map((el) => el.documentId),
      ])
    );
    if (currentStep === CertificateSteps.InvitePeople) {
      if (certificateQuantityType === CertificateQuantityType.Multiple) {
        history.push(currentLocation.name === 'Drive' ? '/drive' : `/drive/${currentLocation.id}`);
      } else if (generatedCertFileList[0]?.documentId) {
        history.push(`/certificate/${generatedCertFileList[0]?.documentId}`);
      }
    } else if (currentFolderId) {
      // check if user started from different directory then root and redirect him back to this place
      currentFolderId === 'root'
        ? history.push(`/drive/`)
        : history.push(`/drive/${currentFolderId}`);
    } else goBack(history);
  };

  const handleBack = () => {
    dispatch(certificateCreatorActions.setCreatorStepBack());
  };

  const getStepTitle = () => {
    if (withLogo) return undefined;

    switch (currentStep) {
      case CertificateSteps.TemplatePreview:
        return t('createCertificate.templatePreview');

      default:
        return t('createCertificate.createNewCertificate');
    }
  };

  useEffect(() => {
    if (
      workspaceWorkflowsStatus === RequestStatus.SUCCESS &&
      !workspaceWorkflows.includes(DocumentType.CERTIFICATES)
    ) {
      history.push('/drive');
    }
  }, [workspaceWorkflows, workspaceWorkflowsStatus, history]);

  useEffect(() => {
    if (workspaceId && workspaceWorkflowsStatus === RequestStatus.IDLE) {
      dispatch(identityActions.fetchWorkspaceWorkflows(workspaceId));
    }

    return () => {
      dispatch(documentCreationActions.clearDocumentCreatorStore());
      dispatch(certificateCreatorActions.clearStore());
    };
  }, [workspaceId, dispatch, workspaceWorkflowsStatus]);

  return (
    <section>
      <CertificateCreatorWebsocket />
      <FullScreenDialog
        className={classes.pageWrapper}
        onBack={!withLogo ? handleBack : undefined}
        isOpen={Boolean(true)}
        onClose={handleClose}
        withLogo={withLogo}
        title={getStepTitle()}
      >
        <StepsResolver />
      </FullScreenDialog>
    </section>
  );
};

export default CertificateCreator;
