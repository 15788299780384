import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  ({ custom, palette }) =>
    createStyles({
      root: {
        display: 'flex',
        alignItems: 'center',
        height: custom.breadcrumbsMenuHeight,
        backgroundColor: palette.background.paper,
      },
      divider: {
        height: custom.breadcrumbsMenuHeight,
      },
    }),
  { index: 0 }
);
