import i18n from 'src/helpers/i18n';
import { SNACKBAR_VARIANT } from 'src/models/common.model';
import { DocumentRole } from 'src/models/document.model';
import documentDSLService from 'src/redux/services/documentDSL.service';
import documentInvitiationConstants from '../constants/documentInvitiation.constants';
import documentInvitiationsService from '../services/documentInvitations.service';
import documentManagementConstants from '../constants/documentManagement.constants';
import snackbarActions from './snackbar.actions';
import identityService from '../services/identity.service';
import documentCoreService from '../services/documentCore.service';
import { isMail } from '../../helpers/globalUtils';
import identityActions from './identity.actions';

const genericErrorHelper = (msg) =>
  snackbarActions.enqueueSnackbar(
    SNACKBAR_VARIANT.ERROR,
    i18n.t('snackbars.common.errorTitle'),
    msg
  );

const getDocumentSigners = (documentId, service) => (dispatch) => {
  dispatch({ type: documentInvitiationConstants.FETCH_DOCUMENT_SIGNERS_REQUEST });
  documentInvitiationsService
    .getDocumentSigners(documentId, service)
    .then((response) => {
      if (response) {
        const documentSigners = response?.data?.data?.ids;
        const isOrderSignificant = response?.data?.data?.isOrderSignificant;
        const requiredSignersCount = response?.data?.data?.requiredSignersCount;

        dispatch({
          type: documentManagementConstants.UPDATE_DOCUMENT_SIGNERS,
          signers: documentSigners,
        });
        dispatch({
          type: documentInvitiationConstants.FETCH_DOCUMENT_SIGNERS_SUCCESS,
          payload: {
            documentSigners,
            isOrderSignificant,
            requiredSignersCount,
          },
        });
      }
    })
    .catch(() => {
      dispatch({ type: documentInvitiationConstants.FETCH_DOCUMENT_SIGNERS_ERROR });
      dispatch(genericErrorHelper(i18n.t('inviteToDocument.invitationsFetchError')));
    });
};

const replaceDocumentSigners = (documentId, isOrderSignificant, requiredSignersCount, ids) => (
  dispatch
) => {
  dispatch({ type: documentInvitiationConstants.REPLACE_DOCUMENT_SIGNERS_REQUEST });
  documentInvitiationsService
    .replaceDocumentSigners(documentId, isOrderSignificant, requiredSignersCount, ids)
    .then((response) => {
      if (response) {
        const txn = response?.data?.data?.txn;
        if (txn) {
          identityService
            .sendTransaction(txn)
            .then((response) => {
              if (response) {
                dispatch({ type: documentInvitiationConstants.REPLACE_DOCUMENT_SIGNERS_SUCCESS });
                dispatch(
                  snackbarActions.enqueueSnackbar(
                    SNACKBAR_VARIANT.SUCCESS,
                    i18n.t('snackbars.common.successTitle'),
                    i18n.t('inviteToDocument.invitationsSendSuccess')
                  )
                );
                setTimeout(() => {
                  dispatch(documentInvitationActions.getDocumentSigners(documentId));
                }, 5000);
              }
            })
            .catch((error) => {
              dispatch({
                type: documentInvitiationConstants.REPLACE_DOCUMENT_SIGNERS_ERROR,
                error,
              });
              dispatch(genericErrorHelper(i18n.t('inviteToDocument.invitationsSendError')));
            });
        }
      }
    })
    .catch((error) => {
      dispatch({ type: documentInvitiationConstants.REPLACE_DOCUMENT_SIGNERS_ERROR, error });
      dispatch(genericErrorHelper(i18n.t('inviteToDocument.invitationsSendError')));
    });
};

const getDocumentRoleTypes = (documentId, service) => (dispatch) => {
  dispatch({ type: documentInvitiationConstants.FETCH_DOCUMENT_ROLES_TYPES_REQUEST });
  documentCoreService
    .getDocumentRoleTypes(documentId, service)
    .then((response) => {
      dispatch({
        type: documentInvitiationConstants.FETCH_DOCUMENT_ROLES_TYPES_SUCCESS,
        payload: response.data.collection ? response.data.collection : response.data,
      });
    })
    .catch(() => {
      dispatch({ type: documentInvitiationConstants.FETCH_DOCUMENT_ROLES_TYPES_ERROR });
    });
};

const getDocumentRoles = (documentId, roles = [], service) => {
  const membersByRolePromises = roles
    .filter((role) => role !== DocumentRole.SIGNERS)
    .map((role) => documentCoreService.getDocumentMembersByRole(documentId, role, service));

  return (dispatch) => {
    dispatch({ type: documentInvitiationConstants.FETCH_DOCUMENT_ROLES_REQUEST });
    Promise.allSettled(membersByRolePromises)
      .then((results) => {
        const fulfilledPromises = results.filter((result) => result.status === 'fulfilled');
        const rejectedPromises = results.filter((result) => result.status === 'rejected');
        let documentRoles = [];
        if (fulfilledPromises?.length > 0) {
          results.forEach((result, index) => {
            if (result.status === 'fulfilled') {
              const roleName = roles.filter((role) => role !== DocumentRole.SIGNERS)[index];
              documentRoles = [
                ...documentRoles,
                ...result.value.data.collection.map((id) => ({
                  id,
                  role: roleName,
                })),
              ];
            }
          });
          dispatch({
            type: documentInvitiationConstants.FETCH_DOCUMENT_ROLES_SUCCESS,
            documentRoles,
          });
        }
        if (rejectedPromises?.length > 0 && fulfilledPromises.length === 0) {
          dispatch({ type: documentInvitiationConstants.FETCH_DOCUMENT_ROLES_ERROR });
          dispatch(genericErrorHelper(i18n.t('inviteToDocument.invitationsRolesFetchError')));
        }
      })
      .catch((error) => {
        dispatch({ type: documentInvitiationConstants.FETCH_DOCUMENT_ROLES_ERROR });
        dispatch(genericErrorHelper(error.code));
      });
  };
};

const replaceDocumentRoles = (documentId, documentRoles) => {
  const admins = documentRoles.filter((item) => item.role === DocumentRole.ADMINS);
  const auditors = documentRoles.filter((item) => item.role === DocumentRole.AUDITORS);
  const editors = documentRoles.filter((item) => item.role === DocumentRole.EDITORS);
  const viewers = documentRoles.filter((item) => item.role === DocumentRole.VIEWERS);

  const documentRolesPromises = [
    documentInvitiationsService.replaceDocumentRoles(
      documentId,
      DocumentRole.ADMINS,
      admins.map((admin) => admin.id)
    ),
    documentInvitiationsService.replaceDocumentRoles(
      documentId,
      DocumentRole.AUDITORS,
      auditors.map((auditor) => auditor.id)
    ),
    documentInvitiationsService.replaceDocumentRoles(
      documentId,
      DocumentRole.EDITORS,
      editors.map((editor) => editor.id)
    ),
    documentInvitiationsService.replaceDocumentRoles(
      documentId,
      DocumentRole.VIEWERS,
      viewers.map((viewer) => viewer.id)
    ),
  ];

  return (dispatch) => {
    dispatch({ type: documentInvitiationConstants.REPLACE_DOCUMENT_ROLES_REQUEST });
    Promise.allSettled(documentRolesPromises)
      .then((results) => {
        const filteredResults = results.filter((result) => result?.value?.data?.data?.txn);
        const fulfilledPromises = filteredResults.filter((result) => result.status === 'fulfilled');
        const rejectedPromises = filteredResults.filter((result) => result.status === 'rejected');
        const txnPromises = filteredResults.map((promise) =>
          identityService.sendTransaction(promise.value.data.data.txn)
        );

        if (fulfilledPromises?.length === 0 && rejectedPromises?.length > 0) {
          dispatch({ type: documentInvitiationConstants.REPLACE_DOCUMENT_ROLES_ERROR });
          dispatch(genericErrorHelper(i18n.t('inviteToDocument.invitationsRolesSendError')));
        }

        if (txnPromises?.length > 0) {
          Promise.all(txnPromises)
            .then(() => {
              if (txnPromises.length === filteredResults.length) {
                dispatch({ type: documentInvitiationConstants.REPLACE_DOCUMENT_ROLES_SUCCESS });
                dispatch(
                  snackbarActions.enqueueSnackbar(
                    SNACKBAR_VARIANT.SUCCESS,
                    i18n.t('snackbars.common.successTitle'),
                    i18n.t('inviteToDocument.invitationsRolesSendSuccess')
                  )
                );
              } else {
                dispatch({
                  type: documentInvitiationConstants.REPLACE_DOCUMENT_ROLES_PARTIAL_SUCCESS,
                });
                dispatch(
                  snackbarActions.enqueueSnackbar(
                    SNACKBAR_VARIANT.SUCCESS,
                    i18n.t('snackbars.common.successTitle'),
                    i18n.t('inviteToDocument.invitationsRolesSendPartialSuccess')
                  )
                );
              }
            })
            .catch(() => {
              dispatch({ type: documentInvitiationConstants.REPLACE_DOCUMENT_ROLES_ERROR });
              dispatch(genericErrorHelper(i18n.t('inviteToDocument.invitationsRolesSendError')));
            });
        }
      })
      .catch(() => {
        dispatch({ type: documentInvitiationConstants.REPLACE_DOCUMENT_ROLES_ERROR });
        dispatch(genericErrorHelper(i18n.t('inviteToDocument.invitationsRolesSendError')));
      });
  };
};

const inviteRolesByMail = (documentId, users, isCertificate) => {
  let invitationPromises;
  if (isCertificate) {
    invitationPromises = users.map((user) =>
      documentDSLService.inviteRoleByMail(
        documentId,
        user?.role || DocumentRole.SIGNERS,
        isMail(user.name) ? user.name : user.email
      )
    );
  } else {
    invitationPromises = users.map((user) =>
      documentInvitiationsService.inviteRoleByMail(
        documentId,
        user?.role || DocumentRole.SIGNERS,
        user.name
      )
    );
  }

  return (dispatch) => {
    dispatch({ type: documentInvitiationConstants.INVITE_USERS_BY_MAIL_REQUEST });
    Promise.allSettled(invitationPromises)
      .then((results) => {
        dispatch({ type: documentInvitiationConstants.INVITE_USERS_BY_MAIL_SUCCESS, results });
        const fulfilledPromises = results.filter((result) => result.status === 'fulfilled');
        const rejectedPromises = results.filter((result) => result.status === 'rejected');
        const txnPromises = fulfilledPromises.map((promise) => {
          if (isCertificate) {
            return identityService.sendTransaction(promise?.value?.data?.data?.collection?.[0].txn);
          }
          return identityService.sendTransaction(promise?.value?.data?.data?.txn);
        });
        const emailsSend = fulfilledPromises.map(
          (rejectedPromise) => ` ${JSON.parse(rejectedPromise.value.config.data)?.email}`
        );

        if (fulfilledPromises?.length === 0 && rejectedPromises?.length > 0) {
          dispatch({ type: documentInvitiationConstants.INVITE_USERS_BY_MAIL_ERROR });
          dispatch(genericErrorHelper(i18n.t('inviteToDocument.invitationEmailsError')));
        }

        Promise.all(txnPromises)
          .then(() => {
            if (txnPromises.length === results.length) {
              dispatch(
                snackbarActions.enqueueSnackbar(
                  SNACKBAR_VARIANT.SUCCESS,
                  i18n.t('snackbars.common.successTitle'),
                  i18n.t('inviteToDocument.invitationsEmailsSuccess')
                )
              );
            } else {
              dispatch(
                snackbarActions.enqueueSnackbar(
                  'info',
                  i18n.t('snackbars.common.infoTitle'),
                  `${i18n.t('inviteToDocument.invitationsEmailsInfo')} ${emailsSend.map(
                    (item) => item
                  )}.`
                )
              );
            }
          })
          .catch(() => {
            dispatch({ type: documentInvitiationConstants.INVITE_USERS_BY_MAIL_ERROR });
            dispatch(genericErrorHelper(i18n.t('inviteToDocument.invitationEmailsError')));
          });
      })
      .catch(() => {
        dispatch({ type: documentInvitiationConstants.INVITE_USERS_BY_MAIL_ERROR });
        dispatch(genericErrorHelper(i18n.t('inviteToDocument.invitationEmailsError')));
      });
  };
};

const deleteRolesByMail = (documentId, users) => {
  const deletionPromises = users.map((user) =>
    documentInvitiationsService.deleteRoleByMail(
      documentId,
      user?.role || DocumentRole.SIGNERS,
      user.email
    )
  );

  return (dispatch) => {
    dispatch({ type: documentInvitiationConstants.DELETE_USERS_MAIL_INVITATION_REQUEST });
    Promise.allSettled(deletionPromises)
      .then((results) => {
        dispatch({
          type: documentInvitiationConstants.DELETE_USERS_MAIL_INVITATION_SUCCESS,
          results,
        });
        const fulfilledPromises = results.filter((result) => result.status === 'fulfilled');
        const rejectedPromises = results.filter((result) => result.status === 'rejected');

        if (fulfilledPromises?.length === 0 && rejectedPromises?.length > 0) {
          dispatch({ type: documentInvitiationConstants.INVITE_USERS_BY_MAIL_ERROR });
          dispatch(genericErrorHelper(i18n.t('inviteToDocument.deleteInvitationEmailsError')));
        }

        /*
         * TODO: Restore when functionality will be introduced to blockchain [LOBDS-179, LOBB-170]
         * */
        /* const txnPromises = fulfilledPromises.map((promise) =>
          identityService.sendTransaction(promise?.value?.data?.data?.txn)
        );
        Promise.all(txnPromises)
          .then(() => {})
          .catch(() => {
            dispatch({ type: documentInvitiationConstants.INVITE_USERS_BY_MAIL_ERROR });
            dispatch(genericErrorHelper(i18n.t('inviteToDocument.deleteInvitationEmailsError')));
          }); */
      })
      .catch(() => {
        dispatch({ type: documentInvitiationConstants.INVITE_USERS_BY_MAIL_ERROR });
        dispatch(genericErrorHelper(i18n.t('inviteToDocument.deleteInvitationEmailsError')));
      });
  };
};

const getWorkspaceEntities = (workspaceId, entityName, limit) => (dispatch) => {
  dispatch({ type: documentInvitiationConstants.GET_WORKSPACE_ENTITIES_REQUEST });
  identityService
    .fetchWorkspaceEntities(workspaceId, entityName, limit)
    .then((response) => {
      dispatch({
        type: documentInvitiationConstants.GET_WORKSPACE_ENTITIES_SUCCESS,
        data: response.data.collection,
      });
    })
    .catch((error) => {
      dispatch({ type: documentInvitiationConstants.GET_WORKSPACE_ENTITIES_ERROR });
      dispatch(
        snackbarActions.enqueueSnackbar(
          SNACKBAR_VARIANT.ERROR,
          i18n.t('snackbars.common.errorTitle'),
          `${i18n.t('snackbars.common.error')} ${error.code}.`
        )
      );
    });
};

const downloadTransferOwnershipPDF = (documentId) => (dispatch) => {
  dispatch({ type: documentInvitiationConstants.DOWNLOAD_TRANSFER_OWNERSHIP_PDF_REQUEST });
  dispatch(
    snackbarActions.enqueueSnackbar(
      SNACKBAR_VARIANT.INFO,
      i18n.t('snackbars.common.infoTitle'),
      i18n.t('documentActions.downloadDocumentToast')
    )
  );
  documentDSLService
    .generateHolderInvitationToken(documentId)
    .then((response) => {
      if (response.data.data.txn) {
        dispatch(identityActions.sendTransactionFromNewDocument(response.data.data.txn));
      }
    })
    .catch(() => {
      dispatch({ type: documentInvitiationConstants.DOWNLOAD_TRANSFER_OWNERSHIP_PDF_ERROR });
      dispatch(genericErrorHelper(i18n.t('inviteToDocument.invitationsFetchError')));
    });
};

const clearStore = () => ({ type: documentInvitiationConstants.CLEAR_DOCUMENT_INVITATIONS_STORE });

const documentInvitationActions = {
  getDocumentSigners,
  replaceDocumentSigners,
  getDocumentRoles,
  getDocumentRoleTypes,
  replaceDocumentRoles,
  inviteRolesByMail,
  deleteRolesByMail,
  getWorkspaceEntities,
  downloadTransferOwnershipPDF,
  clearStore,
};

export default documentInvitationActions;
