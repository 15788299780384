import { RequestStatus } from 'src/helpers/reduxReuquest.util';
import documentsConstants from 'src/redux/constants/documents.constants';

const initialState = {
  createFolderStatus: RequestStatus.IDLE,
  currentFolderId: null,
  currentPath: null,
  defaultSortKey: 'updatedAt',
  defaultSortOrder: 'desc',
  documents: [],
  documentsQuery: {
    limit: 20,
    offset: 0,
  },
  documentsSearchFetchStatus: RequestStatus.IDLE,
  fetchDocumentsStatus: RequestStatus.IDLE,
  fetchFolderPathStatus: RequestStatus.IDLE,
  fetchFoldersStatus: RequestStatus.IDLE,
  folderPath: { idPath: '', namePath: '' },
  folderTree: null,
  foldersSearchFetchStatus: RequestStatus.IDLE,
  hasMoreDocuments: false,
  moveDocumentsStatus: RequestStatus.IDLE,
  removeFolderStatus: RequestStatus.IDLE,
  renameFolderStatus: RequestStatus.IDLE,
  searchedDocuments: [],
  searchedFolders: [],
};

const sortByKeyAndOrder = (array, sortKey, sortOrder) =>
  array.sort((a, b) => {
    if (sortOrder === 'asc') {
      if (sortKey === 'updatedAt') {
        if (new Date(a[sortKey]) < new Date(b[sortKey])) return -1;
        return new Date(a[sortKey]) > new Date(b[sortKey]) ? 1 : 0;
      }
      if (a[sortKey] < b[sortKey]) return -1;
      return a[sortKey] > b[sortKey] ? 1 : 0;
    }

    if (sortKey === 'updatedAt') {
      if (new Date(a[sortKey]) > new Date(b[sortKey])) return -1;
      return new Date(a[sortKey]) < new Date(b[sortKey]) ? 1 : 0;
    }
    if (a[sortKey] > b[sortKey]) return -1;
    return a[sortKey] < b[sortKey] ? 1 : 0;
  });

const updateOnlyOneDocumentInState = (documents, data, sortKey, sortOrder) => {
  const changedGroupIndex = documents.findIndex((type) => type.id === data.id);
  const newDocumentObject = [...documents];
  if (changedGroupIndex !== -1) {
    newDocumentObject[changedGroupIndex] = data;
  } else {
    newDocumentObject.push(data);
  }
  return sortByKeyAndOrder(newDocumentObject, sortKey, sortOrder);
};

const deleteOnlyOneDocumentFromState = (documents, deletedDocument) => {
  const deletedDocumentIndex = documents.findIndex((type) => type.id === deletedDocument.id);
  const documentsArray = [...documents];
  return documentsArray.reduce(
    (prev, x, i) => prev.concat(i !== deletedDocumentIndex ? [x] : []),
    []
  );
};

const getCurrentFolderId = (action) => {
  let currentFolderId = false;
  if (action.rootFolderId) {
    currentFolderId = action.rootFolderId;
  } else {
    action.folders.forEach((folder) => {
      if (folder.name === '/') {
        currentFolderId = folder.id;
      }
    });
  }
  return currentFolderId;
};

const updateFolderTree = (currentFolderTree, updatedFolder, operation) => {
  const newFolders = Array.from(currentFolderTree);
  let folderIndex = -1;

  newFolders.forEach((folder, index) => {
    if (folder.id === updatedFolder.id) {
      folderIndex = index;
    }
  });
  if (folderIndex >= 0) {
    if (operation === 'update') {
      newFolders[folderIndex] = updatedFolder;
    } else {
      newFolders.splice(folderIndex, 1);
    }

    return newFolders;
  }
  return currentFolderTree;
};

const updateDocument = (
  currentDocuments,
  currentFolderId,
  updatedDocument,
  operation,
  sortKey,
  sortOrder
) => {
  const newDocuments = Array.from(currentDocuments);
  if (currentFolderId === updatedDocument.folderId) {
    if (operation === 'move') {
      newDocuments.push(updatedDocument);
    }
  } else if (operation === 'move') {
    let documentIndex = -1;
    newDocuments.forEach((document, index) => {
      if (document.id === updatedDocument.id) {
        documentIndex = index;
      }
    });
    if (documentIndex >= 0) {
      newDocuments.splice(documentIndex, 1);
    }
  }
  return sortByKeyAndOrder(newDocuments, sortKey, sortOrder);
};

const addDocument = (currentDocuments, newDocument, sortKey, sortOrder) => {
  const newDocuments = Array.from(currentDocuments);
  const changedGroupIndex = currentDocuments.findIndex((type) => type.id === newDocument.id);
  if (changedGroupIndex === -1) {
    newDocuments.unshift(newDocument);
  }
  return sortByKeyAndOrder(newDocuments, sortKey, sortOrder);
};

const updateFolderPath = (currentPath, updatedFolder) => {
  const pathIds = currentPath.idPath.split('/');
  const pathNames = currentPath.namePath.split('/');
  let targetIndex = -1;
  for (let i = 0; i < pathIds.length; i += 1) {
    if (pathIds[i] === updatedFolder.id) {
      targetIndex = i;
      break;
    }
  }
  if (targetIndex >= 0) {
    pathNames[targetIndex] = updatedFolder.name;
    return {
      idPath: pathIds.join('/'),
      namePath: pathNames.join('/'),
    };
  }
  return currentPath;
};

function documentsStore(state = initialState, action) {
  switch (action.type) {
    case documentsConstants.FETCH_FOLDER_TREE_REQUEST:
      return {
        ...state,
        fetchFoldersStatus: RequestStatus.PENDING,
        folderTree: initialState.folderTree,
      };
    case documentsConstants.FETCH_FOLDER_TREE_SUCCESS:
      return {
        ...state,
        fetchFoldersStatus: RequestStatus.IDLE,
        folderTree: action.folders,
        currentFolderId: getCurrentFolderId(action),
      };
    case documentsConstants.FETCH_FOLDER_TREE_FAILURE:
      return {
        ...state,
        fetchFoldersStatus: RequestStatus.ERROR,
      };
    case documentsConstants.FETCH_DOCUMENTS_SEARCH_REQUEST:
      return {
        ...state,
        documentsSearchFetchStatus: RequestStatus.PENDING,
      };
    case documentsConstants.FETCH_DOCUMENTS_SEARCH_SUCCESS:
      return {
        ...state,
        documentsSearchFetchStatus: RequestStatus.SUCCESS,
        searchedDocuments: [...action.documents],
      };
    case documentsConstants.FETCH_DOCUMENTS_SEARCH_FAILURE:
      return {
        ...state,
        documentsSearchFetchStatus: RequestStatus.ERROR,
      };
    case documentsConstants.FETCH_FOLDERS_SEARCH_REQUEST:
      return {
        ...state,
        foldersSearchFetchStatus: RequestStatus.PENDING,
      };
    case documentsConstants.FETCH_FOLDERS_SEARCH_SUCCESS:
      return {
        ...state,
        foldersSearchFetchStatus: RequestStatus.SUCCESS,
        searchedFolders: [...action.folders],
      };
    case documentsConstants.FETCH_FOLDERS_SEARCH_FAILURE:
      return {
        ...state,
        foldersSearchFetchStatus: RequestStatus.ERROR,
      };
    case documentsConstants.CREATE_FOLDER_REQUEST:
      return {
        ...state,
        createFolderStatus: RequestStatus.PENDING,
      };
    case documentsConstants.CREATE_FOLDER_SUCCESS:
      return {
        ...state,
        createFolderStatus: RequestStatus.SUCCESS,
      };
    case documentsConstants.CREATE_FOLDER_FAILURE:
      return {
        ...state,
        createFolderStatus: RequestStatus.ERROR,
      };
    case documentsConstants.FETCH_DOCUMENTS_REQUEST:
      return {
        ...state,
        fetchDocumentsStatus: RequestStatus.PENDING,
      };
    case documentsConstants.FETCH_DOCUMENTS_SUCCESS: {
      const { documentsQuery, hasMoreDocuments, documents } = action;

      return {
        ...state,
        fetchDocumentsStatus: RequestStatus.IDLE,
        documents: documentsQuery.offset === 0 ? documents : [...state.documents, ...documents],
        hasMoreDocuments,
        documentsQuery: {
          limit: documentsQuery.limit,
          offset: documentsQuery.offset,
        },
      };
    }
    case documentsConstants.FETCH_DOCUMENTS_FAILURE:
      return {
        ...state,
        fetchDocumentsStatus: RequestStatus.ERROR,
      };
    case documentsConstants.FETCH_FOLDER_PATH_REQUEST:
      return {
        ...state,
        fetchFolderPathStatus: RequestStatus.PENDING,
      };
    case documentsConstants.FETCH_FOLDER_PATH_SUCCESS:
      return {
        ...state,
        folderPath: action.path,
        fetchFolderPathStatus: RequestStatus.SUCCESS,
      };
    case documentsConstants.FETCH_FOLDER_PATH_FAILURE:
      return {
        ...state,
        fetchFolderPathStatus: RequestStatus.ERROR,
      };
    case documentsConstants.RENAME_FOLDER_REQUEST:
      return {
        ...state,
        renameFolderStatus: RequestStatus.PENDING,
      };
    case documentsConstants.RENAME_FOLDER_SUCCESS:
      return {
        ...state,
        renameFolderStatus: RequestStatus.SUCCESS,
      };
    case documentsConstants.RENAME_FOLDER_FAILURE:
      return {
        ...state,
        renameFolderStatus: RequestStatus.ERROR,
      };
    case documentsConstants.REMOVE_FOLDER_REQUEST:
      return {
        ...state,
        removeFolderStatus: RequestStatus.PENDING,
      };
    case documentsConstants.REMOVE_FOLDER_SUCCESS:
      return {
        ...state,
        removeFolderStatus: RequestStatus.SUCCESS,
      };
    case documentsConstants.REMOVE_FOLDER_FAILURE:
      return {
        ...state,
        removeFolderStatus: RequestStatus.ERROR,
      };
    case documentsConstants.WS_ADD_FOLDER:
      return {
        ...state,
        folderTree: [...state.folderTree, action.newFolder],
      };
    case documentsConstants.WS_UPDATE_FOLDER:
      return {
        ...state,
        folderTree: updateFolderTree(state.folderTree, action.updatedFolder, 'update'),
        folderPath: updateFolderPath(state.folderPath, action.updatedFolder),
      };
    case documentsConstants.WS_DELETE_FOLDER:
      return {
        ...state,
        folderTree: updateFolderTree(state.folderTree, action.deletedFolder, 'delete'),
      };
    case documentsConstants.WS_MOVED_DOCUMENT:
      return {
        ...state,
        documents: updateDocument(
          state.documents,
          action.currentFolderId,
          action.movedFile,
          'move',
          action.sortKey,
          action.sortOrder
        ),
      };
    case documentsConstants.WS_CREATED_DOCUMENT:
      return {
        ...state,
        documents: addDocument(
          state.documents,
          action.documentData,
          action.sortKey,
          action.sortOrder
        ),
      };
    case documentsConstants.WS_UPDATED_DOCUMENT:
      return {
        ...state,
        documents: updateOnlyOneDocumentInState(
          state.documents,
          action.documentData,
          action.sortKey,
          action.sortOrder
        ),
      };
    case documentsConstants.WS_DELETE_DOCUMENT:
      return {
        ...state,
        documents: deleteOnlyOneDocumentFromState(state.documents, action.documentData),
      };
    case documentsConstants.MOVE_DOCUMENT_REQUEST:
      return {
        ...state,
        moveDocumentsStatus: RequestStatus.PENDING,
      };
    case documentsConstants.MOVE_DOCUMENT_SUCCESS:
      return {
        ...state,
        moveDocumentsStatus: RequestStatus.SUCCESS,
      };
    case documentsConstants.MOVE_DOCUMENT_FAILURE:
      return {
        ...state,
        moveDocumentsStatus: RequestStatus.ERROR,
      };
    case documentsConstants.CLEAR_STORE:
      return initialState;
    case documentsConstants.CLEAR_FOLDER_PATH:
      return {
        ...state,
        folderPath: initialState.folderPath,
      };
    default:
      return state;
  }
}

export default documentsStore;
