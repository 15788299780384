import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Fab,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  Menu,
  MenuItem,
  Tooltip,
} from '@material-ui/core';
import { AvatarAndTextItem, ExtendedGrid } from 'src/components';
import clsx from 'clsx';
import { MoreHor as ActionsIcon } from 'src/components/CustomIcon';
import { useStyles } from '../styles';

const UserItem = ({ item, actions, smallScreen, handleActionUser }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const { name, email, identityId, active: activeUser, ...otherAvatarProps } = item;

  const additionalTextArray = [];
  if (!activeUser) {
    additionalTextArray.push({
      text: t('common.removed'),
    });
  }

  return (
    <ListItem
      classes={{
        root: clsx(classes.listItemRoot, !activeUser && classes.listItemDeleted),
        container: classes.listItemContainer,
      }}
    >
      <ExtendedGrid container spacingX={2} spacingY={smallScreen ? 1 : 2} alignItemsXxs="center">
        <ExtendedGrid item xxs={12} sm={6} md={5} xl={4} className={classes.colorOnHover}>
          <AvatarAndTextItem
            size="medium"
            description={email}
            text={name}
            mainImageSrc={`${process.env.REACT_APP_AVATAR_URL}/identity/${identityId}?kind=SMALL`}
            additionalTextArray={additionalTextArray}
            {...otherAvatarProps}
          />
        </ExtendedGrid>
        {/* <ExtendedGrid item xxs={6} sm={3} md={2} xl={3}> */}
        {/*  <Typography component="span" variant={smallScreen ? 'subtitle2' : 'body1'}> */}
        {/*    {item.lastActivity} */}
        {/*  </Typography> */}
        {/* </ExtendedGrid> */}
        {/* <ExtendedGrid item xxs={6} sm={3} md={2} textAlignXxs="right" textAlignMd="left"> */}
        {/*  <Tag {...item.status} /> */}
        {/* </ExtendedGrid> */}
        {!smallScreen && !!actions && (
          <ExtendedGrid item xxs textAlignXxs="right">
            <div className={classes.actionButtonsWrapper}>
              {activeUser &&
                actions.map((action, index) => (
                  <Button
                    key={index}
                    variant="text"
                    size="small"
                    endIcon={action.icon}
                    classes={{
                      root: classes.actionButton,
                      endIcon: classes.actionButtonIcon,
                    }}
                    onClick={() => {
                      handleActionUser(item);
                      action.onClick();
                    }}
                  >
                    {action.name}
                  </Button>
                ))}
            </div>
          </ExtendedGrid>
        )}
      </ExtendedGrid>

      {smallScreen && !!actions && activeUser && (
        <ListItemSecondaryAction classes={{ root: classes.mobileActionButtonWrapper }}>
          <Tooltip title={t('common.actions')}>
            <Fab size="small" onClick={handleClick}>
              <ActionsIcon />
            </Fab>
          </Tooltip>
          <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
            {actions.map((action, index) => (
              <MenuItem
                key={index}
                onClick={() => {
                  handleActionUser(item);
                  handleClose();
                  action.onClick();
                }}
              >
                <ListItemIcon>{action.icon}</ListItemIcon>
                {action.name}
              </MenuItem>
            ))}
          </Menu>
        </ListItemSecondaryAction>
      )}
    </ListItem>
  );
};

UserItem.propTypes = {
  item: PropTypes.shape({
    identityId: PropTypes.string,
    email: PropTypes.string,
    name: PropTypes.string,
    active: PropTypes.bool,
  }),
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.node,
      name: PropTypes.string,
      onClick: PropTypes.func,
    })
  ),
  smallScreen: PropTypes.bool,
  handleActionUser: PropTypes.func,
};

export default UserItem;
