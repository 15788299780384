import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import WebSocketHelper from 'src/helpers/WebSocketHelper';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { SNACKBAR_VARIANT } from '../../models/common.model';
import snackbarActions from '../../redux/actions/snackbar.actions';

const CertificateCreatorWebsocket = () => {
  const ws = useRef(null);
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { currentIdentity } = useSelector((state) => state.identityStore, undefined);
  const { documentInvitationData } = useSelector((state) => state.documentJoinStore, undefined);
  const workspaceId = currentIdentity?.workspace?.workspaceId;

  const onWebSocketMessage = (message) => {
    if (message.type === 'RESULT') {
      switch (message.code) {
        case 'DOCUMENT_CREATED':
          if (message.data.result?.documentType === 'dsl')
            history.push(`/certificate/${message.data.result.id}`);
          else history.push(`/document/${message.data.result.id}`);
          break;
        case 'DOCUMENT_UPDATED':
          if (documentInvitationData?.documentId === message.data.result?.id) {
            if (message.data.result?.documentType === 'dsl')
              history.push(`/certificate/${message.data.result.id}`);
            else history.push(`/document/${message.data.result.id}`);
          }
          break;
        default:
          console.warn(`Unknown code [${message.code}]`, message);
          break;
      }
    } else if (message.type === 'ERROR') {
      const resultCode = message?.data?.result?.code;
      const messagePart = resultCode ? `_${resultCode}` : '';
      dispatch(
        snackbarActions.enqueueSnackbar(
          JSON.stringify({
            variant: SNACKBAR_VARIANT.ERROR,
            message: t(`drivePage.action.error.${message.code}${messagePart}`),
          })
        )
      );
    } else {
      console.warn(`Unknown action type [${message.type}]`, message);
    }
  };

  useEffect(() => {
    if (workspaceId) {
      ws.current = WebSocketHelper.subscribe(
        'document-indexing-service',
        `workspaces/${workspaceId}`,
        (message) => {
          onWebSocketMessage(message);
        }
      );
    }

    return () => {
      WebSocketHelper.unsubscribe(ws.current);
    };
  }, [workspaceId]); // eslint-disable-line react-hooks/exhaustive-deps

  return null;
};

export default CertificateCreatorWebsocket;
