import { DocumentRole, rolesTranslations } from 'src/models/document.model';
import { capitalizeFirstLetter } from 'src/helpers/globalUtils';

const selectAvailableRoles = (state, t) =>
  state.documentInvitationsStore.documentRoleTypes
    .filter((role) => role !== DocumentRole.SIGNERS && role !== DocumentRole.HOLDER)
    .map((role) => ({
      value: role,
      label: rolesTranslations(t)[role] || capitalizeFirstLetter(role),
    }));

export const documentInvitationsSelector = {
  selectAvailableRoles,
};
