import React from 'react';
import { Route, Switch, Redirect, useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FullScreenDialog, FullScreenDialogContentWrapper } from 'src/components';
import { goBack } from 'src/helpers/goBack';
import { Basic, Identity, Verification, Security, Notifications, ApiKeys } from './subpages';
import { Links } from './Links';

const UserConfigurationPage = () => {
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();

  const handleClose = () => {
    goBack(history);
  };

  const extractTitle = () => Links(t).find((link) => link.url === location.pathname)?.label;

  return (
    <FullScreenDialog
      isOpen
      onClose={handleClose}
      backgroundVariant="grey"
      title={extractTitle()}
      titleLeftSpace
    >
      <FullScreenDialogContentWrapper menuLinks={Links(t)}>
        <Switch>
          <Route path="/user-settings/api-keys" component={ApiKeys} />
          <Route path="/user-settings/basic" component={Basic} />
          <Route path="/user-settings/identity" component={Identity} />
          <Route path="/user-settings/verification" component={Verification} />
          <Route path="/user-settings/security" component={Security} />
          <Route path="/user-settings/notifications" component={Notifications} />
          <Redirect from="/user-settings" to="/user-settings/basic" />
        </Switch>
      </FullScreenDialogContentWrapper>
    </FullScreenDialog>
  );
};

export default UserConfigurationPage;
