import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FullScreenDialog } from 'src/components';
import { ContentHeading } from 'src/pages/WorkspaceConfiguration/subcomponents';
import { Pen as PenIcon, FilmCamera as VerificationIcon } from 'src/components/CustomIcon';
import { MethodItem } from '../../subcomponents';

const Verification = () => {
  const { t } = useTranslation();

  const [editedSubpage, setEditedSubpage] = useState(null);

  const handleBack = () => {
    setEditedSubpage(null);
  };

  const extractHeader = (name) => {
    switch (name) {
      default:
        return 'Title';
    }
  };

  const renderEditPage = (name) => {
    switch (name) {
      default:
        return 'Cannot render page.';
    }
  };

  const renderSummaryPage = () => (
    <>
      <ContentHeading subtitle={t('userConfigurationPage.verification.subtitle')}>
        {t('userConfigurationPage.verification.header')}
      </ContentHeading>

      <MethodItem
        icon={<PenIcon />}
        name={t('userConfigurationPage.verification.itemSignature.title')}
        subtitle={t('userConfigurationPage.verification.itemSignature.text')}
        actionButtonText={t('common.configured')}
        onClick={() => setEditedSubpage('signature')}
        disabled
      />
      <MethodItem
        icon={<VerificationIcon />}
        name={t('userConfigurationPage.verification.itemVideo.title')}
        subtitle={t('userConfigurationPage.verification.itemVideo.text')}
        actionButtonText={t('common.configure')}
        onClick={() => setEditedSubpage('video')}
        inActive
      />
    </>
  );

  return (
    <>
      {renderSummaryPage()}
      <FullScreenDialog
        isOpen={!!editedSubpage}
        onBack={handleBack}
        onClose={handleBack}
        title={editedSubpage ? extractHeader(editedSubpage) : null}
      >
        {editedSubpage ? renderEditPage(editedSubpage) : null}
      </FullScreenDialog>
    </>
  );
};

export default Verification;
