import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import MomentAdapter from '@material-ui/pickers/adapter/moment';
import { Button, InputAdornment, OutlinedInput, TextField, Typography } from '@material-ui/core';
import { DateRangePicker, LocalizationProvider } from '@material-ui/pickers';
import { ExtendedGrid } from 'src/components';
import moment from 'moment';
import { CalendarSelectDay as CalendarIcon } from 'src/components/CustomIcon';
import clsx from 'clsx';
import { useStyles } from './styles';
import { DateTimeFormats, FormatDate } from '../../helpers/dateTimeFormats';

const ResponsiveDateRangePicker = ({
  classes: classesProp,
  disabled,
  smallDevice,
  asButton,
  onApply,
  setValue,
  value,
  placeholderFrom,
  placeholderEnd,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [error, setError] = React.useState([null, null]);
  const [openState, setOpenState] = React.useState(false);
  const buttonRef = useRef();

  return (
    <div className={clsx(classes.root, classesProp && classesProp.root)}>
      <LocalizationProvider dateAdapter={MomentAdapter}>
        <DateRangePicker
          inputFormat="yyyy-MM-DD"
          disabled={disabled}
          calendars={2}
          mask={null}
          cancelText={t('common.cancel')}
          clearText={t('common.clear')}
          okText={t('common.ok')}
          today={t('calendar.today')}
          startText={t('common.from')}
          endText={t('common.to')}
          PopperProps={{
            placement: 'bottom-end',
          }}
          onOpen={() => {
            setOpenState(true);
          }}
          onClose={() => setOpenState(false)}
          toolbarTitle={t('calendar.selectDateRange')}
          toolbarPlaceholder="-"
          showToolbar={false}
          disableCloseOnSelect={false}
          value={value}
          open={openState}
          onChange={(newValue) => {
            setValue(newValue);
          }}
          onAccept={(date) => {
            onApply(date);
          }}
          onError={([startReason, endReason], [start, end]) => {
            if (
              startReason === 'invalidRange' &&
              new Date(start).getTime() === new Date(end).getTime()
            ) {
              setOpenState(false);
              setError([null, null]);
              const date = [start, end];
              onApply(date);
              return;
            }

            setError([startReason, endReason]);
          }}
          renderInput={(startProps, endProps) => {
            const {
              inputProps: startInputProps,
              error: startError,
              label: startLabel,
            } = startProps;
            const { inputProps: endInputProps, error: endError, label: endLabel } = endProps;
            const { value: startValue, onBlur, onChange, onClick, onFocus } = startInputProps;
            const { value: endValue } = endInputProps;

            if (placeholderFrom) {
              startProps.inputProps.placeholder = placeholderFrom;
            }

            if (placeholderEnd) {
              endProps.inputProps.placeholder = placeholderEnd;
            }

            if (asButton) {
              return (
                <Button
                  variant="text"
                  size="small"
                  className={classes.inputsContainer}
                  ref={buttonRef}
                  onBlur={onBlur}
                  onClick={(e) => {
                    buttonRef.current.focus();
                    if (onClick) {
                      onClick(e);
                    }
                  }}
                  onFocus={onFocus}
                  startIcon={<CalendarIcon />}
                >
                  <Typography color={startValue ? 'textPrimary' : 'textSecondary'} component="span">
                    {startValue ? (
                      <FormatDate
                        date={moment(startValue, 'yyyy-MM-DD')}
                        dateFormat={DateTimeFormats.dateShort}
                      />
                    ) : (
                      startLabel
                    )}
                  </Typography>
                  <Typography color="textSecondary" component="span">
                    &nbsp;-&nbsp;
                  </Typography>
                  <Typography color={endValue ? 'textPrimary' : 'textSecondary'} component="span">
                    {endValue ? (
                      <FormatDate
                        date={moment(endValue, 'yyyy-MM-DD')}
                        dateFormat={DateTimeFormats.dateShort}
                      />
                    ) : (
                      endLabel
                    )}
                  </Typography>

                  <OutlinedInput
                    error={startError || endError}
                    classes={{
                      root: classes.inputRoot,
                      input: classes.input,
                      notchedOutline: classes.inputNotchedOutline,
                    }}
                    readOnly
                    onChange={onChange}
                    value={value}
                  />
                </Button>
              );
            }

            return (
              <ExtendedGrid container spacing={1}>
                <ExtendedGrid item xxs={6}>
                  <TextField
                    {...startProps}
                    error={Boolean(error[0])}
                    fullWidth
                    label={!smallDevice && t('calendar.from')}
                    helperText={undefined}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <CalendarIcon color="disabled" />
                        </InputAdornment>
                      ),
                      classes: {
                        disabled: classes.inputDisabled,
                      },
                    }}
                  />
                </ExtendedGrid>
                <ExtendedGrid item xxs={6}>
                  <TextField
                    {...endProps}
                    error={Boolean(error[0])}
                    fullWidth
                    label={!smallDevice && t('calendar.to')}
                    helperText={undefined}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <CalendarIcon color="disabled" />
                        </InputAdornment>
                      ),

                      classes: {
                        disabled: classes.inputDisabled,
                      },
                    }}
                  />
                </ExtendedGrid>
              </ExtendedGrid>
            );
          }}
        />
      </LocalizationProvider>
    </div>
  );
};

ResponsiveDateRangePicker.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object,
  disabled: PropTypes.bool,
  smallDevice: PropTypes.bool,
  startDate: PropTypes.oneOfType([
    PropTypes.oneOf([undefined, null]),
    PropTypes.string,
    PropTypes.number,
    PropTypes.instanceOf(Date),
  ]),
  endDate: PropTypes.oneOfType([
    PropTypes.oneOf([undefined, null]),
    PropTypes.string,
    PropTypes.number,
    PropTypes.instanceOf(Date),
  ]),
  value: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.oneOf([undefined, null]),
      PropTypes.string,
      PropTypes.number,
      PropTypes.instanceOf(Date),
    ])
  ),
  asButton: PropTypes.bool,
  onApply: PropTypes.func,
  setValue: PropTypes.func,
  placeholderFrom: PropTypes.string,
  placeholderEnd: PropTypes.string,
};

ResponsiveDateRangePicker.defaultProps = {
  smallDevice: false,
  asButton: true,
  disabled: false,
  startDate: null,
  endDate: null,
};

export default ResponsiveDateRangePicker;
