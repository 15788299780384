import ContactHelper from '../../helpers/contactHelper';

export const mergeUsersWithContacts = ({
  workspaceEntities,
  workspaceUsers,
  workspaceId,
  contactsAvailable,
}) => {
  const formattedUsers = workspaceUsers
    ?.filter((user) => user.active === true)
    ?.map((user) => ({
      id: user.identityId,
      name: user.name,
      email: user.email,
      identityId: user.identityId,
      active: true,
      role: null,
      workspaceId,
      group: false,
    }));

  const formattedContacts = [];
  contactsAvailable.forEach((id) => {
    if (!formattedUsers.find((user) => user.id === id)) {
      const matchedIdentity = ContactHelper.getEntityData(id);
      if (matchedIdentity.active && !matchedIdentity.isGroup) {
        formattedContacts.push({
          id: matchedIdentity.identityId,
          name: matchedIdentity.name,
          email: matchedIdentity.email,
          identityId: matchedIdentity.identityId,
          role: null,
          outsideOrganisation: true,
          active: true,
          workspaceName: matchedIdentity.workspaceName,
          workspaceId: matchedIdentity.workspaceId,
          group: false,
        });
      }
    }
  });

  const formattedWorkspaceEntities = [];
  workspaceEntities
    .filter((entity) => entity.active === true)
    .forEach((entity) => {
      if (!entity.group) {
        formattedWorkspaceEntities.push({
          id: entity.entityId,
          name: entity.entityName,
          email: entity.email,
          identityId: entity.entityId,
          role: null,
          outsideOrganisation: entity.email === entity.workspaceName,
          active: true,
          workspaceName: entity.workspaceName,
          workspaceId: entity.workspaceId,
          group: false,
        });
      }
    });

  return [...formattedUsers, ...formattedContacts, ...formattedWorkspaceEntities];
};

export const mergeUsersWithGroupsAndContacts = ({
  workspaceUsers,
  workspaceId,
  workspaceGroups,
  contactsAvailable,
  workspaceEntities,
  documentWorkspaceId,
}) => {
  const formattedUsers = workspaceUsers
    ?.filter((user) => user.active === true)
    ?.map((user) => ({
      id: user.identityId,
      name: user.name,
      email: user.email,
      identityId: user.identityId,
      active: true,
      role: null,
      workspaceId,
      group: false,
    }));

  const formattedGroups = workspaceGroups
    ?.filter((group) => group.info?.active === true)
    ?.map((group) => ({
      id: group.info.groupId,
      name: group.info.name,
      email: null,
      identityId: null,
      role: null,
      active: true,
      workspaceId,
      group: true,
    }));

  const formattedContacts = [];
  contactsAvailable.forEach((id) => {
    if (
      !formattedUsers.find((user) => user.id === id) &&
      !formattedGroups.find((group) => group.id === id)
    ) {
      const matchedIdentity = ContactHelper.getEntityData(id);
      if (matchedIdentity.active) {
        if (!matchedIdentity.isGroup) {
          formattedContacts.push({
            id: matchedIdentity.identityId,
            name: matchedIdentity.name,
            email: matchedIdentity.email,
            identityId: matchedIdentity.identityId,
            role: null,
            outsideOrganisation: true,
            active: true,
            workspaceName: matchedIdentity.workspaceName,
            workspaceId: matchedIdentity.workspaceId,
            group: false,
          });
        } else {
          formattedContacts.push({
            id: matchedIdentity.groupId,
            name: matchedIdentity.name,
            email: matchedIdentity.email,
            groupId: matchedIdentity.groupId,
            role: null,
            outsideOrganisation: true,
            active: true,
            workspaceName: matchedIdentity.workspaceName,
            workspaceId: matchedIdentity.workspaceId,
            group: true,
          });
        }
      }
    }
  });

  const formattedWorkspaceEntities = workspaceEntities
    .filter(
      (entity) =>
        entity.active === true &&
        (entity.group === false ||
          (entity.group === true && documentWorkspaceId === entity.workspaceId))
    )
    .map((entity) => ({
      id: entity.entityId,
      name: entity.entityName,
      email: entity.email,
      identityId: entity.entityId,
      role: null,
      outsideOrganisation: entity.email === entity.workspaceName,
      active: true,
      workspaceName: entity.workspaceName,
      workspaceId: entity.workspaceId,
      group: entity.group,
    }));

  const notWorkspaceEntitiesArray = [...formattedUsers, ...formattedGroups, ...formattedContacts];
  const workspaceEntitiesArray = formattedWorkspaceEntities.filter(
    (wa) => !notWorkspaceEntitiesArray.find((e) => e.id === wa.id)
  );
  return [...notWorkspaceEntitiesArray, ...workspaceEntitiesArray];
};

export const sortInvitedSigners = ({ itemsToSort, sortingArray }) => {
  const sortFunc = (a, b) =>
    a.identityId
      ? sortingArray.indexOf(a.identityId) - sortingArray.indexOf(b.identityId)
      : sortingArray.indexOf(a.id) - sortingArray.indexOf(b.id);

  return itemsToSort.sort(sortFunc);
};
