import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import WebSocketHelper from 'src/helpers/WebSocketHelper';
import ContactHelper from 'src/helpers/contactHelper';
import { SNACKBAR_VARIANT } from 'src/models/common.model';
import { IdentitySelector } from 'src/redux/selectors/identity.selector';
import documentsActions from 'src/redux/actions/documents.actions';

import snackbarActions from 'src/redux/actions/snackbar.actions';
import PropTypes from 'prop-types';

const DrivePageWebsocket = ({
  folderDeleteCallback = () => undefined,
  sortKey,
  sortOrder,
  selectedFolders,
}) => {
  const ws = useRef(null);
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { currentFolderId, folderPath } = useSelector((state) => state.documentsStore, undefined);

  const workspaceId = useSelector(IdentitySelector.selectWorkspaceId, undefined);

  const onWebSocketMessage = (message) => {
    if (message.type === 'RESULT') {
      const { parent, id } = message?.data?.result;
      switch (message.code) {
        case 'FOLDER_CREATED':
          if (parent === currentFolderId) {
            dispatch(documentsActions.websocketAddFolder(message.data.result));
          }
          break;
        case 'FOLDER_UPDATED':
          if (parent === currentFolderId || currentFolderId === id) {
            dispatch(documentsActions.websocketUpdateFolder(message.data.result));
          }
          break;
        case 'FOLDER_DELETED':
          if (selectedFolders.indexOf(id) > -1) {
            folderDeleteCallback();
          }

          if (parent === currentFolderId) {
            dispatch(documentsActions.websocketDeleteFolder(message.data.result));
          }
          if (currentFolderId === id) {
            let prevFolderId = null;
            const folderPathIds = folderPath.idPath.split('/');
            for (let i = 0; i < folderPathIds.length; i += 1) {
              if (folderPathIds[i] === id && i > 0) {
                prevFolderId = folderPathIds[i - 1];
              }
            }
            if (prevFolderId) {
              history.push(`/drive/${prevFolderId}`);
            } else {
              history.push('/drive');
            }
          }
          break;
        case 'DOCUMENT_CREATED':
          dispatch(
            documentsActions.websocketCreateDocument(message.data.result, sortKey, sortOrder)
          );
          break;
        case 'DOCUMENT_MOVED':
          dispatch(
            documentsActions.websocketMoveDocument(
              currentFolderId,
              message.data.result,
              sortKey,
              sortOrder
            )
          );
          break;
        case 'DOCUMENT_UPDATED':
          if (ContactHelper.checkIfUserBelongsToDocument(message.data.result)) {
            dispatch(
              documentsActions.websocketUpdateDocument(message.data.result, sortKey, sortOrder)
            );
          } else {
            dispatch(documentsActions.websocketDeleteDocument(message.data.result));
          }
          break;
        case 'DOCUMENT_DELETED':
          dispatch(documentsActions.websocketDeleteDocument(message.data.result));
          break;
        default:
          console.warn(`Unknown code [${message.code}]`, message);
          break;
      }
    } else if (message.type === 'ERROR') {
      switch (message.code) {
        case 'FOLDER_CREATED':
        default:
          // eslint-disable-next-line no-case-declarations
          const resultCode = message?.data?.result?.code;
          // eslint-disable-next-line no-case-declarations
          const messagePart = resultCode ? `_${resultCode}` : '';
          dispatch(
            snackbarActions.enqueueSnackbar(
              SNACKBAR_VARIANT.ERROR,
              t('snackbars.common.errorTitle'),
              t(`drivePage.action.error.${message.code}${messagePart}`)
            )
          );
          break;
      }
    } else {
      console.warn(`Unknown action type [${message.type}]`, message);
    }
  };

  useEffect(() => {
    if (workspaceId && currentFolderId) {
      ws.current = WebSocketHelper.subscribe(
        'document-indexing-service',
        `workspaces/${workspaceId}`,
        (message) => {
          onWebSocketMessage(message);
        }
      );
    }

    return () => {
      WebSocketHelper.unsubscribe(ws.current);
    };
  }, [workspaceId, currentFolderId]); // eslint-disable-line react-hooks/exhaustive-deps

  return null;
};

DrivePageWebsocket.propTypes = {
  folderDeleteCallback: PropTypes.func,
  sortKey: PropTypes.string,
  sortOrder: PropTypes.string,
  selectedFolders: PropTypes.arrayOf(PropTypes.string),
};

export default DrivePageWebsocket;
