import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { ButtonBase, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { SortingDown as SortDownIcon, SortingUp as SortUpIcon } from 'src/components/CustomIcon';
import { ExtendedGrid } from 'src/components';

import { useStyles } from './styles';

const DataTable = React.forwardRef(
  (
    {
      className: classNameProp,
      cols,
      withTableHeader,
      iconWidth,
      headerTextWidthLikeIcon,
      headerAction,
      title,
      titleRef,
      titleVariant,
      mediumTitleVariant,
      disableTitle,
      sortBy,
      sortOrder,
      onChangeSort,
      isLoading,
      smallScreen,
      rightSpace,
    },
    ref
  ) => {
    const classes = useStyles();
    const mediumScreen = useMediaQuery(({ breakpoints }) => breakpoints.down('md'));
    const theme = useTheme();
    const [titleTextRef, setTitleTextRef] = useState(null);

    const titleTextWidth =
      !headerTextWidthLikeIcon && iconWidth !== 'auto' && titleTextRef !== null
        ? titleTextRef.offsetWidth - iconWidth + theme.spacing(1)
        : null;

    const getSortingArrow = (dataKey) => {
      const getArrow = () => {
        if (dataKey === sortBy) {
          if (sortOrder === 'desc') {
            return <SortDownIcon color="primary" />;
          }
          return <SortUpIcon color="primary" />;
        }
        return <SortDownIcon color="secondary" />;
      };

      return (
        <ButtonBase component="div" className={classes.sortArrow}>
          {getArrow()}
        </ButtonBase>
      );
    };

    const swapOrder = (order) => (order === 'asc' ? 'desc' : 'asc');

    const onSortChange = (dataKey) => {
      if (onChangeSort) {
        if (dataKey === sortBy) {
          onChangeSort({ sortKey: dataKey, sortOrder: swapOrder(sortOrder) });
        } else {
          onChangeSort({ sortKey: dataKey, sortOrder: 'desc' });
        }
      }
    };

    return (
      <div className={clsx(classes.header, classNameProp)} ref={ref}>
        {!disableTitle && (
          <Typography
            variant={mediumScreen ? mediumTitleVariant : titleVariant}
            ref={titleRef}
            style={
              !headerTextWidthLikeIcon && iconWidth !== 'auto'
                ? { minWidth: iconWidth, width: iconWidth }
                : null
            }
            className={clsx(classes.title, iconWidth === 'auto' && classes.iconCellAuto)}
          >
            <strong ref={(element) => setTitleTextRef(element)}>{title}</strong>
          </Typography>
        )}

        {headerAction && <div className={classes.hasItemBeforeRowContent}>{headerAction}</div>}

        {withTableHeader && cols && !smallScreen && (
          <>
            <ExtendedGrid
              container
              spacingX={2}
              alignItemsXxs="center"
              className={clsx(
                classes.itemRowContent,
                !disableTitle && classes.hasItemBeforeRowContent,
                rightSpace && classes.hasItemAfterRowContent
              )}
            >
              {cols.map((col, index) => {
                const headerClasses = [classes.headerCell, col?.colProps?.className];
                const addSpaceToFirstColumn = index === 0 && !headerTextWidthLikeIcon;

                if (col.sortable && onChangeSort) {
                  headerClasses.push(classes.sortable);
                  if (col.dataKey === sortBy && !isLoading) {
                    headerClasses.push(classes.sortActive);
                  }
                }

                return (
                  <ExtendedGrid
                    item
                    key={`table-col-header-${col.dataKey}`}
                    {...col.colProps}
                    className={clsx(headerClasses)}
                    style={addSpaceToFirstColumn ? { paddingLeft: titleTextWidth } : null}
                  >
                    {onChangeSort ? (
                      <>
                        {col.title && col.sortable && (
                          <ButtonBase
                            disableRipple
                            onClick={() => {
                              if (col.sortable && !isLoading) {
                                onSortChange(col.dataKey);
                              }
                            }}
                          >
                            <Typography variant="subtitle2" component="span">
                              {col.title}
                            </Typography>
                            {col.sortable && onChangeSort && getSortingArrow(col.dataKey)}
                          </ButtonBase>
                        )}

                        {col.title && !col.sortable && (
                          <Typography variant="subtitle2" component="span">
                            {col.title}
                          </Typography>
                        )}
                      </>
                    ) : (
                      <Typography variant="subtitle2" component="span">
                        {col.title}
                      </Typography>
                    )}
                  </ExtendedGrid>
                );
              })}
            </ExtendedGrid>

            {rightSpace && <div className={classes.headerEndElement} />}
          </>
        )}
      </div>
    );
  }
);

DataTable.propTypes = {
  className: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  cols: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      dataKey: PropTypes.string.isRequired,
      sortable: PropTypes.bool,
      type: PropTypes.oneOf(['icon', 'text', 'time-ago', 'person-badge', 'document-status']),
      icon: PropTypes.oneOf(['file', 'folder']),
      colProps: PropTypes.shape(),
    })
  ),
  withTableHeader: PropTypes.bool,
  headerAction: PropTypes.node,
  sortBy: PropTypes.string,
  sortOrder: PropTypes.oneOf(['asc', 'desc']),
  onChangeSort: PropTypes.func,
  isLoading: PropTypes.bool,
  iconWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf(['auto'])]),
  headerTextWidthLikeIcon: PropTypes.bool,
  title: PropTypes.string,
  titleVariant: PropTypes.oneOfType([
    PropTypes.oneOf([
      'body1',
      'body2',
      'button',
      'caption',
      'h1',
      'h2',
      'h3',
      'h4',
      'h5',
      'h6',
      'inherit',
      'overline',
      'subtitle1',
      'subtitle2',
    ]),
    PropTypes.string,
  ]),
  mediumTitleVariant: PropTypes.oneOfType([
    PropTypes.oneOf([
      'body1',
      'body2',
      'button',
      'caption',
      'h1',
      'h2',
      'h3',
      'h4',
      'h5',
      'h6',
      'inherit',
      'overline',
      'subtitle1',
      'subtitle2',
    ]),
    PropTypes.string,
  ]),
  titleRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  disableTitle: PropTypes.bool,
  smallScreen: PropTypes.bool,
  rightSpace: PropTypes.bool,
};

DataTable.defaultProps = {
  withTableHeader: false,
  disableTitle: false,
  titleVariant: 'h4',
  mediumTitleVariant: 'h5',
  isLoading: false,
  rightSpace: true,
};

export default DataTable;
