import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { useDrag, useDrop } from 'react-dnd';
import { ButtonBase } from '@material-ui/core';
import { useLongPress } from 'use-long-press';

import {
  BadgeCell,
  DocumentStatusCell,
  EnterButtonCell,
  IconCell,
  TextCell,
  TimeAgoCell,
} from './cells';
import { ExtendedGrid } from '../index';
import {
  CertificateIcon2,
  ChevronRight as ChevronRightIcon,
  FileColor as FileIcon,
  FolderColor as FolderIcon,
} from '../CustomIcon';
import { useStyles } from './styles';

const DataTableRow = ({
  className: classNameProp,
  cols,
  onDblClick,
  smallScreen,
  i,
  iconWidth,
  row,
  onClick: onRowClick,
  rowClasses,
  onLongPress,
  tile,
  defaultIcon,
  isLoading,
  onDrag,
  onDrop,
  isDrag,
}) => {
  const classes = useStyles();
  const longPressProps = useLongPress(() => {
    if (smallScreen && !!onLongPress) {
      onLongPress(row);
    }
  });

  const getIcon = (row) => {
    const iconData = row.icon ? row.icon : defaultIcon;
    const fileIcon =
      row?.documentType === 'dsl' ? (
        <div className={classes.certIcon}>
          <CertificateIcon2 />
        </div>
      ) : (
        <FileIcon />
      );
    return iconData === 'folder' ? <FolderIcon /> : fileIcon;
  };

  const isButtonItem =
    typeof onDblClick === 'function' ||
    typeof onRowClick === 'function' ||
    typeof onLongPress === 'function';

  const Component = isButtonItem ? ButtonBase : 'div';

  const [{ isDragging }, drag, preview] = useDrag(() => ({
    type: 'ITEM',
    item: { row },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();
      return dropResult?.folderId ? onDrop(dropResult?.folderId) : onDrop(dropResult?.id);
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      handlerId: monitor.getHandlerId(),
    }),
  }));

  const [{ isOver }, drop] = useDrop(() => ({
    accept: 'ITEM',
    item: { row },
    drop: () => row,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  }));

  useEffect(() => {
    if (onDrag && isDragging && typeof onDrag === 'function') {
      onDrag(row);
    }
  }, [isDragging]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    preview(getEmptyImage(), {
      captureDraggingState: true,
    });
  }, [preview]);

  const isRightActionButton = typeof onDblClick === 'function' && !tile && !smallScreen;

  return (
    <Component
      ref={isDrag ? drag : drop}
      component="div"
      className={clsx(classes.itemRow, rowClasses, classNameProp, isOver && classes.dropBackground)}
      disabled={isLoading}
      onDoubleClick={() => {
        if (typeof onDblClick === 'function') {
          onDblClick(row);
        }
      }}
      onClick={() => {
        if (onRowClick) {
          if (typeof onRowClick === 'function') {
            onRowClick(row);
          }
        }
      }}
      {...longPressProps}
    >
      {(row.icon || defaultIcon) && (
        <IconCell
          icon={getIcon(row)}
          style={{ minWidth: iconWidth, width: iconWidth }}
          className={clsx(iconWidth === 'auto' && classes.iconCellAuto)}
        />
      )}

      <ExtendedGrid
        container
        spacingX={2}
        alignItemsXxs="center"
        className={clsx(
          classes.itemRowContent,
          (row.icon || defaultIcon) && classes.hasItemBeforeRowContent,
          typeof onDblClick === 'function' && !smallScreen && classes.hasItemAfterRowContent
        )}
      >
        {cols.map((col) => {
          switch (col.type) {
            case 'person-badge':
              return (
                <BadgeCell
                  key={`row-${i}-${col.dataKey}`}
                  person={row[col.dataKey]}
                  {...col.colProps}
                />
              );
            case 'document-status':
              return !smallScreen ? (
                <DocumentStatusCell
                  key={`row-${i}-${col.dataKey}`}
                  status={row[col.dataKey]}
                  isOnlyToPreview={row?.roles?.signers === undefined}
                  smallScreen={smallScreen}
                  {...col.colProps}
                />
              ) : null;
            case 'time-ago':
              return (
                <TimeAgoCell
                  key={`row-${i}-${col.dataKey}`}
                  value={row[col.dataKey]}
                  smallScreen={smallScreen}
                  {...col.colProps}
                />
              );
            case 'text':
            default:
              return (
                <TextCell
                  key={`row-${i}-${col.dataKey}`}
                  value={row[col.dataKey]}
                  {...col.colProps}
                />
              );
          }
        })}
      </ExtendedGrid>

      {smallScreen && (
        <>
          {cols.map(
            (col) =>
              col.type === 'document-status' && (
                <DocumentStatusCell
                  key={`row-${i}-${col.dataKey}`}
                  status={row[col.dataKey]}
                  isOnlyToPreview={row?.roles?.signers === undefined}
                  smallScreen={smallScreen}
                  {...col.colProps}
                />
              )
          )}
        </>
      )}

      {isRightActionButton && (
        <EnterButtonCell onClick={() => onDblClick(row)} type="icon" icon={<ChevronRightIcon />} />
      )}
    </Component>
  );
};

DataTableRow.propTypes = {
  className: PropTypes.string,
  cols: PropTypes.arrayOf(PropTypes.shape()),
  onClick: PropTypes.func,
  onDblClick: PropTypes.func,
  smallScreen: PropTypes.bool,
  i: PropTypes.number,
  iconWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf(['auto'])]),
  row: PropTypes.shape(),
  rowClasses: PropTypes.arrayOf(PropTypes.string),
  onLongPress: PropTypes.func,
  tile: PropTypes.bool,
  defaultIcon: PropTypes.string,
  isLoading: PropTypes.bool,
  onDrag: PropTypes.func,
  onDrop: PropTypes.func,
  isDrag: PropTypes.bool,
};

DataTableRow.defaultProps = {
  iconWidth: 'auto',
  tile: false,
};

export default DataTableRow;
