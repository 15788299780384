import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  ({ palette, shadows, shape, spacing, transitions, typography }) =>
    createStyles({
      root: {
        justifyContent: 'flex-start',
        textAlign: 'left',
        marginTop: spacing(0.5),
        padding: `${spacing(1)}px ${spacing(5)}px ${spacing(1)}px ${spacing(1)}px`,
        marginLeft: spacing(-1),
        marginRight: spacing(-1),
        width: `calc(100% + ${spacing(2)}px)`,
        borderRadius: shape.borderRadius,
        '&:hover, &:focus': {
          backgroundColor: palette.action.hover,
          '&:not($disabled) $checkIconWrapper': {
            opacity: 1,
          },
        },
      },
      disabled: {
        opacity: '1 !important',
        pointerEvents: 'none',
      },
      textMultiline: {
        margin: 0,
      },
      read: {},
      notRead: {
        '& $notificationContent': {
          fontWeight: typography.fontWeightBold,
        },
        '& $checkIconWrapper': {
          opacity: 0,
          transition: transitions.create(['color', 'opacity'], {
            duration: transitions.duration.short,
          }),
        },
      },
      notificationContent: {},
      date: {
        fontSize: 10,
        lineHeight: 1.3,
      },
      checkIconWrapper: {
        position: 'absolute',
        zIndex: 1,
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        backgroundColor: palette.action.hover,
        '& svg': {
          display: 'block',
          backgroundColor: palette.background.paper,
          borderRadius: '50%',
          padding: spacing(0.5),
          marginRight: spacing(1),
          boxShadow: shadows[6],
        },
      },
      avatar: {
        position: 'relative',
        '& svg': {
          fontSize: spacing(2.5),
        },
        '& $loader': {
          position: 'absolute',
          top: 0,
          left: 0,
        },
      },
      loader: {},
    }),
  { index: 0 }
);
