import React, { useCallback, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import documentsActions from 'src/redux/actions/documents.actions';
import { FileColsConfig, FolderColsConfig } from 'src/helpers/fileAndFolderColsConfig';
import { Button, useMediaQuery } from '@material-ui/core';
import OutsideClickHandler from 'react-outside-click-handler';
import {
  Cut as CutIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  FileAdd as AddFileIcon,
  FolderAdd as AddFolderIcon,
  Paste as PasteIcon,
  Plus as AddIcon,
  Refresh as ClearIcon,
} from 'src/components/CustomIcon';
import { DataTable, FullPageLoader, NoDocumentsBanner, SelectTemplateDialog } from 'src/components';
import { AddNewFolder, DeleteFolder, RenameFolder } from 'src/components/DataTable/editDialogs';
import { AppHeader } from 'src/pages/App/subcomponents';
import FieldDragLayer from 'src/components/DataTable/dragLayer';
import { RequestStatus } from 'src/helpers/reduxReuquest.util';
import { SNACKBAR_VARIANT } from 'src/models/common.model';
import { DocumentsSelector } from 'src/redux/selectors/documents.selector';
import {
  BreadcrumbsAndSettings,
  ItemsActions,
  MobileBreadcrumbs,
  SpeedDial,
} from './subcomponents';

import { useStyles } from './styles';
import DrivePageWebsocket from './DrivePageWebsocket';
import snackbarActions from '../../redux/actions/snackbar.actions';
import usePrevious from '../../helpers/hooks/usePrevious';
import ContactHelper from '../../helpers/contactHelper';

const iconWidth = 40;

const DrivePage = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const match = useRouteMatch();
  const smallScreen = useMediaQuery(({ breakpoints }) => breakpoints.down('sm'));

  const {
    fetchFoldersStatus,
    renameFolderStatus,
    removeFolderStatus,

    moveDocumentsStatus,
    createFolderStatus,
    fetchDocumentsStatus,
    currentFolderId,
    folderTree,
    documents,
    hasMoreDocuments,
    folderPath,
    fetchFolderPathStatus,
    defaultSortKey,
    defaultSortOrder,
  } = useSelector((state) => state.documentsStore, undefined);
  const foldersList = useSelector(DocumentsSelector.selectOrdinaryFolders, undefined);
  const { currentIdentity, structureStatus, structure, workspaceStructureStatus } = useSelector(
    (state) => state.identityStore,
    undefined
  );

  const [createDocumentOpen, setCreateDocumentOpen] = useState(false);
  const [addNewFolderDialogOpen, setAddNewFolderDialogOpen] = useState(false);
  const [deleteFolderDialogOpen, setDeleteFolderDialogOpen] = useState(false);
  const [renameFolderDialogOpen, setRenameFolderDialogOpen] = useState(false);
  const [page, setPage] = useState(0);

  const dialogsToggle = {
    createDocumentDialog: {
      open: () => setCreateDocumentOpen(true),
      close: () => setCreateDocumentOpen(false),
    },
    addNewFolderDialog: {
      open: () => setAddNewFolderDialogOpen(true),
      close: () => setAddNewFolderDialogOpen(false),
    },
    deleteDialog: {
      open: () => setDeleteFolderDialogOpen(true),
      close: () => setDeleteFolderDialogOpen(false),
    },
    renameDialog: {
      open: () => setRenameFolderDialogOpen(true),
      close: () => setRenameFolderDialogOpen(false),
    },
  };

  const [selectedFilesRowName, setSelectedFilesRowName] = useState([]);
  const [selectedFilesRows, setSelectedFilesRows] = useState([]);
  const [selectedFolders, setSelectedFolders] = useState([]);
  const selectedFilesRowsRef = useRef({ sourceFolder: null, items: [] });
  const [sortKey, setSortKey] = useState(defaultSortKey);
  const [sortOrder, setSortOrder] = useState(defaultSortOrder);
  const [dragMode, setDragMode] = useState(false);
  const foldersColConfig = FolderColsConfig(t);
  const filesColConfig = FileColsConfig(t);
  const identityIsEmpty = structureStatus === RequestStatus.SUCCESS && structure.length === 0;
  const folderIsRoot = folderPath?.namePath === '/';
  const workspaceId = currentIdentity?.workspace?.workspaceId;
  const urlFolderId = match.params?.folderId ? match.params?.folderId : undefined;
  const prevState = usePrevious({
    documents,
  });
  const isLoadingMore = fetchDocumentsStatus === RequestStatus.PENDING && hasMoreDocuments;

  const onFileRowSelection = (row, callback = () => undefined) => {
    const newRowsId = Array.from(selectedFilesRows);
    const newRowsName = Array.from(selectedFilesRowName);
    if (selectedFilesRows.indexOf(row.id) !== -1) {
      newRowsId.splice(newRowsId.indexOf(row.id), 1);
      newRowsName.splice(newRowsName.indexOf(row.name), 1);
    } else {
      newRowsId.push(row.id);
      newRowsName.push(row.name);
    }
    callback(newRowsId);
    setSelectedFilesRows(newRowsId);
    setSelectedFilesRowName(newRowsName);
    setSelectedFolders([]);
  };

  const onCut = (items, sourceFolder) => {
    selectedFilesRowsRef.current = { sourceFolder, items };
    setDragMode(true);
  };

  const onPaste = (targetFolderId) => {
    const { sourceFolder, items } = selectedFilesRowsRef.current;
    const folderId = targetFolderId || currentFolderId;
    setDragMode(false);
    if (sourceFolder && items.length > 0 && sourceFolder !== folderId) {
      if (workspaceId) {
        selectedFilesRowsRef.current = { sourceFolder: null, items: [] };
        setSelectedFilesRowName([]);

        dispatch(documentsActions.moveDocuments(workspaceId, sourceFolder, folderId, items));
      }
    }
  };

  const onLoadMoreFiles = () => {
    if (!isLoadingMore && !isLoadingMoreFiles) {
      setPage(page + 1);
    }
  };

  const goToDocument = (row) => {
    localStorage.setItem('createdDocumentFolderId', folderIsRoot ? 'root' : currentFolderId);
    if (row?.documentType === 'dsl') {
      history.push(`/certificate/${row.id}`);
    } else {
      history.push(`/document/${row.id}`);
    }
  };

  const loadFilesRows = (offset) => {
    if (workspaceId && currentFolderId)
      dispatch(
        documentsActions.fetchDocuments({
          workspaceId,
          folderId: currentFolderId,
          offset,
          orderBy: sortKey,
          sortOrder,
          filterKey: undefined,
          filterValue: undefined,
        })
      );
  };

  const loadFolderPath = useCallback(
    (workspaceId, currentFolderId) => {
      if (workspaceId && currentFolderId) {
        dispatch(documentsActions.getCurrentFolderPath(workspaceId, currentFolderId));
      } else {
        dispatch(documentsActions.clearFolderPath());
      }
    },
    [dispatch]
  );

  const isLoadingMoreFiles = fetchDocumentsStatus === RequestStatus.PENDING && hasMoreDocuments;
  const isLoadingFiles = fetchDocumentsStatus === RequestStatus.PENDING;

  const isLoadingFolders = fetchFoldersStatus === RequestStatus.PENDING;
  const isAnyFileSelected = selectedFilesRows.length > 0;
  const isAnyFolderSelected = selectedFolders.length > 0;
  const isAnythingSelected = isAnyFileSelected || isAnyFolderSelected;
  const onlyFilesSelected = isAnyFileSelected && !isAnyFolderSelected;
  const onlyFoldersSelected = !isAnyFileSelected && isAnyFolderSelected;
  const isAnyCut = selectedFilesRowsRef.current.items.length > 0;

  const mainActions = {
    folderActions: [
      {
        icon: <EditIcon />,
        name: t(`drivePage.foldersTable.changeName.${smallScreen ? 'longText' : 'shortText'}`),
        onClick: dialogsToggle.renameDialog.open,
      },
      {
        icon: <DeleteIcon />,
        name: t(`drivePage.foldersTable.delete.${smallScreen ? 'longText' : 'shortText'}`),
        onClick: dialogsToggle.deleteDialog.open,
      },
    ],
    filesActions: [
      smallScreen && {
        icon: <CutIcon />,
        name: t('common.cut'),
        onClick: () => onCut(selectedFilesRows, currentFolderId),
      },
      isAnyCut &&
        smallScreen && {
          icon: <PasteIcon />,
          name: t('common.paste'),
          onClick: () => onPaste(),
        },
    ].filter(Boolean),
    commonActions: [
      {
        icon: <ClearIcon />,
        name: t('common.clearSelection'),
        onClick: () => {
          setSelectedFilesRowName([]);
          setSelectedFolders([]);
          setSelectedFilesRows([]);
        },
      },
    ],
  };
  const { folderActions, filesActions, commonActions } = mainActions;

  const getAllActions = () => {
    const actions = [];
    if (isAnythingSelected) {
      if (onlyFoldersSelected) {
        actions.push(...folderActions);
      }
      if (onlyFilesSelected) {
        actions.push(...filesActions);
      }
      actions.push(...commonActions);
    }
    return actions;
  };
  const allAvailableActions = getAllActions();

  const onFolderClick = (row, isDoubleClick) => {
    if (smallScreen) {
      history.push(`/drive/${row.id}`);
    } else if (!isDoubleClick) {
      const newSelection = [];
      const prevSelection = selectedFolders.length > 0 ? selectedFolders[0] : false;
      setSelectedFilesRowName([]);
      setSelectedFilesRows([]);

      if (prevSelection !== row.id) {
        newSelection.push(row.id);
      }
      setSelectedFolders(newSelection);
    } else {
      setSelectedFolders([]);
      history.push(`/drive/${row.id}`);
    }
  };

  const addFolderAction = {
    icon: smallScreen ? <AddFolderIcon /> : <AddIcon />,
    name: t(`drivePage.foldersTable.addNew.${smallScreen ? 'longText' : 'shortText'}`),
    onClick: dialogsToggle.addNewFolderDialog.open,
  };

  const addDocumentAction = {
    icon: <AddFileIcon />,
    name: t(`sideBar.addDocument.${smallScreen ? 'longText' : 'shortText'}`),
    onClick: dialogsToggle.createDocumentDialog.open,
  };

  const canShowFolderActions = !folderIsRoot;

  const noFoldersConfig = {
    label: t('drivePage.foldersTable.noItems'),
  };
  if (!identityIsEmpty) {
    noFoldersConfig.buttonText = addFolderAction.name;
    noFoldersConfig.onClick = addFolderAction.onClick;
  }

  const allLoaded =
    workspaceStructureStatus === RequestStatus.SUCCESS &&
    fetchFoldersStatus === RequestStatus.IDLE &&
    fetchFolderPathStatus === RequestStatus.SUCCESS &&
    (!isLoadingFiles || isLoadingMoreFiles);

  // TODO: fix empty folderTree after workspace change
  const showNoDocumentsBanner =
    allLoaded && folderIsRoot && folderTree?.length <= 2 && documents.length === 0;

  useEffect(() => {
    if (
      documents &&
      JSON.stringify(documents) !== JSON.stringify(prevState?.documents) &&
      documents.length > 0
    ) {
      const missingContacts = [];
      documents.forEach((document) => {
        const matchedIdentity = ContactHelper.getEntityData(document.author);
        if (!matchedIdentity && !ContactHelper.checkIfIsInProgress(document.author)) {
          missingContacts.push(document.author);
        }
      });
      if (missingContacts.length > 0) {
        ContactHelper.addContactsToQueue(missingContacts);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documents, prevState]);

  // Create folder dialog finalized effect
  useEffect(() => {
    if (createFolderStatus === RequestStatus.SUCCESS) {
      dialogsToggle.addNewFolderDialog.close();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createFolderStatus]);

  // on sort change
  useEffect(() => {
    loadFilesRows(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortKey, sortOrder]);

  // on page / scroll change
  useEffect(() => {
    if (page !== 0) {
      loadFilesRows(20 * page);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  // Remove folder dialog finalized effect
  useEffect(() => {
    if (removeFolderStatus === RequestStatus.SUCCESS) {
      dispatch(
        snackbarActions.enqueueSnackbar(
          SNACKBAR_VARIANT.INFO,
          t('drivePage.foldersDialog.delete.toastSuccessText')
        )
      );
      dialogsToggle.deleteDialog.close();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [removeFolderStatus]);

  // Move document folder dialog finalized effect
  useEffect(() => {
    if (moveDocumentsStatus === RequestStatus.SUCCESS) {
      setSelectedFilesRows([]);
      dispatch(
        snackbarActions.enqueueSnackbar(
          SNACKBAR_VARIANT.INFO,
          t('drivePage.moveDocuments.toastSuccessText')
        )
      );
    }
    if (moveDocumentsStatus === RequestStatus.ERROR) {
      dispatch(
        snackbarActions.enqueueSnackbar(
          SNACKBAR_VARIANT.INFO,
          t('drivePage.moveDocuments.toastFailureText')
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [moveDocumentsStatus]);

  // Rename folder dialog finalized effect
  useEffect(() => {
    if (renameFolderStatus === RequestStatus.SUCCESS) {
      dispatch(
        snackbarActions.enqueueSnackbar(
          SNACKBAR_VARIANT.INFO,
          t('drivePage.foldersDialog.rename.toastSuccessText')
        )
      );
      dialogsToggle.renameDialog.close();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [renameFolderStatus]);

  // Load folder list when url has changed
  useEffect(() => {
    if (workspaceId) {
      dispatch(documentsActions.getFolderTree(workspaceId, urlFolderId || 'root'));
      if (urlFolderId) localStorage.setItem('currentFolderId', urlFolderId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlFolderId, workspaceId]);

  // Load files and folder path when new currentFolderId
  useEffect(() => {
    if (currentFolderId) {
      localStorage.setItem('currentFolderId', currentFolderId);
      loadFolderPath(workspaceId, currentFolderId);
      setPage(0);
      loadFilesRows(0);
    }
    localStorage.setItem('lastMainPage', 'drive');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspaceId, currentFolderId]);

  // cleanup documents store
  useEffect(
    () => () => {
      dispatch(documentsActions.clearDocumentsStore());
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  if (identityIsEmpty) {
    return null;
  }

  return (
    <>
      <DrivePageWebsocket
        folderDeleteCallback={() => setSelectedFolders([])}
        selectedFolders={selectedFolders}
        sortKey={sortKey}
        sortOrder={sortOrder}
      />
      {allLoaded && (
        <>
          {smallScreen && (
            <>
              {canShowFolderActions && !showNoDocumentsBanner && (
                <AppHeader
                  className={classes.appBar}
                  isMainHeader={false}
                  disableTitle
                  additionalLeftAction={<MobileBreadcrumbs folderPath={folderPath} />}
                  additionalRightAction={
                    <ItemsActions smallScreen={smallScreen} actions={folderActions} />
                  }
                />
              )}

              <SpeedDial
                actions={
                  isAnythingSelected ? allAvailableActions : [addDocumentAction, addFolderAction]
                }
                icon={!isAnythingSelected && <AddIcon />}
              />
            </>
          )}
          {showNoDocumentsBanner ? (
            <NoDocumentsBanner
              header={t('noDocuments.header')}
              headerVariant={smallScreen ? 'h3' : 'h2'}
              description={t('noDocuments.description')}
              showButton={!smallScreen}
              buttonText={t('noDocuments.button')}
              onClick={dialogsToggle.createDocumentDialog.open}
              classes={{ root: classes.noDocumentsBanner }}
            />
          ) : (
            <>
              <FieldDragLayer selectedRows={selectedFilesRowName} />
              <div className={classes.root}>
                {!smallScreen && (
                  <BreadcrumbsAndSettings
                    className={classes.breadcrumbs}
                    additionalContent={
                      allAvailableActions.length > 0 && (
                        <ItemsActions smallScreen={smallScreen} actions={allAvailableActions} />
                      )
                    }
                    folderPath={folderPath}
                  />
                )}

                {foldersColConfig && (
                  <OutsideClickHandler
                    disabled={deleteFolderDialogOpen || renameFolderDialogOpen}
                    onOutsideClick={(e) => {
                      const hasException = e.target.getElementsByClassName(
                        'outside-handler-exception'
                      )[0];
                      if (!hasException) {
                        setSelectedFolders([]);
                      }
                    }}
                  >
                    <DataTable
                      classes={{
                        headerWrapper: clsx(classes.header, classes.firstHeader),
                      }}
                      title={t('drivePage.foldersTable.title')}
                      headerAction={
                        !smallScreen ? (
                          <Button
                            variant="text"
                            size="small"
                            endIcon={addFolderAction.icon}
                            onClick={addFolderAction.onClick}
                          >
                            <strong>{addFolderAction.name}</strong>
                          </Button>
                        ) : null
                      }
                      iconWidth={iconWidth}
                      headerTextWidthLikeIcon
                      rows={foldersList}
                      cols={foldersColConfig}
                      onClick={(row) => {
                        onFolderClick(row, false);
                      }}
                      selectable
                      onDblClick={(row) => {
                        onFolderClick(row, true);
                      }}
                      selectedRows={selectedFolders}
                      isLoading={isLoadingFolders}
                      defaultIcon="folder"
                      tileRowsOnMobile
                      smallScreen={smallScreen}
                      disableHeader={smallScreen}
                      noItems={noFoldersConfig}
                    />
                    <DeleteFolder
                      open={deleteFolderDialogOpen}
                      onClose={dialogsToggle.deleteDialog.close}
                      onChange={() => {
                        let targetFolder;
                        if (smallScreen) {
                          targetFolder = currentFolderId;
                        } else {
                          [targetFolder] = selectedFolders;
                        }
                        if (targetFolder) {
                          dispatch(documentsActions.removeFolder(workspaceId, targetFolder));
                        }
                      }}
                    />
                    {renameFolderDialogOpen && (
                      <RenameFolder
                        open
                        smallScreen={smallScreen}
                        onClose={dialogsToggle.renameDialog.close}
                        onChange={(newFolderName) => {
                          let targetFolder;
                          if (smallScreen) {
                            targetFolder = currentFolderId;
                          } else {
                            [targetFolder] = selectedFolders;
                          }
                          if (targetFolder) {
                            dispatch(
                              documentsActions.renameFolder(
                                workspaceId,
                                targetFolder,
                                newFolderName
                              )
                            );
                          }
                        }}
                        currentFolderName={
                          foldersList?.find(
                            (folder) =>
                              folder.id === (smallScreen ? currentFolderId : selectedFolders[0])
                          )?.name
                        }
                      />
                    )}
                    <div />
                  </OutsideClickHandler>
                )}
                {filesColConfig && (
                  <OutsideClickHandler
                    onOutsideClick={() => {
                      setSelectedFilesRowName([]);
                      setSelectedFilesRows([]);
                    }}
                  >
                    <DataTable
                      classes={{
                        headerWrapper: clsx(classes.header, classes.secondHeader),
                      }}
                      title={t('drivePage.documentsTable.title')}
                      rows={documents}
                      cols={filesColConfig}
                      withTableHeader
                      iconWidth={iconWidth}
                      headerTextWidthLikeIcon={false}
                      selectable
                      onClick={(row) => {
                        if (smallScreen) {
                          goToDocument(row);
                        } else {
                          onFileRowSelection(row);
                        }
                      }}
                      onDblClick={(row) => {
                        if (!smallScreen) {
                          goToDocument(row);
                        }
                      }}
                      onLongPress={(row) => {
                        if (smallScreen) {
                          onFileRowSelection(row);
                        }
                      }}
                      onDrag={(row) => {
                        if (isAnyFileSelected) {
                          onCut(selectedFilesRows, currentFolderId);
                        } else {
                          onFileRowSelection(row, (items) => onCut(items, currentFolderId));
                        }
                      }}
                      onDrop={(targetFolderId) => {
                        onPaste(targetFolderId);
                      }}
                      selectedRows={selectedFilesRows}
                      sortBy={sortKey}
                      sortOrder={sortOrder}
                      onChangeSort={({ sortOrder, sortKey }) => {
                        setSortKey(sortKey);
                        setSortOrder(sortOrder);
                        setPage(0);
                      }}
                      isLoading={isLoadingFiles}
                      isLoadingMore={isLoadingMoreFiles}
                      hasMoreItems={hasMoreDocuments} // TODO: remove !
                      paginationType="infinite"
                      defaultIcon="file"
                      onLoadMoreFiles
                      smallScreen={smallScreen}
                      disableHeader={smallScreen}
                      onLoadMore={() => {
                        onLoadMoreFiles();
                      }}
                      noItems={{
                        label: t('drivePage.documentsTable.noItems'),
                      }}
                      isDrag
                      isAnyFileSelected={isAnyFileSelected}
                      dragMode={dragMode}
                    />
                  </OutsideClickHandler>
                )}
              </div>
            </>
          )}

          {addNewFolderDialogOpen && (
            <AddNewFolder
              open={addNewFolderDialogOpen}
              onClose={dialogsToggle.addNewFolderDialog.close}
              onChange={(newFolderName) => {
                dispatch(
                  documentsActions.createFolder(workspaceId, currentFolderId, newFolderName)
                );
              }}
            />
          )}
          <SelectTemplateDialog
            open={createDocumentOpen}
            onClose={dialogsToggle.createDocumentDialog.close}
          />
        </>
      )}
      <FullPageLoader
        open={!allLoaded}
        classes={{ root: classes.loader }}
        transitionDuration={{ enter: 100, exit: 300 }}
      />
    </>
  );
};

export default DrivePage;
