import i18n from 'src/helpers/i18n';
import { SNACKBAR_VARIANT } from 'src/models/common.model';
import billingConstants from '../constants/billing.constants';
import billingService from '../services/billing.service';
import snackbarActions from './snackbar.actions';

const billingActions = {
  checkAccessToWorkspace,
  estimateSubscriptionPrice,
  initializeCustomerCard,
  subscribePlan,
  unsubscribePlan,
  reactiveSubscribePlan,
  getInvoiceUserData,
  addPromotionCode,
  clearStore,
};

function checkAccessToWorkspace(workspaceId) {
  return (dispatch) => {
    dispatch({ type: billingConstants.FETCH_ACCESS_TO_WORKSPACE_REQUEST });
    billingService
      .checkAccessToWorkspace(workspaceId)
      .then((response) => {
        dispatch({
          type: billingConstants.FETCH_ACCESS_TO_WORKSPACE_SUCCESS,
          accessToWorkspace: !!response.data,
        });
      })
      .catch((error) => {
        const noAccess = error.code === 'NO_ACCESS_ERROR';
        const hasAnotherServerError = !noAccess;
        dispatch({
          type: billingConstants.FETCH_ACCESS_TO_WORKSPACE_FAILURE,
          accessToWorkspace: !noAccess,
        });

        if (hasAnotherServerError) {
          dispatch(
            snackbarActions.enqueueSnackbar(
              SNACKBAR_VARIANT.ERROR,
              i18n.t('snackbars.common.errorTitle'),
              `${i18n.t('snackbars.common.error')} ${error.code}.`
            )
          );
        }
      });
  };
}

function estimateSubscriptionPrice(workspaceId, discountCode) {
  return (dispatch) => {
    dispatch({ type: billingConstants.ESTIMATE_SUBSCRIPTION_PRICE_REQUEST });
    billingService
      .estimateSubscriptionPrice(workspaceId, discountCode)
      .then((response) => {
        dispatch({
          type: billingConstants.ESTIMATE_SUBSCRIPTION_PRICE_SUCCESS,
          data: response.data,
        });

        if (response.data?.discount) {
          dispatch(
            snackbarActions.enqueueSnackbar(
              SNACKBAR_VARIANT.SUCCESS,
              i18n.t('snackbars.common.successTitle'),
              i18n.t('paymentMethod.discountCode.toast.success')
            )
          );
        }
      })
      .catch((error) => {
        const couponNotFound = error.code === 'NOT_FOUND';

        dispatch({
          type: billingConstants.ESTIMATE_SUBSCRIPTION_PRICE_FAILURE,
          couponNotFound: discountCode !== '' && couponNotFound,
        });

        if (couponNotFound) {
          dispatch(
            snackbarActions.enqueueSnackbar(
              SNACKBAR_VARIANT.ERROR,
              i18n.t('snackbars.common.errorTitle'),
              i18n.t('paymentMethod.discountCode.toast.error')
            )
          );
        } else {
          dispatch(
            snackbarActions.enqueueSnackbar(
              SNACKBAR_VARIANT.ERROR,
              i18n.t('snackbars.common.errorTitle'),
              `${i18n.t('snackbars.common.error')} ${error.code}.`
            )
          );
        }
      });
  };
}

function initializeCustomerCard(workspaceId, callbackOnSuccess) {
  return (dispatch) => {
    dispatch({ type: billingConstants.INITIALIZE_CUSTOMER_CARD_REQUEST });
    billingService
      .initializeCustomerCard(workspaceId)
      .then((response) => {
        dispatch({
          type: billingConstants.INITIALIZE_CUSTOMER_CARD_SUCCESS,
        });
        if (typeof callbackOnSuccess === 'function') {
          dispatch(() => callbackOnSuccess(response.data?.clientSecret));
        }
      })
      .catch((error) => {
        dispatch({ type: billingConstants.INITIALIZE_CUSTOMER_CARD_FAILURE });
        dispatch(
          snackbarActions.enqueueSnackbar(
            SNACKBAR_VARIANT.ERROR,
            i18n.t('snackbars.common.errorTitle'),
            `${i18n.t('snackbars.common.error')} ${error.code}.`
          )
        );
      });
  };
}

function subscribePlan(workspaceId, subscribePlan, taxId, discountCode, callbackOnSuccess) {
  return (dispatch) => {
    dispatch({ type: billingConstants.SUBSCRIBE_PLAN_REQUEST });
    billingService
      .subscribePlan(workspaceId, subscribePlan, taxId, discountCode)
      .then(() => {
        dispatch({
          type: billingConstants.SUBSCRIBE_PLAN_SUCCESS,
        });
        dispatch(
          snackbarActions.enqueueSnackbar(
            SNACKBAR_VARIANT.SUCCESS,
            i18n.t('snackbars.common.successTitle'),
            i18n.t('paymentMethod.toast.success')
          )
        );
        if (typeof callbackOnSuccess === 'function') {
          dispatch(callbackOnSuccess);
        }
      })
      .catch((error) => {
        const internalError = error?.code === 'INTERNAL_ERROR';
        const taxIdError = error?.message.includes('Invalid value for');
        const hasTaxIdError = internalError && taxIdError;

        dispatch({ type: billingConstants.SUBSCRIBE_PLAN_FAILURE, taxIdError: hasTaxIdError });

        if (!hasTaxIdError) {
          dispatch(
            snackbarActions.enqueueSnackbar(
              SNACKBAR_VARIANT.ERROR,
              i18n.t('snackbars.common.errorTitle'),
              i18n.t('paymentMethod.toast.error')
            )
          );
        } else {
          dispatch(
            snackbarActions.enqueueSnackbar(
              SNACKBAR_VARIANT.ERROR,
              i18n.t('snackbars.common.errorTitle'),
              i18n.t('paymentMethod.toast.taxIdError')
            )
          );
        }
      });
  };
}

function unsubscribePlan(workspaceId) {
  return (dispatch) => {
    dispatch({ type: billingConstants.UNSUBSCRIBE_PLAN_REQUEST });
    billingService
      .unsubscribePlan(workspaceId)
      .then(() => {
        dispatch({
          type: billingConstants.UNSUBSCRIBE_PLAN_SUCCESS,
        });
        dispatch(
          snackbarActions.enqueueSnackbar(
            SNACKBAR_VARIANT.SUCCESS,
            i18n.t('snackbars.common.successTitle'),
            i18n.t('paymentMethod.unsubscribe.toast.success')
          )
        );
      })
      .catch((error) => {
        dispatch({ type: billingConstants.UNSUBSCRIBE_PLAN_FAILURE });
        dispatch(
          snackbarActions.enqueueSnackbar(
            SNACKBAR_VARIANT.ERROR,
            i18n.t('snackbars.common.errorTitle'),
            `${i18n.t('snackbars.common.error')} ${error.code}.`
          )
        );
      });
  };
}

function reactiveSubscribePlan(workspaceId) {
  return (dispatch) => {
    dispatch({ type: billingConstants.REACTIVE_SUBSCRIBE_PLAN_REQUEST });
    billingService
      .reactiveSubscribePlan(workspaceId)
      .then(() => {
        dispatch({
          type: billingConstants.REACTIVE_SUBSCRIBE_PLAN_SUCCESS,
        });
        dispatch(
          snackbarActions.enqueueSnackbar(
            SNACKBAR_VARIANT.SUCCESS,
            i18n.t('snackbars.common.successTitle'),
            i18n.t('paymentMethod.reactivate.toast.success')
          )
        );
      })
      .catch((error) => {
        dispatch({ type: billingConstants.REACTIVE_SUBSCRIBE_PLAN_FAILURE });
        dispatch(
          snackbarActions.enqueueSnackbar(
            SNACKBAR_VARIANT.ERROR,
            i18n.t('snackbars.common.errorTitle'),
            `${i18n.t('snackbars.common.error')} ${error.code}.`
          )
        );
      });
  };
}

function getInvoiceUserData(workspaceId) {
  return (dispatch) => {
    dispatch({ type: billingConstants.FETCH_INVOICE_USER_DATA_REQUEST });
    billingService
      .getInvoiceUserData(workspaceId)
      .then((response) => {
        dispatch({
          type: billingConstants.FETCH_INVOICE_USER_DATA_SUCCESS,
          data: response.data,
        });
      })
      .catch((e) => {
        dispatch({ type: billingConstants.FETCH_INVOICE_USER_DATA_FAILURE });
        if (e.code !== 'NOT_FOUND') {
          dispatch(
            snackbarActions.enqueueSnackbar(
              SNACKBAR_VARIANT.ERROR,
              i18n.t('snackbars.common.errorTitle'),
              i18n.t('paymentMethod.invoice.toast.error')
            )
          );
        }
      });
  };
}

function addPromotionCode(workspaceId, promotionCode) {
  return (dispatch) => {
    dispatch({ type: billingConstants.ADD_PROMOTION_CODE_REQUEST });
    billingService
      .addPromotionCode(workspaceId, promotionCode)
      .then((response) => {
        dispatch({
          type: billingConstants.ADD_PROMOTION_CODE_SUCCESS,
          data: response.data,
        });
        dispatch(
          snackbarActions.enqueueSnackbar(
            SNACKBAR_VARIANT.SUCCESS,
            i18n.t('snackbars.common.successTitle'),
            i18n.t('paymentMethod.discountCode.toast.success')
          )
        );
      })
      .catch(() => {
        dispatch({ type: billingConstants.ADD_PROMOTION_CODE_FAILURE });
        dispatch(
          snackbarActions.enqueueSnackbar(
            SNACKBAR_VARIANT.ERROR,
            i18n.t('snackbars.common.errorTitle'),
            i18n.t('paymentMethod.discountCode.toast.error')
          )
        );
      });
  };
}

function clearStore() {
  return { type: billingConstants.CLEAR_BILLING_STORE };
}

export default billingActions;
