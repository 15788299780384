import certificateCreatorConstants from '../constants/certificateCreator.constants';
import documentDSLServices from '../services/documentDSL.service';
import filesModuleService from '../services/filesModule.service';
import identityActions from './identity.actions';
import { generateUUID } from '../../helpers/globalUtils';
import snackbarActions from './snackbar.actions';
import { SNACKBAR_VARIANT } from '../../models/common.model';
import i18n from '../../helpers/i18n';

const setCreatorStep = ({ currentStep }) => (dispatch) =>
  dispatch({
    type: certificateCreatorConstants.SET_CREATOR_STEP,
    payload: currentStep,
  });

const setCreatorStepBack = () => (dispatch) => {
  dispatch({
    type: certificateCreatorConstants.SET_CREATOR_STEP_BACK,
  });
};

const setSelectedTemplate = (template) => (dispatch) =>
  dispatch({
    type: certificateCreatorConstants.SET_SELECTED_TEMPLATE_ID,
    payload: template,
  });

const setQuantityType = ({ type }) => (dispatch) =>
  dispatch({
    type: certificateCreatorConstants.SET_CERTIFICATE_QUANTITY_TYPE,
    payload: type,
  });

const setRawImportedData = ({ importedData }) => (dispatch) =>
  dispatch({
    type: certificateCreatorConstants.SET_RAW_IMPORTED_DATA,
    payload: importedData,
  });

const setDraftFromImportedData = () => (dispatch) =>
  dispatch({
    type: certificateCreatorConstants.SET_DRAFT_FROM_IMPORTED_DATA,
  });

const updateDraftImportedData = ({ index, key, value }) => (dispatch) =>
  dispatch({
    type: certificateCreatorConstants.UPDATE_DRAFT_IMPORTED_DATA,
    payload: { index, key, value },
  });

const updateDraftSingleFields = (field) => (dispatch) =>
  dispatch({
    type: certificateCreatorConstants.UPDATE_DRAFT_SINGLE_FIELDS,
    payload: field,
  });

const addNewRowToDraftImportedData = () => (dispatch) =>
  dispatch({
    type: certificateCreatorConstants.ADD_NEW_ROW_TO_DRAFT_IMPORTED_DATA,
  });

const updateSelectedDraftImportedData = (selectedIds) => (dispatch) =>
  dispatch({
    type: certificateCreatorConstants.UPDATE_SELECTED_DRAFT_IMPORTED_DATA,
    payload: selectedIds,
  });

const deleteSelectedDraftImportedData = () => (dispatch) =>
  dispatch({
    type: certificateCreatorConstants.DELETED_SELECTED_DRAFT_IMPORTED_DATA,
  });

const setMappedFields = ({ keyValuePair }) => (dispatch) =>
  dispatch({
    type: certificateCreatorConstants.SET_MAPPED_FIELDS,
    payload: keyValuePair,
  });

const fetchTemplateList = (workspaceId) => (dispatch) => {
  dispatch({ type: certificateCreatorConstants.FETCH_TEMPLATE_LIST_REQUEST });
  documentDSLServices
    .fetchTemplateList(workspaceId)
    .then((response) => {
      dispatch({
        type: certificateCreatorConstants.FETCH_TEMPLATE_LIST_SUCCESS,
        payload: response.data.data,
      });
    })
    .catch(() => {
      dispatch({ type: certificateCreatorConstants.FETCH_TEMPLATE_LIST_FAILURE });
    });
};

const fetchTemplateListThumbnailToken = ({ template, workspaceId }) => (dispatch) => {
  dispatch({ type: certificateCreatorConstants.FETCH_TEMPLATE_LIST_THUMBNAIL_TOKEN_REQUEST });

  documentDSLServices
    .fetchTemplateListThumbnailToken({
      workspaceId,
      templateThumbnailId: template.thumbnailPreviewId,
      templateId: template.id,
    })
    .then((response) => {
      dispatch({
        type: certificateCreatorConstants.FETCH_TEMPLATE_LIST_THUMBNAIL_TOKEN_SUCCESS,
        payload: { [template.id]: response.data.data.token },
      });
    })
    .catch(() => {
      dispatch({ type: certificateCreatorConstants.FETCH_TEMPLATE_LIST_THUMBNAIL_TOKEN_FAILURE });
    });
};

const fetchTemplatePreview = ({ template, workspaceId }) => (dispatch) => {
  dispatch({
    type: certificateCreatorConstants.FETCH_TEMPLATE_PREVIEW_REQUEST,
  });
  documentDSLServices
    .fetchTemplateListThumbnailToken({
      workspaceId,
      templateThumbnailId: template.previewId,
      templateId: template.id,
    })
    .then((response) => {
      filesModuleService
        .downloadFile(response.data.data.token)
        .then((response) => {
          dispatch({
            type: certificateCreatorConstants.FETCH_TEMPLATE_PREVIEW_SUCCESS,
            payload: window.URL.createObjectURL(response.data),
          });
        })
        .catch((error) => {
          dispatch({
            type: certificateCreatorConstants.FETCH_TEMPLATE_PREVIEW_FAILURE,
            payload: error,
          });
        });
    })
    .catch((error) => {
      dispatch({
        type: certificateCreatorConstants.FETCH_TEMPLATE_PREVIEW_FAILURE,
        payload: error,
      });
    });
};

const fetchTemplateDetails = ({ templateId, workspaceId }) => (dispatch) => {
  dispatch({ type: certificateCreatorConstants.FETCH_TEMPLATE_FIELDS_REQUEST });
  documentDSLServices
    .fetchTemplateDetails({ workspaceId, templateId })
    .then((response) => {
      dispatch({
        type: certificateCreatorConstants.FETCH_TEMPLATE_FIELDS_SUCCESS,
        payload: response.data.data,
      });
    })
    .catch(() => {
      dispatch({ type: certificateCreatorConstants.FETCH_TEMPLATE_FIELDS_FAILURE });
    });
};

const createCertificate = ({ templateId, workspaceId, certificateList }) => (dispatch) => {
  dispatch({ type: certificateCreatorConstants.CREATE_NEW_CERTIFICATE_FILE_REQUEST });
  const additionalFiles = [];
  certificateList.forEach((certificate) => {
    if (certificate.additionalFiles && certificate.additionalFiles.length > 0) {
      additionalFiles.push({ additionalFilesRandomId: generateUUID() });
    }
  });

  documentDSLServices
    .createCertificate({ workspaceId, templateId, certificateList })
    .then((response) => {
      dispatch({
        type: certificateCreatorConstants.CREATE_NEW_CERTIFICATE_FILE_SUCCESS,
        payload: [...response.data.data, ...additionalFiles],
      });
    })
    .catch((error) => {
      dispatch(
        snackbarActions.enqueueSnackbar(
          SNACKBAR_VARIANT.ERROR,
          i18n.t('snackbars.common.errorTitle'),
          `${i18n.t('snackbars.common.error')} ${error.code}.`
        )
      );
      dispatch({ type: certificateCreatorConstants.CREATE_NEW_CERTIFICATE_FILE_FAILURE });
    });
};

const createCertificateTransaction = ({
  workspaceId,
  workflowName,
  documentAttributes,
  folderId,
  callback,
}) => (dispatch) => {
  const body = {
    workflowName,
    documentAttributes,
  };
  dispatch({ type: certificateCreatorConstants.CREATE_NEW_CERTIFICATE_REQUEST });
  documentDSLServices
    .sendCertificateDocuments({ workspaceId, body, folderId })
    .then((response) => {
      dispatch({ type: certificateCreatorConstants.CREATE_NEW_CERTIFICATE_SUCCESS });
      if (response) {
        const txn = response?.data?.data?.txn;
        if (txn) {
          dispatch(identityActions.sendTransactionFromNewCertificate(txn, callback));
        }
      }
    })
    .catch(() => {
      dispatch({ type: certificateCreatorConstants.CREATE_NEW_CERTIFICATE_FAILURE });
    });
};

const updateNewCertificateFileCreatedList = (data) => (dispatch) =>
  dispatch({
    type: certificateCreatorConstants.UPDATE_NEW_CERTIFICATE_FILE_CREATED_LIST,
    payload: data,
  });

const updateCertificateRoles = ({ workspaceId, admins, documentIds, editors, viewers }) => (
  dispatch
) => {
  dispatch({ type: certificateCreatorConstants.UPDATE_CERTIFICATE_ROLES_REQUEST });
  documentDSLServices
    .updateCertificateRoles({ workspaceId, admins, documentIds, editors, viewers })
    .then((response) => {
      dispatch({ type: certificateCreatorConstants.UPDATE_CERTIFICATE_ROLES_SUCCESS });
      if (response) {
        const txn = response?.data?.data?.txn;
        if (txn) {
          dispatch(identityActions.sendTransactionFromNewCertificate(txn));
          dispatch(
            snackbarActions.enqueueSnackbar(
              SNACKBAR_VARIANT.SUCCESS,
              i18n.t('snackbars.common.successTitle'),
              i18n.t('inviteToDocument.invitationsRolesSendSuccess')
            )
          );
        }
      }
    })
    .catch((error) => {
      dispatch({ type: certificateCreatorConstants.UPDATE_CERTIFICATE_ROLES_FAILURE });
      dispatch(
        snackbarActions.enqueueSnackbar(
          SNACKBAR_VARIANT.ERROR,
          i18n.t('snackbars.common.errorTitle'),
          `${i18n.t('snackbars.common.error')} ${error.code}.`
        )
      );
    });
};

const updateCertificateSigners = ({ workspaceId, documentIds, signers, isOrderSignificant }) => (
  dispatch
) => {
  dispatch({ type: certificateCreatorConstants.UPDATE_CERTIFICATE_SIGNERS_REQUEST });
  documentDSLServices
    .updateCertificateSigner({ workspaceId, documentIds, signers, isOrderSignificant })
    .then((response) => {
      dispatch({ type: certificateCreatorConstants.UPDATE_CERTIFICATE_SIGNERS_SUCCESS });
      if (response) {
        const txn = response?.data?.data?.txn;
        if (txn) {
          dispatch(identityActions.sendTransactionFromNewCertificate(txn));

          dispatch(
            snackbarActions.enqueueSnackbar(
              SNACKBAR_VARIANT.SUCCESS,
              i18n.t('snackbars.common.successTitle'),
              i18n.t('inviteToDocument.invitationsSendSuccess')
            )
          );
        }
      }
    })
    .catch((error) => {
      dispatch({ type: certificateCreatorConstants.UPDATE_CERTIFICATE_SIGNERS_FAILURE });
      dispatch(
        snackbarActions.enqueueSnackbar(
          SNACKBAR_VARIANT.ERROR,
          i18n.t('snackbars.common.errorTitle'),
          `${i18n.t('snackbars.common.error')} ${error.code}.`
        )
      );
    });
};

const inviteMultipleByRole = ({ collection }) => (dispatch) => {
  dispatch({ type: certificateCreatorConstants.INVITE_MULTIPLE_HOLDERS_REQUEST });
  documentDSLServices
    .inviteMultipleByRole(collection)
    .then((response) => {
      if (response) {
        const txnCollection = response?.data?.data?.collection;

        if (txnCollection) {
          txnCollection.forEach((item) => {
            dispatch(identityActions.sendTransactionFromNewCertificate(item.txn));

            dispatch(
              snackbarActions.enqueueSnackbar(
                SNACKBAR_VARIANT.SUCCESS,
                i18n.t('snackbars.common.successTitle'),
                i18n.t('inviteToDocument.invitationsRolesSendSuccess')
              )
            );
          });

          dispatch({ type: certificateCreatorConstants.INVITE_MULTIPLE_HOLDERS_SUCCESS });
        }
      }
    })
    .catch((error) => {
      dispatch({ type: certificateCreatorConstants.INVITE_MULTIPLE_HOLDERS_FAILURE });
      dispatch(
        snackbarActions.enqueueSnackbar(
          SNACKBAR_VARIANT.ERROR,
          i18n.t('snackbars.common.errorTitle'),
          `${i18n.t('snackbars.common.error')} ${error.code}.`
        )
      );
    });
};

const setCurrentLocation = (currentLocation) => (dispatch) =>
  dispatch({
    type: certificateCreatorConstants.SET_CURRENT_LOCATION,
    payload: currentLocation,
  });

const setMultipleHolders = (holders) => (dispatch) =>
  dispatch({
    type: certificateCreatorConstants.SET_MULTIPLE_HOLDERS,
    payload: holders,
  });

const addCreatedCertificates = (documentId) => (dispatch) =>
  dispatch({
    type: certificateCreatorConstants.ADD_CREATED_CERTIFICATE,
    payload: documentId,
  });

const clearStore = () => (dispatch) => dispatch({ type: certificateCreatorConstants.CLEAR });
const resetForNewTemplate = () => (dispatch) =>
  dispatch({ type: certificateCreatorConstants.CLEAR });

const certificateCreatorActions = {
  setCreatorStep,
  setCreatorStepBack,
  setSelectedTemplate,
  setQuantityType,
  setRawImportedData,
  setDraftFromImportedData,
  updateDraftImportedData,
  addNewRowToDraftImportedData,
  updateSelectedDraftImportedData,
  deleteSelectedDraftImportedData,
  updateDraftSingleFields,
  setMappedFields,
  fetchTemplateList,
  fetchTemplateListThumbnailToken,
  fetchTemplatePreview,
  fetchTemplateDetails,
  setCurrentLocation,
  createCertificate,
  createCertificateTransaction,
  updateNewCertificateFileCreatedList,
  updateCertificateRoles,
  updateCertificateSigners,
  setMultipleHolders,
  inviteMultipleByRole,
  clearStore,
  resetForNewTemplate,
  addCreatedCertificates,
};

export default certificateCreatorActions;
