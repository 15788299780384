import { Drive as DriveIcon, ViewBoxes, Wallet } from 'src/components/CustomIcon';
import React from 'react';

export const Links = (t, full) =>
  full
    ? [
        {
          label: t('workspacePage.drawer.basic'),
          url: '/workspace-settings/basic',
          icon: <ViewBoxes />,
          children: [
            {
              label: t('workspacePage.drawer.basicSummary'),
              url: '/workspace-settings/basic/summary',
            },
            {
              label: t('workspacePage.drawer.basicSettings'),
              url: '/workspace-settings/basic/settings',
            },
          ],
        },
        {
          label: t('workspacePage.drawer.userManagement'),
          url: '/workspace-settings/user-management',
          icon: <DriveIcon />,
          children: [
            {
              label: t('workspacePage.drawer.userManagementUser'),
              url: '/workspace-settings/user-management/users',
            },
            {
              label: t('workspacePage.drawer.userManagementGroup'),
              url: '/workspace-settings/user-management/groups',
            },
          ],
        },
        {
          label: t('workspacePage.drawer.subscriptionsAndBilling'),
          url: '/workspace-settings/subscriptions-and-billing',
          icon: <Wallet />,
          children: [
            {
              label: t('workspacePage.drawer.subscriptionsAndBillingOverview'),
              url: '/workspace-settings/subscriptions-and-billing/overview',
            },
            {
              label: t('workspacePage.drawer.subscriptionsAndBillingBillingHistory'),
              url: '/workspace-settings/subscriptions-and-billing/billing-history',
            },
            {
              label: t('workspacePage.drawer.subscriptionsAndBillingContacts'),
              url: '/workspace-settings/subscriptions-and-billing/contacts',
            },
            {
              label: t('workspacePage.drawer.subscriptionsAndBillingPaymentMethods'),
              url: '/workspace-settings/subscriptions-and-billing/payment-methods',
            },
          ],
        },
      ]
    : [
        {
          label: t('workspacePage.drawer.basic'),
          url: '/workspace-settings/basic',
          icon: <ViewBoxes />,
          children: [
            {
              label: t('workspacePage.drawer.basicSettings'),
              url: '/workspace-settings/basic/settings',
            },
          ],
        },
        {
          label: t('workspacePage.drawer.userManagement'),
          url: '/workspace-settings/user-management',
          icon: <DriveIcon />,
          children: [
            {
              label: t('workspacePage.drawer.userManagementUser'),
              url: '/workspace-settings/user-management/users',
            },
            {
              label: t('workspacePage.drawer.userManagementGroup'),
              url: '/workspace-settings/user-management/groups',
            },
          ],
        },
        {
          label: t('workspacePage.drawer.subscriptionsAndBilling'),
          url: '/workspace-settings/subscriptions-and-billing',
          icon: <Wallet />,
          children: [
            {
              label: t('workspacePage.drawer.subscriptionsAndBillingOverview'),
              url: '/workspace-settings/subscriptions-and-billing/manage',
            },
          ],
        },
      ];

export default Links;
