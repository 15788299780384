import React, { useEffect, useState } from 'react';
import {
  CircularProgress,
  Drawer,
  Tabs,
  Tab,
  Typography,
  useMediaQuery,
  Box,
  Button,
  LinearProgress,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import usePrevious from 'src/helpers/hooks/usePrevious';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import communicationsActions from 'src/redux/actions/communications.actions';
import { RequestStatus } from 'src/helpers/reduxReuquest.util';
import {
  Close as CloseIcon,
  Email as EmailIcon,
  Notification as NotificationIcon,
} from '../CustomIcon';
import { ContentContainer, HeaderButton, TopBarContainer } from '../index';
import {
  HeaderSidenavNotificationsItem,
  HeaderSidenavNotificationsTabPanel,
} from './subcomponents';

import { useStyles } from './styles';

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  };
}

const AppSidenavNotifications = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const interval = Number(process.env.REACT_APP_NOTIFICATION_INTERVAL) || 5;
  const INTERVAL_TIMEOUT = interval * 1000;
  const desktopScreen = useMediaQuery(({ breakpoints }) => breakpoints.up('sm'));

  const [open, setOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(0);

  const fetchSystemMessagesStatus = useSelector(
    (state) => state.communicationsStore.fetchSystemMessagesStatus
  );
  const systemMessages = useSelector((state) => state.communicationsStore.systemMessages);
  const setSystemAllMessagesAsRead = useSelector(
    (state) => state.communicationsStore.setSystemAllMessagesAsRead
  );

  const prevState = usePrevious({ fetchSystemMessagesStatus });

  const deleteLoading = setSystemAllMessagesAsRead === RequestStatus.PENDING;
  const lengthOfSystemMessages = systemMessages.filter((item) => item.read === false).length;
  const isAnySystemMessages = lengthOfSystemMessages > 0;

  const groupedSystemMessages = {
    todaySystemMessages: isAnySystemMessages
      ? systemMessages.filter((message) => moment(message.createdAt).isSame(moment(), 'day'))
      : [],
    earlierSystemMessages: isAnySystemMessages
      ? systemMessages.filter((message) => !moment(message.createdAt).isSame(moment(), 'day'))
      : [],
  };

  useEffect(() => {
    dispatch(communicationsActions.getUserNotificationSystemMessages());
    const interval = setInterval(() => {
      if (fetchSystemMessagesStatus !== RequestStatus.PENDING) {
        dispatch(communicationsActions.getUserNotificationSystemMessages());
      }
    }, INTERVAL_TIMEOUT);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [INTERVAL_TIMEOUT, dispatch]);

  const handleDrawerToggle = {
    open: () => {
      setOpen(true);
    },
    close: () => {
      setOpen(false);
    },
  };

  const handleChangeTab = (event, value) => {
    setActiveTab(value);
  };

  const setAllAsRead = () => {
    dispatch(communicationsActions.setAllNotificationSystemMessagesAsRead());
  };

  const renderHeader = (text) => (
    <div className={classes.header}>
      <Typography variant="subtitle2">{text}</Typography>
    </div>
  );

  const loading =
    prevState?.fetchSystemMessagesStatus === RequestStatus.IDLE &&
    fetchSystemMessagesStatus === RequestStatus.PENDING;
  const failedFetchNotifications = !loading && fetchSystemMessagesStatus === RequestStatus.ERROR;

  return (
    <>
      <HeaderButton
        icon={<NotificationIcon />}
        onClick={handleDrawerToggle.open}
        badge={isAnySystemMessages}
        badgeContent={isAnySystemMessages ? `+${lengthOfSystemMessages}` : null}
        tooltip={t('notifications.header')}
      />
      <Drawer
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor="right"
        open={open}
        onClose={handleDrawerToggle.close}
      >
        <div className={classes.list} role="presentation">
          <TopBarContainer className={classes.top}>
            <Typography variant="h6">
              <strong>{t('notifications.header')}</strong>
            </Typography>
            <HeaderButton
              className={classes.closeButton}
              color="primary"
              size="medium"
              onClick={handleDrawerToggle.close}
              icon={<CloseIcon />}
              tooltip={t('common.close')}
            />
          </TopBarContainer>

          <ContentContainer vertical={false} className={classes.mainContent}>
            <div className={classes.tabs}>
              <Tabs
                value={activeTab}
                onChange={handleChangeTab}
                variant={desktopScreen ? 'standard' : 'fullWidth'}
              >
                <Tab
                  label={t('notifications.tabs.direct')}
                  classes={{
                    root: classes.tab,
                    selected: classes.selectedTab,
                  }}
                  icon={<EmailIcon />}
                  {...a11yProps(0)}
                />
                {/* <Tab
                  label={t('notifications.tabs.lastChanges')}
                  classes={{
                    root: classes.tab,
                    selected: classes.selectedTab,
                  }}
                  icon={<LastChangesIcon />}
                  {...a11yProps(1)}
                /> */}
              </Tabs>
            </div>

            <HeaderSidenavNotificationsTabPanel
              value={activeTab}
              index={0}
              className={classes.tabPanel}
            >
              {loading ? (
                <div className={classes.noContent}>
                  <CircularProgress />
                </div>
              ) : (
                <>
                  {failedFetchNotifications && !isAnySystemMessages ? (
                    <Box textAlign="center" className={classes.noContent}>
                      <CircularProgress />
                      <Typography align="center" color="textSecondary">
                        {t('notifications.errorFetch')}
                      </Typography>
                    </Box>
                  ) : (
                    <>
                      {isAnySystemMessages ? (
                        <>
                          <div className={classes.clearButtonWrapper}>
                            <Button
                              size="small"
                              onClick={setAllAsRead}
                              variant="text"
                              classes={{ root: classes.clearButton }}
                            >
                              {t('notifications.setAsRead.setAll')}
                            </Button>
                          </div>

                          {groupedSystemMessages.todaySystemMessages?.length ? (
                            <>
                              {renderHeader(t('notifications.from.today'))}

                              <div className={classes.listNotifications}>
                                {groupedSystemMessages.todaySystemMessages.map((item, index) => (
                                  <HeaderSidenavNotificationsItem
                                    key={index}
                                    notification={item}
                                    live
                                    className={classes.listItem}
                                  />
                                ))}
                              </div>
                            </>
                          ) : null}

                          {groupedSystemMessages.earlierSystemMessages?.length ? (
                            <>
                              {renderHeader(t('notifications.from.earlier'))}

                              <div className={classes.listNotifications}>
                                {groupedSystemMessages.earlierSystemMessages.map((item, index) => (
                                  <HeaderSidenavNotificationsItem
                                    key={index}
                                    notification={item}
                                    live
                                    className={classes.listItem}
                                  />
                                ))}
                              </div>
                            </>
                          ) : null}
                        </>
                      ) : (
                        <div className={classes.noContent}>
                          <Typography variant="h6" align="center" color="textSecondary">
                            {t('notifications.noNotifications')}
                          </Typography>
                        </div>
                      )}
                      {deleteLoading && <LinearProgress />}
                    </>
                  )}
                </>
              )}
            </HeaderSidenavNotificationsTabPanel>
          </ContentContainer>
        </div>
      </Drawer>
    </>
  );
};

export default AppSidenavNotifications;
