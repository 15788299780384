import { fade } from '@material-ui/core/styles';
import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  ({ breakpoints, custom, mixins, palette, spacing, transitions, typography }) => {
    const { minHeight: toolbarH } = mixins.toolbar;
    const { leftMenuWidth: menuWidth } = custom;

    return createStyles({
      drawerRoot: {
        width: menuWidth,
        maxWidth: '100%',
      },
      drawerPaper: {
        width: 'inherit',
        maxWidth: 'inherit',
        overflowY: 'unset',
        color: palette.primary.contrastText,
        background:
          'radial-gradient(68.43% 21.5% at 57.2% 95.99%, #cacaca 0%, rgba(18, 18, 49, 0) 100%), radial-gradient(158.97% 59.54% at 50% 0%, #cecece 0%, rgba(21, 19, 47, 0) 100%), #004B45',
        backgroundBlendMode: 'overlay, overlay, normal',
      },
      topContent: {
        ...mixins.toolbar,
      },
      mainContent: {
        flex: 1,
        width: '100%',
        overflow: 'auto',
      },
      bottomContent: {},
      logo: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: toolbarH / 2 + spacing(1),
        '& $imageContainer + p': {
          display: 'block',
          marginTop: spacing(0.5),
        },
      },
      image: {
        display: 'block',
        width: 'auto',
        maxWidth: '100%',
        maxHeight: spacing(6),
        margin: '0 auto',
      },
      imageContainer: {
        width: '100%',
      },
      addButton: {
        marginTop: spacing(2),
        paddingLeft: spacing(1),
        paddingRight: spacing(1),
        [breakpoints.up('lg')]: {
          marginTop: spacing(3),
        },
        '&.Mui-disabled': {
          backgroundColor: palette.primary.main,
          color: palette.common.white,
          opacity: 0.6,
        },
      },
      menuDesktop: {
        marginTop: spacing(1),
      },
      menuButton: {
        marginRight: spacing(3),
        [breakpoints.up('sm')]: {
          display: 'none',
        },
      },
      navLink: {
        background: 'transparent',
        width: '100%',
      },
      navItem: {
        color: 'inherit !important',
        fontWeight: typography.fontWeightRegular,
        '&:hover, &:focus, &:active': {
          '& $navItemIcon': {
            color: fade(palette.primary.contrastText, palette.tonalOffset * 3),
          },
        },
        '&$navItemActive': {
          '& $navItemIcon': {
            color: `${palette.primary.light} !important`,
          },
        },
      },
      navItemActive: {
        fontWeight: typography.fontWeightMedium,
      },
      navItemText: {
        fontSize: '1.1rem',
      },
      navItemIcon: {
        color: fade(palette.primary.contrastText, 0.25),
        transition: transitions.create('all', {
          duration: transitions.duration.shorter,
          easing: transitions.easing.easeOut,
        }),
      },
    });
  },
  { index: 0 }
);
