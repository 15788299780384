import { fade } from '@material-ui/core/styles';
import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  ({ breakpoints, palette, shadows, shape, spacing, transitions, typography }) =>
    createStyles({
      certIcon: {
        color: '#2575ff',
        display: 'flex',
      },
      dataTableWrapper: {
        '&:not(:last-child)': {
          marginBottom: spacing(4),
          '& $noItems': {
            marginBottom: spacing(2),
          },
          [breakpoints.up('md')]: {
            marginBottom: spacing(2.5),
          },
          [breakpoints.up('lg')]: {
            marginBottom: spacing(4),
          },
        },
      },
      container: {
        width: `calc(100% + ${spacing(1)}px)`,
        margin: `0 ${spacing(-0.5)}px`,
        [breakpoints.up('sm')]: {
          marginLeft: spacing(-1),
          marginRight: spacing(-1),
          width: `calc(100% + ${spacing(2)}px)`,
        },
        [breakpoints.up('md')]: {
          marginLeft: spacing(-1.5),
          marginRight: spacing(-1.5),
          width: `calc(100% + ${spacing(3)}px)`,
        },
      },
      tilesContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        [breakpoints.up('xs')]: {
          marginBottom: spacing(-2),
        },
      },
      header: {
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        backgroundColor: palette.background.paper,
        paddingTop: spacing(0.5),
        paddingLeft: spacing(0.5),
        paddingRight: spacing(0.5),
        minHeight: 48,
        [breakpoints.up('sm')]: {
          paddingLeft: spacing(1),
          paddingRight: spacing(1),
        },
        [breakpoints.up('md')]: {
          minHeight: 64,
          paddingTop: spacing(1),
          paddingLeft: spacing(1.5),
          paddingRight: spacing(1.5),
        },
      },
      headerWrapper: {},
      headerCell: {
        lineHeight: 1.2,
        '& *': {
          lineHeight: 'inherit !important',
          textAlign: 'inherit !important',
        },
      },
      headerEndElement: {
        height: 2,
        width: 32,
        minWidth: 32,
      },
      title: {
        minWidth: 24,
      },
      circularLoader: {
        display: 'block',
        margin: `${spacing(1)}px auto`,
      },
      headerTitle: {
        fontWeight: typography.fontWeightBold,
        fontSize: typography.h4.fontSize,
        color: palette.common.black,
        '& span': {
          fontWeight: 'inherit',
          fontSize: 'inherit',
          color: 'inherit',
        },
      },
      itemRowContent: {},
      hasItemBeforeRowContent: {
        marginLeft: '0 !important',
        [breakpoints.up('md')]: {
          marginLeft: `${spacing(1)}px !important`,
        },
      },
      hasItemAfterRowContent: {
        [breakpoints.up('md')]: {
          marginRight: `${spacing(1)}px !important`,
        },
      },
      sortable: {
        '& span': {
          userSelect: 'none',
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
        },
      },
      sortArrow: {
        marginLeft: 1,
        width: 24,
        minWidth: 24,
        height: 24,
        minHeight: 24,
        marginTop: -4,
        marginBottom: -4,
        borderRadius: '50%',
        transition: transitions.create(['background-color', 'color'], {
          duration: transitions.duration.short,
        }),
        '& svg': {
          display: 'block',
          width: 'inherit',
          height: 'inherit',
        },
        '&:hover, &:focus': {
          backgroundColor: palette.action.hover,
        },
      },
      sortActive: {
        '& $sortArrow': {
          '& svg': {
            color: palette.primary.main,
          },
        },
      },
      itemRow: {
        display: 'flex',
        alignItems: 'center',
        minHeight: 48,
        padding: spacing(1.75, 0.5),
        borderRadius: shape.borderRadius * 0.5,
        marginBottom: 1,
        width: '100%',
        [breakpoints.up('md')]: {
          padding: spacing(0.5, 1.5),
        },
        '& .MuiFab-root': {
          backgroundColor: palette.common.white,
        },
        '& .titleCell': {
          fontWeight: typography.fontWeightMedium,
          transition: transitions.create(['color'], {
            duration: transitions.duration.short,
          }),
        },
        '&:not($itemTileRow)': {
          '& [class^="IconCell-iconCell-"], & [class*="IconCell-iconCell-"]': {
            '& svg': {
              [breakpoints.down('xs')]: {
                width: 32,
                height: 32,
              },
            },
          },
        },
        /* '& [class^="DataTableRow-iconCellAuto-"], & [class*="DataTableRow-iconCellAuto-"]': {
          minWidth: 'auto !important',
        }, */
      },
      itemRowSelected: {
        backgroundColor: fade(palette.primary.light, 0.15),
      },
      draggingRow: {
        opacity: 0.5,
      },
      itemTileRow: {
        justifyContent: 'left',
        border: `1px solid ${palette.divider}`,
        borderRadius: shape.borderRadius,
        padding: spacing(2, 1.5),
        '&:not(:last-child)': {
          marginBottom: spacing(1),
        },
        '&:hover': {
          boxShadow: shadows[6],
        },
        '& span': {
          lineHeight: 1.3,
        },
        [breakpoints.up('xs')]: {
          width: `calc(50% - ${spacing(1)}px)`,
          marginBottom: `${spacing(2)}px !important`,
          marginRight: spacing(0.5),
          marginLeft: spacing(0.5),
        },
        [breakpoints.up('sm')]: {
          width: `calc(33.333333% - ${spacing(1)}px)`,
          marginBottom: `${spacing(2)}px !important`,
          marginRight: spacing(0.5),
          marginLeft: spacing(0.5),
        },
      },
      clickableRow: {
        cursor: 'default',
        transition: transitions.create(['background-color', 'color'], {
          duration: transitions.duration.standard,
        }),
        '&:hover:not($itemRowSelected)': {
          backgroundColor: fade(palette.primary.light, 0.075),
          '& .titleCell': {
            color: palette.primary.main,
          },
        },
      },
      cell: {
        paddingTop: spacing(0.25),
        paddingBottom: spacing(0.25),
        lineHeight: 1.2,
        '& *': {
          lineHeight: 'inherit !important',
        },
      },
      iconCell: {
        display: 'flex',
        justifyContent: 'center',
        [breakpoints.down('sm')]: {
          minWidth: 'auto !important',
        },
      },
      iconCellAuto: {
        [breakpoints.up('md')]: {
          minWidth: '40px !important',
        },
      },
      statusCell: {
        fontWeight: typography.fontWeightMedium,
        '& span': {
          display: 'inline-flex',
          alignItems: 'center',
        },
        '& svg': {
          fontSize: 16,
          marginLeft: spacing(0.5),
        },
        '&.CREATED': {
          color: palette.secondary.dark,
        },
        '&.signed': {
          color: palette.primary.main,
          '& svg': {
            color: palette.primary.light,
          },
        },
        '&$mobileCell': {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          paddingLeft: spacing(1),
          minWidth: 160,
          [breakpoints.down('xs')]: {
            minWidth: 120,
          },
        },
      },
      timeAgoCell: {},
      mobileCell: {},
      badge: {
        marginTop: -4,
        marginBottom: -4,
        display: 'inline-flex',
      },
      tag: {
        minWidth: '100px !important',
        justifyContent: 'center',
      },
      dropBackground: {
        backgroundColor: palette.primary.lightest,
      },
      noItems: {},
    }),
  { index: 0 }
);
