import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  ({ breakpoints, spacing }) =>
    createStyles({
      root: {
        '&:not(:last-child)': {
          paddingBottom: spacing(4),
          [breakpoints.up('lg')]: {
            paddingBottom: spacing(5),
          },
        },
      },
      title: {
        lineHeight: 1.3,
      },
      titleWithSpace: {
        marginBottom: spacing(1),
        [breakpoints.up('sm')]: {
          marginBottom: spacing(1.5),
        },
      },
    }),
  { index: 0 }
);
