import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  ({ breakpoints, spacing }) =>
    createStyles({
      root: {
        width: '100%',
        [breakpoints.up('sm')]: {
          paddingTop: spacing(1.15),
          paddingBottom: spacing(1.15),
        },
        maxWidth: breakpoints.values.md,
      },
    }),
  { index: 0 }
);
