import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  ({ breakpoints, palette, spacing, typography }) =>
    createStyles({
      root: {},
      content: {},
      inputs: {
        '& > *:not(:last-child)': {
          marginBottom: spacing(2),
        },
      },
      cardErrorRoot: {
        alignItems: 'center',
        padding: `${spacing(1)}px ${spacing(2)}px`,
        marginBottom: spacing(1),
        lineHeight: 1.4,
        '& $cardErrorIcon, & $cardErrorMessage': {
          padding: 0,
        },
      },
      cardErrorIcon: {
        marginTop: -1,
        marginBottom: -1,
        fontSize: spacing(2.5),
      },
      cardErrorMessage: {
        fontSize: typography.body2.fontSize,
      },
      cardContainer: {
        background: palette.action.hover,
        border: `2px solid ${palette.bottomLineColor}`,
      },
      cardHeader: {
        [breakpoints.up('lg')]: {
          paddingTop: spacing(3),
          paddingLeft: spacing(3),
          paddingRight: spacing(3),
        },
      },
      cardHeaderIcon: {
        display: 'flex',
      },
      cardContent: {
        [breakpoints.up('lg')]: {
          padding: spacing(3),
          '&:last-child': {
            paddingBottom: spacing(3),
          },
        },
      },
      checkboxRoot: {
        marginLeft: '-8px !important',
      },
      checkboxFormControlRoot: {
        marginRight: 0,
      },
      checkboxFormControlLabel: {
        fontSize: typography.subtitle2.fontSize,
        color: palette.text.secondary,
        textAlign: 'justify',
      },
      checkedCheckboxFormControlLabel: {
        color: palette.primary.main,
      },
      limitedAccessInfo: {
        marginBottom: spacing(3),
      },
    }),
  { index: 0 }
);
