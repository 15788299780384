import React from 'react';
import PropTypes from 'prop-types';
import { Box, LinearProgress, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { useStyles } from './styles';

const LinearProgressWithLabel = ({ text, size, color, labelPosition, value }) => {
  const largeSize = size === 'large';
  const classes = useStyles();
  const { root, colorPrimary, bar, barColorPrimary } = classes;

  const propsProgress = {
    classes: {
      root,
      colorPrimary,
      bar,
      barColorPrimary: clsx(barColorPrimary, {
        [classes[`${String(color)}BarColorPrimary`]]: color,
      }),
    },
    value,
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      className={clsx(classes.box, largeSize && classes.boxLarge)}
    >
      {text && (
        <Typography variant="body2" align={labelPosition} className={classes.label} component="div">
          {text}
        </Typography>
      )}
      <Box width="100%">
        <LinearProgress
          className={clsx(largeSize && classes.large)}
          variant="determinate"
          {...propsProgress}
        />
      </Box>
    </Box>
  );
};

LinearProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
  text: PropTypes.string,
  labelPosition: PropTypes.oneOf(['left', 'center', 'right']),
  size: PropTypes.oneOf(['default', 'large']),
  color: PropTypes.oneOf(['primary', 'warning', 'error']),
};

LinearProgressWithLabel.defaultProps = {
  labelPosition: 'left',
  size: 'default',
  color: 'primary',
};

export default LinearProgressWithLabel;
