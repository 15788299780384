import React, { useRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';

const StripeInput = (props) => {
  const { component: Component, inputRef, ...other } = props;
  const elementRef = useRef();

  useImperativeHandle(inputRef, () => ({
    focus: () => elementRef.current.focus,
  }));

  return (
    <Component
      onReady={(element) => {
        elementRef.current = element;
      }}
      {...other}
    />
  );
};

StripeInput.propTypes = {
  inputRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  component: PropTypes.elementType,
};

export default StripeInput;
