import { EmptyBanner, SmallContentWithScrollbar } from 'src/components';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { LinearProgress } from '@material-ui/core';
import certificateCreatorActions from 'src/redux/actions/certificateCreator.actions';
import { CertificateQuantityType, CertificateSteps } from 'src/models/certificate.model';
import { useStyles } from './styles';

const CreateFilesForCertProgressScreen = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { generatedCertFileList, sentForGeneratingList, certificateQuantityType } = useSelector(
    (state) => state.certificateCreatorStore,
    undefined
  );
  const classes = useStyles();
  const progress =
    sentForGeneratingList.length === 0
      ? 0
      : (generatedCertFileList.length / sentForGeneratingList.length) * 100;

  useEffect(() => {
    if (progress === 100) {
      if (certificateQuantityType === CertificateQuantityType.Single) {
        dispatch(
          certificateCreatorActions.setCreatorStep({
            currentStep: CertificateSteps.UploadAdditionalDocuments,
          })
        );
      } else if (certificateQuantityType === CertificateQuantityType.Multiple) {
        dispatch(
          certificateCreatorActions.setCreatorStep({
            currentStep: CertificateSteps.ChooseLocation,
          })
        );
      }
    }
  }, [dispatch, certificateQuantityType, progress]);

  const title =
    certificateQuantityType === CertificateQuantityType.Multiple
      ? t('createCertificate.generatingCertFilesTitle')
      : t('createCertificate.generatingCertFileTitle');

  const description =
    certificateQuantityType === CertificateQuantityType.Multiple
      ? t('createCertificate.generatingCertFilesDescription')
      : t('createCertificate.generatingCertFileDescription');

  return (
    <SmallContentWithScrollbar
      classes={{ root: classes.root, content: classes.content }}
      scrollableContent
      size="large"
    >
      <EmptyBanner
        src="/images/frames/bc-added.svg"
        title={title}
        description={description}
        bottomContent={
          <div className={classes.progressWrapper}>
            {certificateQuantityType === CertificateQuantityType.Single ? (
              <LinearProgress />
            ) : (
              <LinearProgress variant="determinate" value={progress} />
            )}
          </div>
        }
      />
    </SmallContentWithScrollbar>
  );
};

export default CreateFilesForCertProgressScreen;
