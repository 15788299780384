enum billingConstants {
  ESTIMATE_SUBSCRIPTION_PRICE_REQUEST = 'ESTIMATE_SUBSCRIPTION_PRICE_REQUEST',
  ESTIMATE_SUBSCRIPTION_PRICE_SUCCESS = 'ESTIMATE_SUBSCRIPTION_PRICE_SUCCESS',
  ESTIMATE_SUBSCRIPTION_PRICE_FAILURE = 'ESTIMATE_SUBSCRIPTION_PRICE_FAILURE',

  FETCH_ACCESS_TO_WORKSPACE_REQUEST = 'FETCH_ACCESS_TO_WORKSPACE_REQUEST',
  FETCH_ACCESS_TO_WORKSPACE_SUCCESS = 'FETCH_ACCESS_TO_WORKSPACE_SUCCESS',
  FETCH_ACCESS_TO_WORKSPACE_FAILURE = 'FETCH_ACCESS_TO_WORKSPACE_FAILURE',

  INITIALIZE_CUSTOMER_CARD_REQUEST = 'INITIALIZE_CUSTOMER_CARD_REQUEST',
  INITIALIZE_CUSTOMER_CARD_SUCCESS = 'INITIALIZE_CUSTOMER_CARD_SUCCESS',
  INITIALIZE_CUSTOMER_CARD_FAILURE = 'INITIALIZE_CUSTOMER_CARD_FAILURE',

  SUBSCRIBE_PLAN_REQUEST = 'SUBSCRIBE_PLAN_REQUEST',
  SUBSCRIBE_PLAN_SUCCESS = 'SUBSCRIBE_PLAN_SUCCESS',
  SUBSCRIBE_PLAN_FAILURE = 'SUBSCRIBE_PLAN_FAILURE',

  UNSUBSCRIBE_PLAN_REQUEST = 'UNSUBSCRIBE_PLAN_REQUEST',
  UNSUBSCRIBE_PLAN_SUCCESS = 'UNSUBSCRIBE_PLAN_SUCCESS',
  UNSUBSCRIBE_PLAN_FAILURE = 'UNSUBSCRIBE_PLAN_FAILURE',

  REACTIVE_SUBSCRIBE_PLAN_REQUEST = 'REACTIVE_SUBSCRIBE_PLAN_REQUEST',
  REACTIVE_SUBSCRIBE_PLAN_SUCCESS = 'REACTIVE_SUBSCRIBE_PLAN_SUCCESS',
  REACTIVE_SUBSCRIBE_PLAN_FAILURE = 'REACTIVE_SUBSCRIBE_PLAN_FAILURE',

  FETCH_INVOICE_USER_DATA_REQUEST = 'FETCH_INVOICE_USER_DATA_REQUEST',
  FETCH_INVOICE_USER_DATA_SUCCESS = 'FETCH_INVOICE_USER_DATA_SUCCESS',
  FETCH_INVOICE_USER_DATA_FAILURE = 'FETCH_INVOICE_USER_DATA_FAILURE',

  ADD_PROMOTION_CODE_REQUEST = 'ADD_PROMOTION_CODE_REQUEST',
  ADD_PROMOTION_CODE_SUCCESS = 'ADD_PROMOTION_CODE_SUCCESS',
  ADD_PROMOTION_CODE_FAILURE = 'ADD_PROMOTION_CODE_FAILURE',

  CLEAR_BILLING_STORE = 'CLEAR_BILLING_STORE',
}

export default billingConstants;
