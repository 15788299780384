import React from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from '@material-ui/core';
import { InviteToDocument, FullScreenDialog } from 'src/components';

const InvitationsManagement = ({ open, canInviteToSigners, handleClose }) => {
  const smallScreen = useMediaQuery(({ breakpoints }) => breakpoints.down('sm'));

  return (
    <FullScreenDialog isOpen={open} onClose={handleClose} withLogo={!smallScreen}>
      {open ? (
        <InviteToDocument returnToDocument={handleClose} canInviteToSigners={canInviteToSigners} />
      ) : null}
    </FullScreenDialog>
  );
};

InvitationsManagement.propTypes = {
  open: PropTypes.bool,
  canInviteToSigners: PropTypes.bool,
  handleClose: PropTypes.func,
};

InvitationsManagement.defaultProps = {
  canInviteToSigners: true,
};

export default InvitationsManagement;
