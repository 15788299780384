import { DocumentRole, DocumentStatus, DocumentType } from 'src/models/document.model';
import { documentManagementSelector } from './documentManagement.selector';

const isDocumentExpired = (state) =>
  state.documentDisplayStore.document?.status === DocumentStatus.EXPIRED;

const isDocumentRejected = (state) =>
  state.documentDisplayStore.document?.status === DocumentStatus.REJECTED;

const isCertificate = (state) =>
  state.documentDisplayStore.document?.documentType === DocumentType.CERTIFICATES;

const isDocumentFinished = (state) =>
  state.documentDisplayStore.document?.status === DocumentStatus.FINISHED;

const isDocumentSigning = (state) =>
  state.documentDisplayStore.document?.status === DocumentStatus.SIGNING;

const isDocumentIssuing = (state) =>
  state.documentDisplayStore.document?.status === DocumentStatus.ISSUING;

const isDocumentEditing = (state) =>
  state.documentDisplayStore.document?.status === DocumentStatus.EDITING;

const isDocumentValid = (state) =>
  state.documentDisplayStore.document?.status === DocumentStatus.VALID;

const isDocumentTempInvalidated = (state) =>
  state.documentDisplayStore.document?.status === DocumentStatus.TEMPORARILY_INVALIDATE;

const isDocumentInvalidated = (state) =>
  state.documentDisplayStore.document?.status === DocumentStatus.INVALIDATED;

const canRejectDocument = (state) => {
  const isDocumentAdmin = documentManagementSelector.isDocumentAdmin(state);
  const isDocumentExpired = documentDisplaySelector.isDocumentExpired(state);
  const isDocumentFinished = documentDisplaySelector.isDocumentFinished(state);
  const isDocumentInvalidated = documentDisplaySelector.isDocumentInvalidated(state);
  const isDocumentRejected = documentDisplaySelector.isDocumentRejected(state);
  const isDocumentTempInvalidated = documentDisplaySelector.isDocumentTempInvalidated(state);
  const isDocumentValid = documentDisplaySelector.isDocumentValid(state);

  return (
    ([DocumentType.DOCUMENT_TO_BE_SIGNED, DocumentType.CERTIFICATES].includes(
      state.documentDisplayStore.document?.documentType
    ) &&
      isDocumentAdmin &&
      !isDocumentExpired &&
      !isDocumentFinished &&
      !isDocumentInvalidated &&
      !isDocumentRejected &&
      !isDocumentTempInvalidated &&
      !isDocumentValid) ||
    false
  );
};

const hasUserSignedDocument = (state) => {
  const { documentMembers } = state.documentManagementStore;
  const activeIdentity = state.identityStore.currentIdentity;

  const getCurrentUserSignerFromMembers = documentMembers.find(
    (key) => key.id === activeIdentity?.identity?.identityId && key.role === DocumentRole.SIGNERS
  );

  return getCurrentUserSignerFromMembers !== undefined
    ? getCurrentUserSignerFromMembers?.status === 'SIGNED'
    : false;
};

const canSign = (state) => {
  const isDocumentRejected = documentDisplaySelector.isDocumentRejected(state);
  const isDocumentFinished = documentDisplaySelector.isDocumentFinished(state);
  const isDocumentSigner = documentManagementSelector.isDocumentSigner(state);
  const isDocumentValid = documentDisplaySelector.isDocumentValid(state);
  const isDocumentTempInvalidated = documentDisplaySelector.isDocumentTempInvalidated(state);
  const isDocumentInvalidated = documentDisplaySelector.isDocumentInvalidated(state);
  const isDocumentExpired = documentDisplaySelector.isDocumentExpired(state);
  const isMemberSignedDocument = documentDisplaySelector.hasUserSignedDocument(state);

  return (
    ([DocumentType.DOCUMENT_TO_BE_SIGNED, DocumentType.CERTIFICATES].includes(
      state.documentDisplayStore.document?.documentType
    ) &&
      !isDocumentRejected &&
      !isDocumentFinished &&
      !isDocumentTempInvalidated &&
      !isDocumentInvalidated &&
      !isDocumentExpired &&
      !isDocumentValid &&
      isDocumentSigner &&
      !isMemberSignedDocument) ||
    false
  );
};

const selectDocumentExpiresOn = (state) =>
  state.documentDisplayStore.document?.additionalAttributes?.expiresOn;

export const documentDisplaySelector = {
  canRejectDocument,
  canSign,
  hasUserSignedDocument,
  isCertificate,
  isDocumentEditing,
  isDocumentFinished,
  isDocumentInvalidated,
  isDocumentIssuing,
  isDocumentRejected,
  isDocumentExpired,
  isDocumentSigning,
  isDocumentTempInvalidated,
  isDocumentValid,
  selectDocumentExpiresOn,
};
