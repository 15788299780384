import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import documentActions from 'src/redux/actions/documents.actions';
import { Autocomplete } from '@material-ui/lab';
import { AppBar, CircularProgress, Dialog, Paper, TextField } from '@material-ui/core';
import { HeaderButton, TopBarContainer } from 'src/components';
import { RequestStatus } from 'src/helpers/reduxReuquest.util';
import { ArrowLeftSLine as BackIcon, Search as SearchIcon } from 'src/components/CustomIcon';
import { FileColsConfig, FolderColsConfig } from 'src/helpers/fileAndFolderColsConfig';
import DataTableHeader from '../DataTable/DataTableHeader';
import { HeaderSearchItem } from './subcomponents';
import { useStyles } from './styles';

const PaperComponent = ({ children }) => <Paper elevation={16}>{children}</Paper>;
PaperComponent.propTypes = {
  children: PropTypes.node,
};

const HeaderSearch = ({ showOpenButton, smallScreen, iconWidth }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [didMount, setDidMount] = useState(false);
  const [open, setOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(false);
  const [isSearchActive, setSearchActive] = useState(false);

  useEffect(() => {
    setDidMount(true);
    return () => setDidMount(false);
  }, []);

  const toggleSearchActive = () => {
    if (smallScreen) {
      setSearchActive(!isSearchActive);
    } else {
      setSearchActive(false);
    }
  };

  const documentsSearchFetchStatus = useSelector(
    (state) => state.documentsStore.documentsSearchFetchStatus
  );
  const foldersSearchFetchStatus = useSelector(
    (state) => state.documentsStore.foldersSearchFetchStatus
  );

  const foldersColConfig = FolderColsConfig(t);
  const filesColConfig = FileColsConfig(t);

  const workspace = useSelector((state) => state.identityStore);
  const workspaceId = workspace.currentIdentity?.workspace?.workspaceId;
  const searchedList = useSelector((state) => {
    const documents = state.documentsStore.searchedDocuments.map((item) => {
      item.category = 'documents';
      return item;
    });
    const folders = state.documentsStore.searchedFolders.map((item) => {
      item.category = 'folders';
      return item;
    });
    return folders.concat(documents).filter((item) => item.name !== 'trash' && item.name !== '/');
  });

  useEffect(() => {
    if (searchTerm.length > 0) {
      setLoading(true);
    } else {
      setLoading(false);
      setOpen(false);
    }
    if (searchTerm.length > 0) {
      dispatch(documentActions.getDocumentsBySearchQuery(workspaceId, searchTerm));
      dispatch(documentActions.getFoldersBySearchQuery(workspaceId, searchTerm));
    }
  }, [searchTerm, workspaceId, dispatch]);

  useEffect(() => {
    if (
      documentsSearchFetchStatus === RequestStatus.PENDING ||
      foldersSearchFetchStatus === RequestStatus.PENDING
    ) {
      setOpen(true);
      setLoading(true);
    }
    if (
      documentsSearchFetchStatus === RequestStatus.SUCCESS ||
      foldersSearchFetchStatus === RequestStatus.SUCCESS
    ) {
      setOpen(true);
      setLoading(false);
    } else if (
      documentsSearchFetchStatus === RequestStatus.ERROR &&
      foldersSearchFetchStatus === RequestStatus.ERROR
    ) {
      setOpen(true);
      setLoading(false);
    }
  }, [documentsSearchFetchStatus, foldersSearchFetchStatus]);

  useEffect(() => {
    if (loading) {
      setOpen(true);
    }
  }, [loading]);

  const onTagsChange = (event, newValue) => {
    if (newValue) {
      const docUrlPrefix = newValue?.documentType === 'dsl' ? 'certificate' : 'document';
      history.push(
        `/${
          Object.prototype.hasOwnProperty.call(newValue, 'folderName') ? docUrlPrefix : 'drive'
        }/${newValue?.id}`
      );
    }
  };

  const getRowParams = (type) => {
    switch (type) {
      case 'folders':
        return {
          title: t('header.searchFoldersGroup'),
          cols: foldersColConfig,
          icon: 'folder',
        };

      default:
      case 'documents':
        return {
          title: t('header.searchDocumentsGroup'),
          cols: filesColConfig,
          icon: 'file',
        };
    }
  };

  const renderGroup = (params) => {
    const groupParams = getRowParams(params.group);

    return [
      <DataTableHeader
        key={params.key}
        className={clsx(classes.groupHeader, smallScreen && classes.smallScreenGroupHeader)}
        title={groupParams.title}
        cols={groupParams.cols}
        titleVariant="subtitle2"
        mediumTitleVariant="subtitle2"
        withTableHeader
        iconWidth={iconWidth}
        disableTitle={false}
        smallScreen={smallScreen}
        rightSpace={false}
      />,
      params.children,
    ];
  };

  const renderOption = (option) => {
    const groupParams = getRowParams(option.category);

    return (
      <HeaderSearchItem
        item={option}
        smallScreen={smallScreen}
        cols={groupParams.cols}
        iconWidth={iconWidth}
        defaultIcon={groupParams.icon}
      />
    );
  };

  const renderInput = (params) => (
    <TextField
      {...params}
      id="search-input"
      placeholder={t('header.searchItems')}
      fullWidth
      variant={smallScreen ? 'standard' : 'outlined'}
      onChange={(e) => setSearchTerm(e.target.value)}
      InputProps={{
        ...params.InputProps,
        endAdornment: (
          <>
            {loading && (
              <CircularProgress
                size={smallScreen ? /* 40 : 32 */ 32 : 24}
                className={classes.loader}
              />
            )}
            {params.InputProps.endAdornment}
          </>
        ),
        startAdornment: smallScreen && (
          <HeaderButton
            onClick={toggleSearchActive}
            icon={<BackIcon />}
            className={classes.backButton}
            tooltip={t('common.back')}
          />
        ),
      }}
    />
  );
  const renderAutocomplete = () => (
    <Autocomplete
      classes={{
        inputRoot: clsx(classes.inputRoot, smallScreen && classes.smallScreenInputRoot),
        input: clsx(smallScreen && classes.smallScreenInput),
        popper: clsx(smallScreen && classes.smallScreenPopper),
        popperDisablePortal: clsx(
          classes.popperDisablePortal,
          smallScreen && classes.smallScreenPopperDisablePortal
        ),
        paper: clsx(smallScreen && classes.smallScreenPaper),
        listbox: clsx(classes.listBox, smallScreen && classes.smallScreenListBox),
        noOptions: clsx(smallScreen && classes.smallScreenNoOptionsAndLoading),
        loading: clsx(smallScreen && classes.smallScreenNoOptionsAndLoading),
        endAdornment: clsx(classes.endAdornment, smallScreen && classes.smallScreenEndAdornment),
        clearIndicator: clsx(
          classes.clearIndicator,
          smallScreen && classes.smallScreenClearIndicator
        ),
        hasClearIcon: clsx(classes.hasClearIcon, smallScreen && classes.smallScreenHasClearIcon),
        option: classes.option,
      }}
      open={open}
      options={searchedList}
      loading={loading}
      onClose={() => {
        setOpen(false);
      }}
      groupBy={(option) => option.category}
      getOptionLabel={(option) => option.name}
      onChange={(event, newValue) => onTagsChange(event, newValue)}
      renderGroup={renderGroup}
      renderOption={renderOption}
      renderInput={renderInput}
      clearOnBlur
      fullWidth
      disablePortal
      PaperComponent={smallScreen ? 'div' : PaperComponent}
      loadingText={t('common.loading')}
      clearText={t('common.clear')}
      noOptionsText={t('common.noResults')}
    />
  );

  if (!didMount) {
    return null;
  }
  return (
    <div className={clsx(classes.root, smallScreen && classes.smallScreenRoot)}>
      {smallScreen ? (
        <>
          <Dialog open={isSearchActive} fullScreen>
            <AppBar position="relative" className={classes.appBar}>
              <TopBarContainer horizontal={false}>{renderAutocomplete()}</TopBarContainer>
            </AppBar>
          </Dialog>

          {showOpenButton && (
            <HeaderButton
              onClick={toggleSearchActive}
              icon={<SearchIcon />}
              tooltip={t('common.search')}
            />
          )}
        </>
      ) : (
        renderAutocomplete()
      )}
    </div>
  );
};

HeaderSearch.propTypes = {
  showOpenButton: PropTypes.bool,
  smallScreen: PropTypes.bool,
  iconWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf(['auto'])]),
};

HeaderSearch.defaultProps = {
  iconWidth: 'auto',
  showOpenButton: true,
};

export default HeaderSearch;
