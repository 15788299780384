import i18n from 'src/helpers/i18n';
import { SNACKBAR_VARIANT } from 'src/models/common.model';
import documentsConstants from '../constants/documents.constants';
import documentsService from '../services/documents.service';
import snackbarActions from './snackbar.actions';

const documentsActions = {
  getFolderTree,
  createFolder,
  fetchDocuments,
  getCurrentFolderPath,
  renameFolder,
  removeFolder,
  websocketAddFolder,
  websocketUpdateFolder,
  websocketDeleteFolder,
  websocketMoveDocument,
  websocketCreateDocument,
  websocketUpdateDocument,
  websocketDeleteDocument,
  moveDocuments,
  getDocumentsBySearchQuery,
  getFoldersBySearchQuery,
  clearDocumentsStore,
  fetchAnyDocuments,
  clearFolderPath,
};

function getFolderTree(workspaceId, rootFolderId, depth) {
  return (dispatch) => {
    dispatch({ type: documentsConstants.FETCH_FOLDER_TREE_REQUEST });
    documentsService
      .fetchFolderTree(workspaceId, rootFolderId, depth)
      .then((response) => {
        dispatch({
          type: documentsConstants.FETCH_FOLDER_TREE_SUCCESS,
          folders: response?.data?.collection,
          rootFolderId:
            response?.data?.collection.find((f) => f.isRoot)?.id || rootFolderId || null,
        });
      })
      .catch(() => {
        dispatch({ type: documentsConstants.FETCH_FOLDER_TREE_FAILURE });
      });
  };
}

function getDocumentsBySearchQuery(workspaceId, query, rolesId) {
  return (dispatch) => {
    dispatch({ type: documentsConstants.FETCH_DOCUMENTS_SEARCH_REQUEST });
    documentsService
      .findDocument(workspaceId, query, rolesId)
      .then((response) => {
        dispatch({
          type: documentsConstants.FETCH_DOCUMENTS_SEARCH_SUCCESS,
          documents: response?.data?.collection,
        });
      })
      .catch(() => {
        dispatch({ type: documentsConstants.FETCH_DOCUMENTS_SEARCH_FAILURE });
      });
  };
}

function createFolder(workspaceId, rootFolderId, name) {
  return (dispatch) => {
    dispatch({ type: documentsConstants.CREATE_FOLDER_REQUEST });
    documentsService
      .createFolder(workspaceId, rootFolderId, name)
      .then(() => {
        dispatch({ type: documentsConstants.CREATE_FOLDER_SUCCESS });
        dispatch(
          snackbarActions.enqueueSnackbar(
            SNACKBAR_VARIANT.INFO,
            i18n.t('snackbars.common.successTitle'),
            i18n.t('drivePage.foldersDialog.addNew.toastSuccessText')
          )
        );
      })
      .catch(() => {
        dispatch({ type: documentsConstants.CREATE_FOLDER_FAILURE });
      });
  };
}

function fetchDocuments({
  workspaceId,
  folderId,
  limit = 20,
  offset = 0,
  orderBy,
  sortOrder,
  filterKey,
  filterValue,
}) {
  return (dispatch) => {
    dispatch({ type: documentsConstants.FETCH_DOCUMENTS_REQUEST });
    documentsService
      .fetchDocuments({
        workspaceId,
        folder: folderId,
        limit,
        offset,
        orderBy,
        sortOrder,
        filterKey,
        filterValue,
      })
      .then((response) => {
        dispatch({
          type: documentsConstants.FETCH_DOCUMENTS_SUCCESS,
          documents: response.data.collection,
          hasMoreDocuments: response.data.hasNextPage,
          documentsQuery: response.data.query,
        });
      })
      .catch(() => {
        dispatch({ type: documentsConstants.FETCH_DOCUMENTS_FAILURE });
      });
  };
}

function fetchAnyDocuments(
  workspaceId,
  limit,
  offset,
  orderBy,
  sortOrder,
  append,
  filterKey,
  filterValue,
  rolesId
) {
  return (dispatch) => {
    dispatch({ type: documentsConstants.FETCH_DOCUMENTS_REQUEST, append });
    documentsService
      .fetchAnyDocuments(
        workspaceId,
        limit,
        offset,
        orderBy,
        sortOrder,
        filterKey,
        filterValue,
        rolesId
      )
      .then((response) => {
        dispatch({
          type: documentsConstants.FETCH_DOCUMENTS_SUCCESS,
          documents: response.data.collection,
          hasMoreDocuments: response.data.hasNextPage,
          documentsQuery: response.data.query,
          append,
        });
      })
      .catch(() => {
        dispatch({ type: documentsConstants.FETCH_DOCUMENTS_FAILURE });
      });
  };
}

function getFoldersBySearchQuery(workspaceId, query) {
  return (dispatch) => {
    dispatch({ type: documentsConstants.FETCH_FOLDERS_SEARCH_REQUEST });
    documentsService
      .findFolder(workspaceId, query)
      .then((response) => {
        dispatch({
          type: documentsConstants.FETCH_FOLDERS_SEARCH_SUCCESS,
          folders: response?.data?.collection,
        });
      })
      .catch(() => {
        dispatch({ type: documentsConstants.FETCH_FOLDERS_SEARCH_FAILURE });
      });
  };
}

function getCurrentFolderPath(workspaceId, rootFolderId) {
  return (dispatch) => {
    dispatch({ type: documentsConstants.FETCH_FOLDER_PATH_REQUEST });
    documentsService
      .getFolderPath(workspaceId, rootFolderId)
      .then((response) => {
        dispatch({ type: documentsConstants.FETCH_FOLDER_PATH_SUCCESS, path: response.data });
      })
      .catch(() => {
        dispatch({ type: documentsConstants.FETCH_FOLDER_PATH_FAILURE });
      });
  };
}

function renameFolder(workspaceId, folderId, newFolderName) {
  return (dispatch) => {
    dispatch({ type: documentsConstants.RENAME_FOLDER_REQUEST });
    documentsService
      .renameFolder(workspaceId, folderId, newFolderName)
      .then(() => {
        dispatch({ type: documentsConstants.RENAME_FOLDER_SUCCESS });
      })
      .catch(() => {
        dispatch({ type: documentsConstants.RENAME_FOLDER_FAILURE });
      });
  };
}

function removeFolder(workspaceId, folderId) {
  return (dispatch) => {
    dispatch({ type: documentsConstants.REMOVE_FOLDER_REQUEST });
    documentsService
      .removeFolder(workspaceId, folderId)
      .then(() => {
        dispatch({ type: documentsConstants.REMOVE_FOLDER_SUCCESS });
      })
      .catch(() => {
        dispatch({ type: documentsConstants.REMOVE_FOLDER_FAILURE });
      });
  };
}

function moveDocuments(workspaceId, sourceFolderId, targetFolderId, documents) {
  return (dispatch) => {
    dispatch({ type: documentsConstants.MOVE_DOCUMENT_REQUEST });
    documentsService
      .moveDocument(workspaceId, documents, sourceFolderId, targetFolderId)
      .then(() => {
        dispatch({ type: documentsConstants.MOVE_DOCUMENT_SUCCESS });
      })
      .catch(() => {
        dispatch({ type: documentsConstants.MOVE_DOCUMENT_FAILURE });
      });
  };
}

function websocketAddFolder(folderData) {
  return { type: documentsConstants.WS_ADD_FOLDER, newFolder: folderData };
}

function websocketUpdateFolder(folderData) {
  return { type: documentsConstants.WS_UPDATE_FOLDER, updatedFolder: folderData };
}
function websocketDeleteFolder(folderData) {
  return { type: documentsConstants.WS_DELETE_FOLDER, deletedFolder: folderData };
}
function websocketMoveDocument(currentFolderId, documentData, sortKey, sortOrder) {
  return {
    type: documentsConstants.WS_MOVED_DOCUMENT,
    currentFolderId,
    movedFile: documentData,
    sortKey,
    sortOrder,
  };
}

function websocketCreateDocument(data, sortKey, sortOrder) {
  return { type: documentsConstants.WS_CREATED_DOCUMENT, documentData: data, sortKey, sortOrder };
}

function websocketUpdateDocument(data, sortKey, sortOrder) {
  return { type: documentsConstants.WS_UPDATED_DOCUMENT, documentData: data, sortKey, sortOrder };
}

function websocketDeleteDocument(data) {
  return { type: documentsConstants.WS_DELETE_DOCUMENT, documentData: data };
}

function clearDocumentsStore() {
  return { type: documentsConstants.CLEAR_STORE };
}

function clearFolderPath() {
  return { type: documentsConstants.CLEAR_FOLDER_PATH };
}

export default documentsActions;
