import { fade } from '@material-ui/core/styles';
import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  ({ palette, typography, spacing }) =>
    createStyles({
      root: {
        height: spacing(4) - 1,
        borderRadius: spacing(2),
      },
      large: {
        height: spacing(6) - 1,
        borderRadius: spacing(3),
      },
      colorPrimary: {
        background: fade(palette.action.disabled, 0.75),
      },
      bar: {
        borderRadius: spacing(3),
      },
      barColorPrimary: {},
      primaryBarColorPrimary: {},
      warningBarColorPrimary: {
        backgroundColor: palette.warning.main,
      },
      errorBarColorPrimary: {
        backgroundColor: palette.error.main,
      },
      label: {
        position: 'absolute',
        width: '100%',
        padding: spacing(0, 1.5),
        color: palette.common.white,
        fontWeight: typography.fontWeightMedium,
        zIndex: 2,
        lineHeight: 1.1,
      },
      box: {
        position: 'relative',
      },
      boxLarge: {
        '& $label': {
          padding: spacing(0, 2),
        },
      },
    }),
  { index: 0 }
);
