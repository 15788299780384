import { fade } from '@material-ui/core/styles';
import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  ({ shape, spacing, palette, typography }) =>
    createStyles({
      root: {
        marginTop: spacing(3),
        '& > *:not(:last-child)': {
          marginBottom: spacing(1),
        },
        '& $prices, & $highlightedBox': {
          borderRadius: shape.borderRadius,
        },
      },
      prices: {
        textAlign: 'center',
        padding: spacing(2),
        border: `1px dashed ${palette.bottomLineColor}`,
        backgroundColor: palette.background.default,
        '& > *:not(:last-child)': {
          marginBottom: spacing(1.5),
        },
      },
      oldPrice: {
        textDecoration: 'line-through',
        fontWeight: typography.fontWeightRegular,
      },
      highlightedBox: {
        padding: spacing(1),
        '&:not($highlightedBoxVariant)': {
          backgroundColor: fade(palette.primary.main, palette.action.hoverOpacity),
          color: palette.primary.main,
        },
      },
      highlightedBoxVariant: {
        backgroundColor: palette.success.main,
        color: palette.success.contrastText,
      },
      prizeForAll: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        lineHeight: 1.2,
        paddingLeft: 32 + 2,
        '& h1, & h2, & h3, & h4, & h5, & h6': {
          lineHeight: 'inherit',
        },
        '& button': {
          margin: -6,
          marginLeft: 2,
          '& svg': {
            fontSize: 20,
          },
        },
      },
      priceDetails: {
        textAlign: 'left',
        '& hr': {
          margin: spacing(2, 0),
          backgroundColor: palette.bottomLineColor,
        },
        '& svg': {
          display: 'block',
          marginLeft: 'auto',
          fontSize: 16,
          marginRight: -2,
          color: palette.primary.light,
        },
        '& table': {
          '& tr': {
            '& td': {
              padding: `${spacing(0.75)}px ${spacing(1)}px`,
              border: 'none',
              '&:first-child': {
                color: palette.text.secondary,
              },
              '&:last-child': {
                textAlign: 'right',
              },
            },
          },
        },
      },
      priceDetailsMainItem: {
        fontSize: typography.h4.fontSize,
        color: palette.primary.main,
      },
    }),
  { index: 0 }
);
