import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { Button, CircularProgress, Tooltip, Typography } from '@material-ui/core';
import { Help, MobileDataOff, PublicOff, Share as ShareIcon } from 'src/components/CustomIcon';
import { sortInvitedSigners } from 'src/components/InviteToDocument/helpers';
import { useDispatch, useSelector } from 'react-redux';
import contactsActions from 'src/redux/actions/contacts.actions';
import { RequestStatus } from 'src/helpers/reduxReuquest.util';
import { DocumentRole } from 'src/models/document.model';
import { ImportExportRounded, PublicRounded } from '@material-ui/icons';
import { RecipientsItem, SingersCounter } from './subcomponents';
import { TabHeader } from '../../subcomponents';
import { useStyles } from './styles';
import { prepareDocumentMembers, sortingMembers } from '../../helpers';

const Recipients = ({
  className: classNameProp,
  enableInviting,
  openInvitationsManagementDialog,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [didMount, setDidMount] = useState(false);
  const [getEntityStatus, setGetEntityStatus] = useState(false);
  const [missingContacts, setMissingContacts] = useState([]);
  const [parsedMembers, setParsedMembers] = useState([]);
  const [allMembersAndInvitations, setAllMembersAndInvitations] = useState([]);
  const history = useHistory();

  useEffect(() => {
    setDidMount(true);
    return () => setDidMount(false);
  }, []);

  const {
    documentMembers,
    documentSigners,
    documentInvitations,
    getDocumentMembersStatus,
    getDocumentInvitationsStatus,
  } = useSelector((state) => state.documentManagementStore, undefined);

  const { contacts, contactBookEntitiesFetchStatus } = useSelector(
    (state) => state.contactsStore,
    undefined
  );

  const { document } = useSelector((state) => state.documentDisplayStore);

  const contactsNotFound = [];
  const preparedMembers = prepareDocumentMembers({ documentMembers, contactsNotFound });

  if (JSON.stringify(preparedMembers) !== JSON.stringify(parsedMembers)) {
    setParsedMembers(preparedMembers);
  } else if (
    JSON.stringify(preparedMembers) === JSON.stringify(parsedMembers) &&
    !getEntityStatus
  ) {
    setGetEntityStatus(true);
  }

  useEffect(() => {
    if (getDocumentInvitationsStatus === RequestStatus.SUCCESS && getEntityStatus) {
      const invitationsRender = documentInvitations.map((item) => {
        let itemId = null;
        if (!item.mail && item.role === DocumentRole.HOLDER) {
          item.name = t('common.generatedToken');
          itemId = 'PDF';
        }
        return {
          id: itemId,
          role: item.role,
          status: item.status,
          position: item.role === DocumentRole.SIGNERS ? 2 : 4,
          userData: {
            name: item.name ? item.name : item.mail,
            email: item.mail,
            active: true,
          },
        };
      });

      setAllMembersAndInvitations(sortingMembers(parsedMembers.concat(invitationsRender)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getDocumentInvitationsStatus, parsedMembers]);

  if (JSON.stringify(contactsNotFound) !== JSON.stringify(missingContacts)) {
    setMissingContacts(contactsNotFound);
  }

  const isLoading =
    getDocumentMembersStatus === RequestStatus.IDLE ||
    getDocumentMembersStatus === RequestStatus.PENDING;

  useEffect(() => {
    if (missingContacts.length > 0 && contactBookEntitiesFetchStatus !== RequestStatus.PENDING) {
      dispatch(contactsActions.addMissingToQueue(missingContacts));
    }
  }, [missingContacts, contactBookEntitiesFetchStatus, dispatch, contacts]);

  const [signers, restOfMembers] = allMembersAndInvitations.reduce(
    ([p, f], e) => (e.role === DocumentRole.SIGNERS ? [[...p, e], f] : [p, [...f, e]]),
    [[], []]
  );

  const hasSigners = signers.length > 0;
  const hasRestOfMembers = restOfMembers.length > 0;

  const isCertificate = history.location.pathname.includes('certificate');

  if (!didMount) {
    return null;
  }

  return (
    <div className={clsx(classes.root, classNameProp)}>
      <div className={classes.entriesList}>
        <TabHeader
          label=""
          action={
            enableInviting && (
              <Button
                size="small"
                variant="text"
                color="primary"
                endIcon={<ShareIcon />}
                onClick={openInvitationsManagementDialog}
              >
                {t('documentActions.tabs.recipients.sharingManage')}
              </Button>
            )
          }
          classes={{ root: classes.tabHeader }}
        />
        {isCertificate && (
          <>
            <div className={classes.advancedSettings}>
              <Typography variant="body1" color="textSecondary">
                {t('inviteToDocument.section.advancedSettings.title')}
              </Typography>
            </div>
            {Object.keys(document).length !== 0 && (
              <div className={classes.row}>
                {document?.publicFile ? (
                  <div className={clsx(classes.icon, classes.iconSuccess)}>
                    <PublicRounded />
                  </div>
                ) : (
                  <div className={clsx(classes.icon, classes.iconDefault)}>
                    <PublicOff />
                  </div>
                )}
                {document?.publicFile
                  ? t('documentActions.tabs.recipients.checkboxes.publicAccess.title')
                  : t('documentActions.tabs.recipients.checkboxes.publicAccess.titlePrivate')}

                <Tooltip title={t('documentActions.tabs.recipients.checkboxes.publicAccess.hint')}>
                  <Help className={classes.helpIcon} />
                </Tooltip>
              </div>
            )}
            {document?.additionalAttributes && (
              <div className={classes.row}>
                {document?.additionalAttributes?.transferable ? (
                  <div className={clsx(classes.icon, classes.iconSuccess)}>
                    <ImportExportRounded />
                  </div>
                ) : (
                  <div className={clsx(classes.icon, classes.iconDefault)}>
                    <MobileDataOff />
                  </div>
                )}
                {document?.additionalAttributes?.transferable
                  ? t('documentActions.tabs.recipients.checkboxes.retransferCertificate.title')
                  : t(
                      'documentActions.tabs.recipients.checkboxes.retransferCertificate.titleNonTransferable'
                    )}
                <Tooltip
                  title={t('documentActions.tabs.recipients.checkboxes.retransferCertificate.hint')}
                >
                  <Help className={classes.helpIcon} />
                </Tooltip>
              </div>
            )}
          </>
        )}

        {hasSigners || hasRestOfMembers ? (
          <>
            {hasSigners && (
              <>
                <div className={clsx(classes.entriesDividerText, classes.entriesListTitle)}>
                  <Typography variant="body1" color="textSecondary">
                    {t('documentActions.tabs.recipients.header.signers')}
                  </Typography>
                  <SingersCounter signers={signers} />
                </div>
                <div>
                  {sortInvitedSigners({
                    itemsToSort: signers,
                    sortingArray: documentSigners,
                  }).map((item, index) => (
                    <RecipientsItem
                      key={index}
                      userId={item.id}
                      status={item.status}
                      role={item.role}
                      active={item.active}
                      userData={item.userData}
                    />
                  ))}
                </div>
              </>
            )}

            {hasRestOfMembers && (
              <>
                <Typography
                  variant="body1"
                  color="textSecondary"
                  className={classes.entriesDividerText}
                >
                  {t('documentActions.tabs.recipients.otherRoles')}
                </Typography>
                {restOfMembers.map((item, index) => (
                  <RecipientsItem
                    key={index}
                    userId={item.id}
                    status={item.status}
                    role={item.role}
                    userData={item.userData}
                  />
                ))}
              </>
            )}
          </>
        ) : (
          <div className={classes.noItems}>
            {isLoading ? (
              <CircularProgress />
            ) : (
              <Typography variant="subtitle2" component="span">
                {t('documentActions.tabs.recipients.noItems')}
              </Typography>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

Recipients.propTypes = {
  className: PropTypes.string,
  enableInviting: PropTypes.bool.isRequired,
  openInvitationsManagementDialog: PropTypes.func,
};

export default Recipients;
