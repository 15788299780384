import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { TextField, Typography } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';
import { ExtendedGrid } from 'src/components';
import { taxIdTypes } from './taxIdTypes';
import { useStyles } from './styles';

const TaxIdSelect = ({ className: classNameProp, disabled, error, onChange, onChangeInput }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [countryValue, setCountryValue] = useState(null);
  const [countryInputValue, setCountryInputValue] = useState('');
  const [numberValue, setNumberValue] = useState('');

  const onChangeCountry = (event, newValue) => {
    if (typeof onChange === 'function') {
      onChange({
        taxIdType: newValue?.enum || '',
        value: '',
      });
    }

    setCountryValue(newValue);
    setNumberValue('');
  };

  const onChangeNumber = (event) => {
    const numberValue = event.target.value;
    if (typeof onChange === 'function') {
      onChange({
        taxIdType: countryValue.enum,
        value: `${countryValue?.prefix || ''}${numberValue}${countryValue?.suffix || ''}`,
      });
    }

    setNumberValue(numberValue);
  };

  return (
    <div className={clsx(classes.root, classNameProp)}>
      <Typography gutterBottom>
        <strong>{t('paymentMethod.taxId.header')}</strong>
      </Typography>

      <ExtendedGrid container spacingX={1} alignItemsXxs="flex-end">
        <ExtendedGrid item xxs={6}>
          <Autocomplete
            name="tax_enum"
            disabled={disabled}
            options={taxIdTypes}
            value={countryValue}
            inputValue={countryInputValue}
            onChange={onChangeCountry}
            onInputChange={(event, newInputValue) => {
              setCountryInputValue(newInputValue);
            }}
            getOptionLabel={(option) => option.label || ''}
            getOptionSelected={(option) => option.label === countryValue?.label || ''}
            fullWidth
            blurOnSelect
            clearText={t('common.clear')}
            noOptionsText={t('common.noResults')}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t('paymentMethod.taxId.country.label')}
                placeholder={t('paymentMethod.taxId.country.placeholder')}
                InputLabelProps={{
                  disableAnimation: true,
                  shrink: true,
                }}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'enum', // disable autocomplete and autofill
                }}
              />
            )}
          />
        </ExtendedGrid>
        <ExtendedGrid item xxs={6}>
          <TextField
            name="tax_value"
            label={countryValue?.description}
            disabled={disabled || !countryValue?.enum}
            placeholder={countryValue?.example}
            value={numberValue}
            error={error}
            onChange={(event) => {
              onChangeNumber(event);
              if (typeof onChangeInput === 'function') {
                onChangeInput();
              }
            }}
            InputProps={{
              startAdornment: countryValue?.prefix ? (
                <Typography className={classes.prefix}>{countryValue.prefix}</Typography>
              ) : null,
              endAdornment: countryValue?.suffix ? (
                <Typography className={classes.suffix}>{countryValue.suffix}</Typography>
              ) : null,
            }}
          />
        </ExtendedGrid>
      </ExtendedGrid>
    </div>
  );
};

TaxIdSelect.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  onChange: PropTypes.func,
  onChangeInput: PropTypes.func,
};

export default TaxIdSelect;
