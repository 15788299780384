import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  ({ breakpoints, mixins, palette, spacing, typography }) =>
    createStyles({
      drawerPaper: {
        width: 'inherit',
        maxWidth: 'inherit',
        overflowY: 'unset',
        maxHeight: '100vh',
      },
      top: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        ...mixins.toolbar,
      },
      closeButton: {
        [breakpoints.up('lg')]: {
          marginRight: -12,
        },
      },
      mainContent: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        flex: 1,
        overflowY: 'auto',
        paddingBottom: spacing(1),
        [breakpoints.up('lg')]: {
          paddingBottom: spacing(3),
        },
      },
      list: {
        maxWidth: 510,
        width: '510px',
        overflowY: 'unset',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        [breakpoints.down('xs')]: {
          width: '100vw',
          maxWidth: '100%',
        },
      },
      listTop: {
        marginBottom: spacing(2),
      },
      fullList: {
        width: 'auto',
        maxWidth: 450,
      },
      tabs: {
        background: palette.background.paper,
        zIndex: 2,
        position: 'sticky',
        top: 0,
        left: 0,
        paddingBottom: spacing(1),
        [breakpoints.up('sm')]: {
          marginLeft: spacing(-1),
          marginRight: spacing(-1),
        },
        [breakpoints.up('md')]: {
          marginLeft: spacing(-1.5),
          marginRight: spacing(-1.5),
        },
        [breakpoints.up('lg')]: {
          marginLeft: spacing(-2),
          marginRight: spacing(-2),
        },
        '& $tab:not(:last-child)': {
          borderRight: `1px solid ${palette.divider}`,
        },
      },
      tab: {
        fontSize: typography.subtitle2.fontSize,
      },
      selectedTab: {
        boxShadow: 'none !important',
      },
      header: {
        position: 'sticky',
        top: 48 + spacing(1),
        background: palette.background.paper,
        zIndex: 2,
        left: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        minHeight: 36 + spacing(1),
        marginLeft: spacing(-1),
        marginRight: spacing(-1),
        padding: `${spacing(0.5)}px ${spacing(1)}px`,
      },
      listNotifications: {
        '&:not(:last-child)': {
          paddingBottom: spacing(2),
          [breakpoints.up('md')]: {
            paddingBottom: spacing(3),
          },
        },
      },
      noContent: {
        minHeight: 'inherit',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        '& > *': {
          display: 'block',
          margin: '0 auto',
        },
        '& > *:not(:last-child)': {
          marginBottom: spacing(1.5),
        },
      },
      clearButtonWrapper: {
        position: 'sticky',
        top: 48 + spacing(1),
        zIndex: 3,
      },
      clearButton: {
        position: 'absolute',
        top: 0,
        right: 0,
        minHeight: 36,
        paddingLeft: spacing(1),
        paddingRight: spacing(1),
        marginRight: spacing(-1),
        transform: `translateY(${spacing(0.5)}px)`,
      },
      tabPanel: {
        position: 'relative',
        display: 'flex',
        flex: 1,
        '& > div': {
          minHeight: '100%',
          width: '100%',
        },
      },
    }),
  { index: 0 }
);
