import React, { useState, useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import documentDisplayActions from 'src/redux/actions/documentDisplay.actions';
import documentDownloadActions from 'src/redux/actions/documentDownload.actions';
import documentInvitationActions from 'src/redux/actions/documentInvitiation.actions';
import documentManagementActions from 'src/redux/actions/documentManagement.actions';
import snackbarActions from 'src/redux/actions/snackbar.actions';
import WebSocketHelper from 'src/helpers/WebSocketHelper';
import usePrevious from 'src/helpers/hooks/usePrevious';
import ContactHelper from 'src/helpers/contactHelper';
import { goBack } from 'src/helpers/goBack';
import { Button, CircularProgress, useMediaQuery } from '@material-ui/core';
import { EmptyBanner, HeaderButton } from 'src/components';
import {
  Download as DownloadIcon,
  Archive as ArchiveIcon,
  Close as CloseIcon,
  Info as InfoIcon,
  Time as HistoryIcon,
  Users as RecipientsIcon,
  Visibility as PreviewIcon,
} from 'src/components/CustomIcon';
import { RequestStatus } from 'src/helpers/reduxReuquest.util';
import { SNACKBAR_VARIANT } from 'src/models/common.model';
import { DocumentRole, DocumentType } from 'src/models/document.model';
import { IdentitySelector } from 'src/redux/selectors/identity.selector';
import contactsActions from 'src/redux/actions/contacts.actions';
import { documentDisplaySelector } from 'src/redux/selectors/documentDisplay.selector';
import { documentManagementSelector } from 'src/redux/selectors/documentManagement.selector';
import { moduleName as dslService } from 'src/redux/services/documentDSL.service';
import { moduleName as documentService } from 'src/redux/services/documentCore.service';
import {
  ActionsTabs,
  DocumentContent,
  RightSidebar,
  SignBanner,
  TopBar,
  InvitationsManagement,
} from './subcomponents';
import { RejectDocument, ReloadDocument, SignDocument } from './subcomponents/editDialogs';
import { History, Recipients, Info } from './tabs';
import { getOrderSigningRestrictions, prepareDocumentMembers } from './helpers';
import { SigningBannerAfterTextModal } from './subcomponents/Dialogs';
import { sortInvitedSigners } from '../../components/InviteToDocument/helpers';
import DocumentStatusFrame from './subcomponents/DocumentStatusFrame';
import CertificateActionsWebsocket from './CertificateActionsWebsocket';
import DocumentStatusNotification from '../../components/DocumentStatusNotification';
import { useStyles } from './styles';

const DocumentActionsPage = ({ history }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();

  const isCertificate = history.location.pathname.includes('certificate');
  const apiService = isCertificate ? dslService : documentService;
  const miniDevice = useMediaQuery(({ breakpoints }) => breakpoints.down('xs'));
  const smallDevice = useMediaQuery(({ breakpoints }) => breakpoints.down('sm'));

  const LOADED_PAGES = 3;
  const MAX_COUNT_TRY = 2;

  const LOCAL_STORAGE_CLOSED_INVITATION_DIALOG = 'ever_closed_invitation_dialog';
  const everClosedInvitations = JSON.parse(
    localStorage.getItem(LOCAL_STORAGE_CLOSED_INVITATION_DIALOG) || '[]'
  );
  const [missingContacts, setMissingContacts] = useState([]);
  const [actualizationBannerOpen, setActualizationBannerOpen] = useState(false);
  const [editedSubpage, setEditedSubpage] = useState(null);
  const [fullSidebarHeight, setFullSidebarHeight] = useState(false);
  const [selectedActionTab, setSelectedActionTab] = useState('preview');
  const [didMount, setDidMount] = useState(false);
  const [tryCount, setTryCount] = useState(0);
  const [disabledMenu, setDisabledMenu] = useState(false);
  const [activeFileIndexDocument, setActiveFileIndexDocument] = useState(0);
  const [invitationsManagementDialogOpen, setInvitationsManagementDialogOpen] = useState(false);
  const [isSigningBannerAfterTextModal, setSigningBannerAfterTextModal] = useState(false);

  const { id: documentId } = useParams();
  const activeIdentity = useSelector((state) => state.identityStore.currentIdentity, undefined);
  const {
    getDocumentStatus,
    getDownloadTokenStatus,
    downloadFileStatus,
    activeFileIndex,
    document: documentInfo,
    downloadTokens,
    allFilesLoadedStatus,
    downloadFiles: downloadFilesImage,
  } = useSelector((state) => state.documentDisplayStore, undefined);
  const {
    getDocumentMembersStatus,
    getDocumentMembersByRoleStatus: getDocumentAdminsStatus,
    getDocumentHistoryStatus: documentHistoryStatus,
    documentMembers,
    documentSigners,
    documentInvitations,
    getDocumentInvitationsStatus,
    getDocumentCardStatus,
    rejectingDocumentStatus,
  } = useSelector((state) => state.documentManagementStore, undefined);

  const { fetchDocumentSignersStatus: documentSignersStatus, isOrderSignificant } = useSelector(
    (state) => state.documentInvitationsStore,
    undefined
  );
  const isSigningOrderAvailable = useSelector(
    IdentitySelector.hasSigningOrderPermission,
    undefined
  );
  const { downloadDocuments: downloadDocumentsPending } = useSelector(
    (state) => state.documentDownloadStore,
    undefined
  );
  const { contacts, contactBookEntitiesFetchStatus } = useSelector(
    (state) => state.contactsStore,
    undefined
  );

  const isDocumentAdmin = useSelector(documentManagementSelector.isDocumentAdmin, undefined);
  const isDocumentEditor = useSelector(documentManagementSelector.isDocumentEditor, undefined);

  const isDocumentRejected = useSelector(documentDisplaySelector.isDocumentRejected, undefined);
  const isDocumentFinished = useSelector(documentDisplaySelector.isDocumentFinished, undefined);
  const isDocumentSigning = useSelector(documentDisplaySelector.isDocumentSigning, undefined);
  const canRejectDocument = useSelector(documentDisplaySelector.canRejectDocument, undefined);
  const canSign = useSelector(documentDisplaySelector.canSign, undefined);
  const isRejectInProgress = rejectingDocumentStatus === RequestStatus.PENDING;
  const isLoading = downloadFileStatus === RequestStatus.PENDING;
  const downloadTokenStatusSuccess = getDownloadTokenStatus === RequestStatus.SUCCESS;
  const documentStatusError = getDocumentStatus === RequestStatus.ERROR;
  const documentStatusSuccess = getDocumentStatus === RequestStatus.SUCCESS;
  const documentMembersStatusSuccess = getDocumentMembersStatus === RequestStatus.SUCCESS;
  const isDownloadingDocumentCard = getDocumentCardStatus === RequestStatus.PENDING;
  const documentType = documentInfo?.documentType;
  const invitedSigners = documentInvitations.filter((el) => el.role === DocumentRole.SIGNERS);
  const disableForInvitedSigners = invitedSigners.length > 0 && isCertificate;

  const prevState = usePrevious({
    getDownloadTokenStatus,
    downloadFileStatus,
    downloadFilesImage,
    activeFileIndex,
  });

  const firstRender = useRef(true);

  const availableTabs = {
    previewTab: [
      {
        name: t('documentActions.tabs.preview.title'),
        icon: <PreviewIcon />,
        value: 'preview',
      },
    ],
    basicTabs: [
      {
        name: t('documentActions.tabs.recipients.title'),
        icon: <RecipientsIcon />,
        value: 'recipients',
      },
      {
        name: t('documentActions.tabs.info.title'),
        icon: <InfoIcon />,
        value: 'info',
      },
      {
        name: t('documentActions.tabs.history.title'),
        icon: <HistoryIcon />,
        value: 'history',
      },
    ],
  };

  let subId = false;

  const getDownloadToken = (index) => {
    const indexFile = index || activeFileIndex;
    let fileIndexNext = indexFile;
    const activeFilesCount = downloadFilesImage?.filter(
      (item) => item.indexFile === activeFileIndex
    ).length;
    let pagesSum = activeFilesCount + LOADED_PAGES;

    const filePages = documentInfo.files?.filter((item) => item.index === indexFile)[0]?.pages;

    if (!filePages && !activeFilesCount) {
      addEmptyFileImage(indexFile);
      return;
    }

    if (pagesSum >= filePages) {
      pagesSum = filePages;
    }

    if (pagesSum > activeFilesCount) {
      dispatch(
        documentDisplayActions.getDownloadTokens(
          documentId,
          indexFile,
          activeFilesCount,
          pagesSum,
          apiService
        )
      );
    } else {
      if (indexFile < documentInfo.files.length - 1) {
        fileIndexNext = activeFileIndex + 1;
      } else {
        fileIndexNext = checkLoadedThisDocument(0);
      }
      if (fileIndexNext >= 0) {
        dispatch(documentDisplayActions.setActiveFileIndex(fileIndexNext));
      }
    }
  };

  const checkShowScroll = () => {
    const scrollElement = document.getElementById('scroll-element-file');
    const hasVerticalScrollbar = scrollElement?.scrollHeight > scrollElement?.clientHeight;
    if (
      !hasVerticalScrollbar &&
      activeFileIndex === prevState?.activeFileIndex &&
      prevState?.activeFileIndex >= 0 &&
      getDownloadTokenStatus !== RequestStatus.PENDING &&
      downloadFileStatus !== RequestStatus.PENDING
    ) {
      getDownloadToken();
    }
  };

  const addEmptyFileImage = (indexFile) => {
    dispatch(documentDisplayActions.addEmptyFile(indexFile));
    setDisabledMenu(false);
  };

  const checkLoadedThisDocument = (indexFile) => {
    if (downloadFilesImage.filter((item) => item.indexFile === indexFile).length) {
      return -1;
    }
    return indexFile;
  };

  const changeFileIndex = (index) => {
    if (index === 'prev' && activeFileIndexDocument > 0) {
      index = activeFileIndexDocument - 1;
    } else if (index === 'prev') {
      index = documentInfo.files.length - 1;
    }

    if (index === 'next' && activeFileIndexDocument < documentInfo.files.length - 1) {
      index = activeFileIndexDocument + 1;
    } else if (index === 'next') {
      index = 0;
    }

    if (allFilesLoadedStatus) {
      scrollToDocument(index);
      return;
    }

    dispatch(documentDisplayActions.clearFilesAndTokens(index));
  };

  const scrollToDocument = (index) => {
    const scrollContent = document.getElementById('scroll-element-file');
    if (scrollContent) {
      scrollContent.scrollTop = document.querySelectorAll(`.document-number-${index}`)[0].offsetTop;
      setActiveFileIndexDocument(index);
    }
  };
  const downloadFiles = () => {
    dispatch(
      documentDisplayActions.downloadFiles(
        downloadTokens.filter(
          (token) =>
            token.page >=
              downloadFilesImage.filter((item) => item.indexFile === activeFileIndex).length &&
            token.indexFile === activeFileIndex
        ),
        activeFileIndex
      )
    );
  };

  const reloadFile = () => {
    setTryCount(0);
    dispatch(documentDisplayActions.clearFilesAndTokens(activeFileIndex));
  };

  const downloadDocumentCard = () => {
    dispatch(documentManagementActions.getDocumentCard(documentId));
  };

  const downloadDocument = () => {
    dispatch(documentDownloadActions.downloadDocument(documentId, apiService));
  };

  const handleDocumentUpdate = (message) => {
    if (message.type === 'RESULT') {
      const { id } = message?.data?.result;
      switch (message.code) {
        case 'DOCUMENT_UPDATED':
          if (documentId === id) {
            if (ContactHelper.checkIfUserBelongsToDocument(message.data.result)) {
              dispatch(documentDisplayActions.websocketUpdateDocument(message.data.result));
              dispatch(documentManagementActions.websocketUpdate(message.data.result));
              dispatch(documentDisplayActions.getDocument(id, apiService));
              dispatch(documentManagementActions.getDocumentMembers(id, apiService));
              dispatch(documentManagementActions.getDocumentInvitations(id, apiService));
              dispatch(documentManagementActions.reloadDocumentHistory());
              dispatch(documentInvitationActions.getDocumentSigners(documentId, apiService));
              setActualizationBannerOpen(false);
            } else {
              setActualizationBannerOpen(true);
            }
          }
          break;

        case 'DOCUMENT_FILES_UPDATED':
          if (documentId === id) {
            dispatch(documentDisplayActions.clearFilesAndTokens());
            dispatch(documentDisplayActions.getDocument(id, apiService));
          }
          break;

        case 'DOCUMENT_INVITATION_SET':
          if (documentId === id) {
            dispatch(documentManagementActions.getDocumentMembers(id, apiService));
            dispatch(documentManagementActions.getDocumentInvitations(id, apiService));
          }
          break;

        case 'DOCUMENT_DELETED':
          if (documentId === id) {
            dispatch(
              snackbarActions.enqueueSnackbar(
                'info',
                t('snackbars.common.infoTitle'),
                t('documentActions.onDeletedDocumentRedirect')
              )
            );
            handleClose();
          }
          break;

        default:
          console.warn(`Unknown code [${message.code}]`, message);
          break;
      }
    } else if (message.type === 'ERROR') {
      switch (message.code) {
        case 'DOCUMENT_UPDATED':
        default:
          dispatch(
            snackbarActions.enqueueSnackbar(
              SNACKBAR_VARIANT.ERROR,
              t('snackbars.common.errorTitle'),
              t(
                `drivePage.action.error.${message.code}${
                  message?.data?.result?.code ? `_${message.data.result.code}` : ''
                }`
              )
            )
          );
          break;
      }
    } else {
      console.warn(`Unknown action type [${message.type}]`, message);
    }
  };

  const subscribe = () => {
    const workspaceId = activeIdentity?.workspace?.workspaceId;
    if (workspaceId) {
      subId = WebSocketHelper.subscribe(
        'document-indexing-service',
        `workspaces/${workspaceId}`,
        (message) => handleDocumentUpdate(message)
      );
    }
  };

  useEffect(() => {
    setDidMount(true);
    dispatch(documentDisplayActions.clearDocumentDisplayStore());
    if (selectedActionTab !== 'preview') {
      setSelectedActionTab('preview');
    }

    return () => {
      setDidMount(false);
      dispatch(documentDisplayActions.clearDocumentDisplayStore());
      dispatch(documentInvitationActions.clearStore());
      dispatch(documentManagementActions.clearDocumentManagementStore());
      WebSocketHelper.unsubscribe(subId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, subId]);

  useMemo(() => {
    if (!smallDevice && selectedActionTab === 'preview') {
      setSelectedActionTab('recipients');
    }
  }, [selectedActionTab, smallDevice]);

  useEffect(() => {
    const workspaceId = activeIdentity?.workspace?.workspaceId;
    if (workspaceId) {
      subscribe();
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeIdentity?.workspace?.workspaceId, dispatch]);

  useEffect(() => {
    if (getDocumentStatus === RequestStatus.IDLE) {
      dispatch(documentDisplayActions.getDocument(documentId, apiService));
    }
  }, [dispatch, apiService, documentId, getDocumentStatus]);

  useEffect(() => {
    if (documentStatusSuccess && documentSignersStatus === RequestStatus.IDLE) {
      dispatch(documentInvitationActions.getDocumentSigners(documentId, apiService));
    }
  }, [apiService, dispatch, documentId, documentStatusSuccess, documentSignersStatus]);

  useEffect(() => {
    if (documentStatusSuccess) {
      if (documentHistoryStatus === RequestStatus.IDLE) {
        dispatch(
          documentManagementActions.getHistoryDocument({
            documentId,
            workspaceId: documentInfo.workspaceId,
            service: apiService,
          })
        );
      }
      if (getDocumentMembersStatus === RequestStatus.IDLE) {
        dispatch(documentManagementActions.getDocumentMembers(documentId, apiService));
      }
      if (getDocumentInvitationsStatus === RequestStatus.IDLE) {
        dispatch(documentManagementActions.getDocumentInvitations(documentId, apiService));
      }
      if (getDocumentAdminsStatus === RequestStatus.IDLE) {
        dispatch(
          documentManagementActions.getDocumentMembersByRole(
            documentId,
            DocumentRole.ADMINS,
            apiService
          )
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    documentHistoryStatus,
    documentId,
    documentStatusSuccess,
    getDocumentAdminsStatus,
    getDocumentMembersStatus,
    getDocumentInvitationsStatus,
  ]);

  useMemo(() => {
    if (
      didMount &&
      firstRender.current &&
      documentMembersStatusSuccess &&
      !isDocumentRejected &&
      !isDocumentFinished &&
      documentType !== DocumentType.DURABLE_MEDIA
    ) {
      if (
        documentMembers.length <= 3 &&
        documentMembers.find((member) => member.id === activeIdentity?.identity?.identityId) &&
        !everClosedInvitations.includes(documentId)
      ) {
        firstRender.current = false;
        setInvitationsManagementDialogOpen(true);
      }
    }
  }, [
    didMount,
    documentMembersStatusSuccess,
    isDocumentRejected,
    isDocumentFinished,
    documentType,
    documentMembers,
    everClosedInvitations,
    documentId,
    activeIdentity?.identity?.identityId,
  ]);

  useEffect(() => {
    if (downloadTokenStatusSuccess) {
      downloadFiles();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [downloadTokenStatusSuccess]);

  useEffect(() => {
    if (activeFileIndex >= 0) {
      getDownloadToken();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeFileIndex]);

  useMemo(() => {
    if (didMount && getDownloadTokenStatus === RequestStatus.ERROR) {
      if (tryCount < MAX_COUNT_TRY) {
        setTryCount(tryCount + 1);
        getDownloadToken();
      } else {
        setEditedSubpage('reload');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getDownloadTokenStatus, tryCount]);

  useEffect(() => {
    if (
      getDownloadTokenStatus === RequestStatus.IDLE &&
      prevState?.getDownloadTokenStatus !== RequestStatus.IDLE &&
      downloadFileStatus === RequestStatus.IDLE &&
      prevState?.downloadFileStatus !== RequestStatus.IDLE &&
      activeFileIndex === prevState?.activeFileIndex
    ) {
      getDownloadToken();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getDownloadTokenStatus, downloadFileStatus]);

  useEffect(() => {
    if (didMount && downloadFileStatus === RequestStatus.ERROR) {
      if (tryCount < MAX_COUNT_TRY) {
        setTryCount(tryCount + 1);
        downloadFiles();
      } else {
        setEditedSubpage('reload');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [downloadFileStatus, tryCount]);

  const contactsNotFound = [];
  documentMembers.forEach((member) => {
    const matchedIdentity = ContactHelper.getEntityData(member.id);
    if (!matchedIdentity) {
      if (!ContactHelper.checkIfIsInProgress(member.id)) {
        contactsNotFound.push(member.id);
      }
    }
  });
  // TODO: create selector for prepareDocumentMembers, could be reusable
  const preparedSigners = sortInvitedSigners({
    itemsToSort: prepareDocumentMembers({ documentMembers, contactsNotFound }).filter(
      (member) => member.role === DocumentRole.SIGNERS
    ),
    sortingArray: documentSigners,
  });

  useEffect(() => {
    if (missingContacts.length > 0 && contactBookEntitiesFetchStatus !== RequestStatus.PENDING) {
      dispatch(contactsActions.addMissingToQueue(missingContacts));
    }
  }, [missingContacts, contactBookEntitiesFetchStatus, dispatch, contacts]);

  if (JSON.stringify(contactsNotFound) !== JSON.stringify(missingContacts)) {
    setMissingContacts(contactsNotFound);
  }

  const handleBack = () => {
    setEditedSubpage(null);
  };

  const handleFullSidebarToggle = () => {
    setFullSidebarHeight(!fullSidebarHeight);
  };

  const handleChangeTab = (event, newValue) => {
    setSelectedActionTab(newValue);
    // checkShowScroll();
  };

  const handleClose = () => {
    const createdDocumentFolderId = localStorage.getItem('createdDocumentFolderId');
    if (createdDocumentFolderId) {
      createdDocumentFolderId === 'root'
        ? history.push(`/drive/`)
        : history.push(`/drive/${createdDocumentFolderId}`);
      localStorage.removeItem('createdDocumentFolderId');
    } else {
      goBack(history);
    }
  };

  const getCurrentTabs = () => {
    if (smallDevice) {
      return [...availableTabs.previewTab, ...availableTabs.basicTabs];
    }
    return availableTabs.basicTabs;
  };

  const { canSignDueToOrder, userToSign } = getOrderSigningRestrictions({
    activeIdentity,
    isSigningOrderAvailable,
    isOrderSignificant,
    signers: preparedSigners,
  });
  const canDownload = documentStatusSuccess || false;
  const canRequestDownload = !downloadDocumentsPending.includes(documentId);

  const canInvite =
    (isDocumentAdmin && documentInfo.documentType === DocumentType.CERTIFICATES) ||
    (!isDocumentRejected && !isDocumentFinished && isDocumentAdmin) ||
    false;
  const canRenameDocument = isDocumentAdmin || isDocumentEditor;

  const getTabContent = (index) => {
    if (!documentInfo) {
      return null;
    }
    switch (index) {
      case 'recipients':
        return (
          <Recipients
            enableInviting={canInvite}
            openInvitationsManagementDialog={() => setInvitationsManagementDialogOpen(true)}
          />
        );

      case 'info':
        return <Info />;

      case 'history':
        return (
          <History
            smallDevice={smallDevice}
            fullHeight={smallDevice ? false : fullSidebarHeight}
            onCollapseClick={!smallDevice ? handleFullSidebarToggle : undefined}
          />
        );

      case 'preview':
      default:
        return (
          <DocumentContent
            filesSrc={downloadFilesImage}
            activeFileIndexRedux={activeFileIndex}
            activeFileIndex={activeFileIndexDocument}
            setActiveFileIndexDocument={setActiveFileIndexDocument}
            files={documentInfo.files}
            smallContent={smallDevice}
            getDownloadToken={getDownloadToken}
            changeFileIndex={changeFileIndex}
            checkShowScroll={checkShowScroll}
            loading={isLoading}
            disabledMenu={disabledMenu}
            setDisabledMenu={setDisabledMenu}
            watermark={isDocumentFinished}
            watermarkText={t('statuses.finished.short')}
          />
        );
    }
  };

  const DocumentErrorBanner = (title) => (
    <EmptyBanner src="/images/frames/error.svg" title={title} />
  );

  const NavigationTabs = (
    <ActionsTabs
      value={selectedActionTab}
      onChange={handleChangeTab}
      tabs={getCurrentTabs()}
      showIcon={!miniDevice}
    />
  );

  const getSigningBannerAfterText = () => {
    let afterText = null;
    if (!canSignDueToOrder) {
      afterText = (
        <div className={classes.afterText}>
          {t('documentActions.needYourAction.signAfter', { name: userToSign })}
        </div>
      );
    } else if (disableForInvitedSigners) {
      afterText = (
        <div className={classes.afterText}>
          {t('documentActions.needYourAction.invitedSigners')}
        </div>
      );
    }

    return afterText;
  };

  const SigningBanner = (
    <>
      <DocumentStatusFrame />
      {documentStatusSuccess && (canSign || canRejectDocument) && (
        <SignBanner
          display
          disabled={
            ((!canSignDueToOrder || disableForInvitedSigners) && !smallDevice) || isRejectInProgress
          } // disabled for desktop and modal for mobile
          rejectionButton={(disabled) =>
            canRejectDocument && (
              <Button
                className={classes.documentCardButton}
                size="large"
                fullWidth
                variant="text"
                color="primary"
                disabled={disabled || isRejectInProgress}
                endIcon={isRejectInProgress && <CircularProgress size={20} color="inherit" />}
                onClick={() => setEditedSubpage('reject')}
              >
                {t('documentActions.rejectDocument.header')}
              </Button>
            )
          }
          afterText={!smallDevice && getSigningBannerAfterText()}
          user={{
            id: activeIdentity?.identity?.identityId,
            name: activeIdentity?.identity?.name,
            workspaceName: activeIdentity?.workspace?.name,
          }}
          onSignClick={() =>
            (!canSignDueToOrder || disableForInvitedSigners) && smallDevice // when mobile and cannot sign due to order then show info modal
              ? setSigningBannerAfterTextModal(true)
              : setEditedSubpage('sign')
          }
          title={!smallDevice ? t('documentActions.needYourAction.title') : null}
          signText={
            miniDevice
              ? t('documentActions.needYourAction.signDocument.shortTerm')
              : t('documentActions.needYourAction.signDocument.longTerm')
          }
          canSign={canSign}
        />
      )}
    </>
  );

  const topBarOptions = () => {
    const options = [];

    if (documentStatusSuccess && !actualizationBannerOpen) {
      if (canDownload) {
        const canRequestDownload = !downloadDocumentsPending.includes(documentId);

        options.push({
          name: t('common.downloadDocument'),
          icon: <DownloadIcon />,
          secondaryIcon: !canRequestDownload && <CircularProgress size={smallDevice ? 20 : 16} />,
          disabled: !canRequestDownload,
          onClick: () => dispatch(documentDownloadActions.downloadDocument(documentId, apiService)),
        });
      }
      if (smallDevice) {
        options.push({
          name: t('documentActions.tabs.history.downloadHistory'),
          icon: <ArchiveIcon />,
          secondaryIcon: isDownloadingDocumentCard && <CircularProgress size={20} />,
          disabled: isDownloadingDocumentCard,
          onClick: downloadDocumentCard,
        });
      }

      if (canRejectDocument) {
        options.push({
          name: t('common.reject'),
          icon: <CloseIcon />,
          onClick: () => setEditedSubpage('reject'),
        });
      }
    }

    return options;
  };

  if (!didMount) {
    return null;
  }

  return (
    <>
      {isCertificate && <CertificateActionsWebsocket />}
      <div className={classes.root}>
        <div className={clsx(classes.main)}>
          <TopBar
            documentId={!documentStatusError ? documentId : null}
            title={!documentStatusError ? documentInfo.name : null}
            disableChangeTitle={!canRenameDocument || actualizationBannerOpen}
            mainOptions={smallDevice ? topBarOptions() : undefined}
            onBack={handleClose}
            rightContent={
              canDownload && !smallDevice ? (
                <HeaderButton
                  onClick={downloadDocument}
                  color="default"
                  icon={
                    !canRequestDownload ? (
                      <CircularProgress size={smallDevice ? 20 : 16} />
                    ) : (
                      <DownloadIcon />
                    )
                  }
                  tooltip={t('common.downloadDocument')}
                />
              ) : null
            }
          >
            <>
              {smallDevice && <DocumentStatusNotification />}
              {smallDevice && !documentStatusError && !actualizationBannerOpen && NavigationTabs}
            </>
          </TopBar>

          <div className={classes.content} id={smallDevice ? 'scroll-element-sidebar' : ''}>
            {documentStatusError || actualizationBannerOpen
              ? DocumentErrorBanner(
                  t(
                    `documentActions.banner.${
                      documentStatusError ? 'fetchDocumentError' : 'actualizationDocument'
                    }`
                  )
                )
              : getTabContent(smallDevice ? selectedActionTab : 'preview')}
          </div>
          {smallDevice && !documentStatusError && !actualizationBannerOpen && (
            <div className={classes.mobileBottomContent}>{SigningBanner}</div>
          )}
        </div>
        {!smallDevice && !documentStatusError && !actualizationBannerOpen && (
          <RightSidebar
            signBanner={SigningBanner}
            tabs={NavigationTabs}
            fullHeightContent={selectedActionTab === 'history' && fullSidebarHeight}
          >
            {getTabContent(selectedActionTab)}
          </RightSidebar>
        )}
      </div>

      <InvitationsManagement
        open={invitationsManagementDialogOpen}
        canInviteToSigners={!isDocumentSigning}
        handleClose={() => {
          setInvitationsManagementDialogOpen(false);

          if (!everClosedInvitations.includes(documentId)) {
            everClosedInvitations.push(documentId);
            localStorage.setItem(
              LOCAL_STORAGE_CLOSED_INVITATION_DIALOG,
              JSON.stringify(everClosedInvitations)
            );
          }
        }}
      />

      {documentStatusSuccess && (
        <>
          <RejectDocument
            open={editedSubpage === 'reject'}
            documentId={documentId}
            onClose={() => {
              handleBack();
            }}
          />

          <ReloadDocument
            open={editedSubpage === 'reload'}
            actionAccept={() => {
              reloadFile();
              handleBack();
            }}
            onClose={handleBack}
          />

          <SignDocument
            open={editedSubpage === 'sign'}
            documentId={documentId}
            onClose={handleBack}
          />
        </>
      )}
      {(!canSignDueToOrder || disableForInvitedSigners) && smallDevice && (
        <SigningBannerAfterTextModal
          open={isSigningBannerAfterTextModal}
          description={getSigningBannerAfterText()}
          onClose={() => setSigningBannerAfterTextModal(false)}
        />
      )}
    </>
  );
};

DocumentActionsPage.propTypes = {
  history: PropTypes.objectOf(PropTypes.shape),
};

export default DocumentActionsPage;
