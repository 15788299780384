import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Card,
  CardContent,
  CardActions,
  CardHeader,
  Typography,
  useMediaQuery,
  Button,
  CircularProgress,
} from '@material-ui/core';
import { LocalOfferRounded as DiscountIcon } from '@material-ui/icons';
import Img from 'react-image';
import {
  ExtendedGrid,
  FullScreenDialog,
  LinearProgressWithLabel,
  ConfirmDialog,
  Gap,
} from 'src/components';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import billingActions from 'src/redux/actions/billing.actions';
import moment from 'moment';
import { RequestStatus } from 'src/helpers/reduxReuquest.util';
import { AddDiscountCode } from './editDialogs';
import { ContentHeading } from '../../subcomponents';
import { useStyles } from '../styles';

const SubscriptionsAndPayments = () => {
  const classes = useStyles();
  const [editedSubpage, setEditedSubpage] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
  const [resumeSubscribeOpen, setResumeSubscribeOpen] = React.useState(false);
  const { t, i18n } = useTranslation();
  const { language } = i18n;
  const history = useHistory();
  const dispatch = useDispatch();
  const [didMount, setDidMount] = useState(false);
  const smallScreen = useMediaQuery(({ breakpoints }) => breakpoints.down('md'));
  const { currentIdentity: activeIdentity } = useSelector((state) => state.identityStore);
  const {
    invoiceUserData,
    invoiceUserDataStatus,
    unsubscribePlanStatus,
    reactiveSubscribePlanStatus,
  } = useSelector((state) => state.billingStore);

  const workspaceId = activeIdentity?.workspace?.workspaceId;
  const isPendingUnsubscribe = unsubscribePlanStatus === RequestStatus.PENDING;
  const isPendingReactive = reactiveSubscribePlanStatus === RequestStatus.PENDING;

  const gigabyteToBytes = 1073741824;
  const invoiceUserDataStatusSuccess = invoiceUserDataStatus === RequestStatus.SUCCESS;
  const cancelAt = invoiceUserData?.subscription?.cancelAt;
  const canceledSubscription = !!(cancelAt || invoiceUserData?.subscription?.status === 'canceled');
  const nextPaymentAttempt = invoiceUserData?.invoice?.nextPaymentAttempt;
  const dateToDisplay = cancelAt || nextPaymentAttempt;
  const invoiceCurrency = invoiceUserData?.invoice?.currency;
  const totalToPay = invoiceUserData?.invoice?.total;
  const subTotalToPay = invoiceUserData?.invoice?.subtotal;
  const canceledWithNextPayment =
    canceledSubscription && invoiceUserData?.invoice && nextPaymentAttempt && totalToPay;

  const workspaceTotalSpace = invoiceUserData?.space?.totalSpace / gigabyteToBytes;
  const workspaceUsedSpace = invoiceUserData?.space?.usedSpace / gigabyteToBytes;
  const workspaceUsedPercent = (workspaceUsedSpace / workspaceTotalSpace) * 100;

  useEffect(() => {
    setDidMount(true);
    if (workspaceId) {
      getInvoiceData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, workspaceId]);

  useEffect(() => {
    if (
      invoiceUserDataStatus === RequestStatus.ERROR ||
      invoiceUserData?.subscription?.status === 'past_due'
    ) {
      history.push('/payment-method/add');
    }
  }, [history, invoiceUserDataStatus, invoiceUserData]);

  const handleBack = (reload) => {
    setEditedSubpage(null);
    if (reload === true) {
      getInvoiceData();
    }
  };

  const editPages = {
    add: {
      title: t('workspacePage.subscriptionsAndPayments.addDiscountCodeTitle'),
      Component: <AddDiscountCode onClose={handleBack} workspaceId={workspaceId} />,
    },
  };

  const deleteDialogToggle = {
    open: () => {
      setDeleteDialogOpen(true);
    },
    close: () => {
      setDeleteDialogOpen(false);
    },
  };

  const resumeDialogToggle = {
    open: () => {
      setResumeSubscribeOpen(true);
    },
    close: () => {
      setResumeSubscribeOpen(false);
    },
  };

  const cancelSubscription = () => {
    dispatch(billingActions.unsubscribePlan(workspaceId));
  };

  const resumeSubscription = () => {
    dispatch(billingActions.reactiveSubscribePlan(workspaceId));
  };

  const handleCancelSubscription = (flag) => {
    if (flag) {
      cancelSubscription();
    } else {
      deleteDialogToggle.open();
    }
  };

  const handleResumeSubscription = (flag) => {
    if (flag) {
      resumeSubscription();
    } else {
      resumeDialogToggle.open();
    }
  };

  useEffect(() => {
    if (unsubscribePlanStatus === RequestStatus.SUCCESS) {
      deleteDialogToggle.close();
      getInvoiceData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unsubscribePlanStatus]);

  useEffect(() => {
    if (reactiveSubscribePlanStatus === RequestStatus.SUCCESS) {
      resumeDialogToggle.close();
      getInvoiceData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reactiveSubscribePlanStatus]);

  const getInvoiceData = () => {
    dispatch(billingActions.getInvoiceUserData(workspaceId));
  };

  const getStatus = (type) => {
    switch (type) {
      case 'incomplete':
        return {
          text: t('workspacePage.subscriptionsAndPayments.status.incomplete'),
          color: classes.warning,
        };
      case 'incomplete_expired':
        return {
          text: t('workspacePage.subscriptionsAndPayments.status.incompleteExpired'),
          color: classes.warning,
        };
      case 'trialing':
        return {
          text: t('workspacePage.subscriptionsAndPayments.status.trialing'),
          color: classes.success,
        };
      case 'active':
        return {
          text: t('workspacePage.subscriptionsAndPayments.status.active'),
          color: classes.success,
        };
      case 'past_due':
        return {
          text: t('workspacePage.subscriptionsAndPayments.status.pastDue'),
          color: classes.error,
        };
      case 'canceled':
        return {
          text: t('workspacePage.subscriptionsAndPayments.status.canceled'),
          color: classes.error,
        };
      case 'unpaid':
        return {
          text: t('workspacePage.subscriptionsAndPayments.status.unpaid'),
          color: classes.error,
        };
      default:
        return {
          text: t('workspacePage.subscriptionsAndPayments.status.trialing'),
          color: classes.success,
        };
    }
  };

  const renderLoader = () => (
    <div className={classes.contentLoader}>
      <CircularProgress size={smallScreen ? 24 : 32} />
    </div>
  );

  const getWorkspaceUsedSpaceColor = () => {
    if (workspaceUsedPercent < 90) {
      return 'primary';
    }
    if (workspaceUsedPercent < 95) {
      return 'warning';
    }
    return 'error';
  };

  const renderEditPage = (name) => editPages[name].Component;
  const setTitle = (name) => editPages[name].title;

  const renderSummaryPage = () => (
    <>
      <ContentHeading>{t('workspacePage.drawer.subscriptionsAndBillingOverview')}</ContentHeading>

      <ExtendedGrid alignItemsXxs="stretch" container spacing={2}>
        <ExtendedGrid item xxs={12} sm={7}>
          <Card variant="outlined" className={classes.card}>
            <CardHeader
              title={t('workspacePage.subscriptionsAndPayments.estimatedBillingTitle')}
              className={classes.cardHeader}
            />
            <CardContent>
              {!invoiceUserDataStatusSuccess ? (
                renderLoader()
              ) : (
                <>
                  <ExtendedGrid
                    container
                    spacing={2}
                    alignItemsXxs={
                      canceledSubscription &&
                      invoiceUserData.invoice &&
                      nextPaymentAttempt &&
                      totalToPay
                        ? 'flex-start'
                        : 'flex-end'
                    }
                  >
                    <ExtendedGrid item xxs={12} xs={6} sm={12} lg={6} xl={4}>
                      {totalToPay && subTotalToPay && (
                        <>
                          {subTotalToPay !== totalToPay && (
                            <Typography
                              variant="body1"
                              color="secondary"
                              className={classes.oldPrice}
                              style={{ lineHeight: 1 }}
                            >
                              {(subTotalToPay / 100).toFixed(2)} {invoiceCurrency.toUpperCase()}
                            </Typography>
                          )}
                        </>
                      )}
                      <Typography variant={smallScreen ? 'h3' : 'h2'} color="primary">
                        {totalToPay && invoiceUserData.card
                          ? `${(totalToPay / 100).toFixed(2)} ${invoiceCurrency.toUpperCase()}`
                          : '-'}
                      </Typography>

                      <Typography variant="subtitle2">
                        {t('workspacePage.subscriptionsAndPayments.vat')}
                      </Typography>
                    </ExtendedGrid>

                    <ExtendedGrid item xxs={12} xs={6} sm={12} lg={6} xl={4}>
                      <Typography variant={smallScreen ? 'h3' : 'h2'} color="primary">
                        {/* TODO: use <FormatDate /> component instead during work on subscriptions */}
                        {dateToDisplay
                          ? moment
                              .unix(dateToDisplay / 1000)
                              .locale(language)
                              .format('ll')
                          : '-'}
                      </Typography>
                      <Typography variant="subtitle2">
                        {canceledSubscription
                          ? t(
                              `workspacePage.subscriptionsAndPayments.${
                                canceledWithNextPayment ? 'canceledWithNextDate' : 'canceledDate'
                              }`
                            )
                          : t(
                              `workspacePage.subscriptionsAndPayments.${
                                invoiceUserData.card ? 'nextPayment' : 'endDate'
                              }`
                            )}
                      </Typography>
                    </ExtendedGrid>

                    <ExtendedGrid item xxs={12} xs={6} sm={12} lg={6} xl={4}>
                      <Typography
                        variant={smallScreen ? 'h3' : 'h2'}
                        className={clsx(
                          classes.status,
                          getStatus(invoiceUserData.subscription.status).color
                        )}
                      >
                        {getStatus(invoiceUserData.subscription.status).text}
                      </Typography>

                      <Typography variant="subtitle2">
                        {t('workspacePage.subscriptionsAndPayments.statusSubscription')}
                      </Typography>
                    </ExtendedGrid>
                  </ExtendedGrid>
                  <Img
                    useSuspense={false}
                    src="/images/frames/payment.svg"
                    className={classes.image}
                    container={(children) => (
                      <div className={classes.imageContainer}>{children}</div>
                    )}
                  />
                </>
              )}
            </CardContent>

            {invoiceUserDataStatusSuccess &&
              !canceledSubscription &&
              invoiceUserData.invoice &&
              invoiceUserData.card && (
                <CardActions className={clsx(classes.cardActions, classes.cardActionsLeft)}>
                  <Button
                    variant="text"
                    color="primary"
                    size="medium"
                    classes={{
                      root: classes.actionButton,
                    }}
                    onClick={() => setEditedSubpage('add')}
                  >
                    {t('workspacePage.subscriptionsAndPayments.addCode')}
                  </Button>
                  {invoiceUserData.discount?.name && (
                    <div className={classes.actionButtonCode}>
                      <ExtendedGrid container wrapXxs="nowrap" alignItemsXxs="center">
                        <ExtendedGrid item xxs="auto">
                          <DiscountIcon color="disabled" />
                        </ExtendedGrid>
                        <ExtendedGrid item xxs>
                          <Typography variant="subtitle2">
                            {t('workspacePage.subscriptionsAndPayments.codeText')}{' '}
                            <Typography component="span" color="textPrimary">
                              <strong>{invoiceUserData.discount.name}</strong>
                            </Typography>
                          </Typography>
                        </ExtendedGrid>
                      </ExtendedGrid>
                    </div>
                  )}
                </CardActions>
              )}
          </Card>
        </ExtendedGrid>

        <ExtendedGrid item xxs={12} sm={5}>
          <Card variant="outlined" className={classes.card}>
            <CardHeader
              title={t('workspacePage.subscriptionsAndPayments.detailsTitle')}
              className={classes.cardHeader}
            />

            <CardContent>
              {!invoiceUserDataStatusSuccess ? (
                renderLoader()
              ) : (
                <>
                  {invoiceUserData.card ? (
                    <ExtendedGrid
                      container
                      alignItemsXxs="center"
                      justifyContentXxs="center"
                      wrapXs="nowrap"
                      wrapSm="wrap"
                      wrapLg="nowrap"
                      spacing={1}
                    >
                      <ExtendedGrid
                        item
                        xxs={12}
                        xs
                        sm={12}
                        lg="auto"
                        textAlignXxs="center"
                        textAlignXs="left"
                        textAlignSm="center"
                      >
                        <Img
                          useSuspense={false}
                          src={`/images/cards/${invoiceUserData.card.brand}.svg`}
                          className={classes.imageBank}
                        />
                      </ExtendedGrid>
                      <ExtendedGrid
                        item
                        xxs={12}
                        xs="auto"
                        sm={12}
                        lg
                        textAlignXxs="center"
                        textAlignXs="right"
                        textAlignSm="center"
                        textAlignLg="right"
                      >
                        <Typography variant="h6" color="textPrimary">
                          <strong>**** **** **** {invoiceUserData.card.lastDigits}</strong>
                        </Typography>
                        <Typography variant="subtitle2">{invoiceUserData.card.name}</Typography>
                        <Typography variant="subtitle2">
                          {t('workspacePage.subscriptionsAndPayments.endCreditCard')}{' '}
                          {invoiceUserData.card.expMonth <= 10
                            ? `0${invoiceUserData.card.expMonth}`
                            : `${invoiceUserData.card.expMonth}`}
                          /{invoiceUserData.card.expYear}
                        </Typography>
                      </ExtendedGrid>
                    </ExtendedGrid>
                  ) : (
                    <Typography color="textSecondary">
                      {t('workspacePage.subscriptionsAndPayments.noCardAttached')}
                    </Typography>
                  )}
                </>
              )}
            </CardContent>

            {invoiceUserDataStatusSuccess && (
              <CardActions className={classes.cardActions}>
                <Button
                  variant="text"
                  size="medium"
                  classes={{
                    root: classes.actionButton,
                  }}
                  onClick={() => {
                    history.push('/payment-method/edit');
                  }}
                >
                  {t(
                    `workspacePage.subscriptionsAndPayments.${
                      invoiceUserData.card ? 'updateCard' : 'addCard'
                    }`
                  )}
                </Button>
                {invoiceUserData.card &&
                  (canceledSubscription ? (
                    <Button
                      variant="text"
                      size="medium"
                      classes={{
                        root: clsx(classes.actionButton, classes.actionButtonCancel),
                      }}
                      onClick={() => handleResumeSubscription(false)}
                    >
                      {t('workspacePage.subscriptionsAndPayments.resumeSubscribe')}
                    </Button>
                  ) : (
                    <>
                      {invoiceUserData.invoice && (
                        <Button
                          variant="text"
                          size="medium"
                          classes={{
                            root: clsx(classes.actionButton, classes.actionButtonCancel),
                          }}
                          onClick={() => handleCancelSubscription(false)}
                        >
                          {t('workspacePage.subscriptionsAndPayments.cancelSubscribe')}
                        </Button>
                      )}
                    </>
                  ))}
              </CardActions>
            )}
          </Card>
        </ExtendedGrid>

        <ExtendedGrid item xxs={12}>
          <Card variant="outlined" className={classes.root}>
            <CardHeader
              title={
                <Typography variant="h4">
                  {t('workspacePage.subscriptionsAndPayments.summaryTitle')}
                </Typography>
              }
            />
            <CardContent>
              {!invoiceUserDataStatusSuccess ? (
                renderLoader()
              ) : (
                <ExtendedGrid
                  container
                  spacing={4}
                  alignItemsXxs="flex-start"
                  justifyContentXxs="space-between"
                >
                  {invoiceUserData.space && (
                    <>
                      <ExtendedGrid item xxs={12}>
                        <Typography color="textSecondary" gutterBottom>
                          {t('workspacePage.subscriptionsAndPayments.capacityWorkspace')}
                        </Typography>
                        <Gap size="small" />
                        <LinearProgressWithLabel
                          size="default"
                          color={getWorkspaceUsedSpaceColor()}
                          value={parseFloat(
                            parseFloat(
                              workspaceUsedPercent > 100 ? 100 : workspaceUsedPercent.toFixed(0)
                            ).toFixed(0)
                          )}
                          text={`${workspaceUsedSpace.toFixed(
                            2
                          )} GB / ${workspaceTotalSpace.toFixed(
                            2
                          )} GB (${workspaceUsedPercent.toFixed(1)}%)`}
                        />
                      </ExtendedGrid>
                      {invoiceUserData?.space?.members && (
                        <ExtendedGrid item xxs={12}>
                          <Typography color="textSecondary" gutterBottom>
                            {t('workspacePage.subscriptionsAndPayments.quantityWorkspace')}
                          </Typography>

                          <Typography variant="h2" color="primary">
                            {invoiceUserData.space.members}
                          </Typography>
                        </ExtendedGrid>
                      )}
                    </>
                  )}
                </ExtendedGrid>
              )}
            </CardContent>
          </Card>
        </ExtendedGrid>
      </ExtendedGrid>

      {invoiceUserDataStatusSuccess && (
        <>
          <ConfirmDialog
            variant="error"
            open={deleteDialogOpen}
            title={t('workspacePage.subscriptionsAndPayments.cancelSubscriptionTitle')}
            subtitle={t('workspacePage.subscriptionsAndPayments.cancelSubscriptionSubtitle')}
            applyButtonText={t(
              'workspacePage.subscriptionsAndPayments.cancelSubscriptionCancelAction'
            )}
            cancelButtonText={t('common.cancel')}
            actionAccept={() => handleCancelSubscription(true)}
            actionCancel={deleteDialogToggle.close}
            isLoading={isPendingUnsubscribe}
          />
          <ConfirmDialog
            variant="primary"
            open={resumeSubscribeOpen}
            title={t('workspacePage.subscriptionsAndPayments.resumeSubscriptionTitle')}
            subtitle={t('workspacePage.subscriptionsAndPayments.resumeSubscriptionSubtitle')}
            applyButtonText={t(
              'workspacePage.subscriptionsAndPayments.resumeSubscriptionResumeAction'
            )}
            cancelButtonText={t('common.cancel')}
            actionAccept={() => handleResumeSubscription(true)}
            actionCancel={resumeDialogToggle.close}
            isLoading={isPendingReactive}
          />
        </>
      )}
    </>
  );

  if (!didMount) {
    return null;
  }
  return (
    <>
      {renderSummaryPage()}
      <FullScreenDialog
        isOpen={!!editedSubpage}
        onBack={handleBack}
        onClose={handleBack}
        title={editedSubpage ? setTitle(editedSubpage) : null}
      >
        {editedSubpage ? renderEditPage(editedSubpage) : null}
      </FullScreenDialog>
    </>
  );
};

SubscriptionsAndPayments.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
    contentLoader: PropTypes.string,
    card: PropTypes.string,
    cardHeader: PropTypes.string,
    cardActions: PropTypes.string,
    cardActionsLeft: PropTypes.string,
    cardTitle: PropTypes.string,
    actionButton: PropTypes.string,
    actionButtonCancel: PropTypes.string,
    actionButtonCode: PropTypes.string,
    divider: PropTypes.string,
    oldPrice: PropTypes.string,
    imageContainer: PropTypes.string,
    image: PropTypes.string,
    imageBank: PropTypes.string,
    status: PropTypes.string,
    success: PropTypes.string,
    warning: PropTypes.string,
    error: PropTypes.string,
  }).isRequired,
};

export default SubscriptionsAndPayments;
