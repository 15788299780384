export const Permission = {
  DOCUMENT_SERVICE_USER_GET_FILE_UPLOAD: 'DOCUMENT_SERVICE_USER_GET_FILE_UPLOAD',
  DOCUMENT_SERVICE_USER_POST_DOCUMENTS: 'DOCUMENT_SERVICE_USER_POST_DOCUMENTS',
  DOCUMENT_SERVICE_USER_SIGN_ORDER: 'DOCUMENT_SERVICE_USER_SIGN_ORDER',
};
export const PermissionList = [
  Permission.DOCUMENT_SERVICE_USER_GET_FILE_UPLOAD,
  Permission.DOCUMENT_SERVICE_USER_POST_DOCUMENTS,
  Permission.DOCUMENT_SERVICE_USER_SIGN_ORDER,
];
