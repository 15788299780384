import { RequestStatus } from 'src/helpers/reduxReuquest.util';
import documentCreationConstants from '../constants/documentCreation.constants';

const initialState = {
  createNewDocumentStatus: RequestStatus.IDLE,
  filesListDropzone: [],
  getUploadTokenStatus: RequestStatus.IDLE,
  newDocument: {},
  signatureDocument: '',
  templateDocument: '',
  uploadFileStatus: RequestStatus.IDLE,
  uploadTokens: [],
};

const deleteToken = (state, index) =>
  state.uploadTokens.filter((item, elIndex) => elIndex !== index);

const deleteFile = (state, index) =>
  state.filesListDropzone.filter((item, elIndex) => elIndex !== index);

const renderFiles = (state, uploadTokens) =>
  state.filesListDropzone.map((file, index) => ({
    ...file,
    token: uploadTokens[index]?.token,
    fileId: uploadTokens[index]?.fileId,
  }));

const renderUploadProgress = (state, progress, fileToken) =>
  state.filesListDropzone.map((file) => {
    if (file.token === fileToken) {
      return {
        ...file,
        progress,
      };
    }
    return {
      ...file,
    };
  });

const changeStatusUploadFile = (state, status, token) =>
  state.filesListDropzone.map((file) => {
    if (file.token === token) {
      return {
        ...file,
        uploadStatus: status,
      };
    }
    return {
      ...file,
      progress: file.progress,
    };
  });

function documentCreatorStore(state = initialState, action) {
  switch (action.type) {
    case documentCreationConstants.UPLOAD_FILE_REQUEST:
      return {
        ...state,
        uploadFileStatus: RequestStatus.PENDING,
      };
    case documentCreationConstants.UPLOAD_FILE_SUCCESS:
      return {
        ...state,
        uploadFileStatus: RequestStatus.SUCCESS,
        filesListDropzone: changeStatusUploadFile(state, RequestStatus.SUCCESS, action.token),
      };
    case documentCreationConstants.UPLOAD_FILE_FAILURE:
      return {
        ...state,
        uploadFileStatus: RequestStatus.ERROR,
        filesListDropzone: changeStatusUploadFile(state, RequestStatus.ERROR, action.token),
      };
    case documentCreationConstants.GET_UPLOAD_TOKEN_REQUEST:
      return {
        ...state,
        getUploadTokenStatus: RequestStatus.PENDING,
      };
    case documentCreationConstants.GET_UPLOAD_TOKEN_SUCCESS:
      return {
        ...state,
        getUploadTokenStatus: RequestStatus.SUCCESS,
        uploadTokens: [...state.uploadTokens, action.data],
        filesListDropzone: renderFiles(state, [...state.uploadTokens, action.data]),
      };
    case documentCreationConstants.GET_UPLOAD_TOKEN_FAILURE:
      return {
        ...state,
        getUploadTokenStatus: RequestStatus.ERROR,
      };

    case documentCreationConstants.CREATE_NEW_DOCUMENT_REQUEST:
      return {
        ...state,
        createNewDocumentStatus: RequestStatus.PENDING,
      };
    case documentCreationConstants.CREATE_NEW_DOCUMENT_SUCCESS:
      return {
        ...state,
        createNewDocumentStatus: RequestStatus.SUCCESS,
        newDocument: action.data,
      };
    case documentCreationConstants.CREATE_NEW_DOCUMENT_FAILURE:
      return {
        ...state,
        createNewDocumentStatus: RequestStatus.ERROR,
      };

    case documentCreationConstants.ADD_FILE_DROPZONE:
      return {
        ...state,
        filesListDropzone: [...state.filesListDropzone, action.file],
      };

    case documentCreationConstants.DELETE_FILE_DROPZONE:
      return {
        ...state,
        uploadTokens: deleteToken(state, action.index),
        filesListDropzone: deleteFile(state, action.index),
        uploadFileStatus: RequestStatus.IDLE,
      };
    case documentCreationConstants.SET_PROGRESS_UPLOAD_FILE:
      return {
        ...state,
        filesListDropzone: renderUploadProgress(state, action.progress, action.token),
      };
    case documentCreationConstants.SET_TEMPLATE_DOCUMENT:
      return {
        ...state,
        templateDocument: action.template,
      };
    case documentCreationConstants.SET_SIGNATURE_DOCUMENT:
      return {
        ...state,
        signatureDocument: action.signature,
      };
    case documentCreationConstants.CLEAR_DOCUMENT_CREATOR_STORE:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}

export default documentCreatorStore;
