const selectAllFolders = (state) => state.documentsStore.folderTree || [];
const selectOrdinaryFolders = (state) => {
  const { currentFolderId, folderTree } = state.documentsStore;
  if (!folderTree) return [];
  return folderTree.filter(
    (folder) => folder.id !== currentFolderId && !folder.isTrash && !folder.isRoot
  );
};

const selectTrash = (state) => state.documentsStore?.folderTree.find((folder) => folder.isTrash);

export const DocumentsSelector = {
  selectAllFolders,
  selectOrdinaryFolders,
  selectTrash,
};
