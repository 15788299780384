import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import WebSocketHelper from 'src/helpers/WebSocketHelper';
import snackbarActions from 'src/redux/actions/snackbar.actions';
import { SNACKBAR_VARIANT } from 'src/models/common.model';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const DocumentCreatorWebsocket = () => {
  const ws = useRef(null);
  const dispatch = useDispatch();
  const { currentIdentity } = useSelector((state) => state.identityStore, undefined);
  const workspaceId = currentIdentity?.workspace?.workspaceId;
  const history = useHistory();
  const { t } = useTranslation();

  const onWebSocketMessage = (message) => {
    if (message.type === 'RESULT') {
      switch (message.code) {
        case 'DOCUMENT_CREATED':
          if (message.data.result?.documentType !== 'dsl') {
            history.push(`/document/${message.data.result.id}`);
          }
          if (message.data.result.folderName !== '/') {
            localStorage.setItem('createdDocumentFolderId', message.data.result.folderId);
          } else {
            localStorage.setItem('createdDocumentFolderId', 'root');
          }
          break;
        default:
          console.warn(`Unknown code [${message.code}]`, message);
          break;
      }
    } else if (message.type === 'ERROR') {
      const resultCode = message?.data?.result?.code;
      const messagePart = resultCode ? `_${resultCode}` : '';
      dispatch(
        snackbarActions.enqueueSnackbar(
          SNACKBAR_VARIANT.ERROR,
          t('snackbars.common.errorTitle'),
          t(`drivePage.action.error.${message.code}${messagePart}`)
        )
      );
    } else {
      console.warn(`Unknown action type [${message.type}]`, message);
    }
  };

  useEffect(() => {
    if (workspaceId) {
      ws.current = WebSocketHelper.subscribe(
        'document-indexing-service',
        `workspaces/${workspaceId}`,
        (message) => {
          onWebSocketMessage(message);
        }
      );
    }

    return () => {
      WebSocketHelper.unsubscribe(ws.current);
    };
  }, [workspaceId]); // eslint-disable-line react-hooks/exhaustive-deps

  return null;
};

export default DocumentCreatorWebsocket;
