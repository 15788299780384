import { fade } from '@material-ui/core/styles';
import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  ({ breakpoints, palette, spacing }) =>
    createStyles({
      root: {
        width: '100%',
        clear: 'both',
      },
      content: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100%',

        marginLeft: 'auto',
        marginRight: 'auto',
        width: '100%',
      },
      mobileTitle: {
        paddingBottom: spacing(2),
        marginBottom: spacing(1),
        position: 'sticky',
        top: 0,
        left: 0,
        zIndex: 1,
        backgroundColor: palette.background.paper,
      },
      buttons: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        flex: 1,
        maxWidth: breakpoints.values.sm,
        margin: '0 auto',
        '& > *:not(:last-child)': {
          marginBottom: spacing(1),
        },
      },
      actionButton: {
        maxWidth: breakpoints.values.xs,
        margin: '0 auto',
      },
      alertButton: {
        marginTop: spacing(1),
        color: palette.error.contrastText,
        textDecoration: 'none !important',
        border: `1px solid ${fade(palette.error.contrastText, 0.5)}`,
        '&:hover': {
          border: `1px solid ${palette.error.contrastText}`,
          backgroundColor: fade(palette.common.black, palette.action.hoverOpacity),
          '@media (hover: none)': {
            backgroundColor: 'transparent',
          },
        },
      },
    }),
  { index: 0 }
);
