import transitions from '@material-ui/core/styles/transitions';
import { lighten } from '@material-ui/core/styles';
import palette from '../palette';
import typography from '../typography';
import spacing from '../spacing';
import shadows from '../shadows';

export default {
  root: {
    borderRadius: spacing(0.75),
    fontSize: typography.button.fontSize,
    minWidth: 'auto',
    padding: '11px 20px',
    textTransform: typography.button.textTransform,
    lineHeight: '1.2',
    fontWeight: typography.fontWeightMedium,
    transition: transitions.create(
      ['background-color', 'box-shadow', 'border', 'color', 'opacity'],
      {
        duration: transitions.duration.short,
      }
    ),
    '&:hover': {
      boxShadow: shadows[15],
    },
    '&$focusVisible': {
      boxShadow: shadows[6],
    },
    '&$disabled': {
      boxShadow: 'none',
    },
  },
  text: {
    padding: '11px 24px',
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&:focus, &:active': {
      color: palette.primary.main,
    },
  },
  textPrimary: {
    color: palette.secondary.dark,
    '&:hover': {
      backgroundColor: 'transparent',
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
    '&:not($disabled) $startIcon': {
      color: palette.primary.main,
    },
    '&:not($disabled) $endIcon': {
      color: palette.primary.main,
    },
  },
  contained: {
    backgroundColor: palette.action.selected,
    boxShadow: 'none',
    '&:hover': {
      boxShadow: shadows[15],
    },
    '&$focusVisible': {
      boxShadow: shadows[6],
    },
    '&$disabled': {
      backgroundColor: palette.action.disabledBackground,
      color: palette.action.disabled,
    },
  },
  containedPrimary: {
    '&:hover': {
      backgroundColor: lighten(palette.primary.main, 0.075),
    },
    '&:focus, &:active': {
      backgroundColor: palette.primary.dark,
    },
    '&$disabled': {
      backgroundColor: palette.action.disabledBackground,
      color: palette.action.disabled,
    },
  },
  containedSecondary: {
    color: palette.primary.main,
    backgroundColor: lighten(palette.primary.main, 0.925),
    border: `1px solid ${lighten(palette.primary.main, 0.925)}`,
    padding: '10px 19px',
    '&:hover': {
      color: palette.primary.contrastText,
      backgroundColor: lighten(palette.primary.main, 0.075),
      border: `1px solid ${lighten(palette.primary.main, 0.075)}`,
    },
    '&:focus, &:active': {
      color: palette.primary.main,
      backgroundColor: lighten(palette.primary.main, 0.925),
      border: `1px solid ${palette.primary.main}`,
    },
    '&$disabled': {
      backgroundColor: palette.action.disabledBackground,
      color: palette.action.disabled,
    },
  },
  sizeSmall: {
    padding: '9px 16px',
    fontSize: 12,
    minWidth: 'auto',
    '&$containedSecondary': {
      padding: '8px 15px',
    },
  },
  sizeLarge: {
    padding: '15px 24px',
    fontSize: '1.1rem',
    minWidth: 'auto',
    '&$containedSecondary': {
      padding: '14px 23px',
    },
  },
  startIcon: {
    marginRight: spacing(1),
    marginTop: -1,
    marginBottom: -1,
    marginLeft: -2,
    '&$iconSizeSmall': {
      marginLeft: 0,
    },
  },
  endIcon: {
    display: 'inherit',
    marginTop: -1,
    marginBottom: -1,
    marginRight: -2,
    marginLeft: spacing(1),
    '&$iconSizeSmall': {
      marginRight: 0,
    },
  },
  iconSizeSmall: {
    '& > *:first-child': {
      fontSize: 16,
    },
  },
  iconSizeMedium: {
    '& > *:first-child': {
      fontSize: 18,
    },
  },
  iconSizeLarge: {
    '& > *:first-child': {
      fontSize: 20,
    },
  },
};
