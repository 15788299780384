import { useEffect, useRef, useState } from 'react';
import { debounceTime, from } from 'rxjs';
import documentDSLServices from 'src/redux/services/documentDSL.service';
import { DocumentRole } from 'src/models/document.model';
import ContactHelper from '../../helpers/contactHelper';

export const getOrderSigningRestrictions = ({
  signers,
  isOrderSignificant,
  isSigningOrderAvailable,
  activeIdentity,
}) => {
  const userToSign = signers.find((member) => member.status === 'AWAITING_SIGNATURE');
  // if document has disabled order or workspace has disabled order then enable to sign
  if (isOrderSignificant === false || isSigningOrderAvailable === false) {
    return {
      canSignDueToOrder: true,
      userToSign: '',
    };
  }
  // if there is no any waitng user or no contacts signers or activity not loaded
  if (Boolean(userToSign) === false || Boolean(activeIdentity) === false)
    return {
      canSignDueToOrder: true,
      userToSign: '',
    };
  // check if first awaiting index is same as current user
  const canSignDueToOrder =
    signers.findIndex((member) => member.status === 'AWAITING_SIGNATURE') ===
    signers.findIndex((member) => member.id === activeIdentity.identity.identityId);

  // get contact identity name of user who should sign now
  return {
    canSignDueToOrder,
    userToSign: userToSign.userData.name || '',
  };
};

export const sortingMembers = (members) =>
  members
    .sort((a, b) => {
      if (a.userData?.email < b.userData?.email) return 1;
      if (a.userData?.email > b.userData?.email) return -1;
      return 0;
    })
    .sort((a, b) => {
      if (a.role < b.role) return -1;
      if (a.role > b.role) return 1;
      return 0;
    })
    .sort((a, b) => {
      if (a.position < b.position) return -1;
      if (a.position > b.position) return 1;
      return 0;
    });

export const prepareDocumentMembers = ({ documentMembers, contactsNotFound }) =>
  documentMembers.map((member) => {
    const matchedIdentity = ContactHelper.getEntityData(member.id);

    if (!matchedIdentity) {
      if (!ContactHelper.checkIfIsInProgress(member.id)) {
        contactsNotFound.push(member.id);
      }
    } else {
      return {
        ...member,
        position: member.role === DocumentRole.SIGNERS ? 1 : 3,
        userData: matchedIdentity.isGroup
          ? {
              name: matchedIdentity.name,
              email: matchedIdentity.name,
              active: matchedIdentity.active,
              group: true,
            }
          : {
              name: matchedIdentity.name,
              email: matchedIdentity.email,
              active: matchedIdentity.active,
              group: false,
            },
      };
    }
    return { ...member, userData: { name: member.id, email: member.id } };
  });

export const useTransactions = (documentId, listener = null, isCertificate = false) => {
  const subRef = useRef(null);
  const [state, setState] = useState({
    isLoading: false,
    allowedTransactions: [],
  });

  const refresh = async () => {
    if (!state.isLoading) {
      const response = await documentDSLServices.checkAvailableMutations(documentId);

      subRef.current?.next(response);
    }
  };

  useEffect(() => {
    if (documentId && isCertificate) {
      setState({
        isLoading: true,
        allowedTransactions: [],
      });
      subRef.current = from(documentDSLServices.checkAvailableMutations(documentId))
        .pipe(debounceTime(1000))
        .subscribe({
          next: (response) => {
            setState({
              isLoading: false,
              allowedTransactions: response.data.data.mutationCollection,
            });
          },
          error: (error) => {
            console.log('error -', error);
            setState({
              isLoading: false,
              allowedTransactions: [],
            });
          },
        });
    }

    return () => {
      subRef.current?.unsubscribe();
    };
  }, [documentId, listener, isCertificate]);

  return {
    isLoading: state.isLoading,
    allowedTransactions: state.allowedTransactions,
    refresh,
  };
};
