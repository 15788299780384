import axios from 'src/helpers/axios';
import { parseError } from 'src/helpers/parseErrors';

export const moduleName = 'billing-service';

const billingService = {
  estimateSubscriptionPrice,
  checkAccessToWorkspace,
  initializeCustomerCard,
  subscribePlan,
  unsubscribePlan,
  reactiveSubscribePlan,
  getInvoiceUserData,
  addPromotionCode,
};

function checkAccessToWorkspace(workspaceId) {
  return axios
    .get(`/rest/${moduleName}/workspaces/${workspaceId}/check-access`, {
      withPermissions: ['BILLING_SERVICE_USER_GET_ACCESS'],
    })
    .then((response) => response.data)
    .catch((error) => parseError(error));
}

function estimateSubscriptionPrice(workspaceId, discountCode) {
  return axios
    .get(
      `/rest/${moduleName}/workspaces/${workspaceId}/price${
        discountCode ? `?code=${discountCode}` : ''
      }`,
      {
        withPermissions: ['BILLING_SERVICE_USER_GET_PRICE'],
      }
    )
    .then((response) => response.data)
    .catch((error) => parseError(error));
}

function initializeCustomerCard(workspaceId) {
  return axios
    .post(`/rest/${moduleName}/workspaces/${workspaceId}/card-wallet`, null, {
      withPermissions: ['BILLING_SERVICE_USER_POST_SETUP_INTENT'],
    })
    .then((response) => response.data)
    .catch((error) => parseError(error));
}

function subscribePlan(workspaceId, subscriptionId, taxId, discountCode) {
  const subscribeData = {};

  if (subscriptionId) {
    subscribeData.paymentMethodId = subscriptionId;
    if (taxId?.taxIdType && taxId?.value) {
      subscribeData.taxId = taxId;
    }
    if (discountCode) {
      subscribeData.promoCode = discountCode;
    }
  }

  return axios
    .post(`/rest/${moduleName}/workspaces/${workspaceId}/subscribe`, subscribeData, {
      withPermissions: ['BILLING_SERVICE_USER_POST_SUBSCRIBE'],
    })
    .then((response) => response.data)
    .catch((error) => parseError(error));
}

function unsubscribePlan(workspaceId) {
  return axios
    .delete(`/rest/${moduleName}/workspaces/${workspaceId}/unsubscribe`, {
      withPermissions: ['BILLING_SERVICE_USER_DELETE_UNSUBSCRIBE'],
    })
    .then((response) => response.data)
    .catch((error) => parseError(error));
}

function reactiveSubscribePlan(workspaceId) {
  return axios
    .put(`/rest/${moduleName}/workspaces/${workspaceId}/reactive`, {
      withPermissions: ['BILLING_SERVICE_USER_PUT_REACTIVE_SUBSCRIPTION'],
    })
    .then((response) => response.data)
    .catch((error) => parseError(error));
}

function getInvoiceUserData(workspaceId) {
  return axios
    .get(`/rest/${moduleName}/workspaces/${workspaceId}/upcoming-invoice`, {
      withPermissions: ['BILLING_SERVICE_USER_GET_INVOICE'],
    })
    .then((response) => response.data)
    .catch((error) => parseError(error));
}

function addPromotionCode(workspaceId, discountCode) {
  return axios
    .post(`/rest/${moduleName}/workspaces/${workspaceId}/promotion?code=${discountCode}`, null, {
      withPermissions: ['BILLING_SERVICE_USER_POST_PROMOTION'],
    })
    .then((response) => response.data)
    .catch((error) => parseError(error));
}

export default billingService;
