import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import WebSocketHelper from 'src/helpers/WebSocketHelper';
import certificateCreatorActions from 'src/redux/actions/certificateCreator.actions';
import { CertificateSteps } from 'src/models/certificate.model';

const CertificateCreatorWebsocket = () => {
  const ws = useRef(null);
  const wsCreate = useRef(null);
  const dispatch = useDispatch();
  const { currentIdentity } = useSelector((state) => state.identityStore, undefined);

  const { generatedCertFileList, createdCertificates } = useSelector(
    (state) => state.certificateCreatorStore,
    undefined
  );

  const workspaceId = currentIdentity?.workspace?.workspaceId;

  const onWebSocketMessage = (message) => {
    if (message.action === 'CREATE') {
      dispatch(certificateCreatorActions.addCreatedCertificates(message.documentId));
    }

    switch (message.eventType) {
      case 'CERTIFICATE_FILE_CREATED': {
        dispatch(certificateCreatorActions.updateNewCertificateFileCreatedList(message));
        break;
      }
      case 'CERTIFICATE_ADDITIONAL_FILES_CREATED': {
        dispatch(certificateCreatorActions.updateNewCertificateFileCreatedList(message));
        break;
      }
      default: {
        console.warn(`Unknown code [${message.code}]`, message);
      }
    }
  };

  useEffect(() => {
    if (workspaceId) {
      ws.current = WebSocketHelper.subscribe(
        'document-dsl-service',
        `workspaces/${workspaceId}/certificate-files`,
        (message) => {
          onWebSocketMessage(message);
        }
      );
      wsCreate.current = WebSocketHelper.subscribe(
        'document-dsl-service',
        `workspaces/${workspaceId}/document-updates`,
        (message) => {
          onWebSocketMessage(message);
        }
      );
    }

    return () => {
      WebSocketHelper.unsubscribe(ws.current);
      WebSocketHelper.unsubscribe(wsCreate.current);
    };
  }, [workspaceId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      createdCertificates.length > 0 &&
      createdCertificates.length ===
        generatedCertFileList.filter((file) => file.eventType === 'CERTIFICATE_FILE_CREATED').length
    ) {
      dispatch(
        certificateCreatorActions.setCreatorStep({
          currentStep: CertificateSteps.InvitePeople,
        })
      );
    }
  }, [createdCertificates, generatedCertFileList, dispatch]);

  return null;
};

export default CertificateCreatorWebsocket;
