import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Collapse, Drawer } from '@material-ui/core';
import { useStyles } from './styles';

const RightSidebar = ({ children, signBanner, tabs, fullHeightContent }) => {
  const [contentScrollable, setContentScrollable] = useState(true);
  const classes = useStyles();
  const onCollapseToggle = {
    onStart: () => {
      setContentScrollable(false);
    },
    onEnd: () => {
      setContentScrollable(true);
    },
  };

  return (
    <Drawer
      className={classes.drawer}
      variant="permanent"
      classes={{
        paper: clsx(
          classes.drawerPaper,
          'scrollElementDrawer',
          !contentScrollable && classes.drawerPaperNotScrollable
        ),
      }}
      PaperProps={{ id: 'scroll-element-sidebar' }}
      anchor="right"
    >
      <div className={classes.contentWrapper}>
        <Collapse
          in={!fullHeightContent}
          unmountOnExit
          onEnter={onCollapseToggle.onStart}
          onEntered={onCollapseToggle.onEnd}
          onExit={onCollapseToggle.onStart}
          onExited={onCollapseToggle.onEnd}
        >
          <div className={clsx(classes.content, classes.contentTop)}>
            {signBanner}
            {tabs}
          </div>
        </Collapse>

        {children && (
          <div className={clsx(classes.content, classes.contentBottom, classes.childrenWrapper)}>
            {children}
          </div>
        )}
      </div>
    </Drawer>
  );
};

RightSidebar.propTypes = {
  children: PropTypes.node,
  signBanner: PropTypes.node,
  tabs: PropTypes.node,
  fullHeightContent: PropTypes.bool,
};

RightSidebar.defaultProps = {
  fullHeightContent: false,
};

export default RightSidebar;
