import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  ({ palette, spacing, typography }) =>
    createStyles({
      root: {},
      ol: {
        display: 'flex',
        flexWrap: 'nowrap',
        alignItems: 'center',
        padding: 0,
        margin: 0,
        listStyle: 'none',
        '& svg': {
          fontSize: typography.htmlFontSize,
        },
      },
      li: {
        height: 20,
        display: 'flex',
        borderRadius: 2,
        padding: `0 ${spacing(0.5)}px`,
        fontSize: typography.subtitle2.fontSize,
        '& a, & p': {
          display: 'flex',
          alignItems: 'center',
        },
        '& p': {
          fontSize: 'inherit',
          fontWeight: 'inherit',
        },
        '& a': {
          color: palette.text.secondary,
          '&:hover, &:focus': {
            color: palette.text.primary,
          },
        },
        '& svg': {
          marginRight: spacing(0.5),
        },
        '&:not($lastLi)': {
          textDecoration: 'underline',
        },
      },
      firstLi: {
        marginLeft: spacing(-0.5),
      },
      lastLi: {
        fontWeight: typography.fontWeightBold,
        marginRight: spacing(-0.5),
        cursor: 'default',
      },
      menuItem: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: 0,
      },
      separator: {
        display: 'flex',
        userSelect: 'none',
        marginLeft: spacing(0.25),
        marginRight: spacing(0.25),
      },
      dropBackground: {
        backgroundColor: palette.primary.lightest,
        borderRadius: spacing(0.5),
      },
    }),
  { index: 0 }
);

export const useStylesCollapsed = makeStyles(
  ({ palette }) =>
    createStyles({
      button: {
        height: 20,
        display: 'flex',
        color: palette.text.secondary,
        borderRadius: 2,
        '&:hover, &:focus, &:active': {
          '& $iconChevron': {
            color: palette.primary.main,
          },
        },
      },
      active: {
        color: palette.primary.main,
      },
      icon: {
        width: 24,
        height: 14,
        marginLeft: -2,
        marginRight: -2,
      },
      iconChevron: {
        marginLeft: -2,
        marginRight: 2,
      },
    }),
  { index: 0 }
);
