import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useStyles } from './styles';
import { FileColor as FileIcon } from '../../CustomIcon';
import { IconCell } from '../cells';

const ItemsTemplate = ({ fields }) => {
  const classes = useStyles();
  const rows = fields.map((field, index) => (
    <div key={`${field}_${index}`} className={clsx(classes.dragItem)}>
      <IconCell icon={<FileIcon />} className={clsx(classes.dragItemCell)} />
      {field}
    </div>
  ));
  return <div>{rows}</div>;
};
ItemsTemplate.propTypes = {
  fields: PropTypes.arrayOf(PropTypes.string),
};

export default ItemsTemplate;
