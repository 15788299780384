/* eslint-disable import/no-cycle */
import axios from 'src/helpers/axios';
import { parseError } from 'src/helpers/parseErrors';

const authService = {
  getUserProfile,
  getApiKeys,
  getPermissions,
  createNewApiKey,
  deleteApiKey,
  patchUserName,
  patchPassword,
  patchEmail,
  postEmail,
  patchPhoneNumber,
  postPhoneNumber,
  validatePermissions,
  deleteToken,
  patchUserLanguage,
  deleteUserAccount,
};

function getUserProfile() {
  return axios
    .get('rest/auth/profile')
    .then((response) => response.data)
    .catch((error) => parseError(error));
}

function getPermissions() {
  return axios
    .get('rest/auth/profile/permissions')
    .then((response) => response.data)
    .catch((error) => parseError(error));
}

function getApiKeys() {
  const queryObj = {
    page: 0,
    limit: 20,
  };
  const query = encodeURIComponent(JSON.stringify(queryObj));
  return axios
    .get(`rest/auth/api-keys?query=${query}`)
    .then((response) => response.data)
    .catch((error) => parseError(error));
}

function deleteApiKey(publicKey) {
  return axios
    .delete(`rest/auth/api-keys/${publicKey}`, {
      withPermissions: ['AUTH_USER_DELETE_API_KEYS'],
      withTwoFactor: true,
    })
    .then((response) => response.data)
    .catch((error) => parseError(error));
}

function createNewApiKey({ permissions, name }) {
  return axios
    .post(
      '/rest/auth/api-keys',
      { permissions, name },
      { withPermissions: ['AUTH_USER_POST_API_KEYS'], withTwoFactor: true }
    )
    .then((response) => response)
    .catch((error) => parseError(error));
}

function patchUserName(updatedUserName) {
  return axios
    .patch(
      'rest/auth/profile/user-name',
      { userName: updatedUserName },
      { withPermissions: ['AUTH_USER_PATCH_USER_NAME'], withTwoFactor: true }
    )
    .then((response) => response)
    .catch((error) => parseError(error));
}

function patchPassword(oldPassword, newPassword) {
  return axios
    .patch(
      'rest/auth/profile/password',
      { oldPassword, newPassword },
      { withPermissions: ['AUTH_USER_PATCH_PASSWORD'], withTwoFactor: true }
    )
    .then((response) => response)
    .catch((error) => parseError(error));
}

function patchEmail(updatedEmail) {
  return axios
    .patch(
      'rest/auth/profile/email',
      { email: updatedEmail },
      { withPermissions: ['AUTH_USER_PATCH_EMAIL'], withTwoFactor: true }
    )
    .then((response) => response)
    .catch((error) => parseError(error));
}

function postEmail(confirmationCode) {
  return axios
    .post('/rest/auth/profile/email/confirm', null, {
      withPermissions: ['AUTH_USER_POST_EMAIL_CONFIRM'],
      headers: { 'Two-Factor-Code': confirmationCode },
    })
    .then((response) => response)
    .catch((error) => parseError(error));
}

function patchPhoneNumber(updatedPhone) {
  return axios
    .patch(
      'rest/auth/profile/phone',
      { ...updatedPhone },
      { withPermissions: ['AUTH_USER_PATCH_PHONE'], withTwoFactor: true }
    )
    .then((response) => response)
    .catch((error) => parseError(error));
}

function postPhoneNumber(confirmationCode) {
  return axios
    .post('/rest/auth/profile/phone/confirm', null, {
      withPermissions: ['AUTH_USER_POST_PHONE_CONFIRM'],
      headers: { 'Two-Factor-Code': confirmationCode },
    })
    .then((response) => response)
    .catch((error) => parseError(error));
}

function validatePermissions(permissions = []) {
  return axios
    .post(`/rest/auth/token/validation`, { permissions })
    .then((response) => response)
    .catch((error) => parseError(error));
}

function deleteToken() {
  return axios
    .delete('/rest/auth/token', null, {
      withPermissions: ['AUTH_USER_DELETE_TOKEN'],
    })
    .then((response) => response)
    .catch((error) => parseError(error));
}

function patchUserLanguage(newLanguage) {
  return axios
    .patch(
      `/rest/auth/profile/language`,
      { language: newLanguage.toUpperCase() },
      {
        withPermissions: ['AUTH_USER_PATCH_LANGUAGE'],
      }
    )
    .then((response) => response)
    .catch((error) => parseError(error));
}

function deleteUserAccount() {
  return axios
    .delete('rest/auth/profile', {
      withPermissions: ['AUTH_USER_DELETE_PROFILE'],
      withTwoFactor: true,
    })
    .then((response) => response)
    .catch((error) => parseError(error));
}

export default authService;
