import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { Button, Card, CircularProgress, Collapse, Typography } from '@material-ui/core';
import { AvatarAndTextItem, ExtendedGrid } from 'src/components';
import { RequestStatus } from 'src/helpers/reduxReuquest.util';
import { useStyles } from './styles';
import { DocumentStatus } from '../../../../models/document.model';

const SignBanner = ({
  className: classNameProp,
  display,
  user: { id: userId, name: userName, workspaceName: userWorkspaceName },
  title,
  afterText,
  rejectionButton,
  onSignClick,
  signText,
  disabled = false,
  canSign = true,
}) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const { document: documentInfo } = useSelector((state) => state.documentDisplayStore, undefined);
  const documentSigningStatus = useSelector(
    (state) => state.documentManagementStore.documentSigningStatus
  );
  const isRejectLoading = documentInfo.status === DocumentStatus.EDITING && loading;
  const displayUser = userName || userWorkspaceName;
  const displayAction = typeof onSignClick === 'function' && signText && canSign;

  useEffect(() => {
    if (documentSigningStatus === RequestStatus.ERROR) {
      setLoading(false);
    }
    if (documentSigningStatus !== RequestStatus.IDLE) {
      setLoading(true);
    }
  }, [documentSigningStatus]);

  return (
    <Collapse in={display}>
      <Card className={clsx(classes.root, classNameProp)} variant="outlined">
        {title && canSign && <Typography variant="h4">{title}</Typography>}

        <ExtendedGrid container spacing={2} alignItemsXxs="center" wrapXxs="nowrap" wrapMd="wrap">
          {displayUser && (
            <ExtendedGrid
              item
              xxs={displayAction ? true : 12}
              md={12}
              className={clsx(displayAction && classes.user)}
            >
              <AvatarAndTextItem
                text={userName}
                description={userWorkspaceName}
                mainImageSrc={
                  userId
                    ? `${process.env.REACT_APP_AVATAR_URL}/identity/${userId}?kind=SMALL`
                    : null
                }
              />
            </ExtendedGrid>
          )}
          {displayAction && (
            <ExtendedGrid item xxs={displayUser ? 'auto' : 12} md={12}>
              <Button
                size="large"
                fullWidth
                disabled={!display || loading || disabled}
                className={classes.button}
                onClick={onSignClick}
                endIcon={loading && <CircularProgress size={20} color="inherit" />}
              >
                {signText}
              </Button>
            </ExtendedGrid>
          )}
          {rejectionButton && (
            <ExtendedGrid item xxs={displayUser ? 'auto' : 12} md={12}>
              {rejectionButton(isRejectLoading)}
            </ExtendedGrid>
          )}
          {afterText}
        </ExtendedGrid>
      </Card>
    </Collapse>
  );
};

SignBanner.propTypes = {
  className: PropTypes.string,
  display: PropTypes.bool,
  user: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    workspaceName: PropTypes.string,
  }).isRequired,
  title: PropTypes.string,
  afterText: PropTypes.bool,
  rejectionButton: PropTypes.node,
  onSignClick: PropTypes.func,
  signText: PropTypes.string,
  disabled: PropTypes.bool,
  canSign: PropTypes.bool,
};

SignBanner.defaultProps = {
  display: true,
};

export default SignBanner;
