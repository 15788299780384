import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  ({ breakpoints, spacing }) =>
    createStyles({
      root: {},
      content: {
        [breakpoints.down('sm')]: {
          flex: 1,
        },
      },
      items: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        minHeight: '100%',
        maxWidth: breakpoints.values.sm,
        margin: '0 auto',
        '& > *:not(:last-child)': {
          marginBottom: spacing(1),
        },
      },
      actionButton: {
        display: 'block',
        maxWidth: breakpoints.values.xs,
        margin: '0 auto',
      },
    }),
  { index: 0 }
);
