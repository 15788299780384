import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Typography } from '@material-ui/core';
import { useStyles } from './styles';

const HeaderUserDrawerBox = ({ className: classNameProp, children, title, titleVariant }) => {
  const classes = useStyles();
  const hasChildren = !!children;

  return (
    <div className={clsx(classes.root, classNameProp && classNameProp)}>
      {title && (
        <Typography
          variant={titleVariant}
          gutterBottom={hasChildren}
          classes={{
            root: classes.title,
            gutterBottom: clsx(hasChildren && classes.titleWithSpace),
          }}
        >
          <strong>{title}</strong>
        </Typography>
      )}

      {children}
    </div>
  );
};

HeaderUserDrawerBox.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  title: PropTypes.string,
  titleVariant: PropTypes.oneOf([
    'body1',
    'body2',
    'button',
    'caption',
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'inherit',
    'overline',
    'subtitle1',
    'subtitle2',
  ]),
};

HeaderUserDrawerBox.defaultProps = {
  titleVariant: 'h6',
};

export default HeaderUserDrawerBox;
