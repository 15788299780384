import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { TextField, useTheme } from '@material-ui/core';
import MuiOutlinedInput from '../../theme/options/overrides/MuiOutlinedInput';
import { StripeInput } from '../index';
import { useStyles } from './styles';

const StripeInputElement = ({
  className: classNameProp,
  component,
  label,
  name,
  stripeOptions,
  ...other
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const [errorMessage, setErrorMessage] = useState(null);

  function handleElementChange({ error }) {
    if (error) {
      setErrorMessage(error.message);
    } else {
      setErrorMessage(null);
    }
  }

  const hasError = errorMessage !== null;

  const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        iconColor: theme.palette.text.disabled,
        color: theme.palette.text.primary,
        fontWeight: MuiOutlinedInput.input.fontWeight,
        fontSize: theme.typography.htmlFontSize,
        fontFamily: theme.typography.fontFamily,
        ':-webkit-autofill': {
          color: theme.palette.text.primary,
        },
        '::placeholder': {
          fontWeight: theme.typography.fontWeightRegular,
          color: theme.palette.secondary.main,
        },
      },
      invalid: {
        iconColor: theme.palette.error.light,
        color: theme.palette.error.main,
      },
    },
  };

  return (
    <TextField
      fullWidth
      label={label}
      name={name}
      error={hasError}
      helperText={hasError ? errorMessage || 'Invalid' : ''}
      onChange={handleElementChange}
      InputLabelProps={{
        shrink: true,
      }}
      InputProps={{
        inputProps: {
          component,
          options: { ...stripeOptions, ...CARD_ELEMENT_OPTIONS },
        },
        inputComponent: StripeInput,
        classes: { input: classes.input, disabled: classes.disabled },
      }}
      classes={{ root: clsx(classes.root, classNameProp) }}
      {...other}
    />
  );
};

StripeInputElement.propTypes = {
  className: PropTypes.string,
  component: PropTypes.elementType,
  label: PropTypes.string,
  name: PropTypes.string,
  stripeOptions: PropTypes.shape({}),
};

export default StripeInputElement;
